import { ReactElement, useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { QuestionnaireVersionsContext } from '../Questionnaire/ContextProvider/QuestionnaireVersionsContextProvider';
import { QuestionnaireVersionFilterContext } from '../../../Context/QuestionnaireVersionFilterContextProvider';
import { EditorDropdown } from '../Questionnaire/QuestionnaireEditor/Components/EditorDropdown';

type Props = {
  onChange?: (qvid: string | null) => void;
};

export function QuestionnaireVersionFilter(props: Props): ReactElement {
  const { questionnaireVersions } = useContext(QuestionnaireVersionsContext);
  const { questionnaireVersionId, setQuestionnaireVersion } = useContext(QuestionnaireVersionFilterContext);
  const { t } = useTranslation();

  const onChange = (qvid: string | null): void => {
    setQuestionnaireVersion(qvid || '');
    props?.onChange?.(qvid);
  };

  const qvFilterOptions = questionnaireVersions.map((qv) => ({
    label: `${qv.majorVersion}.${qv.minorVersion}`,
    value: qv.id,
  }));

  return (
    <EditorDropdown
      label={t('admin.entityMappings.questionnaireVersionDropdownLabel')}
      selectedOptionValue={questionnaireVersionId}
      options={qvFilterOptions}
      onChange={onChange}
    />
  );
}
