import _ from 'lodash';
import { Fragment, ReactElement, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import {
  Box,
  Divider,
  ExpandMoreIcon,
  ExpansionPanel,
  ExpansionPanelActions,
  ExpansionPanelDetails,
  ExpansionPanelSummary,
} from '@breathelife/mui';
import { Language, PublicLink, PublicLinkMetadata } from '@breathelife/types';
import { Input } from '@breathelife/ui-components';

import { Button } from '../../../Components/Button/Button';
import { Checkbox } from '../../../Components/Checkbox/Checkbox';
import { Image } from '../../../Components/Images/Image';
import Typography from '../../../Components/Typography';
import { CloudinaryUpload } from '../../../Components/Upload/Cloudinary/CloudinaryUpload';
import { StyledGrid } from './Styles';

type Props = {
  enabledLanguages: Language[];
  publicLink: PublicLink;
  isUpdating: boolean;
  onSaveClick: (publicLinkData: PublicLink) => void;
};

export function PublicLinkView({
  enabledLanguages,
  publicLink: publicLinkProp,
  isUpdating,
  onSaveClick,
}: Props): ReactElement {
  const { t } = useTranslation();
  const [isPublicLinkEnabled, setPublicLinkEnabled] = useState<boolean>(Boolean(publicLinkProp.enabled));
  const [publicLinkMetadata, setPublicLinkMetadata] = useState<PublicLinkMetadata>(publicLinkProp?.metadata ?? {});

  useEffect(() => {
    setPublicLinkEnabled(publicLinkProp.enabled);
    setPublicLinkMetadata(publicLinkProp.metadata ?? {});
  }, [publicLinkProp]);

  const handleChange = (target: string, value: string): void => {
    const updatedPublicLinkMetadata = _.setWith(_.cloneDeep(publicLinkMetadata), target, value, _.cloneDeep);
    setPublicLinkMetadata(updatedPublicLinkMetadata);
  };

  const parseImageUrl = (url: string | undefined): string => {
    if (typeof url === 'undefined' || !/cloudinary/.test(url)) return '';
    return url;
  };

  const productLine = 'life';

  return (
    <ExpansionPanel defaultExpanded>
      <ExpansionPanelSummary expandIcon={<ExpandMoreIcon />} aria-controls='publicLink-content' id='publicLink-header'>
        <Box display='flex' flexDirection='row' mr={2}>
          <Typography variant='h2'>{t('admin.settingsManagement.publicLink.title')}</Typography>
        </Box>
      </ExpansionPanelSummary>
      <Divider />
      <ExpansionPanelDetails>
        <Box display='flex' flexDirection='column' width='100%' m={2}>
          <StyledGrid item xs={3} sm={3}>
            <Checkbox
              color='primary'
              label={t('admin.settingsManagement.publicLink.enablePublicLink')}
              checked={isPublicLinkEnabled}
              onChange={() => setPublicLinkEnabled(!isPublicLinkEnabled)}
            />
          </StyledGrid>
          {isPublicLinkEnabled && publicLinkMetadata ? (
            <Box my={4} key={productLine}>
              {enabledLanguages.map((language) => (
                <Fragment key={language}>
                  <Box m={2}>
                    <Input
                      fullWidth
                      name={`${productLine}.title.${language}`}
                      id={`${productLine}-title-${language}`}
                      label={t('admin.settingsManagement.publicLink.titleLabel', {
                        language: t(`language.${language}`),
                      })}
                      inputVariant='outlined'
                      value={publicLinkMetadata[productLine]?.title?.[language]}
                      onChange={(event) => handleChange(event.target.name, event.target.value)}
                    />
                  </Box>
                  <Box m={2}>
                    <Input
                      fullWidth
                      name={`${productLine}.description.${language}`}
                      id={`${productLine}-description-${language}`}
                      label={t('admin.settingsManagement.publicLink.descriptionLabel', {
                        language: t(`language.${language}`),
                      })}
                      inputVariant='outlined'
                      value={publicLinkMetadata[productLine]?.description?.[language]}
                      onChange={(event) => handleChange(event.target.name, event.target.value)}
                    />
                  </Box>
                  <Box m={2}>
                    <Typography variant='body5'>
                      {t('admin.settingsManagement.publicLink.imageLabel', {
                        language: t(`language.${language}`),
                      })}
                    </Typography>
                    <Image
                      src={parseImageUrl(publicLinkMetadata[productLine]?.imageUrl?.[language])}
                      radius={0}
                      isProfile={false}
                      width={300}
                    />
                    <CloudinaryUpload
                      buttonText={t('admin.settingsManagement.publicLink.imageButtonText', {
                        language: t(`language.${language}`),
                      })}
                      setPicture={(logo) => handleChange(`${productLine}.imageUrl.${language}`, logo)}
                      uploadPreset='px_admin_public_link'
                      minWidth={1200}
                      minHeight={630}
                    />
                  </Box>
                </Fragment>
              ))}
            </Box>
          ) : null}
        </Box>
      </ExpansionPanelDetails>
      <Divider />
      <ExpansionPanelActions>
        <Button
          data-testid='publicLinkSettings-save'
          color='primary'
          variant='text'
          isLoading={isUpdating}
          onClick={() =>
            onSaveClick({
              enabled: isPublicLinkEnabled,
              metadata: publicLinkMetadata,
            })
          }
        >
          {t('cta.save').toUpperCase()}
        </Button>
      </ExpansionPanelActions>
    </ExpansionPanel>
  );
}
