import { LeadDetailTab } from '@breathelife/types';
import _ from 'lodash';
import { ReactElement, Fragment, useEffect, useState } from 'react';

import { TabPanel } from '../../../../Components/Tabs/Tabs';
import { useCarrierContext } from '../../../../Hooks';
import { DetailViewType } from '../../../../Models/Layout';
import { Lead } from '../../../../Models/Lead';
import { LeadDetailTabs } from '../Tabs/LeadDetailTabs';
import { TabsContainer } from '../Tabs/Styles';
import { ApplicationsViewContainer } from './ApplicationsView/ApplicationsViewContainer';
import { HistoryView } from './HistoryView/HistoryView';
import { NeedsAnalysisView } from './NeedsAnalysisView/NeedsAnalysisView';
import { NotesView } from './NotesView/NotesView';
import { PersonalInfoView } from './PersonalInfoView/PersonalInfoView';
import { ProductsViewContainer } from './ProductsView/ProductsViewContainer';

type Props = {
  type: DetailViewType;
  lead?: Lead;
  onSendEmailClick: (lead: Lead) => void;
  onClose: () => void;
};

export function LeadDetailBody(props: Props): ReactElement | null {
  const { enabledTabs } = useCarrierContext();
  // Don't select anything until lead detail is ready
  const [selectedTabIndex, setSelectedTabIndex] = useState<number>();
  const { lead, onClose, onSendEmailClick, type: detailViewType } = props;

  useEffect(() => {
    if (lead && !_.isNumber(selectedTabIndex)) {
      setSelectedTabIndex(0);
    }
  }, [lead, selectedTabIndex]);

  useEffect(() => {
    if (detailViewType === DetailViewType.create) {
      setSelectedTabIndex(0);
    }
  }, [detailViewType]);

  const personalInfoTab = enabledTabs[LeadDetailTab.personalInfo];
  const needsAnalysisTab = enabledTabs[LeadDetailTab.needsAnalysis];
  const productsTab = enabledTabs[LeadDetailTab.products];
  const notesTab = enabledTabs[LeadDetailTab.notes];
  const historyTab = enabledTabs[LeadDetailTab.history];
  const applicationsTab = enabledTabs[LeadDetailTab.applications];

  return (
    <Fragment>
      <TabsContainer px={3} borderBottom={1}>
        <LeadDetailTabs
          type={detailViewType}
          selectedTab={selectedTabIndex}
          // eslint-disable-next-line @typescript-eslint/naming-convention
          onSelectedTabChange={(_event: any, value: number) => setSelectedTabIndex(value)}
          disabled={_.isNil(selectedTabIndex)}
        />
      </TabsContainer>

      {personalInfoTab && (
        <TabPanel selectedIndex={selectedTabIndex} tabIndex={personalInfoTab.index}>
          <PersonalInfoView lead={lead} detailViewType={detailViewType} onClose={onClose} />
        </TabPanel>
      )}

      {needsAnalysisTab && (
        <TabPanel selectedIndex={selectedTabIndex} tabIndex={needsAnalysisTab.index}>
          <NeedsAnalysisView lead={lead} onSendEmailClick={onSendEmailClick} tabProps={needsAnalysisTab} />
        </TabPanel>
      )}

      {productsTab && (
        <TabPanel selectedIndex={selectedTabIndex} tabIndex={productsTab.index}>
          <ProductsViewContainer
            lead={lead}
            setSelectedTabIndex={setSelectedTabIndex}
            needsAnalysisTabIndex={needsAnalysisTab?.index}
            onSendNeedsAnalysis={onSendEmailClick}
          />
        </TabPanel>
      )}

      {applicationsTab && (
        <TabPanel selectedIndex={selectedTabIndex} tabIndex={applicationsTab.index}>
          <ApplicationsViewContainer lead={lead} />
        </TabPanel>
      )}

      {notesTab && (
        <TabPanel selectedIndex={selectedTabIndex} tabIndex={notesTab.index}>
          <NotesView lead={lead} />
        </TabPanel>
      )}

      {historyTab && (
        <TabPanel selectedIndex={selectedTabIndex} tabIndex={historyTab.index}>
          <HistoryView lead={lead} />
        </TabPanel>
      )}
    </Fragment>
  );
}
