import { Box } from '@breathelife/mui';
import { ReactElement, Fragment } from 'react';
import { useTranslation } from 'react-i18next';
import styled from '../../../../Styles/themed-styled-components';

import { Button } from '../../../../Components/Button/Button';
import { CloseButton } from '../../../../Components/Button/CloseButton';
import Typography from '../../../../Components/Typography';
import { DetailViewType } from '../../../../Models/Layout';

const Container = styled(Box)`
  background-color: ${(props) => props.theme.colors.grey[20]};
  width: 100%;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  padding: 8px 10px;
  margin-top: 8px;
`;

type Props = {
  onDrawerClose: () => void;
  onClickSave: () => void;
  isFormValid: boolean;
  onDelete: () => void;
  ruleCanBeDeleted: boolean;
  viewType: DetailViewType;
};

export function SalesDecisionRuleDetailHeader({
  onDrawerClose,
  onClickSave,
  isFormValid,
  onDelete,
  ruleCanBeDeleted,
  viewType,
}: Props): ReactElement {
  const { t } = useTranslation();

  return (
    <Fragment>
      <Box display='flex' justifyContent='space-between' p={3}>
        <Typography variant='h2'>
          {viewType === DetailViewType.create
            ? t('admin.salesDecisionRulesManagement.createNewSalesDecisionRule')
            : t('admin.salesDecisionRulesManagement.editSalesDecisionRule')}{' '}
        </Typography>
        <CloseButton data-testid='create-sales-decision-rule-close' onClick={onDrawerClose} />
      </Box>
      <Container display='flex' flexDirection='row'>
        <Box mr='auto'>
          {ruleCanBeDeleted && (
            <Button data-testid='sales-decision-rule-delete' color='primary' variant='outlined' onClick={onDelete}>
              {t('cta.delete')}
            </Button>
          )}
        </Box>
        <Box mr={2}>
          <Button color='primary' variant='outlined' onClick={onDrawerClose}>
            {t('cta.cancel')}
          </Button>
        </Box>
        <Button
          data-testid='ProductDetailView-save'
          color='primary'
          variant='contained'
          onClick={onClickSave}
          disabled={!isFormValid}
        >
          {t('cta.save')}
        </Button>
      </Container>
    </Fragment>
  );
}
