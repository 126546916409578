import { ReactElement, createContext, PropsWithChildren, useContext } from 'react';

import { PartIdentifierTag, SectionBlueprint, SectionGroupKey } from '@breathelife/types';

import { useFetchQuestionnaireVersionQuery } from '../../../../ReactQuery/Admin/Questionnaire/questionnaireVersion.queries';
import { QuestionnaireVersionDetailWithNodeIdInfo } from '../../../../Services/QuestionnaireVersionService';
import { QuestionnaireEditorContext } from './QuestionnaireEditorContextProvider';

type QuestionnaireVersionDataContextValue = {
  questionnaireVersionId?: string;
  questionnaireVersionData?: QuestionnaireVersionDetailWithNodeIdInfo;
  isLoading: boolean;
};

export const QuestionnaireVersionDataContext = createContext<QuestionnaireVersionDataContextValue>({
  isLoading: false,
});

export function useSectionBlueprintsInSectionGroup(sectionGroupKey: SectionGroupKey): SectionBlueprint[] {
  const { questionnaireVersionData } = useContext(QuestionnaireVersionDataContext);
  return (
    questionnaireVersionData?.blueprint.sectionBlueprints?.filter(
      (sectionBlueprint) => sectionBlueprint.sectionGroupKey === sectionGroupKey,
    ) || []
  );
}

export function useContractSectionBlueprints(): SectionBlueprint[] {
  return useSectionBlueprintsInSectionGroup('contract');
}

export function useInsuredPeopleSectionBlueprints(): SectionBlueprint[] {
  return useSectionBlueprintsInSectionGroup('insuredPeople');
}

export function useQuestionnaireVersionDetailWithNodeIdInfo(): QuestionnaireVersionDetailWithNodeIdInfo | undefined {
  const { questionnaireVersionData } = useContext(QuestionnaireVersionDataContext);
  return questionnaireVersionData;
}

export function QuestionnaireVersionDataContextProvider({
  questionnaireVersionId,
  children,
}: PropsWithChildren<{ questionnaireVersionId: string }>): ReactElement {
  const questionnaireEditorContextData = useContext(QuestionnaireEditorContext);

  const { data: questionnaireVersionData, isLoading } = useFetchQuestionnaireVersionQuery(questionnaireVersionId, {
    onSuccess: ({ blueprint }) => {
      if (!questionnaireEditorContextData.selectedPartIdentifier) {
        questionnaireEditorContextData?.onSelectedPartIdentifierChange({
          tag: PartIdentifierTag.section,
          sectionGroupPartName: 'insuredPeople',
          sectionPartName: blueprint.sectionBlueprints[0]?.partName ?? '',
        });
      }
    },
  });

  return (
    <QuestionnaireVersionDataContext.Provider
      value={{
        isLoading,
        questionnaireVersionId,
        questionnaireVersionData,
      }}
    >
      {children}
    </QuestionnaireVersionDataContext.Provider>
  );
}
