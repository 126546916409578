import { ReactElement, useCallback, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { Grid } from '@breathelife/mui';
import { ConsiderationBlueprint } from '@breathelife/types';
import { Input } from '@breathelife/ui-components';

type Props = {
  value?: string;
  onChange: (data: Partial<ConsiderationBlueprint>) => void;
};

export function ReasonInput({ value, onChange }: Props): ReactElement {
  const { t } = useTranslation();
  const [fieldValue, setFieldValue] = useState(value ?? '');

  const onBlur = useCallback(() => {
    onChange({ reason: fieldValue });
  }, [onChange, fieldValue]);

  return (
    <Grid item xs={12}>
      <Input
        data-testid='sales-decision-rule-reason'
        value={fieldValue}
        onChange={(event) => {
          setFieldValue(event.target.value);
        }}
        onBlur={onBlur}
        label={t('admin.salesDecisionRulesManagement.labels.reason')}
        inputVariant='outlined'
      />
    </Grid>
  );
}
