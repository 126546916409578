import { Box } from '@breathelife/mui';
import { ReactElement, useCallback, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { SubmitButton } from '../../../../../Components/Button/SubmitButton';
import Typography from '../../../../../Components/Typography';
import { ModalLayout } from '../../../../../Layouts/Modal/ModalLayout';
import ApiService from '../../../../../Services/ApiService';
import { useQueryClient } from 'react-query';
import { QueryId } from '../../../../../ReactQuery/common/common.types';

type Initial = { tag: 'Initial'; loading: false };
type SubmissionInProgress = { tag: 'SubmissionInProgress'; loading: true };
type SubmissionError = { tag: 'SubmissionError'; loading: false };
type SubmissionDone = { tag: 'SubmissionDone'; loading: false; zipFile: Buffer };

type State = Initial | SubmissionInProgress | SubmissionError | SubmissionDone;

function downloadZipFile(applicationId: string, refNo: string, buffer: Buffer): void {
  const dataBlob = new Blob([buffer], { type: 'application/zip' });
  const dataBlobURL =
    window.URL && window.URL.createObjectURL
      ? window.URL.createObjectURL(dataBlob)
      : window.webkitURL.createObjectURL(dataBlob);
  const tempLink = document.createElement('a');
  tempLink.style.display = 'none';
  tempLink.href = dataBlobURL;
  tempLink.setAttribute('download', `${refNo}_submission_${applicationId}.zip`);
  document.body.appendChild(tempLink);
  tempLink.click();
  tempLink.parentNode && tempLink.parentNode.removeChild(tempLink);
}

async function manuallySubmit(
  applicationId: string,
  refNo: string,
  state: State,
  setState: (state: State) => void,
  close: () => void,
): Promise<void> {
  if (state.tag !== 'Initial') {
    return;
  }

  setState({ tag: 'SubmissionInProgress', loading: true });

  try {
    const buffer = await ApiService.manuallySubmitApplication(applicationId);
    if (buffer) {
      setState({ tag: 'SubmissionDone', loading: false, zipFile: buffer });
      downloadZipFile(applicationId, refNo, buffer);
      close();
    } else {
      setState({ tag: 'SubmissionError', loading: false });
    }
  } catch {
    setState({ tag: 'SubmissionError', loading: false });
  }
}

type Props = {
  applicationId: string;
  refNo: string;
  isOpen: boolean;
  closeModal: () => void;
};
export function ManualSubmissionModal(props: Props): ReactElement {
  const { t } = useTranslation();

  // Get QueryClient from the context
  const queryClient = useQueryClient();

  const onClose = useCallback(() => {
    void queryClient.invalidateQueries([QueryId.applicationSupportApplications, props.applicationId]);
    props.closeModal();
  }, [props, queryClient]);

  const [state, setState] = useState<State>({ tag: 'Initial', loading: false });

  return (
    <ModalLayout
      maxWidth='sm'
      title={t('modals.manualSubmission.title')}
      isOpen={props.isOpen}
      closeModal={() => {
        setState({ tag: 'Initial', loading: false });
        props.closeModal();
      }}
      submitButton={
        state.tag !== 'SubmissionError' ? (
          <SubmitButton
            isLoading={state.loading}
            disabled={state.loading}
            onClick={() => {
              void manuallySubmit(props.applicationId, props.refNo, state, setState, onClose);
            }}
          >
            {t('modals.manualSubmission.submitButton')}
          </SubmitButton>
        ) : (
          <SubmitButton
            isLoading={false}
            disabled={false}
            onClick={() => {
              setState({ tag: 'Initial', loading: false });
              props.closeModal();
            }}
          >
            {t('modals.manualSubmission.closeButton')}
          </SubmitButton>
        )
      }
    >
      <Box mb={3}>
        <Typography variant='body1'>{t('modals.manualSubmission.explanation')}</Typography>
      </Box>
      {state.tag === 'SubmissionError' && (
        <Box>
          <Typography color='red' variant='body1'>
            {t('modals.manualSubmission.error')}
          </Typography>
        </Box>
      )}
    </ModalLayout>
  );
}
