import _ from 'lodash';
import { Fragment, ReactElement, useCallback, useContext, useEffect, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

import { A11yMessage } from '@breathelife/ui-components';
import { Box, SortDirection } from '@breathelife/mui';

import LoadingView from '../../../Components/LoadingView/LoadingView';
import { Pagination } from '../../../Components/Pagination/Pagination';
import { Table } from '../../../Components/Table/Table';
import { getFileIcon } from '../../../Helpers/getFileIcon';
import { useCarrierContext, useDispatch, useModalState, useNavigation, useSelector } from '../../../Hooks';
import { Application } from '../../../Models/Application';
import { LeadTab, ModalState, ModalType } from '../../../Models/Layout';
import { Lead } from '../../../Models/Lead';
import { DeleteLeadModal } from '../../../Pages/Home/Modals/DeleteLeadModal';
import { DownloadFilesModal } from '../../../Pages/Home/Modals/DownloadFilesModal';
import { LeadArchiveConfirmationModal } from '../../../Pages/Home/Modals/LeadArchiveConfirmationModal';
import { SendEmailModal } from '../../../Pages/Home/Modals/SendEmailModal';
import { LeadsAssignModalContainer } from '../../../Pages/Home/Modals/UserListModal/LeadsAssignModalContainer';
import { LeadsListFilterModalContainer } from '../../../Pages/Home/Modals/UserListModal/LeadsListFilterModalContainer';
import { LeadsPageDataContext, PER_PAGE_OPTIONS } from '../../../Pages/Leads/LeadsPageDataContextProvider';
import { defaultState as defaultLayoutState, layoutSlice, ModalPayload } from '../../../ReduxStore/Layout/LayoutSlice';
import LeadDetailContainer from '../LeadDetailView/LeadDetailContainer';
import { CopyApplicationConfirmationModal } from '../Modals/CopyApplicationConfirmationModal';
import emptyListViewLookup from './EmptyListView';
import { LeadMoreActionButton } from './LeadMoreActionButton';
import { ListHeader } from './ListHeader';
import { MaterialTableContainer } from './Styles';
import { ScreenName, TypewriterTracking } from '@breathelife/frontend-tracking';
import { DataExtractType } from '@breathelife/types';
import { useUpdateLeadArchiveMutation } from '../../../ReactQuery/Lead/lead.mutations';
import { useCreateDataExtractMutation } from '../../../ReactQuery/Admin/ExportData/exportData.mutations';
import { hash } from '@breathelife/hash';
import { DataExtractConsentModal } from '../../Leads/Modals/DataExtractConsentModal';
import { generateLeadDetailUrl, generateLeadsListUrl } from '../../../Navigation/Urls';

export function LeadsListView(): ReactElement {
  const { externalResources } = useCarrierContext();
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { leadTab: navigationLeadsTab } = useNavigation();
  const navigate = useNavigate();

  const modalState = useSelector((store) => store.leadPlatform.layout.modalState);
  const [isOpenDataExtractConsentModal, onOpenDataExtractConsentModal, onCloseDataExtractConsentModal] =
    useModalState();

  const {
    renderedTableColumnsInfo,
    tableOptions,
    leadsQueryIsLoading,
    leadsQueryIsSuccess,
    leads,
    totalNumberOfLeads,
    setFilters,
    setPage,
    setPerPage,
    setSorting,
  } = useContext(LeadsPageDataContext);

  const { page: currentPage, filters: filterParams, perPage } = tableOptions;
  const selectedTab = navigationLeadsTab || LeadTab.active;

  //eslint-disable-next-line react-hooks/exhaustive-deps
  const EmptyListView = useMemo(() => emptyListViewLookup(selectedTab), [leads]);

  const setModalState = useCallback(
    (payload: ModalPayload) => dispatch(layoutSlice.actions.setModalState(payload)),
    [dispatch],
  );

  const updateModalState = useCallback(
    (payload: Partial<ModalState>) => dispatch(layoutSlice.actions.updateModalState(payload)),
    [dispatch],
  );

  const onTableRowClick = useCallback(
    (id: string) => {
      const selectedLead = leads.find((lead) => {
        return lead.id.toString() === id;
      });
      if (!selectedLead || !selectedLead?.id) return;
      navigate(generateLeadDetailUrl(selectedLead.id, navigationLeadsTab));

      TypewriterTracking.viewedScreen({
        screenName: ScreenName.leadProfile,
        hashedId: selectedLead?.applicationId ? hash(selectedLead?.applicationId) : null,
      });
    },
    [leads, navigationLeadsTab, navigate],
  );

  useEffect(() => {
    setFilters({ signatureStatus: undefined, status: undefined });
  }, [setFilters]);

  const onCloseModal = useCallback(() => {
    // We don't reset `type` here so that the exit transitions can be rendered properly
    updateModalState(_.omit(defaultLayoutState.modalState, 'type'));
  }, [updateModalState]);

  const hasLeads = leads.length > 0;

  const downloadableFiles = externalResources?.downloadableFiles || [];
  const filesToDownload = downloadableFiles.map((file) => ({
    ...file,
    icon: getFileIcon(file.type),
  }));

  const onOrderChange = useCallback(
    (columnId, sortDirection: SortDirection) => {
      if (!sortDirection) {
        return;
      }
      setSorting(columnId, sortDirection);
    },
    [setSorting],
  );

  // If there are no leads on a page (except for the first page), go to the previous page
  // after deleting/archiving a lead, when it was the only lead of the page in other cases fist page should be set
  useEffect(() => {
    if (leadsQueryIsSuccess && leads.length === 0) {
      const page = tableOptions.page > 1 ? tableOptions.page - 1 : 1;
      setPage(page);
    }
  }, [leadsQueryIsSuccess, leads?.length, tableOptions.page]);

  // When navigation leads tab is changed, table page should be set to first page
  useEffect(() => {
    if (!_.isUndefined(navigationLeadsTab)) {
      setPage(1);
    }
  }, [navigationLeadsTab]);

  // When filter or sorting are applied, the table page should be set to the first page
  useEffect(() => {
    if (tableOptions?.sort?.columnName && tableOptions?.sort?.direction) {
      setPage(1);
    }
  }, [tableOptions?.sort?.columnName, tableOptions?.sort?.direction]);

  const onSearchChange = useCallback((search: string) => setFilters({ $search: search }), [setFilters]);

  const openAssignModal = useCallback(
    (lead: Lead) => {
      dispatch(layoutSlice.actions.setModalState({ modalState: { lead, isOpen: true, type: ModalType.assign } }));
    },
    [dispatch],
  );

  const onSendEmailClick = useCallback(
    (lead: Lead) => {
      setModalState({ modalState: { lead, isOpen: true, type: ModalType.email } });
      TypewriterTracking.viewedScreen({
        screenName: ScreenName.leadNeedsAnalysisEmail,
        // TODO: discuss whether we should keep this. If So, what do we do now with multiple application per lead
        hashedId: lead.applicationId ? hash(lead.applicationId) : null,
      });
    },
    [setModalState],
  );

  const onCopyClick = (application: Application): void => {
    setModalState({ modalState: { application, isOpen: true, type: ModalType.copyApplication } });
  };

  const onDeleteClick = useCallback(
    (lead: Lead) => {
      setModalState({ modalState: { lead, isOpen: true, type: ModalType.deleteLead } });
      TypewriterTracking.viewedScreen({
        screenName: ScreenName.leadDelete,
        // TODO: discuss whether we should keep this. If So, what do we do now with multiple application per lead
        hashedId: lead.applicationId ? hash(lead.applicationId) : null,
      });
    },
    [setModalState],
  );

  const onDownloadFilesClick = useCallback(() => {
    setModalState({ modalState: { isOpen: true, type: ModalType.downloadFiles } });
  }, [setModalState]);

  const createDataExtractMutation = useCreateDataExtractMutation();

  const updateLeadArchiveMutation = useUpdateLeadArchiveMutation({
    onSuccess: () => {
      navigate(generateLeadsListUrl(navigationLeadsTab));
      setModalState({ modalState: defaultLayoutState.modalState });
    },
  });

  const onToggleArchiveClick = useCallback(
    (lead: Lead, userId: string) => {
      if (lead.assignedToId === userId) {
        updateLeadArchiveMutation.mutate({ leadId: lead.id, archive: !lead.archived });
      } else {
        setModalState({ modalState: { lead, isOpen: true, type: ModalType.archiveConfirmation } });
      }
    },
    [setModalState, updateLeadArchiveMutation],
  );

  const onConfirmDataExtractConsentModal = useCallback(() => {
    onCloseDataExtractConsentModal();

    createDataExtractMutation.mutate({
      type: DataExtractType.marketingDataExtract,
    });
  }, [onCloseDataExtractConsentModal, createDataExtractMutation]);

  useEffect(() => {
    if (_.isUndefined(navigationLeadsTab)) return;

    const trackingScreenName = {
      [LeadTab.active]: ScreenName.leadsListActive,
      [LeadTab.archived]: ScreenName.leadsListArchived,
    };

    TypewriterTracking.viewedScreen({
      screenName: trackingScreenName[navigationLeadsTab],
      hashedId: null,
    });

    setFilters({ archived: navigationLeadsTab === LeadTab.archived });
  }, [navigationLeadsTab, setFilters]);

  return (
    <Fragment>
      <ListHeader
        onSearchChange={onSearchChange}
        onFiltersChanged={setFilters}
        onClickMarketingDataExtractButton={onOpenDataExtractConsentModal}
        setModalState={setModalState}
        selectedUserId={filterParams.selectedUserId}
        searchTerm={filterParams.$search}
      />

      {leadsQueryIsLoading && <LoadingView />}

      {hasLeads && renderedTableColumnsInfo && (
        <MaterialTableContainer data-testid={`lead-table-${LeadTab[selectedTab]}`} role='region'>
          <Table<Lead>
            data={leads}
            onRowClick={onTableRowClick}
            onOrderChange={onOrderChange}
            initialState={{
              sortBy: [
                ...(tableOptions.sort
                  ? [{ id: tableOptions.sort.columnName, desc: tableOptions.sort.direction === 'desc' }]
                  : []),
              ],
            }}
            columns={[
              ...renderedTableColumnsInfo,
              {
                id: 'actions',
                Header: 'Actions',
                defaultCanSort: false,
                disableSortBy: true,
                accessor: (lead: Lead) => lead,
                Cell: ({ value }: { value: Lead }) => (
                  <LeadMoreActionButton
                    lead={value}
                    onCopyClick={onCopyClick}
                    onDeleteClick={onDeleteClick}
                    onSendEmailClick={onSendEmailClick}
                    onAssignClick={openAssignModal}
                    onToggleArchiveClick={onToggleArchiveClick}
                  />
                ),
              },
            ]}
          />

          <Box position='relative' px={2.5}>
            <Pagination
              total={totalNumberOfLeads}
              page={currentPage}
              perPage={perPage}
              perPageOptions={PER_PAGE_OPTIONS}
              onPageChange={setPage}
              onPerPageChange={setPerPage}
            />
          </Box>
        </MaterialTableContainer>
      )}

      {!hasLeads && leadsQueryIsSuccess && (
        <EmptyListView hasFilters={_.values(filterParams).filter((value) => value !== undefined).length > 0} />
      )}

      <A11yMessage
        message={totalNumberOfLeads + ' ' + t(`pagination.results.${totalNumberOfLeads === 1 ? 'singular' : 'plural'}`)}
      />

      <LeadDetailContainer
        onSendEmailClick={(lead: Lead) => onSendEmailClick(lead)}
        onToggleArchiveClick={onToggleArchiveClick}
        onCopyClick={onCopyClick}
        onDeleteClick={onDeleteClick}
        onDownloadFilesClick={onDownloadFilesClick}
      />
      {modalState.type === ModalType.email && modalState.lead && (
        <SendEmailModal isOpen={modalState.isOpen} lead={modalState.lead} closeModal={onCloseModal} />
      )}
      {modalState.type === ModalType.copyApplication && modalState.application && (
        <CopyApplicationConfirmationModal
          isOpen={modalState.isOpen}
          application={modalState.application}
          closeModal={onCloseModal}
        />
      )}
      {modalState.type === ModalType.deleteLead && modalState.lead && (
        <DeleteLeadModal isOpen={modalState.isOpen} lead={modalState.lead} closeModal={onCloseModal} />
      )}
      {modalState.type === ModalType.assign && modalState.lead && (
        <LeadsAssignModalContainer
          isOpen={modalState.isOpen}
          lead={modalState.lead}
          closeModal={onCloseModal}
          submitLabel={t('cta.assign')}
        />
      )}
      {modalState.type === ModalType.leadsListFilter && (
        <LeadsListFilterModalContainer
          isOpen={modalState.isOpen}
          onModalSubmit={(selectedUserId) => {
            setModalState({ modalState: defaultLayoutState.modalState });
            setFilters({ selectedUserId });
          }}
          closeModal={onCloseModal}
          submitLabel={t('modals.leadsListFilters.submit')}
          defaultSelectedUserId={filterParams.selectedUserId}
        />
      )}
      {modalState.type === ModalType.archiveConfirmation && modalState.lead && (
        <LeadArchiveConfirmationModal isOpen={modalState.isOpen} lead={modalState.lead} closeModal={onCloseModal} />
      )}
      {modalState.type === ModalType.downloadFiles && (
        <DownloadFilesModal isOpen={modalState.isOpen} files={filesToDownload || []} closeModal={onCloseModal} />
      )}
      <DataExtractConsentModal
        isOpen={isOpenDataExtractConsentModal}
        onClose={onCloseDataExtractConsentModal}
        onConfirm={onConfirmDataExtractConsentModal}
      />
    </Fragment>
  );
}
