import { Fragment, ReactElement, useEffect } from 'react';
import { useTranslation } from 'react-i18next';

import { Box, Divider, Skeleton } from '@breathelife/mui';
import { CarrierNames, Localizable, PublicLink } from '@breathelife/types';

import { ExportButton } from '../../../Components/ExportData/ExportButton';
import { ExportDataProvider } from '../../../Components/ExportData/ExportDataProvider';
import { ImportButton } from '../../../Components/ExportData/ImportButton';
import { ImportDataProvider } from '../../../Components/ExportData/ImportDataProvider';
import { ImportModal } from '../../../Components/ExportData/ImportModal';
import { HelmetContainer } from '../../../Components/HelmetContainer';
import Typography from '../../../Components/Typography';
import { useDispatch, useSelector } from '../../../Hooks';
import * as SettingsOperations from '../../../ReduxStore/Admin/SettingsManagement/SettingsOperations';
import { CarrierNameEditView } from './CarrierNameViewContainer';
import { ConsumerFooterView } from './ConsumerFooterViewContainer';
import { LanguagesEditView, LanguageSettings } from './LanguagesViewContainer';
import { PublicLinkView } from './PublicLinkViewContainer';

export function SettingsViewContainer(): ReactElement {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const {
    settings,
    isFetching,
    isUpdatingLanguages,
    isUpdatingPublicLink,
    isUpdatingCarrierName,
    isUpdatingConsumerFooter,
  } = useSelector((store) => store.leadPlatform.settings);

  useEffect(() => {
    void dispatch(SettingsOperations.fetchAdminSettings());
  }, [dispatch]);

  return (
    <Fragment>
      <HelmetContainer text={t('pageTitles.settings')} />
      <Box display='flex' flexDirection={'column'} flexGrow={1}>
        <Box m={2} display='flex' justifyContent={'space-between'}>
          <Box>
            <Typography variant='h1'>{t('admin.settingsManagement.title')}</Typography>
            <Typography variant='body1'>{t('admin.settingsManagement.subtitle')}</Typography>
          </Box>
          <Box display='flex'>
            <Box mr={1}>
              <ExportDataProvider settings>
                <ExportButton />
              </ExportDataProvider>
            </Box>
            <ImportDataProvider settings>
              <ImportButton />
              <ImportModal title={t('admin.importData.settings')} />
            </ImportDataProvider>
          </Box>
        </Box>
        <Divider />
        <Box m={2} display='flex' flexDirection={'column'}>
          {(isFetching || !settings) && <Skeleton variant='rect' height={50} />}
          {!isFetching && settings && (
            <Fragment>
              <LanguagesEditView
                enabledLanguages={settings.data.enabledLanguages}
                defaultLanguage={settings.data.defaultLanguage}
                isUpdating={isUpdatingLanguages}
                onSaveClick={({ enabledLanguages, defaultLanguage }: LanguageSettings) =>
                  dispatch(
                    SettingsOperations.updateSettings(settings, {
                      ...settings,
                      data: { ...settings.data, enabledLanguages, defaultLanguage },
                    }),
                  )
                }
              />
              <CarrierNameEditView
                carrierNames={settings.data.carrierNames}
                enabledLanguages={settings.data.enabledLanguages}
                isUpdating={isUpdatingCarrierName}
                onSaveClick={(carrierNameData: CarrierNames) =>
                  dispatch(
                    SettingsOperations.updateSettings(settings, {
                      ...settings,
                      data: { ...settings.data, carrierNames: carrierNameData },
                    }),
                  )
                }
              />
              <PublicLinkView
                enabledLanguages={settings.data.enabledLanguages}
                publicLink={settings.data.publicLink}
                isUpdating={isUpdatingPublicLink}
                onSaveClick={(publicLinkData: PublicLink) =>
                  dispatch(
                    SettingsOperations.updateSettings(settings, {
                      ...settings,
                      data: { ...settings.data, publicLink: publicLinkData },
                    }),
                  )
                }
              />
              <ConsumerFooterView
                consumerFooter={settings.data.consumerFooter}
                enabledLanguages={settings.data.enabledLanguages}
                isUpdating={isUpdatingConsumerFooter}
                onSaveClick={(consumerFooterData: Localizable) =>
                  dispatch(
                    SettingsOperations.updateSettings(settings, {
                      ...settings,
                      data: { ...settings.data, consumerFooter: consumerFooterData },
                    }),
                  )
                }
              />
            </Fragment>
          )}
        </Box>
      </Box>
    </Fragment>
  );
}
