import { ReactElement, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useTheme } from '../../Styles/themed-styled-components';

import { logger } from '@breathelife/monitoring-frontend';
import { ApplicationMode } from '@breathelife/types';

import { LeadPlatformTheme } from '../../Styles/Types';
import { StyledBadge } from './Badge';

export type Props = {
  mode: ApplicationMode;
};

export function ApplicationModeBadge(props: Props): ReactElement | null {
  const theme = useTheme() as LeadPlatformTheme;
  const { t } = useTranslation();

  const ApplicationModeTextColors: {
    [mode in ApplicationMode]: { textColor: string; backgroundColor: string };
  } = useMemo(
    () => ({
      digital: { textColor: theme.colors.green[50], backgroundColor: theme.colors.green[20] },
      paper: { textColor: theme.colors.purple[50], backgroundColor: theme.colors.purple[20] },
    }),
    [theme],
  );

  if (!ApplicationModeTextColors[props.mode]) {
    logger.error(`Unknown application mode: ${props.mode}`);
    return null;
  }

  const modeLabel = t(`applicationModes.${props.mode}`);
  const statusColors = ApplicationModeTextColors[props.mode];

  return (
    <StyledBadge backgroundColor={statusColors.backgroundColor} textColor={statusColors.textColor} label={modeLabel} />
  );
}
