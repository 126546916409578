import { SerializedNodeIdToAnswerPathMap } from '@breathelife/questionnaire-engine';
import {
  BlueprintModification,
  PageQueryOptions,
  QuestionnaireBlueprint,
  QuestionnaireVersionDetail,
  QuestionnaireVersionInfo,
  QuestionnaireVersionInfoPage,
  QuestionnaireVersionRowData,
} from '@breathelife/types';

import {
  generateNodeIdInCollectionMap,
  generateQuestionnaireNodeIdsFromBlueprint,
  NodeIdInCollections,
  QuestionnaireNodeIds,
} from '../Helpers/questionnaireEditor/questionnaireNodeIds';
import ApiService from './ApiService';

export type QuestionnaireVersionDetailWithNodeIdInfo = QuestionnaireVersionDetail & {
  nodeIdToAnswerPath?: SerializedNodeIdToAnswerPathMap;
  nodeIdInCollectionMap?: NodeIdInCollections;
  questionnaireNodeIds?: QuestionnaireNodeIds;
};

export async function fetchQuestionnaireVersionAndNodeIdData(
  questionnaireVersionId: string,
): Promise<QuestionnaireVersionDetailWithNodeIdInfo> {
  const { data: questionnaireVersion } = await ApiService.admin.fetchQuestionnaireVersion(questionnaireVersionId);

  const nodeIdToAnswerPath = questionnaireVersion.dataStructure as SerializedNodeIdToAnswerPathMap;

  const questionnaireNodeIds = generateQuestionnaireNodeIdsFromBlueprint(
    questionnaireVersion.blueprint,
    nodeIdToAnswerPath,
  );

  const nodeIdInCollectionMap = generateNodeIdInCollectionMap(questionnaireNodeIds);

  return {
    ...questionnaireVersion,
    questionnaireNodeIds,
    nodeIdInCollectionMap,
    nodeIdToAnswerPath,
  };
}

export async function patchQuestionnaireVersionBlueprint(
  questionnaireVersionId: string,
  modification: BlueprintModification,
): Promise<QuestionnaireBlueprint> {
  const { data } = await ApiService.admin.patchQuestionnaireVersion(questionnaireVersionId, modification);
  return data.blueprint;
}

export async function fetchQuestionnaireVersions(
  questionnaireId: string,
  fetchOptions: PageQueryOptions<QuestionnaireVersionRowData>,
): Promise<QuestionnaireVersionInfoPage> {
  const axiosResponse = await ApiService.admin.fetchQuestionnaireVersions(questionnaireId, fetchOptions);
  return axiosResponse.data;
}

export async function fetchAllQuestionnaireVersions(questionnaireId: string): Promise<QuestionnaireVersionInfo[]> {
  return ApiService.admin.fetchAllQuestionnaireVersions(questionnaireId);
}

export async function publishQuestionnaire(questionnaireVersionId: string, description: string): Promise<void> {
  return ApiService.admin.publishQuestionnaireVersion(questionnaireVersionId, description);
}

export async function createNewDraftQuestionnaireVersion(
  questionnaireId: string,
  description: string,
): Promise<string> {
  const response = await ApiService.admin.newDraftQuestionnaireVersion({
    questionnaireId,
    description,
  });
  return response.data;
}
