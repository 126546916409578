import { CloseIcon, IconButton } from '@breathelife/mui';

import styled, { css } from '../../../../Styles/themed-styled-components';

export const PROPOSED_INSURED_TAB_BAR_HEIGHT_PX = 48;

export const Tab = styled.div<{ isActive: boolean }>`
  height: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 14px 16px;

  // TODO validate hover states, currently matching LCP
  ${(props) =>
    props.isActive
      ? css`
          background-color: ${(props) => props.theme.colors.grey[0]};
          color: ${(props) => props.theme.colors.grey[90]};
          box-shadow:
            inset 0px 1px 0px ${(props) => props.theme.colors.grey[30]},
            inset 0px -2px 0px ${(props) => props.theme.colors.primary.default},
            inset -1px 0px 0px ${(props) => props.theme.colors.grey[30]};
        `
      : css`
          cursor: pointer;
          background-color: ${(props) => props.theme.colors.grey[10]};
          color: ${(props) => props.theme.colors.grey[70]};
          box-shadow:
            inset 0px 1px 0px ${(props) => props.theme.colors.grey[30]},
            inset 0px -1px 0px ${(props) => props.theme.colors.grey[30]},
            inset -1px 0px 0px ${(props) => props.theme.colors.grey[30]};
        `}
`;

// TODO limit size of individual tabs based on how many tabs have been added
export const TabBar = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
  min-height: ${PROPOSED_INSURED_TAB_BAR_HEIGHT_PX}px;
  white-space: nowrap;
  background-color: ${(props) => props.theme.colors.grey[10]};
  box-shadow:
    inset 0px -1px 0px ${(props) => props.theme.colors.grey[30]},
    inset 0px 1px 0px ${(props) => props.theme.colors.grey[30]};
  overflow-x: auto;
`;

export const RemoveButton = styled(IconButton)`
  && {
    border: 1px solid transparent;
    padding: 3px;

    :hover {
      background-color: ${(props) => props.theme.colors.grey[10]};
      border-color: ${(props) => props.theme.colors.grey[10]};
    }
    :active {
      background-color: ${(props) => props.theme.colors.grey[30]};
      border-color: ${(props) => props.theme.colors.grey[30]};
    }
    :focus:not(:active) {
      outline: 0;
      border-color: ${(props) => props.theme.colors.tertiary.default};
    }
  }

  &&& {
    margin-left: 16px;
    padding: 0px;
  }
`;

export const RemoveIcon = styled(CloseIcon)`
  &&& {
    color: ${(props) => props.theme.colors.grey[60]};
    height: 16px;
    width: 16px;
  }
`;

export const AddInsuredButton = styled(IconButton)`
  &&& {
    background-color: ${(props) => props.theme.colors.grey[0]};
    border: 1px solid;
    border-color: ${(props) => props.theme.colors.grey[50]};
    height: 30px;
    margin: 0 14px;
    width: 30px;

    &:hover {
      border-color: ${(props) => props.theme.colors.primary.default};
    }
    &:active {
      background-color: ${(props) => props.theme.colors.grey[20]};
      border-color: ${(props) => props.theme.colors.primary.default};
    }
    &:focus:not(:active) {
      border-color: ${(props) => props.theme.colors.tertiary.default};
    }
  }
`;
