import { UseQueryOptions, UseQueryResult, useQuery } from 'react-query';
import { useTranslation } from 'react-i18next';

import { logger } from '@breathelife/monitoring-frontend';
import { Theme } from '@breathelife/types';

import { QueryId } from '../common/common.types';
import { useDispatch } from '../../Hooks';
import { notificationSlice } from '../../ReduxStore/Notification/NotificationSlice';
import { fetchThemes } from '../../Services/ThemeService';

export function useFetchAllThemesQuery(options?: UseQueryOptions<Theme[]>): UseQueryResult<Theme[]> {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  return useQuery<Theme[]>(
    [QueryId.themes],
    async () => {
      return await fetchThemes();
    },
    {
      ...options,
      onError: (err) => {
        dispatch(
          notificationSlice.actions.setError({
            message: t('notifications.failedToFetchThemes'),
          }),
        );
        logger.error(err);
        options?.onError?.(err);
      },
    },
  );
}
