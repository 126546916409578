import { Loader } from '@breathelife/ui-components';
import { ReactElement, useContext } from 'react';
import { useTranslation } from 'react-i18next';
import styled from '../../../../Styles/themed-styled-components';
import { EntityMappingContext } from '../../../../Context/EntityMappingContextProvider';
import { MonacoCodeEditor } from '../MonacoCodeEditor';

const NoConfigurationSelectedInfoWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-grow: 1;
  background-color: ${(props) => props.theme.colors.grey[30]};
`;

export function EntityMappingCodeEditor(): ReactElement {
  const {
    canEditCode,
    setRetrieveUpdatedCodeFunction,
    savedEntityMapping,
    entityMappingIsSelected,
    typeDefinitions,
    isFetching,
  } = useContext(EntityMappingContext);
  const { t } = useTranslation();
  if (!entityMappingIsSelected) {
    return (
      <NoConfigurationSelectedInfoWrapper>
        <p>{t('admin.entityMappings.selectEntityToEditInfo')}</p>
      </NoConfigurationSelectedInfoWrapper>
    );
  }

  if (isFetching) {
    return (
      <NoConfigurationSelectedInfoWrapper>
        <Loader>
          <p>{t('admin.entityMappings.loading')}</p>
        </Loader>
      </NoConfigurationSelectedInfoWrapper>
    );
  }

  return (
    <MonacoCodeEditor
      code={savedEntityMapping?.customCode}
      onCodeChange={setRetrieveUpdatedCodeFunction}
      readOnly={!canEditCode}
      typeDefinitions={typeDefinitions}
    />
  );
}
