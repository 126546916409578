import { ChangeEvent, ReactElement, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { v4 as uuid } from 'uuid';

import { Grid } from '@breathelife/mui';
import { NodeIds } from '@breathelife/insurance-form-builder';
import { Language, Localizable, SectionGroupBlueprint } from '@breathelife/types';
import { Input as TextInput } from '@breathelife/ui-components';

import { SubmitButton } from '../../../../../Components/Button/SubmitButton';
import { NumberInput } from '../../../../../Components/NumberInput/NumberInput';
import { useCarrierContext } from '../../../../../Hooks';
import { ModalLayout } from '../../../../../Layouts/Modal/ModalLayout';

type Props = {
  title: string;
  sectionGroupBlueprint?: SectionGroupBlueprint;
  isOpen: boolean;
  isRepeatableSectionGroup: boolean;
  onClose: () => void;
  onSave: (sectionGroupBlueprint: SectionGroupBlueprint) => void;
};

export function SectionGroupModalEditor({
  title,
  sectionGroupBlueprint,
  isOpen,
  isRepeatableSectionGroup,
  onClose,
  onSave,
}: Props): ReactElement {
  const { t } = useTranslation();
  const { languageSettings, showBlueprintIdInEditor } = useCarrierContext();
  const { enabledLanguages } = languageSettings;

  const [referenceLabel, setReferenceLabel] = useState<string>(sectionGroupBlueprint?.referenceLabel ?? '');
  const [sectionGroupTitle, setSectionGroupTitle] = useState<Partial<Localizable>>(sectionGroupBlueprint?.title ?? {});
  const [insuredPeopleCount, setInsuredPeopleCount] = useState<number>(
    sectionGroupBlueprint?.repeatable?.maxRepeatable ?? 1,
  );

  const onSubmit = (): void => {
    const sectionGroupBlueprintEdit: SectionGroupBlueprint = {
      id: sectionGroupBlueprint?.id || uuid(),
      partName: `custom-${uuid()}`,
      title: sectionGroupTitle,
      referenceLabel: referenceLabel.trim(),
    };

    if (isRepeatableSectionGroup) {
      sectionGroupBlueprintEdit.repeatable = {
        minRepeatable: 1,
        maxRepeatable: insuredPeopleCount,
        repeatableAnswerNodeId: NodeIds.insuredPeople,
      };
    }

    onSave(sectionGroupBlueprintEdit);
    onClose();
  };

  const onReferenceLabelChange = (event: ChangeEvent<HTMLInputElement>): void => {
    setReferenceLabel(event.target.value);
  };

  const onSectionGroupTitleChangeByLanguage =
    (language: Language) =>
    (event: ChangeEvent<HTMLInputElement>): void => {
      const title = event.target.value;
      setSectionGroupTitle((prev) => ({ ...prev, [language]: title }));
    };

  const onInsuredPeopleCountChange = (value?: number): void => {
    if (typeof value === 'undefined' || value === 0) {
      return;
    }
    setInsuredPeopleCount(value);
  };

  return (
    <ModalLayout
      title={title}
      isOpen={isOpen}
      closeModal={onClose}
      submitButton={
        <SubmitButton onClick={onSubmit} data-testid='editSectionModal'>
          {t('cta.save')}
        </SubmitButton>
      }
    >
      <Grid container spacing={2} alignItems='center'>
        {showBlueprintIdInEditor && (
          <Grid item xs={12} key={'editSectionGroup-number-of-insured'}>
            <TextInput label={'Id'} value={sectionGroupBlueprint?.id} disabled />
          </Grid>
        )}

        <TextInput
          label={t('admin.questionnaireManagement.input.referenceLabel')}
          value={referenceLabel}
          onChange={onReferenceLabelChange}
        />

        {enabledLanguages.map((language) => (
          <Grid item xs={6} key={`editSectionGroup-title-${language}`}>
            <TextInput
              inputVariant='outlined'
              label={t('admin.questionnaireManagement.input.sectionGroupTitle', {
                language: t(`language.${language}`),
              })}
              value={sectionGroupTitle[language] ?? ''}
              onChange={onSectionGroupTitleChangeByLanguage(language)}
            />
          </Grid>
        ))}

        {isRepeatableSectionGroup && (
          <Grid item xs={6} key={'editSectionGroup-number-of-insured'}>
            <NumberInput
              inputVariant='outlined'
              label={t('admin.questionnaireManagement.input.numberOfInsured')}
              value={insuredPeopleCount}
              onNumberChange={onInsuredPeopleCountChange}
            />
          </Grid>
        )}
      </Grid>
    </ModalLayout>
  );
}
