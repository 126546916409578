import { useCallback, useReducer } from 'react';

type Sort<SortByColumnName> = { columnName: SortByColumnName; direction: 'desc' | 'asc' };

export type TableOptionsState<Filters, SortByColumnName> = {
  sort?: Sort<SortByColumnName>;
  filters: Filters;
  page: number;
  perPage: number;
};

type TableOptionsAction<Filters, SortByColumnName> =
  | { type: 'setFilters'; payload?: Partial<Filters> }
  | { type: 'setSorting'; payload: Sort<SortByColumnName> }
  | { type: 'setPage'; payload: number }
  | { type: 'setPerPage'; payload: number };

export type TableOptionsOutput<Filters, SortByColumnName> = {
  tableOptions: TableOptionsState<Filters, SortByColumnName>;
  setFilters: (filters?: Partial<Filters>) => void;
  setSorting: (columnName: SortByColumnName, direction: 'desc' | 'asc') => void;
  setPage: (page: number) => void;
  setPerPage: (perPage: number) => void;
};

function useTableOptionsReducer<Filters, SortByColumnName>(
  state: TableOptionsState<Filters, SortByColumnName>,
  action: TableOptionsAction<Filters, SortByColumnName>,
): TableOptionsState<Filters, SortByColumnName> {
  switch (action.type) {
    case 'setFilters':
      const filters = { ...state.filters, ...action.payload };
      return { ...state, filters };
    case 'setSorting':
      return { ...state, sort: action.payload };
    case 'setPage':
      return { ...state, page: action.payload };
    case 'setPerPage':
      return { ...state, perPage: action.payload };
    default:
      return state;
  }
}

export function useTableOptions<Filters, SortByColumnName>(
  initialState: TableOptionsState<Filters, SortByColumnName>,
): TableOptionsOutput<Filters, SortByColumnName> {
  const [tableOptions, dispatch] = useReducer<
    (
      state: TableOptionsState<Filters, SortByColumnName>,
      action: TableOptionsAction<Filters, SortByColumnName>,
    ) => TableOptionsState<Filters, SortByColumnName>
  >(useTableOptionsReducer, initialState);

  const setFilters = useCallback(
    (filters?: Partial<Filters>) => {
      dispatch({ type: 'setFilters', payload: filters });
    },
    [dispatch],
  );

  const setSorting = useCallback(
    (columnName: SortByColumnName, direction: 'desc' | 'asc') => {
      dispatch({ type: 'setSorting', payload: { columnName, direction } });
    },
    [dispatch],
  );

  const setPage = useCallback(
    (page: number) => {
      dispatch({ type: 'setPage', payload: page });
    },
    [dispatch],
  );

  const setPerPage = useCallback(
    (perPage: number) => {
      dispatch({ type: 'setPerPage', payload: perPage });
    },
    [dispatch],
  );

  return {
    tableOptions,
    setFilters,
    setSorting,
    setPage,
    setPerPage,
  };
}
