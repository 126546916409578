import { Box } from '@breathelife/mui';
import { ReactElement, Fragment, ReactNode } from 'react';
import styled from '../../Styles/themed-styled-components';

import { ErrorBoundary } from '@breathelife/ui-components';

import { GenericError } from '../../Components/Error/GenericError';
import { HelmetContainer } from '../../Components/HelmetContainer';
import { UserMenu } from '../../Components/Menu/UserMenu';
import { HEADER_HEIGHT } from '../../Models/Layout';
import { PageTabs, Tabs } from './PageTabs';
import { useTranslation } from 'react-i18next';

type PageLayoutProps = {
  children: ReactNode;
  tabs: Tabs[];
  pageTitle: string;
};

const StyledPageHeaderContainer = styled(Box)`
  border-bottom: 1px solid ${(props) => props.theme.colors.grey[40]};
`;

export function LeadsPageLayout(props: PageLayoutProps): ReactElement {
  const { pageTitle, tabs = false } = props;
  const { t } = useTranslation();
  return (
    <Fragment>
      <HelmetContainer text={pageTitle} />
      <Box py={2} position='relative' width='100%' height='100vh' display='flex' flexDirection='column'>
        <StyledPageHeaderContainer
          display='flex'
          justifyContent='space-between'
          alignItems='center'
          height={HEADER_HEIGHT}
          role='navigation'
          aria-label={t('leadsListTable.headerAriaLabel')}
        >
          {tabs && tabs.length !== 0 && <PageTabs tabs={tabs} defaultTab={tabs[0].to} />}
          <Box>
            <UserMenu variant='default' showCarret showUserName />
          </Box>
        </StyledPageHeaderContainer>

        <Box flexGrow={1} minHeight={0} role='main'>
          <ErrorBoundary renderErrorComponent={() => <GenericError />}>{props.children}</ErrorBoundary>
        </Box>
      </Box>
    </Fragment>
  );
}
