import { ReactElement } from 'react';
import { useTranslation } from 'react-i18next';

import { Grid } from '@breathelife/mui';

import { Checkbox, CheckboxGroup } from '../../../Components/Checkbox/Checkbox';
import { getLanguage } from '../../../Localization/utils';
import { NodeIdSelectOption } from '../../../Helpers/questionnaireEditor/questionnaireNodeIds';

export function CriteriaValuesCheckboxGroup(props: {
  options: NodeIdSelectOption[];
  values: Array<NodeIdSelectOption['id']>;
  onChange: (values: Array<NodeIdSelectOption['id']>) => void;
}): ReactElement {
  const { options, values, onChange } = props;

  const { t } = useTranslation();
  const language = getLanguage();

  return (
    <CheckboxGroup label={t('admin.conditions.labels.selectedValues.pluralish')}>
      <Grid container spacing={1}>
        {options?.length ? (
          options.map((option) => {
            const { optionName, optionLabel, id } = option;
            const isOptionSelected = values.includes(id);
            return (
              <Grid item key={optionName}>
                <Checkbox
                  color='primary'
                  label={optionLabel[language]}
                  checked={isOptionSelected}
                  onChange={() => {
                    onChange(isOptionSelected ? values.filter((value) => value !== id) : [...values, id]);
                  }}
                />
              </Grid>
            );
          })
        ) : (
          <Grid item>{t('admin.conditions.labels.selectedValues.noOptions')}</Grid>
        )}
      </Grid>
    </CheckboxGroup>
  );
}
