import { NodeIds } from '@breathelife/insurance-form-builder';
import {
  RenderingSectionGroup,
  isRenderingRepeatedSectionGroup,
  QuestionnaireEngine,
} from '@breathelife/questionnaire-engine';
import { LineOfBusinessName, VersionedAnswers } from '@breathelife/types';
import { useCarrierContext } from '../Hooks';

export function getApplicantFullName(
  answers: VersionedAnswers,
  questionnaireEngine: QuestionnaireEngine,
  lineOfBusiness: LineOfBusinessName,
): string {
  const { features } = useCarrierContext();

  const firstName = questionnaireEngine.getAnswer(
    answers,
    features.assistedApplication?.nodeIdsApplicantFullNamePerLineOfBusiness?.[lineOfBusiness]?.firstName,
    {},
  );
  const lastName = questionnaireEngine.getAnswer(
    answers,
    features.assistedApplication?.nodeIdsApplicantFullNamePerLineOfBusiness?.[lineOfBusiness]?.lastName,
    {},
  );

  return [firstName, lastName].filter(Boolean).join(' ');
}

export function getInsuredDateOfBirth(answers: VersionedAnswers, questionnaireEngine: QuestionnaireEngine): string {
  return (questionnaireEngine.getAnswer(answers, NodeIds.insuredDateOfBirth, {}) as string) || '';
}

export enum SectionGroupId {
  insuredPeople = 'insuredPeople',
  contract = 'contract',
}

function getSectionGroupId(sectionGroup: RenderingSectionGroup): string {
  if (isRenderingRepeatedSectionGroup(sectionGroup)) {
    return sectionGroup.metadata.parentId;
  } else {
    return sectionGroup.id;
  }
}

export function isInsuredPeopleSectionGroup(sectionGroup: RenderingSectionGroup): boolean {
  const sectionGroupId = getSectionGroupId(sectionGroup);

  return sectionGroupId === SectionGroupId.insuredPeople;
}

export function convertYesNoToBoolean(value: unknown): boolean | undefined {
  switch (value) {
    case 'yes':
      return true;
    case 'no':
      return false;
    default:
      return undefined;
  }
}
