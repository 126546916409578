import dayjs from 'dayjs';
import { ReactElement, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { CellProps } from 'react-table';

import { Box, Divider } from '@breathelife/mui';
import { ApplicationSupportESignatureFile } from '@breathelife/types';

import { Table } from '../../../../../Components/Table/Table';
import Typography from '../../../../../Components/Typography';

export function CeremonyFiles(props: { files: Partial<ApplicationSupportESignatureFile>[] }): ReactElement {
  const { t } = useTranslation();

  const { files } = props;

  const columns = useMemo<
    {
      id?: string;
      Header: string;
      accessor: keyof ApplicationSupportESignatureFile;
      Cell?: (props: CellProps<ApplicationSupportESignatureFile>) => ReactElement;
      disableSortBy?: boolean;
    }[]
  >(
    () => [
      {
        Header: t('applicationSupport.eSignature.fileType'),
        accessor: 'docType',
        Cell: function ({ cell: { value } }: { cell: { value: string } }): ReactElement {
          return <Typography variant='body5'>{value}</Typography>;
        },
      },
      {
        Header: t('applicationSupport.eSignature.fileName'),
        accessor: 'displayName',
        Cell: function ({ cell: { value } }: { cell: { value: string } }): ReactElement {
          return <Typography variant='body5'>{value}</Typography>;
        },
      },
      {
        Header: t('applicationSupport.eSignature.lastUpdateDate'),
        accessor: 'updatedAt',
        Cell: function ({ cell: { value } }: { cell: { value: Date } }): ReactElement {
          return <Typography variant='body5'>{value ? dayjs(value).format('D MMMM YYYY HH:MM A') : ''}</Typography>;
        },
      },
    ],

    [t],
  );

  return (
    <Box mb={3}>
      <Box pb='1rem'>
        <Typography variant='h2'>{t('applicationSupport.eSignature.ceremonyFiles')}</Typography>
      </Box>
      <Divider />
      {(!files || !files.length) && (
        <Box mt='1rem'>
          <Typography variant='body5'>{t('applicationSupport.associatedFiles.noFilesFound')}</Typography>
        </Box>
      )}

      {!!files.length && (
        <Box mt={2}>
          <Table<Partial<ApplicationSupportESignatureFile>> columns={columns} data={files} autoHeight={true} />
        </Box>
      )}
    </Box>
  );
}
