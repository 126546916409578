import styled from '../../../Styles/themed-styled-components';
import { ReactElement, useEffect, useRef } from 'react';
import * as monaco from 'monaco-editor/esm/vs/editor/editor.api';

const DEFAULT_LANGUAGE = 'typescript';

type Props = {
  code?: string;
  language?: 'typescript';
  onCodeChange: (code: string) => void;
  readOnly?: boolean;
  typeDefinitions?: string;
};

const Div = styled.div`
  display: flex;
  flex-grow: 1;
`;

export function MonacoCodeEditor({
  code,
  language,
  onCodeChange,
  readOnly = false,
  typeDefinitions = '',
}: Props): ReactElement {
  const container = useRef<HTMLDivElement | null>(null);
  const editor = useRef<monaco.editor.IStandaloneCodeEditor | null>(null);
  const editorSubscription = useRef<monaco.IDisposable | null>(null);
  const types = useRef<monaco.IDisposable | null>(null);

  const createEditor = (container: HTMLDivElement): monaco.editor.IStandaloneCodeEditor => {
    return monaco.editor.create(container, {
      automaticLayout: true,
      fontFamily: 'monospace',
      language: language || DEFAULT_LANGUAGE,
      readOnly,
      roundedSelection: false,
      scrollBeyondLastLine: false,
      theme: 'vs',
      value: code,
    });
  };

  const onChange = (): void => {
    if (!editor.current) return;
    onCodeChange(editor.current.getValue());
  };

  const setCompilerOptions = (): void => {
    monaco.languages.typescript.typescriptDefaults.setCompilerOptions({
      allowNonTsExtensions: true,
      module: monaco.languages.typescript.ModuleKind.CommonJS,
      moduleResolution: monaco.languages.typescript.ModuleResolutionKind.NodeJs,
      noEmit: true,
      target: monaco.languages.typescript.ScriptTarget.ES2016,
    });
  };

  const setDebuggingOptions = (): void => {
    monaco.languages.typescript.typescriptDefaults.setDiagnosticsOptions({
      noSemanticValidation: false,
      noSyntaxValidation: false,
    });
  };

  const setTypeDefinitions = (): void => {
    types.current = monaco.languages.typescript.typescriptDefaults.addExtraLib(typeDefinitions);
  };

  const initMonaco = (): void => {
    if (!container.current) return;

    setCompilerOptions();
    setDebuggingOptions();
    setTypeDefinitions();

    editor.current = createEditor(container.current);
    editorSubscription.current = editor.current.onDidChangeModelContent(onChange);
  };

  const cleanupMonaco = () => () => {
    editor?.current?.dispose();
    editor?.current?.getModel()?.dispose();
    editorSubscription.current?.dispose();
    types.current?.dispose();
  };

  useEffect(initMonaco, [code]);
  useEffect(cleanupMonaco, [code]);

  return <Div ref={container} />;
}
