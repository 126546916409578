import { NodeIdToAnswerPathMap, QuestionnaireDefinition, QuestionnaireEngine } from '@breathelife/questionnaire-engine';
import {
  DEFAULT_TIMEZONE_NAME,
  InsuranceModule,
  QuestionnaireBlueprint,
  Timezone,
  VersionedAnswers,
} from '@breathelife/types';

import { getQuestionnaireEngineConfig } from '../../Helpers/questionnaireEngineConfigs';
import { useCarrierContext } from '../../Hooks';
import { Application } from '../../Models/Application';
import { CarrierQuestionnaireResponse } from '../../Services/CarrierQuestionnaireService';

export type ApplicationProgressProps = {
  application?: Application;
  needsAnalysisOnly?: boolean;
  questionnaireResponse?: CarrierQuestionnaireResponse;
};

type ApplicationProgressByModuleProps = {
  application?: Application;
  insuranceModule: InsuranceModule;
  nodeIdToAnswerPathMap?: NodeIdToAnswerPathMap;
  questionnaire?: QuestionnaireDefinition;
  blueprint?: QuestionnaireBlueprint;
};

export function getApplicationProgressByModule(props: ApplicationProgressByModuleProps): number {
  const { features } = useCarrierContext();
  const { application, insuranceModule, nodeIdToAnswerPathMap, questionnaire, blueprint } = props;

  if (!application || !nodeIdToAnswerPathMap || !questionnaire || !blueprint) return 0;

  const questionnaireEngineEnabled = features.engineQuestionnaire.enabled;

  // Legacy progress calculation is performed in the backend
  if (!questionnaireEngineEnabled) return application.progress || 0;

  const timezoneResult = Timezone.from(application.timezone || DEFAULT_TIMEZONE_NAME);
  if (timezoneResult.success === false) {
    return 0;
  }

  const questionnaireEngine = new QuestionnaireEngine(
    questionnaire,
    nodeIdToAnswerPathMap,
    {
      insuranceModules: [insuranceModule],
    },
    getQuestionnaireEngineConfig(application.mode, features),
    blueprint,
    timezoneResult.value,
    application.applicationContext,
  );

  if (!questionnaireEngine) return 0;

  const isCompleted =
    insuranceModule === InsuranceModule.insuranceApplication ? application.signed || application.submitted : undefined;

  return questionnaireEngine.calculateProgress(
    new VersionedAnswers({ v1: application.answers, v2: application.answersV2 }),
    isCompleted,
  );
}
