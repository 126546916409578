import { ReactElement, MouseEvent } from 'react';
import { useTranslation } from 'react-i18next';
import { useTheme } from '../../../../Styles/themed-styled-components';

import { AddIcon } from '@breathelife/mui';

import Typography from '../../../../Components/Typography';
import { useAssistedApplicationContext } from '../../../../Hooks/useAssistedApplicationContext';
import { LeadPlatformTheme } from '../../../../Styles/Types';
import { AddInsuredButton, RemoveButton, RemoveIcon, TabBar, Tab } from './Styles';

type Props = {
  maxTabs: number;
  onOpenDeleteProposedInsuredModal: (index: number) => void;
};

export function ProposedInsuredTabBar(props: Props): ReactElement | null {
  const { maxTabs, onOpenDeleteProposedInsuredModal } = props;
  const { t } = useTranslation();
  const theme = useTheme() as LeadPlatformTheme;
  const {
    areAllFieldsDisabled,
    currentProposedInsuredIndex,
    proposedInsuredTabs,
    onSelectProposedInsured,
    onAddProposedInsured,
  } = useAssistedApplicationContext();

  if (proposedInsuredTabs.length === 0) return null;

  const selectedProposedInsuredAdjustedIndex =
    currentProposedInsuredIndex < 0 ||
    currentProposedInsuredIndex >= proposedInsuredTabs.length ||
    currentProposedInsuredIndex >= maxTabs
      ? 0
      : currentProposedInsuredIndex;

  const showAddButton = maxTabs > proposedInsuredTabs.length;

  const onRemoveProposedInsuredClick = (event: MouseEvent, index: number): void => {
    event.stopPropagation();
    onOpenDeleteProposedInsuredModal(index);
  };

  return (
    <TabBar>
      {proposedInsuredTabs.map(
        (tabData) =>
          tabData.index < maxTabs && (
            <Tab
              key={tabData.index}
              isActive={selectedProposedInsuredAdjustedIndex === tabData.index}
              onClick={() => onSelectProposedInsured(tabData.index)}
            >
              <Typography variant='h4'>
                {tabData.name ?? t('assistedApplication.multiInsuredTabs.newProposedInsured')}
              </Typography>
              {proposedInsuredTabs.length > 1 && (
                <RemoveButton
                  aria-label={t('assistedApplication.multiInsuredTabs.deleteProposedInsured', {
                    name: tabData.name,
                  })}
                  onClick={(e) => onRemoveProposedInsuredClick(e, tabData.index)}
                  disabled={areAllFieldsDisabled}
                >
                  <RemoveIcon />
                </RemoveButton>
              )}
            </Tab>
          ),
      )}
      {showAddButton && (
        <AddInsuredButton
          onClick={onAddProposedInsured}
          disabled={areAllFieldsDisabled}
          aria-label={t('assistedApplication.multiInsuredTabs.addNewProposedInsured')}
        >
          <AddIcon htmlColor={theme.colors.primary.default} fontSize='small' />
        </AddInsuredButton>
      )}
    </TabBar>
  );
}
