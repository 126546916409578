import { ReactElement, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';

import {
  Divider,
  ExpandMoreIcon,
  ExpansionPanel,
  ExpansionPanelDetails,
  ExpansionPanelSummary,
} from '@breathelife/mui';
import {
  BlueprintConditionValue,
  BlueprintUpdate,
  DynamicOptionsBlueprint,
  FieldPartIdentifier,
  Language,
  SelectOptionFieldBlueprint,
} from '@breathelife/types';
import { AutoCompleteMultiple, Box, SimpleCheckbox } from '@breathelife/ui-components';

import { Button } from '../../../../../Components/Button/Button';
import { NodeIdSelector } from '../../../../../Components/NodeIds/NodeIdSelector';
import Typography from '../../../../../Components/Typography';
import { NodeDetail, QuestionnaireNodeIds } from '../../../../../Helpers/questionnaireEditor/questionnaireNodeIds';
import { ModalLayout } from '../../../../../Layouts/Modal/ModalLayout';
import { ConditionsEditor } from '../Editors/ConditionsEditor';

const getNodeIdsOfCollection = (
  collectionNodeId: string | undefined,
  questionnaireNodeIds: QuestionnaireNodeIds,
): NodeDetail[] => {
  if (collectionNodeId) {
    return [
      ...(questionnaireNodeIds.inQuestionnaire.withRepeatableAncestor[collectionNodeId] ?? []),
      ...(questionnaireNodeIds.notInQuestionnaire.withRepeatableAncestor[collectionNodeId] ?? []),
    ];
  }
  return [];
};

type Props = {
  blueprint: SelectOptionFieldBlueprint;
  blueprintUpdate: (modification: BlueprintUpdate) => Promise<void>;
  collectionContext: string[];
  disabled: boolean;
  partIdentifier: FieldPartIdentifier;
  questionnaireNodeIds: QuestionnaireNodeIds;
  selectedLanguage: Language;
};

export function DynamicOptionsEditor(props: Props): ReactElement {
  const {
    blueprint,
    blueprintUpdate,
    collectionContext,
    disabled,
    partIdentifier,
    questionnaireNodeIds,
    selectedLanguage,
  } = props;

  const { t } = useTranslation();

  const [dynamicOptions, setDynamicOptions] = useState<DynamicOptionsBlueprint | undefined>(undefined);
  const [askingRemoveConfirmation, setAskingRemoveConfirmation] = useState(false);

  useEffect(() => {
    if (!blueprint.dynamicOptions) return;
    setDynamicOptions(blueprint.dynamicOptions);
  }, [blueprint]);

  const onChange = (dynamicOptionsBlueprint?: DynamicOptionsBlueprint): void => {
    setDynamicOptions(dynamicOptionsBlueprint);
    void blueprintUpdate({
      partIdentifier,
      update: { property: 'dynamicOptions', value: dynamicOptionsBlueprint },
    });
  };

  const onSaveCondition = (condition?: BlueprintConditionValue): void => {
    if (blueprint.dynamicOptions) {
      setDynamicOptions((prevState) => {
        if (prevState) {
          return {
            ...prevState,
            visible: condition,
          };
        }
        return prevState;
      });

      void blueprintUpdate({
        partIdentifier,
        update: {
          property: 'dynamicOptions',
          value: { ...blueprint.dynamicOptions, visible: condition },
        },
      });
    }
  };

  const nodeIdsOfCollection: string[] = useMemo(
    () => getNodeIdsOfCollection(dynamicOptions?.collection, questionnaireNodeIds).map((e) => e.answerNodeId),
    [dynamicOptions?.collection, questionnaireNodeIds],
  );

  return (
    <Box pr={2} pt={1}>
      <ModalLayout
        maxWidth='sm'
        title={t('admin.questionnaireManagement.dynamicOptions.removeModal.title')}
        isOpen={askingRemoveConfirmation}
        closeModal={() => setAskingRemoveConfirmation(false)}
        submitButton={
          <Button
            variant='contained'
            onClick={() => {
              onChange(undefined);
              setAskingRemoveConfirmation(false);
            }}
          >
            {t('admin.questionnaireManagement.dynamicOptions.removeModal.confirmButton')}
          </Button>
        }
      >
        <Typography variant='body1' grey={70}>
          {t('admin.questionnaireManagement.dynamicOptions.removeModal.content')}
        </Typography>
      </ModalLayout>

      <ExpansionPanel>
        <ExpansionPanelSummary expandIcon={<ExpandMoreIcon />}>
          <SimpleCheckbox
            id={`has-dynamic-options-editor-${blueprint.partName}`}
            disabled={disabled}
            checked={!!dynamicOptions}
            onChange={() => {
              if (dynamicOptions) {
                setAskingRemoveConfirmation(true);
              }
            }}
          />
          {t('admin.questionnaireManagement.dynamicOptions.title')}
        </ExpansionPanelSummary>

        <Divider />

        <ExpansionPanelDetails>
          <Box display='flex' flexDirection='column' width='100%'>
            <NodeIdSelector
              label={t('admin.questionnaireManagement.dynamicOptions.pickCollection')}
              nodeIds={questionnaireNodeIds.inQuestionnaire.collection}
              readOnly={disabled}
              selectedNodeId={dynamicOptions?.collection || ''}
              onChange={(nodeId) => {
                const collectionId = nodeId?.value;

                if (collectionId && collectionId !== dynamicOptions?.collection) {
                  onChange({ ...dynamicOptions, collection: collectionId, select: [] });
                }
              }}
              selectedLanguage={selectedLanguage}
            />

            {dynamicOptions?.collection && (
              <Box pt={1}>
                <AutoCompleteMultiple
                  label={t('admin.questionnaireManagement.dynamicOptions.pickSelect')}
                  disabled={disabled}
                  optionInfo={{
                    selectedItems: dynamicOptions.select,
                    items: nodeIdsOfCollection,
                    toOption: (text: string) => ({ label: text, value: text }),
                    onChange: (nodeIds: string[]) => {
                      onChange({ ...dynamicOptions, select: [...nodeIds] });
                    },
                  }}
                />
              </Box>
            )}

            <ConditionsEditor
              questionnaireNodeIds={questionnaireNodeIds}
              collectionContext={collectionContext}
              condition={dynamicOptions?.visible}
              isReadOnly={disabled}
              label={t('admin.questionnaireManagement.rules.visibility.conditions')}
              editHeadingText={t('admin.questionnaireManagement.rules.visibility.edit')}
              selectedLanguage={selectedLanguage}
              saveCondition={onSaveCondition}
            />

            <Box pb={1} pt={1}>
              <Divider />
            </Box>

            <Typography variant='small1'>{t('admin.questionnaireManagement.dynamicOptions.note')}</Typography>
          </Box>
        </ExpansionPanelDetails>
      </ExpansionPanel>
    </Box>
  );
}
