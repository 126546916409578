import { useMemo } from 'react';

import { QuestionnaireEngine, RenderingQuestionnaire } from '@breathelife/questionnaire-engine';
import { DEFAULT_TIMEZONE_NAME, InsuranceModule, RenderingType, Timezone, VersionedAnswers } from '@breathelife/types';

import { getQuestionnaireEngineConfig } from '../../Helpers/questionnaireEngineConfigs';
import { useCarrierContext, useLocale } from '../../Hooks';
import { ApplicationHookProps } from './types';

export function useNeedsAnalysisQuestionnaire(props: ApplicationHookProps): RenderingQuestionnaire | undefined {
  const { features } = useCarrierContext();
  const locale = useLocale();

  const { application, questionnaire, nodeIdToAnswerPathMap, blueprint } = props;

  const questionnaireEngineEnabled = features.engineQuestionnaire.enabled;

  const questionnaireEngine = useMemo(() => {
    if (!questionnaireEngineEnabled || !application || !questionnaire || !nodeIdToAnswerPathMap || !blueprint) return;

    const timezoneResult = Timezone.from(application.timezone || DEFAULT_TIMEZONE_NAME);
    if (timezoneResult.success === false) {
      return;
    }

    return new QuestionnaireEngine(
      questionnaire,
      nodeIdToAnswerPathMap,
      {
        insuranceModules: [InsuranceModule.needsAnalysis],
      },
      getQuestionnaireEngineConfig(application.mode, features),
      blueprint,
      timezoneResult.value,
      application.applicationContext,
    );
  }, [application, nodeIdToAnswerPathMap, questionnaire]);

  return useMemo(() => {
    if (!questionnaireEngineEnabled || !application || !questionnaireEngine) return;

    return questionnaireEngine.generateRenderingQuestionnaire(
      new VersionedAnswers({ v1: application.answers, v2: application.answersV2 }),
      locale,
      () => '',
      {
        renderingType: RenderingType.proSummary,
        shouldValidateAllAnswers: false,
      },
    );
  }, [application, questionnaireEngine, locale]);
}
