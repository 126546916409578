import { useMemo } from 'react';

import { deserializeNodeIdToAnswerPathMap } from '@breathelife/questionnaire-engine';
import { InsuranceModule, LaunchApplicationType } from '@breathelife/types';

import { ApplicationProgressProps, getApplicationProgressByModule } from '../../Helpers/assistedApplication/progress';
import { useCarrierContext } from '../useCarrierContext';

export function useApplicationProgress(completeApplicationProgressProps: ApplicationProgressProps): number {
  const { features } = useCarrierContext();
  const { application, needsAnalysisOnly, questionnaireResponse } = completeApplicationProgressProps;

  return useMemo(() => {
    if (!application || !questionnaireResponse) return 0;

    const questionnaire = questionnaireResponse?.questionnaire;
    const blueprint = questionnaireResponse?.blueprint;
    const serializedNodeIdToAnswerPathMap = questionnaireResponse?.nodeIdToAnswerPath;
    const nodeIdToAnswerPathMap =
      serializedNodeIdToAnswerPathMap && deserializeNodeIdToAnswerPathMap(serializedNodeIdToAnswerPathMap);

    if (
      features.launchApplication.enabled &&
      [LaunchApplicationType.assistedApplication, LaunchApplicationType.coBrowsing].includes(
        features.launchApplication.type,
      ) &&
      !needsAnalysisOnly
    ) {
      return (
        getApplicationProgressByModule({
          application,
          insuranceModule: InsuranceModule.insuranceApplication,
          nodeIdToAnswerPathMap,
          questionnaire,
          blueprint,
        }) ?? 0
      );
    }
    return (
      getApplicationProgressByModule({
        application,
        insuranceModule: InsuranceModule.needsAnalysis,
        nodeIdToAnswerPathMap,
        questionnaire,
        blueprint,
      }) ?? 0
    );
  }, [application, features, needsAnalysisOnly, questionnaireResponse]);
}
