import { ReactElement } from 'react';
import { useTranslation } from 'react-i18next';

import { Box, Grid } from '@breathelife/mui';
import { ESignSigner, OutcomeCode } from '@breathelife/types';

import { OutcomeBadge } from '../../../../../Components/Badge/OutcomeBadge';
import Typography from '../../../../../Components/Typography';
import { SignerInfoHeader } from './SignerInfoHeader';
import { ESignSignerStatusBadge } from '../../../../../Components/Badge/ESignSignerStatusBadge';

type SignerInfoProps = {
  signer: ESignSigner;
  outcome?: OutcomeCode;
  overrideOutcome?: string;
};

export function SignerInfo(props: SignerInfoProps): ReactElement {
  const { t } = useTranslation();
  const { signer, outcome, overrideOutcome } = props;

  return (
    <div>
      <SignerInfoHeader signer={signer} />
      <Box px={2}>
        <Box mb={2}>
          <Grid container>
            {outcome && (
              <Grid item xs={6}>
                <OutcomeBadge outcome={outcome} overrideOutcome={overrideOutcome} withIcon />
              </Grid>
            )}
            <Grid item xs={6}>
              <ESignSignerStatusBadge status={signer?.status} withIcon />
            </Grid>
          </Grid>
        </Box>
        <Box mb={2}>
          <Typography variant='body5' component='div'>
            {t('assistedApplication.eSignatureDetails.email')}
          </Typography>
          <Typography variant='body1' component='div'>
            {signer.email}
          </Typography>
        </Box>
        <Box>
          <Typography variant='body5' component='div'>
            {t('assistedApplication.eSignatureDetails.phoneNumber')}
          </Typography>
          <Typography variant='body1' component='div'>
            {signer.cellphone}
          </Typography>
        </Box>
      </Box>
    </div>
  );
}
