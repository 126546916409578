import { ReactElement, useContext } from 'react';
import styled from '../../../../Styles/themed-styled-components';
import { Loader } from '@breathelife/ui-components';
import { MonacoCodeEditor } from '../MonacoCodeEditor';
import { useTranslation } from 'react-i18next';
import { useGetEntityQuery } from '../../../../ReactQuery/Admin/Entities/entities.queries';
import { useFetchQuestionnaireVersionQuery } from '../../../../ReactQuery/Admin/Questionnaire/questionnaireVersion.queries';
import { QuestionnaireVersionFilterContext } from '../../../../Context/QuestionnaireVersionFilterContextProvider';
import { AvailableUtilities } from '@breathelife/types';
import { useGetUtilitiesTypeDeclarationQuery } from '../../../../ReactQuery/Admin/UtilitiesTypeDeclarations/utilitiesTypeDeclarations.queries';

const Container = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-grow: 1;
  background-color: ${(props) => props.theme.colors.grey[30]};
`;

type Props = {
  entityId: string;
  onCodeChange: (code: string) => void;
};

export function CarrierEntityCodeEditor(props: Props): ReactElement {
  const { questionnaireVersionId } = useContext(QuestionnaireVersionFilterContext);
  const { data: entity, isFetching, isLoading } = useGetEntityQuery(props.entityId);
  const { data: questionnaireVersion } = useFetchQuestionnaireVersionQuery(questionnaireVersionId);
  const { t } = useTranslation();
  const utilitiesDeclarationTypes = useGetUtilitiesTypeDeclarationQuery([AvailableUtilities.zod]);
  if (!entity) {
    return (
      <Container>
        <p>{t('admin.entityMappings.selectEntityToEditInfo')}</p>
      </Container>
    );
  }

  if (isFetching || isLoading) {
    return (
      <Container>
        <Loader>
          <p>{t('admin.entityMappings.loading')}</p>
        </Loader>
      </Container>
    );
  }

  const isQuestionnaireVersionPublished = !questionnaireVersion?.isDraft;

  return (
    <MonacoCodeEditor
      readOnly={isQuestionnaireVersionPublished}
      code={entity.schema}
      typeDefinitions={utilitiesDeclarationTypes.data}
      onCodeChange={props.onCodeChange}
    />
  );
}
