import { MouseEvent, ReactElement, Fragment, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { ThreeDots } from '../../Assets/ThreeDots';
import { MenuElement, PopupMenu } from '../../Components/PopupMenu/PopupMenu';
import { StyledIconButton } from './Styles';

type Props = {
  disabled?: boolean;
  actions: MenuElement[];
};

export function MoreActionButton(props: Props): ReactElement {
  const [anchorEl, setAnchorEl] = useState<(EventTarget & HTMLButtonElement) | null>(null);
  const { t } = useTranslation();
  const showPopover = Boolean(anchorEl);

  const id = showPopover ? 'popover' : undefined;

  return (
    <Fragment>
      <StyledIconButton
        aria-label={t('cta.moreOptions')}
        disabled={!!props.disabled}
        aria-describedby={id}
        onClick={(event) => {
          event.stopPropagation();
          setAnchorEl(event.currentTarget);
        }}
        size='small'
      >
        <ThreeDots role='img' aria-label={t('cta.moreOptions')} />
      </StyledIconButton>

      <PopupMenu
        id={id}
        open={showPopover}
        anchorEl={anchorEl}
        getContentAnchorEl={null}
        items={props.actions}
        disableScrollLock
        onClick={(event: MouseEvent) => {
          event.stopPropagation();
          setAnchorEl(null);
        }}
        onClose={(event: MouseEvent) => {
          event.stopPropagation();
          setAnchorEl(null);
        }}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'center',
        }}
      />
    </Fragment>
  );
}
