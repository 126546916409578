import { ReactElement } from 'react';

import { Collapse, Fade, Grow, Slide, TransitionProps, Zoom } from '@breathelife/mui';

export type TransitionVariant =
  | 'collapse'
  | 'fade'
  | 'grow'
  | 'slideUp'
  | 'slideDown'
  | 'slideLeft'
  | 'slideRight'
  | 'zoom';

export type TransitionInnerProps = TransitionProps & { children?: ReactElement };

type Props = {
  variant: TransitionVariant;
} & TransitionInnerProps;

export function Transition(props: Props): ReactElement {
  const { variant, ...innerProps } = props;

  switch (variant) {
    case 'collapse':
      return <Collapse {...innerProps} />;
    case 'fade':
      return <Fade {...innerProps} />;
    case 'grow':
      return <Grow {...innerProps} />;
    case 'slideUp':
      return <Slide direction='up' {...innerProps} />;
    case 'slideDown':
      return <Slide direction='down' {...innerProps} />;
    case 'slideLeft':
      return <Slide direction='left' {...innerProps} />;
    case 'slideRight':
      return <Slide direction='right' {...innerProps} />;
    case 'zoom':
      return <Zoom {...innerProps} />;
  }
}
