export enum QueryId {
  storedFiles = 'storedFiles',
  fileTemplateRules = 'fileTemplateRules',
  fileTemplates = 'fileTemplates',
  fileTemplate = 'fileTemplate',
  questionnaireVersions = 'questionnaireVersions',
  allQuestionnaireVersions = 'allQuestionnaireVersions',
  questionnaireVersion = 'questionnaireVersion',
  allQuestionnairesNodeIds = 'allQuestionnairesNodeIds',
  latestQuestionnaireId = 'latestQuestionnaireId',
  carrierQuestionnaire = 'carrierQuestionnaire',
  questionnaire = 'questionnaire',
  allQuestionnaires = 'allQuestionnaires',
  allLinesOfBusiness = 'allLinesOfBusiness',
  questionnaireBuilder = 'questionnaireBuilder',
  eSignCeremony = 'eSignCeremony',
  eSignFieldSettings = 'eSignFieldSettings',
  eSignFieldSetting = 'eSignFieldSetting',
  proUserQuestionnaires = 'proUserQuestionnaires',
  fileTemplateRecipients = 'fileTemplateRecipients',
  fileTemplateRecipient = 'fileTemplateRecipient',
  userGroups = 'userGroups',
  users = 'users',
  application = 'application',
  agentLicenses = 'agentLicenses',
  firms = 'firms',
  firm = 'firm',
  leadEmailValid = 'leadEmailValid',
  lead = 'lead',
  leads = 'leads',
  entity = 'entity',
  entities = 'entities',
  entityMappings = 'entityMappings',
  entityMappingDependencies = 'entityMappingDependencies',
  utilitiesTypeDeclarations = 'utilitiesTypeDeclarations',
  participantProcessor = 'participantProcessor',
  thirdPartyIntegrations = 'thirdPartyIntegrations',
  participantRoles = 'participantRoles',
  pointOfSaleDecision = 'pointOfSaleDecision',
  applicationSupportApplications = 'applicationSupportApplications',
  applicationSupportESignCeremonies = 'applicationSupportESignCeremonies',
  applicationSupportAssociatedFiles = 'applicationSupportAssociatedFiles',
  productsWidgetTotalPremiums = 'productsWidgetTotalPremiums',
  underwritingReports = 'underwritingReports',
  quotes = 'quotes',
  jetDecisionOutcomes = 'jetDecisionOutcomes',
  fetchPaymentTransaction = 'fetchPaymentTransaction',
  themes = 'themes',
}
