import { Namespaces, entityMappingCombination } from '@breathelife/types';
import { ReactElement, useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { EntityMappingContext } from '../../../../Context/EntityMappingContextProvider';
import { QuestionnaireVersionFilterContext } from '../../../../Context/QuestionnaireVersionFilterContextProvider';
import { useGetEntitiesQuery } from '../../../../ReactQuery/Admin/Entities/entities.queries';
import { EditorDropdown } from '../../Questionnaire/QuestionnaireEditor/Components/EditorDropdown';

export function EntityMappingFilter(): ReactElement | null {
  const { questionnaireVersionId } = useContext(QuestionnaireVersionFilterContext);
  const { selectedEntityMappingOptions } = useContext(EntityMappingContext);
  useGetEntitiesQuery(questionnaireVersionId);

  const { namespace } = selectedEntityMappingOptions;

  return namespace === Namespaces.CarrierDefined ? <CarrierEntityFilter /> : <FeatureEntityFilter />;
}

function CarrierEntityFilter(): ReactElement {
  const { selectedEntityMappingOptions, updateSelectedEntityMappingOptions } = useContext(EntityMappingContext);
  const { questionnaireVersionId } = useContext(QuestionnaireVersionFilterContext);
  const { data: entities = [] } = useGetEntitiesQuery(questionnaireVersionId);
  const { t } = useTranslation();

  const onChange = (option: string | null): void => {
    if (!option) return;
    const entity = entities.find((e) => e.id === option);
    updateSelectedEntityMappingOptions({ mapping: entity?.name });
  };

  const options = entities.map((e) => ({ label: e.name, value: e.id }));
  const selectedEntity = entities.find(
    (e) =>
      e.questionnaireVersionId === selectedEntityMappingOptions.questionnaireVersionId &&
      e.name === selectedEntityMappingOptions.mapping,
  );

  return (
    <EditorDropdown
      disabled={!questionnaireVersionId}
      label={t('admin.entityMappings.entityDropdownLabel')}
      selectedOptionValue={selectedEntity?.id}
      options={options}
      onChange={onChange}
    />
  );
}

function FeatureEntityFilter(): ReactElement {
  const { t } = useTranslation();
  const { availableEntityMappings, selectedEntityMappingOptions, updateSelectedEntityMappingOptions } =
    useContext(EntityMappingContext);

  const onChange = (value: string | null): void => {
    const result = entityMappingCombination.safeParse({
      namespace: selectedEntityMappingOptions.namespace,
      mapping: value,
    });

    if (result.success) {
      updateSelectedEntityMappingOptions({ mapping: result.data.mapping });
    }
  };

  const { namespace = '', mapping } = selectedEntityMappingOptions;
  const options = (availableEntityMappings[namespace as keyof typeof availableEntityMappings] || []).map(
    (mapping: string) => ({
      label: mapping,
      value: mapping,
    }),
  );

  return (
    <EditorDropdown
      label={t('admin.entityMappings.entityDropdownLabel')}
      selectedOptionValue={mapping}
      options={options}
      disableClearable={false}
      onChange={onChange}
    />
  );
}
