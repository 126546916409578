import { baseCreateMuiTheme } from '@breathelife/mui';

import { LeadPlatformTheme } from '../Styles/Types';
import { fontsFamilies } from './GlobalStyle';

const boxShadow = '0px 1px 3px rgba(9, 30, 66, 0.1)';

const buttonResetStyles = {
  borderRadius: '3px',
  padding: '12px 15px',
  letterSpacing: '-0.42px',
  lineHeight: '16px',
  minWidth: '80px',
  boxShadow,
  '&:hover': {
    boxShadow,
  },
  '&:active': {
    boxShadow,
  },
};
function getPixelNumericalValue(pixels: string): number {
  return parseInt(pixels, 10);
}

export const createMuiTheme = (theme: LeadPlatformTheme) =>
  baseCreateMuiTheme({
    palette: {
      primary: { main: theme.colors.primary.default },
      secondary: { main: theme.colors.secondary.default },
    },
    breakpoints: {
      values: {
        xs: getPixelNumericalValue(theme.moduleBreakpoints.xs),
        sm: getPixelNumericalValue(theme.moduleBreakpoints.sm),
        md: getPixelNumericalValue(theme.moduleBreakpoints.md),
        lg: getPixelNumericalValue(theme.moduleBreakpoints.lg),
        xl: getPixelNumericalValue(theme.moduleBreakpoints.xl),
      },
    },
    typography: {
      fontSize: 16,
      // Use system font instead of using Roboto on all platforms
      fontFamily: fontsFamilies,
      h1: {
        fontSize: theme.text.h1.size,
        fontWeight: theme.text.h1.weight,
      },
      h2: {
        fontSize: theme.text.h2.size,
        fontWeight: theme.text.h2.weight,
      },
      h3: {
        fontSize: theme.text.h3.size,
        fontWeight: theme.text.h3.weight,
      },
      h4: {
        fontSize: theme.text.h4.size,
        fontWeight: theme.text.h4.weight,
      },
    },
    props: {
      MuiButtonBase: {
        disableRipple: true, // Disable ripple animation on click
      },
      MuiTypography: {
        variantMapping: {
          body1: 'span',
          body2: 'span',
        },
      },
    },
    overrides: {
      MuiCircularProgress: {
        colorPrimary: {
          margin: '1em',
          color: `${theme.colors.primary.default} !important`,
        },
      },
      MuiTabs: {
        indicator: {
          borderTopLeftRadius: '2px',
          borderTopRightRadius: '2px',
          height: '3px',
        },
        root: {
          height: '48px',
        },
      },
      MuiTab: {
        textColorPrimary: {
          color: theme.colors.grey[70],
          '&$selected': {
            fontWeight: theme.text.body4.weight,
          },
        },
        root: {
          fontSize: theme.text.body5.size,
          fontWeight: theme.text.body5.weight,
          paddingLeft: '8px !important',
          paddingRight: '8px !important',
          '&:focus:not(:active)': {
            border: `1px solid ${theme.colors.tertiary.default} !important`,
          },
        },
      },
      MuiRadio: {
        root: {
          color: theme.colors.grey[60],
          fontWeight: 500,
        },
        colorPrimary: {
          '&$checked': {
            '&$disabled': {
              color: theme.colors.primary[30],
            },
          },
        },
      },
      MuiSelect: {
        root: {
          fontSize: theme.text.body1.size,
          fontWeight: theme.text.body1.weight,
          color: theme.colors.grey[70],
        },
      },
      MuiIconButton: {
        label: {
          borderRadius: '1em',
          width: '20px',
          height: '20px',
        },
      },
      MuiFormControlLabel: {
        label: {
          color: `${theme.colors.grey[90]} !important`,
          '&$disabled': {
            color: `#cccccc !important`,
          },
        },
      },
      MuiFormHelperText: {
        root: {
          color: `${theme.colors.grey[60]} !important`,
          '&$error': {
            fontSize: theme.text.small1.size,
            fontWeight: theme.text.small1.weight,
            color: `${theme.colors.red[50]} !important`,
          },
        },
      },
      MuiInputBase: {
        root: {
          height: '40px',
          fontSize: theme.text.body1.size,
          fontWeight: theme.text.body1.weight,
        },
        multiline: {
          height: 'unset',
          padding: '12px !important',
        },
        input: {
          '&::placeholder': {
            color: `${theme.colors.grey[70]} !important`,
            opacity: 1,
          },
        },
      },
      MuiOutlinedInput: {
        input: {
          padding: '12px',
        },
      },
      MuiButton: {
        root: {
          height: '40px',
          fontSize: theme.text.button.size,
          fontWeight: theme.text.button.weight,
          textTransform: 'none',
          '&:focus:not(:active)': {
            border: `1px solid ${theme.colors.tertiary.default} !important`,
          },
        },
        sizeSmall: {
          height: '32px',
        },
        outlined: {
          ...buttonResetStyles,
          color: `${theme.colors.grey[70]} !important`,
          borderColor: `${theme.colors.grey[50]} !important`,
          backgroundColor: theme.colors.grey[10],
          '&:hover': {
            backgroundColor: `${theme.colors.grey[20]} !important`,
          },
          '&:active': {
            backgroundColor: `${theme.colors.grey[30]} !important`,
          },
          '&$disabled': {
            opacity: 0.5,
          },
        },
        contained: {
          ...buttonResetStyles,
          color: `${theme.colors.grey[0]} !important`,
          '&:hover': {
            backgroundColor: `${theme.colors.primary[60]} !important`,
          },
          '&:active': {
            backgroundColor: `${theme.colors.primary[70]} !important`,
          },
          '&$disabled': {
            backgroundColor: theme.colors.primary.default,
            opacity: 0.5,
          },
        },
        containedPrimary: {
          backgroundColor: theme.colors.primary.default,
          border: '1px solid transparent',
        },
        containedSecondary: {
          backgroundColor: theme.colors.secondary.default,
          border: '1px solid transparent',
          '&:hover': {
            backgroundColor: `${theme.colors.secondary[60]} !important`,
          },
          '&:active': {
            backgroundColor: `${theme.colors.secondary[70]} !important`,
          },
          '&$disabled': {
            backgroundColor: theme.colors.secondary.default,
          },
          '&:focus:not(:active)': {
            border: `1px solid ${theme.colors.tertiary.default} !important`,
          },
        },
        endIcon: {
          marginLeft: 'auto',
        },
      },
      MuiCard: {
        root: {
          boxShadow: '0px 0px 1px rgba(117, 117, 117, 0.31), 0px 1px 1px rgba(117, 117, 117, 0.25)',
        },
      },
      MuiDialog: {
        paper: {
          boxShadow: '0px 8px 25px rgba(0, 0, 0, 0.05)',
        },
        paperWidthLg: {
          maxWidth: '1040px',
        },
        paperWidthMd: {
          maxWidth: '672px',
        },
        paperWidthSm: {
          maxWidth: '540px',
        },
      },
      MuiDialogActions: {
        root: {
          justifyContent: 'center',
          paddingBottom: '15px',
          paddingTop: '15px',
        },
      },
      MuiDialogContent: {
        root: {
          borderBottom: '1px solid #ebebeb',
          borderTop: '1px solid #ebebeb',
        },
      },
      MuiDialogTitle: {
        root: {
          margin: '5px 0 10px 0',
          textAlign: 'center',
        },
      },
      MuiPaper: {
        root: {
          paddingBottom: '0 !important',
        },
      },
      MuiExpansionPanel: {
        root: {
          border: 'none',
          boxShadow: '0px 8px 25px rgba(0, 0, 0, 0.05)',
        },
      },
      MuiExpansionPanelDetails: {
        root: {
          padding: '16px',
        },
      },
      MuiTableHead: {
        root: {
          '& tr': {
            borderLeft: `0 !important`,
            '&:hover': {
              backgroundColor: 'unset !important',
            },
            '&:active': {
              backgroundColor: 'unset !important',
            },
            '& td': {
              paddingTop: 0,
            },
          },
        },
      },
      MuiTableBody: {
        root: {},
      },
      MuiToolbar: {
        gutters: {
          paddingLeft: '0 !important',
        },
      },
      MuiTooltip: {
        tooltip: {
          color: theme.colors.grey[0],
          backgroundColor: theme.colors.grey[70],
          fontSize: theme.text.small2.size,
          fontWeight: theme.text.small2.weight,
        },
        popper: {
          top: '4px !important',
        },
      },
      MuiTableCell: {
        body: {
          paddingTop: '16px',
          paddingBottom: '16px',
          fontSize: theme.text.body3.size,
          fontWeight: theme.text.body3.weight,
          lineHeight: '1.2em',
          borderBottom: `1px solid ${theme.colors.grey[40]}`,
        },
        head: {
          borderTop: 'none !important',
          borderBottom: 'none !important',
          position: 'relative',
          fontSize: theme.text.body5.size,
          fontWeight: theme.text.body5.weight,
          color: theme.colors.grey[80],
          lineHeight: '1.38em',
          backgroundColor: `${theme.colors.grey[20]} !important`,
          borderRadius: '4px',
        },
      },
      MuiTableRow: {
        root: {
          borderLeft: `3px solid ${theme.colors.grey[0]}`,
          backgroundColor: theme.colors.grey[0],
          color: `${theme.colors.grey[90]}`,

          '&:hover': {
            backgroundColor: `${theme.colors.grey[20]} !important`,
            // For some reason, the border seems 1 px smaller
            borderLeft: `3px solid ${theme.colors.grey[50]}`,
          },

          '&:active': {
            backgroundColor: `${theme.colors.primary[20]} !important`,
            borderLeft: `3px solid ${theme.colors.primary.default}`,
          },
          '&:last-child': {
            '& td': {
              borderBottom: 'none',
            },
          },
        },
      },
      MuiTableFooter: {
        root: {
          '& tr': {
            backgroundColor: `${theme.colors.grey[20]} !important`,
            borderLeft: 'unset !important',
            '&:hover, &:active': {
              backgroundColor: 'unset !important',
              borderLeft: 'unset',
            },
            '& td': {
              paddingTop: 0,
            },
          },
        },
      },
      MuiTableSortLabel: {
        root: {
          borderBottom: '1px solid transparent !important',
          '&:focus:not(:active)': {
            borderColor: `${theme.colors.tertiary.default} !important`,
          },
          color: `${theme.colors.grey[80]} !important`,
        },

        icon: {
          marginLeft: '10px',
        },
      },
      MuiListItemIcon: {
        root: {
          minWidth: '30px',
        },
      },
      // @ts-ignore material-ui support material-ui-lab but the type doesn't
      MuiAlert: {
        outlinedWarning: {
          borderColor: theme.colors.yellow[30],
          backgroundColor: theme.colors.yellow[20],
        },
        outlinedInfo: {
          borderColor: theme.colors.blue[30],
          backgroundColor: theme.colors.blue[20],
        },
        outlinedError: {
          borderColor: theme.colors.red[30],
          backgroundColor: theme.colors.red[20],
        },
        outlinedSuccess: {
          borderColor: theme.colors.green[30],
          backgroundColor: theme.colors.green[20],
        },
        message: {
          color: theme.colors.grey[90],
          fontSize: theme.text.body3.size,
          fontWeight: theme.text.body3.weight,
          padding: '6px 0 12px 0',
        },
        action: {
          display: 'block',
          color: theme.colors.grey[70],
        },
      },
      MuiPaginationItem: {
        page: {
          '&:hover': {
            backgroundColor: theme.colors.grey[30],
          },
          '&:active': {
            backgroundColor: theme.colors.grey[40],
          },
          '&$selected': {
            backgroundColor: `${theme.colors.primary[30]} !important`,
          },
          '&:focus': {
            border: `1px solid ${theme.colors.tertiary.default}`,
          },
        },
      },
    },
  });
