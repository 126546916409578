import {
  ReactElement,
  createContext,
  PropsWithChildren,
  useEffect,
  useMemo,
  useState,
  useRef,
  useContext,
} from 'react';
import { useLocation, useNavigate } from 'react-router-dom';

import { AvailableEntityMappings, EntityMapping, Namespace } from '@breathelife/types';

import { QuestionnaireVersionFilterContext } from './QuestionnaireVersionFilterContextProvider';
import { EntityMappingUnsavedChangesWarningModal } from '../Pages/Admin/Mappings/EntityMappingEditor/EntityMappingUnsavedChangesWarningModal';
import { QuestionnaireVersionsContext } from '../Pages/Admin/Questionnaire/ContextProvider/QuestionnaireVersionsContextProvider';
import { useUpdateEntityMappingMutation } from '../ReactQuery/Admin/EntityMappings/entityMappings.mutations';
import {
  isCompleteEntityMappingRequestOptions,
  useGetEntityMappingQuery,
} from '../ReactQuery/Admin/EntityMappings/entityMappings.queries';
import { CarrierContext } from './CarrierContext';

const INPUT_DATA_TYPE_DECLARATION = 'declare const sdk:SandboxSDK; declare const p:SDKParser;';

type SelectedEntityMappingOptions = Partial<{
  questionnaireVersionId: string;
  namespace: Namespace;
  mapping: string;
}>;

type EntityMappingContextValue = {
  availableEntityMappings: AvailableEntityMappings;
  selectedEntityMappingOptions: SelectedEntityMappingOptions;
  mappingCodeHasChanged: boolean;
  saveEntityMapping: () => void;
  updateSelectedEntityMappingOptions: (options: SelectedEntityMappingOptions) => void;
  canEditCode: boolean;
  entityMappingIsSelected: boolean;
  savedEntityMapping?: EntityMapping;
  setRetrieveUpdatedCodeFunction: (code: string) => void;
  typeDefinitions?: string;
  isFetching: boolean;
};

export const EntityMappingContext = createContext<EntityMappingContextValue>({
  availableEntityMappings: {},
  selectedEntityMappingOptions: {},
  mappingCodeHasChanged: false,
  saveEntityMapping: () => {},
  updateSelectedEntityMappingOptions: () => {},
  canEditCode: false,
  entityMappingIsSelected: false,
  setRetrieveUpdatedCodeFunction: () => {},
  isFetching: false,
});

export function EntityMappingContextProvider(props: PropsWithChildren<Record<string, any>>): ReactElement {
  const navigate = useNavigate();
  const location = useLocation();
  const { questionnaireVersionId } = useContext(QuestionnaireVersionFilterContext);
  const { entityMappings } = useContext(CarrierContext);
  const [selectedEntityMappingOptions, setSelectedEntityMappingOptions] = useState<SelectedEntityMappingOptions>({});

  const { questionnaireVersions } = useContext(QuestionnaireVersionsContext);
  const [mappingCodeHasChanged, setMappingCodeHasChanged] = useState<boolean>(false);
  const { data, isFetching } = useGetEntityMappingQuery(selectedEntityMappingOptions);

  const retrieveUpdatedCodeFunction = useRef<string | undefined>();
  const discardChangesAction = useRef<() => void>();

  const [confirmDiscardChangesModalIsOpen, setConfirmDiscardChangesModalIsOpen] = useState<boolean>(false);
  const [shouldPerformDiscardChangesAction, setShouldPerformDiscardChangesAction] = useState<boolean>(false);

  useEffect(() => {
    setSelectedEntityMappingOptions((prev) => ({ ...prev, questionnaireVersionId }));
  }, [questionnaireVersionId]);

  useEffect(() => {
    if (shouldPerformDiscardChangesAction) {
      discardChangesAction.current?.();
      discardChangesAction.current = undefined;
      setShouldPerformDiscardChangesAction(false);
    }
  }, [shouldPerformDiscardChangesAction]);

  useEffect(() => {
    setMappingCodeHasChanged(false);
  }, [selectedEntityMappingOptions]);

  useEffect(() => {
    if (mappingCodeHasChanged) {
      setConfirmDiscardChangesModalIsOpen(true);
      discardChangesAction.current = () => {
        navigate(location.pathname);
      };
    }
  }, [location.pathname]);

  const canEditCode: boolean = useMemo(
    () =>
      Boolean(
        selectedEntityMappingOptions.questionnaireVersionId &&
          questionnaireVersions.find(
            (questionnaireVersion) => questionnaireVersion.id === selectedEntityMappingOptions.questionnaireVersionId,
          )?.isDraft,
      ),
    [selectedEntityMappingOptions, questionnaireVersions],
  );

  const entityMappingIsSelected: boolean = useMemo(
    () => isCompleteEntityMappingRequestOptions(selectedEntityMappingOptions),
    [selectedEntityMappingOptions],
  );

  const updateEntityMappingMutation = useUpdateEntityMappingMutation({
    async onSettled() {
      setMappingCodeHasChanged(false);
    },
  });

  async function updateSelectedEntityMappingOptions(options: SelectedEntityMappingOptions): Promise<void> {
    setSelectedEntityMappingOptions((prev) => ({ ...prev, ...options }));
  }

  function tryToUpdateSelectedEntityMappingOptions(options: SelectedEntityMappingOptions): void {
    if (mappingCodeHasChanged) {
      setConfirmDiscardChangesModalIsOpen(true);
      discardChangesAction.current = () => {
        void updateSelectedEntityMappingOptions(options);
        discardChangesAction.current = undefined;
      };
    } else {
      void updateSelectedEntityMappingOptions(options);
    }
  }

  function saveEntityMapping(): void {
    if (entityMappingIsSelected && mappingCodeHasChanged && canEditCode && retrieveUpdatedCodeFunction.current) {
      const newCode = retrieveUpdatedCodeFunction.current;
      if (newCode !== undefined && data) {
        updateEntityMappingMutation.mutate({
          ...data.entityMapping,
          customCode: newCode,
        });
      }
    }
  }

  const availableEntityMappings = useMemo(() => {
    if (!entityMappings || !entityMappings.enabled) return {};

    if (entityMappings.carrierDefinedEntities.enabled) {
      return { ...entityMappings.mappings, CarrierDefined: undefined };
    } else {
      return entityMappings.mappings;
    }
  }, [entityMappings]);

  return (
    <EntityMappingContext.Provider
      value={{
        availableEntityMappings,
        selectedEntityMappingOptions,
        mappingCodeHasChanged,
        saveEntityMapping,
        updateSelectedEntityMappingOptions: tryToUpdateSelectedEntityMappingOptions,
        savedEntityMapping: data?.entityMapping,
        typeDefinitions: data?.typeDefinitions?.concat(`\n${INPUT_DATA_TYPE_DECLARATION}`),
        canEditCode,
        entityMappingIsSelected,
        setRetrieveUpdatedCodeFunction: (code: string) => {
          retrieveUpdatedCodeFunction.current = code;
          setMappingCodeHasChanged(true);
        },
        isFetching,
      }}
    >
      <EntityMappingUnsavedChangesWarningModal
        isOpen={confirmDiscardChangesModalIsOpen}
        closeModal={() => {
          setConfirmDiscardChangesModalIsOpen(false);
        }}
        onDiscardChanges={() => {
          setMappingCodeHasChanged(false);
          setConfirmDiscardChangesModalIsOpen(false);
          setShouldPerformDiscardChangesAction(true);
        }}
      />
      {props.children}
    </EntityMappingContext.Provider>
  );
}
