import { Ref, ReactElement } from 'react';

import { Button as MuiButton, ButtonProps, CircularProgressProps } from '@breathelife/mui';

import Typography from '../Typography';
import { StyledLoader } from './Styles';

// The `component` typing has been fixed in Material-ui Version 5. Unfortunately, in version 4, component
// is not typed properly in `ButtonProps` but is supported by the Button API.
export function Button(
  props: ButtonProps & {
    component?: unknown;
    isLoading?: boolean;
    loaderProps?: CircularProgressProps;
    innerRef?: Ref<HTMLButtonElement>;
  },
): ReactElement {
  const { children, innerRef, disabled, isLoading, loaderProps, ...restProps } = props;
  return (
    <MuiButton {...restProps} ref={innerRef} disabled={disabled ?? isLoading}>
      {isLoading && <StyledLoader size='20px' {...loaderProps} />}
      {!isLoading && <Typography variant='button'>{children}</Typography>}
    </MuiButton>
  );
}
