import { Fragment, ReactElement, useCallback } from 'react';
import { useTranslation } from 'react-i18next';

import { Box, ListItemText } from '@breathelife/mui';
import { FileTemplate, FileTemplateOrigin, SupportedApplicationFileMimeTypes } from '@breathelife/types';
import { ImageTypes, Loader } from '@breathelife/ui-components';

import { Icon } from '../../../../../../Components/Icons';
import Typography from '../../../../../../Components/Typography';
import { useCarrierContext, useDispatch, useLocale } from '../../../../../../Hooks';
import { notificationSlice } from '../../../../../../ReduxStore/Notification/NotificationSlice';
import { LinkButton, LinkUploadButton } from './Styles';

type MissingRequiredFileProps = {
  isUploading: boolean;
  onFileUpload: (file: File, template: FileTemplate) => void;
  template: FileTemplate;
};

export function MissingRequiredFile(props: MissingRequiredFileProps): ReactElement {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const { isUploading, onFileUpload, template } = props;
  const {
    carrierInfo: { validFileUploadMimeTypes },
  } = useCarrierContext();

  const platformLanguage = useLocale();
  //  Display file name according to application language
  //  if translation does not exit: default to english name
  const localizedFileName = template.localizedName[platformLanguage] ?? template.localizedName.en;
  const mimeTypes =
    validFileUploadMimeTypes && validFileUploadMimeTypes.length > 0
      ? validFileUploadMimeTypes
      : SupportedApplicationFileMimeTypes;

  const onUploadFilledDocument = useCallback(
    async (file: File) => {
      onFileUpload(file, template);
    },
    [onFileUpload, template],
  );

  const onDownloadTemplate = useCallback(async () => {
    const { origin, externalUrl } = template;
    let url: string | null;

    switch (origin) {
      case FileTemplateOrigin.ExternalStatic:
        url = externalUrl;
        break;

      default:
        // TODO: get signed URL from GCP buckets
        url = null;
        break;
    }

    if (!url) {
      dispatch(
        notificationSlice.actions.setError({
          message: t('notifications.failedToFetchEntity', { entity: t('fileUrl') }),
        }),
      );
      return;
    }

    window.open(url, '_blank')?.focus();
  }, [dispatch, t, template]);

  return (
    <ListItemText>
      <Box display='flex' flexDirection='row' my={3}>
        <Box mr={2}>
          {isUploading ? (
            <Loader size='24' />
          ) : (
            <Icon
              name='warning'
              variant='yellow'
              fill='none'
              imageType={ImageTypes.meaningful}
              ariaLabel={t('assistedApplication.warning')}
            />
          )}
        </Box>
        <Box>
          <Box mb={1}>
            <Typography variant='h4'>{localizedFileName}</Typography>
          </Box>
          <Box display='flex' flexDirection='row' alignItems='center'>
            {[FileTemplateOrigin.ExternalStatic, FileTemplateOrigin.GCPBucket].includes(template.origin) && (
              <Fragment>
                <Box>
                  <LinkButton color='primary' disabled={isUploading} onClick={onDownloadTemplate}>
                    {t('cta.downloadDocument')}
                  </LinkButton>
                </Box>
                <Box component='span' mx={1}>
                  |
                </Box>
              </Fragment>
            )}
            <Box>
              <LinkUploadButton
                color='primary'
                disabled={isUploading}
                mimeTypes={mimeTypes}
                onChange={onUploadFilledDocument}
                component='label'
              >
                {t('cta.uploadFilledDocument')}
              </LinkUploadButton>
            </Box>
          </Box>
        </Box>
      </Box>
    </ListItemText>
  );
}
