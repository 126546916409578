import { ReactElement, useCallback, useContext, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { Tooltip } from '@breathelife/mui';
import { LaunchApplicationType, QuestionnairePreview } from '@breathelife/types';

import { SubmitButton } from '../../../../../Components/Button/SubmitButton';
import { Select } from '../../../../../Components/Select/Select';
import Typography from '../../../../../Components/Typography';
import { useCarrierContext, useSelector } from '../../../../../Hooks';
import { useDispatch } from '../../../../../Hooks/useDispatch';
import { ModalLayout } from '../../../../../Layouts/Modal/ModalLayout';
import { LeadTab } from '../../../../../Models/Layout';
import { parsePreview } from '../../../../../Models/QuestionnairePreview';
import { generateApplicationUrl } from '../../../../../Navigation/Urls';
import { fetchProducts } from '../../../../../ReduxStore/Admin/ProductManagement/ProductManagementOperations';
import {
  getIsLoadingProducts,
  getProducts,
} from '../../../../../ReduxStore/Admin/ProductManagement/ProductManagementSelectors';
import styled from '../../../../../Styles/themed-styled-components';
import { QuestionnaireVersionDataContext } from '../../ContextProvider/QuestionnaireVersionDataContextProvider';
import { useOpenQuestionnairePreviewLink } from '../Hooks/useOpenQuestionnairePreviewLink';
import { useQuestionnairePreviewApplication } from '../Hooks/useQuestionnairePreviewApplication';

const StyledSelect = styled(Select)`
  &&& {
    width: 100%;
    margin-top: 16px;
  }
`;

type Props = {
  isOpen: boolean;
  onClose: () => void;
};

export function PreviewConfigurationModal(props: Props): ReactElement | null {
  const { isOpen, onClose } = props;
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const { features } = useCarrierContext();

  const isNeedsAnalysisEnabled = !!features?.needsAnalysis?.enabled;

  const launchApplicationType =
    features.launchApplication.enabled && features.launchApplication.type ? features.launchApplication.type : undefined;
  const isLoadingProducts = useSelector(getIsLoadingProducts);
  const products = useSelector(getProducts);
  const insuranceProductsExist = !!products?.length;
  const { questionnaireVersionId } = useContext(QuestionnaireVersionDataContext);
  const openQuestionnairePreviewLink = useOpenQuestionnairePreviewLink();

  const [selectedOption, setSelectedOption] = useState<QuestionnairePreview | null>(null);
  const existingApplication = useQuestionnairePreviewApplication({
    isNeedsAnalysisEnabled,
    questionnaireVersionId,
    selectedOption,
  });

  useEffect(() => {
    if (isOpen && !insuranceProductsExist) {
      void fetchProducts()(dispatch);
    }
  }, [isOpen, dispatch, insuranceProductsExist]);

  const onOpenAssistedApplication = useCallback(() => {
    if (existingApplication) {
      const assistedApplicationPath = generateApplicationUrl(
        existingApplication.applicationId,
        existingApplication.leadId,
        LeadTab.active,
      );

      const url = `${window.location.origin}${assistedApplicationPath}`;
      window.open(url, '_blank');
    }
  }, [existingApplication]);

  const onPreviewClick = useCallback(() => {
    if (!questionnaireVersionId || !selectedOption) return;

    if (selectedOption === QuestionnairePreview.assistedApplication && isNeedsAnalysisEnabled && existingApplication) {
      void onOpenAssistedApplication();
    } else {
      void openQuestionnairePreviewLink({
        questionnaireVersionId,
        previewType: selectedOption,
      });
    }

    onClose();
  }, [
    questionnaireVersionId,
    selectedOption,
    isNeedsAnalysisEnabled,
    existingApplication,
    onClose,
    onOpenAssistedApplication,
    openQuestionnairePreviewLink,
  ]);

  const options = useMemo(() => {
    const baseOptions = [
      {
        value: QuestionnairePreview.pdf,
        label: t('admin.questionnaireManagement.previewOptions.pdf'),
      },
    ];

    if (isNeedsAnalysisEnabled) {
      baseOptions.push({
        value: QuestionnairePreview.consumerFlow,
        label: t('admin.questionnaireManagement.previewOptions.consumerFlow'),
      });
    }

    if (launchApplicationType === LaunchApplicationType.assistedApplication) {
      baseOptions.push({
        value: QuestionnairePreview.assistedApplication,
        label: t('admin.questionnaireManagement.previewOptions.assistedApplication'),
      });
    }

    return baseOptions;
  }, [isNeedsAnalysisEnabled, launchApplicationType, t]);

  const canOpenPreview = useMemo(() => {
    // Check if there's at least one product setup in the product editor.
    // This is to make sure a product can be selected to continue in the consumer flow or assisted application.
    if (products.length <= 0) {
      return false;
    }

    switch (selectedOption) {
      case QuestionnairePreview.assistedApplication:
        return true;

      case QuestionnairePreview.consumerFlow:
        return isNeedsAnalysisEnabled;

      case QuestionnairePreview.pdf:
        return true;

      default:
        return false;
    }
  }, [selectedOption, isNeedsAnalysisEnabled, products.length]);

  if (isLoadingProducts) {
    return null;
  }

  return (
    <ModalLayout
      maxWidth='sm'
      title={t('admin.questionnaireManagement.preview')}
      isOpen={isOpen}
      closeModal={onClose}
      submitButton={
        !canOpenPreview ? (
          <Tooltip
            title={<div>{t(`admin.questionnaireManagement.previewRequirements.${selectedOption}`)}</div>}
            placement='top'
          >
            {/* The div is mandatory because when the button is disabled it's not firing events, so the tooltip doesn't work. */}
            <div>
              <OpenButton onClick={onPreviewClick} canOpenPreview={canOpenPreview} />
            </div>
          </Tooltip>
        ) : (
          <OpenButton onClick={onPreviewClick} canOpenPreview={canOpenPreview} />
        )
      }
    >
      <Typography variant='body1' grey={70}>
        {t('admin.questionnaireManagement.previewDescription')}
      </Typography>
      <StyledSelect
        id={'previewUseCaseSelectionInput'}
        options={options}
        value={selectedOption || ''}
        onChange={(value) => setSelectedOption(parsePreview(value))}
      />
    </ModalLayout>
  );
}

interface OpenButtonProps {
  onClick: () => void;
  canOpenPreview: boolean;
}

function OpenButton(props: OpenButtonProps): ReactElement {
  const { onClick, canOpenPreview } = props;
  const { t } = useTranslation();
  return (
    <SubmitButton disabled={!canOpenPreview} onClick={onClick}>
      {t('admin.questionnaireManagement.open')}
    </SubmitButton>
  );
}
