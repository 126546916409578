import { ReactElement, ReactNode } from 'react';

import { Box, Checkbox as MuiCheckbox, CheckboxProps, FormControl, FormHelperText, FormLabel } from '@breathelife/mui';

import { CheckboxOutlineBlankIcon } from '../../Assets/Icons/CheckboxOutlineBlankIcon';
import Typography from '../../Components/Typography';
import { StyledFormControlLabel } from './Styles';

type FormControlLabelProps = {
  label?: string;
};

type CheckboxGroupProps = {
  label?: string;
  children: ReactNode;
  validationError?: string;
};

export function Checkbox(props: CheckboxProps & FormControlLabelProps): ReactElement {
  return (
    <StyledFormControlLabel
      control={
        <MuiCheckbox
          icon={<CheckboxOutlineBlankIcon role='img' />}
          checked={props.checked}
          onChange={props.onChange}
          onBlur={props.onBlur}
          disabled={props.disabled}
          onFocus={props.onFocus}
          color={props.color}
        />
      }
      label={props.label}
    />
  );
}

export function CheckboxGroup(props: CheckboxProps & CheckboxGroupProps): ReactElement {
  return (
    <FormControl component='fieldset' fullWidth>
      {props.label && (
        <Box mb={1}>
          <FormLabel component='legend'>
            <Typography variant='body1' grey={70}>
              {props.label}
            </Typography>
          </FormLabel>
        </Box>
      )}
      {props.children}
      {props.validationError && <FormHelperText error>{props.validationError}</FormHelperText>}
    </FormControl>
  );
}
