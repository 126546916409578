import { ReactElement, useContext, useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import { Box, Button } from '@breathelife/mui';
import { namespace } from '@breathelife/types';

import { CarrierContext } from '../../../../Context/CarrierContext';
import { EntityMappingContext } from '../../../../Context/EntityMappingContextProvider';
import styled from '../../../../Styles/themed-styled-components';
import { EditorDropdown } from '../../Questionnaire/QuestionnaireEditor/Components/EditorDropdown';
import { QuestionnaireVersionFilter } from '../QuestionnaireVersionFilter';
import { EntityMappingDependencies } from './EntityMappingDependencies';
import { EntityMappingFilter } from './EntityMappingFilter';

const SidebarMenuWrapper = styled.div`
  display: grid;
  grid-auto-flow: row;
  grid-gap: 15px;
`;

export function EntityMappingSidebar(): ReactElement {
  const { t } = useTranslation();

  const {
    availableEntityMappings,
    selectedEntityMappingOptions,
    updateSelectedEntityMappingOptions,
    savedEntityMapping,
    saveEntityMapping,
    mappingCodeHasChanged,
  } = useContext(EntityMappingContext);

  const { entityMappings } = useContext(CarrierContext);

  const onQuestionnaireVersionChange = (): void => {
    const optionsReset = {
      namespace: undefined,
      mapping: undefined,
    };

    updateSelectedEntityMappingOptions(optionsReset);
  };

  const onNamespaceChange = (value: string | null): void => {
    const parsedNamespace = namespace(value);

    if (parsedNamespace) {
      updateSelectedEntityMappingOptions({
        namespace: parsedNamespace,
        mapping: undefined,
      });
    }
  };

  const hasCarrierDefinedEntities = useMemo<boolean>(() => {
    if (!entityMappings || !entityMappings.enabled) return false;

    return entityMappings.carrierDefinedEntities.enabled;
  }, [entityMappings]);

  return (
    <SidebarMenuWrapper>
      <QuestionnaireVersionFilter onChange={onQuestionnaireVersionChange} />

      <EditorDropdown
        label={t('admin.entityMappings.namespaceDropdownLabel')}
        selectedOptionValue={selectedEntityMappingOptions.namespace}
        options={Object.keys(availableEntityMappings).map((namespace) => ({
          label: namespace,
          value: namespace,
        }))}
        disableClearable={false}
        onChange={onNamespaceChange}
      />

      <EntityMappingFilter />

      {hasCarrierDefinedEntities && savedEntityMapping && (
        <EntityMappingDependencies entityMapping={savedEntityMapping} />
      )}

      <Box display='flex' justifyContent='flex-end'>
        <Button onClick={saveEntityMapping} disabled={!mappingCodeHasChanged} variant='contained' color='primary'>
          {t('admin.entityMappings.saveButtonLabel')}
        </Button>
      </Box>
    </SidebarMenuWrapper>
  );
}
