import { EntityMapping, GetEntityMappingParams, GetEntityMappingReturnValue } from '@breathelife/types';

import ApiService from './ApiService';

export async function getEntityMapping(options: GetEntityMappingParams): Promise<GetEntityMappingReturnValue> {
  const response = await ApiService.admin.retrieveEntityMapping(options);
  return response.data;
}

export async function updateEntityMapping(data: EntityMapping): Promise<EntityMapping> {
  const response = await ApiService.admin.saveEntityMapping(data);
  return response.data;
}
