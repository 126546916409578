import { ReactElement, useState, Fragment, useRef } from 'react';
import styled from '../../../../Styles/themed-styled-components';
import { Sidebar } from './Sidebar';
import { CreateEntityModal } from './CreateEntityModal';
import { UpdateActionButtons } from './UpdateActionButtons';
import { CarrierEntityFilter } from './CarrierEntityFilter';
import { CarrierEntityCodeEditor } from './CarrierEntityCodeEditor';
import { QuestionnaireVersionFilter } from '../QuestionnaireVersionFilter';

const PageLayout = styled.div`
  overflow-x: hidden;
  display: flex;
  flex-grow: 1;
`;

export function CarrierEntityEditor(): ReactElement {
  const [editMode, setEditMode] = useState<'create' | 'update'>('update');
  const [entityId, setEntityId] = useState('');
  const entityCodeRef = useRef('');

  const setFocusedEntity = (entityId: string): void => {
    setEntityId(entityId);
    setEditMode('update');
  };

  const resetFocusedEntity = (): void => {
    setEntityId('');
    entityCodeRef.current = '';
  };

  const onEditorCodeChange = (code: string): void => {
    entityCodeRef.current = code;
  };

  return (
    <Fragment>
      <PageLayout>
        <Sidebar>
          <QuestionnaireVersionFilter onChange={resetFocusedEntity} />
          <CarrierEntityFilter entityId={entityId} setEntity={setEntityId} onCreate={() => setEditMode('create')} />
          <UpdateActionButtons entityId={entityId} entityCode={entityCodeRef} onDelete={() => setEntityId('')} />
        </Sidebar>

        <CarrierEntityCodeEditor entityId={entityId} onCodeChange={onEditorCodeChange} />
      </PageLayout>

      <CreateEntityModal
        isOpen={editMode === 'create'}
        onCreate={setFocusedEntity}
        onCancel={() => setEditMode('update')}
      />
    </Fragment>
  );
}
