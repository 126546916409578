import { Box } from '@breathelife/mui';
import styled, { css } from '../../../Styles/themed-styled-components';

import { GhostButton } from '../../../Components/Button/GhostButton';
import { GhostIconButton } from '../../../Components/Button/GhostIconButton';
import Typography from '../../../Components/Typography';
import { NavigationButton } from './NavigationButton';
import { PROPOSED_INSURED_TAB_BAR_HEIGHT_PX } from './ProposedInsuredTabBar/Styles';

const HEADER_HEIGHT_PX = 72;

export const ViewContainer = styled(Box)<{ withTabBar: boolean }>`
  display: flex;
  height: calc(
    100vh -
      ${(props) => (props.withTabBar ? HEADER_HEIGHT_PX + PROPOSED_INSURED_TAB_BAR_HEIGHT_PX : HEADER_HEIGHT_PX)}px
  );

  @media (max-width: ${(props) => props.theme.moduleBreakpoints.sm}) {
    flex-direction: column;
  }
`;

export const NavigationContainer = styled(Box)`
  flex: 1;
  padding: 24px;
  overflow: auto;
  border-right: 1px solid ${(props) => props.theme.colors.grey[30]};
  max-width: 20%;
  min-width: 10%;

  nav {
    width: 100%;
  }

  ol {
    list-style: none;
    margin: 0;
    padding: 0;
  }

  @media (max-width: ${(props) => props.theme.moduleBreakpoints.sm}) {
    max-width: initial;
    border-bottom: 1px solid ${(props) => props.theme.colors.grey[30]};
  }
`;

export const ScrollContainer = styled(Box)`
  overflow: auto;
  flex: 3;
  scroll-behavior: smooth;
`;

export const ScrollContent = styled(Box)`
  display: flex;

  @media (max-width: ${(props) => props.theme.moduleBreakpoints.sm}) {
    flex-direction: column;
    padding: 15px;
  }
`;

export const FormContainer = styled(Box)`
  min-width: 700px;
  max-width: 700px;

  @media (max-width: ${(props) => props.theme.moduleBreakpoints.sm}) {
    margin: 0px !important;
    min-width: initial;
    max-width: initial;
  }
`;

export const VisuallyHidden = styled.span`
  position: absolute !important;
  width: 1px !important;
  height: 1px !important;
  padding: 0 !important;
  margin: -1px !important;
  overflow: hidden !important;
  clip: rect(0, 0, 0, 0) !important;
  white-space: nowrap !important;
  border: 0 !important;
`;

export const SectionListItem = styled.li`
  &:not(:last-child) {
    margin-bottom: 8px;
  }
`;

export const SubsectionListItem = styled.li`
  margin: 4px 0 4px 16px;

  &:first-child {
    margin-top: 12px;
  }

  &:not(:last-child) {
    margin-bottom: 4px;
  }
`;

export const NavButton = styled(GhostButton)<{ $isActive?: boolean }>`
  &&&&& {
    min-height: 48px;
    width: 100%;
    border: 1px solid transparent;
    justify-content: start;
    text-align: start;

    ${(props) =>
      props.$isActive &&
      css`
        background-color: ${props.theme.colors.primary[20]};
        border-color: ${props.theme.colors.primary[30]};
      `};
  }
`;

export const SubNavLink = styled.a<{ $isActive?: boolean }>`
  display: block;
  margin: 4px 0px;
  padding: 4px 0px 4px 16px;
  color: ${(props) => props.theme.colors.grey[60]};
  border-left: 1px solid transparent;
  transition: all 0.1s ease;

  &:hover {
    color: ${(props) => props.theme.colors.grey[90]};
  }

  ${(props) =>
    props.$isActive &&
    css`
      margin: 4px 0px;
      border-color: ${props.theme.colors.primary.default};
      color: ${props.theme.colors.grey[90]};
    `};
`;

export const StyledNextNavigationButton = styled(NavigationButton)`
  && {
    margin-left: auto;
  }
`;

export const ApplicationDetailsWidgetViewContainer = styled(Box)`
  width: 360px;

  @media (max-width: ${(props) => props.theme.moduleBreakpoints.sm}) {
    width: initial;
  }
`;

export const StyledActionGhostIconButton = styled(GhostIconButton)<{ $fullWidth?: boolean }>`
  &&&& {
    ${(props) =>
      props.$fullWidth &&
      css`
        width: 100%;
      `};
    justify-content: flex-start;
    color: ${(props) => props.theme.colors.primary.default};
  }
`;

export const HeaderButtonContainer = styled.div`
  align-items: center;
  display: flex;

  @media (max-width: ${(props) => props.theme.moduleBreakpoints.sm}) {
    flex-direction: column;
    min-height: 150px;
    justify-content: space-evenly;
  }
`;

export const HeaderTabContainer = styled.div`
  align-items: flex-end;
  display: flex;
  flex-grow: 1;
`;

export const HeaderTitle = styled(Typography)`
  line-height: 21px;
  color: ${(props) => props.theme.colors.grey[90]};
  margin: 0;
`;

export const HeaderTitleContainer = styled.div`
  align-items: center;
  display: flex;
  flex-grow: 1;
  padding-left: 8px;

  @media (max-width: ${(props) => props.theme.moduleBreakpoints.sm}) {
    padding-bottom: 16px;
  }
`;

export const HeaderWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;

  @media (max-width: ${(props) => props.theme.moduleBreakpoints.sm}) {
    flex-direction: column;
  }
`;

export const CloseButtonContainer = styled(Box)`
  display: flex;
  align-items: center;
  justify-content: center;
  width: fit-content;
`;

export const HeaderWrapperContainer = styled(Box)`
  border-bottom: 1px solid ${(props) => props.theme.colors.grey[30]};
  display: flex;
  height: ${HEADER_HEIGHT_PX}px;
  justify-content: space-between;
  padding: 0px 16px;

  @media (max-width: ${(props) => props.theme.moduleBreakpoints.sm}) {
    height: fit-content;
    padding: 16px;
  }
`;
