import { Dispatch, ReactElement, SetStateAction, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import {
  Box,
  Divider,
  ExpandMoreIcon,
  ExpansionPanel,
  ExpansionPanelDetails as MUIExpansionPanelDetails,
  ExpansionPanelSummary,
  Typography,
} from '@breathelife/mui';

import { Image } from '../../../Components/Images/Image';
import { CloudinaryUpload } from '../../../Components/Upload/Cloudinary/CloudinaryUpload';
import styled from '../../../Styles/themed-styled-components';
import { Theme } from '@breathelife/types';

const ExpansionPanelDetails = styled(MUIExpansionPanelDetails)`
  &&& {
    align-items: center;
    justify-content: space-evenly;
  }
`;

type Props = {
  logoImgUrl: string;
  compactLogoImgUrl: string;
  updatedThemeData: Partial<Theme>;
  setUpdatedThemeData: Dispatch<SetStateAction<Partial<Theme>>>;
};

export function LogoUploadView({
  logoImgUrl: logoImgUrlProp,
  compactLogoImgUrl: compactLogoImgUrlProp,
  updatedThemeData,
  setUpdatedThemeData,
}: Props): ReactElement {
  const { t } = useTranslation();

  const [logoImgUrl, setLogoImgUrl] = useState<string>(logoImgUrlProp);
  const [compactLogoImgUrl, setCompactLogoImgUrl] = useState<string>(compactLogoImgUrlProp);

  useEffect(() => {
    setLogoImgUrl(logoImgUrlProp);
    setCompactLogoImgUrl(compactLogoImgUrlProp);
  }, [logoImgUrlProp, compactLogoImgUrlProp]);

  return (
    <ExpansionPanel defaultExpanded>
      <ExpansionPanelSummary expandIcon={<ExpandMoreIcon />} aria-controls='panel1c-content' id='panel1c-header'>
        <Box display='flex' flexDirection='row' alignItems='center'>
          <Typography variant='h2' style={{ marginRight: '8px' }}>
            {t('admin.themeManagement.logos.title')}
          </Typography>
        </Box>
      </ExpansionPanelSummary>
      <Divider />
      <ExpansionPanelDetails>
        <Box display='flex' flexDirection='column' alignItems='center'>
          <Image src={logoImgUrl} radius={0} height={48} width={138} isProfile={false} />
          <CloudinaryUpload
            buttonText={t('admin.themeManagement.logos.regularButtonText')}
            setPicture={(logo) => {
              setLogoImgUrl(logo);
              setUpdatedThemeData({ ...updatedThemeData, logoImgUrl: logo });
            }}
            minWidth={138}
            minHeight={48}
            uploadPreset='px_admin_regular_logo'
            clientAllowedFormats={['jpg, jpeg', 'png']}
          />
        </Box>
        <Box display='flex' flexDirection='column' alignItems='center'>
          <Image src={compactLogoImgUrl} radius={0} height={48} width={48} isProfile={false} />
          <CloudinaryUpload
            buttonText={t('admin.themeManagement.logos.compactButtonText')}
            setPicture={(logo) => {
              setCompactLogoImgUrl(logo);
              setUpdatedThemeData({ ...updatedThemeData, compactLogoImgUrl: logo });
            }}
            minWidth={40}
            minHeight={40}
            uploadPreset='px_admin_compact_logo'
            clientAllowedFormats={['jpg, jpeg', 'png']}
          />
        </Box>
      </ExpansionPanelDetails>
    </ExpansionPanel>
  );
}
