import { Box } from '@breathelife/mui';
import _ from 'lodash';
import { ReactElement, useCallback, useContext, useEffect, useMemo, useState } from 'react';

import {
  InsuranceProductCreateRequest,
  InsuranceProductUpdateRequest,
  Language,
  SupportedBuyer,
} from '@breathelife/types';

import { Drawer } from '../../../../Components/Drawer/Drawer';
import { CarrierContext } from '../../../../Context/CarrierContext';
import { productFormSchema } from '../../../../Helpers/inputValidation/form/product';
import { useDispatch, useSelector } from '../../../../Hooks';
import { getCurrentLocale } from '../../../../Localization/utils';
import { DetailViewType, ModalType } from '../../../../Models/Layout';
import { useFindFirmsQuery } from '../../../../ReactQuery/Admin/Firm/firm.queries';
import * as ProductManagementOperations from '../../../../ReduxStore/Admin/ProductManagement/ProductManagementOperations';
import {
  getIsLoadingProducts,
  getSelectedProduct,
} from '../../../../ReduxStore/Admin/ProductManagement/ProductManagementSelectors';
import { productManagementSlice } from '../../../../ReduxStore/Admin/ProductManagement/ProductManagementSlice';
import { defaultState as defaultLayoutState, layoutSlice } from '../../../../ReduxStore/Layout/LayoutSlice';
import { FirmDetailContainer } from '../FirmDetail/FirmDetailContainer';
import { PricingDetailContainer } from '../PricingDetail/PricingDetailContainer';
import { AllQuestionnairesNodeIdsContextProvider } from '../AllQuestionnairesNodeIdsContextProvider';
import { LocalizableInputState, ProductFormFields, ValueChangeFunctionPayload } from '../types';
import { ProductDetailHeader } from './ProductDetailHeader';
import { ProductForm } from './ProductForm';

function getDefaultLocalizableState(enabledLanguages: Language[]): LocalizableInputState {
  return enabledLanguages.reduce((acc, language) => ({ ...acc, [language]: '' }), {});
}

function getDefaultProductFormState(enabledLanguages: Language[]): ProductFormFields {
  const localizableDefaultState = getDefaultLocalizableState(enabledLanguages);

  const productFields = {
    name: localizableDefaultState,
    productId: undefined,
    lineOfBusiness: undefined,
    type: undefined,
    holdingForm: undefined,
    minimumAge: undefined,
    maximumAge: undefined,
    policyDetailsUrl: undefined,
    temporaryInsuranceUrl: undefined,
    supportedBuyers: [SupportedBuyer.consumer, SupportedBuyer.pro],
    renewable: false,
    maxRenewalAge: undefined,
    convertible: false,
    maxConversionAge: undefined,
    requiresMedicalExam: false,
    isRider: false,
    index: undefined,
    additionalFeatures: [],
    coverageAmountRanges: [],
    linkedProducts: [],
    linkedAddons: [],
    selector: {
      coverageAmount: undefined,
      id: undefined,
    },
  };

  return productFields;
}

export function ProductDetailContainer(): ReactElement {
  const dispatch = useDispatch();
  const locale = getCurrentLocale();
  const { languageSettings } = useContext(CarrierContext);
  const { isOpen: isCreateProductDrawerOpen, type: viewType } = useSelector(
    (store) => store.leadPlatform.layout.rightPanelState,
  );
  const { isOpen: isModalOpen, type: modalType } = useSelector((store) => store.leadPlatform.layout.modalState);

  const { data: firms } = useFindFirmsQuery();

  const selectedProduct = useSelector(getSelectedProduct);
  const isLoadingProducts = useSelector(getIsLoadingProducts);

  const [validationErrors, setValidationErrors] = useState<Partial<Record<keyof ProductFormFields, any>>>({});

  const [productFormState, setProductFormState] = useState<ProductFormFields>(
    getDefaultProductFormState(languageSettings.enabledLanguages),
  );

  const handleProductInputValueChange = useCallback((payload: ValueChangeFunctionPayload) => {
    setProductFormState((prevFormState) =>
      _.setWith(_.cloneDeep(prevFormState), payload.path, payload.value, _.cloneDeep),
    );
  }, []);

  const handleListFieldItemDelete = useCallback(
    (payload) => {
      const value = _.get(productFormState, payload.path, []).filter(
        (_item: any, index: number) => index !== payload.index,
      );
      setProductFormState({ ...productFormState, [payload.path]: value });
    },
    [productFormState],
  );

  useEffect(() => {
    if (!selectedProduct) return;

    const productFormProperties: ProductFormFields = _.omit(selectedProduct, ['firm']);

    if (!selectedProduct.selector) {
      productFormProperties.selector = {
        coverageAmount: undefined,
        id: undefined,
      };
    }

    setProductFormState({
      ...productFormProperties,
    });
  }, [selectedProduct]);

  const isProductFormValid = useMemo(() => {
    return (
      productFormSchema(languageSettings.enabledLanguages).isValidSync(productFormState) && !validationErrors.productId
    );
  }, [productFormState, validationErrors.productId, languageSettings.enabledLanguages]);

  const onCreateProduct = useCallback(() => {
    const data = {
      ...productFormState,
    } as InsuranceProductCreateRequest;

    void dispatch(ProductManagementOperations.createProduct(data));
  }, [dispatch, productFormState]);

  const onSaveProduct = useCallback(() => {
    const data = {
      ...productFormState,
    } as InsuranceProductUpdateRequest;

    void dispatch(ProductManagementOperations.updateProduct(data.id, data));
  }, [dispatch, productFormState]);

  const onArchiveClick = useCallback(() => {
    const data = {
      ...productFormState,
      archived: !productFormState.archived,
    } as InsuranceProductUpdateRequest;

    void dispatch(ProductManagementOperations.updateProduct(data.id, data));
  }, [dispatch, productFormState]);

  const resetProductForm = useCallback(() => {
    setValidationErrors({});
    setProductFormState(getDefaultProductFormState(languageSettings.enabledLanguages));
  }, [languageSettings.enabledLanguages]);

  const onCloseProductDrawer = useCallback(() => {
    if (!isCreateProductDrawerOpen) return;
    resetProductForm();
    void dispatch(productManagementSlice.actions.setSelectedProduct({ selectedProduct: undefined }));
    void dispatch(layoutSlice.actions.setRightPanelState({ rightPanelState: defaultLayoutState.rightPanelState }));
  }, [dispatch, isCreateProductDrawerOpen, resetProductForm]);

  const productUUID = (productFormState as InsuranceProductUpdateRequest).id;

  const firmsSelect = useMemo(
    () => (Array.isArray(firms) ? firms.map((firm) => ({ name: firm.name, id: firm.id })) : []),
    [firms],
  );

  return (
    <AllQuestionnairesNodeIdsContextProvider>
      <Drawer open={isCreateProductDrawerOpen} onClose={onCloseProductDrawer}>
        <ProductDetailHeader
          onClose={onCloseProductDrawer}
          onCreateClick={onCreateProduct}
          onArchiveClick={onArchiveClick}
          onSaveClick={onSaveProduct}
          isFormValid={isProductFormValid}
          viewType={viewType ?? DetailViewType.create}
          productName={productFormState.name?.[locale]}
          isProductArchived={productFormState.archived ?? false}
          isExistingProduct={selectedProduct !== undefined && selectedProduct.id !== ''}
        />
        {!isLoadingProducts && isCreateProductDrawerOpen && (
          <Box p={3}>
            <ProductForm
              validationErrors={validationErrors}
              setValidationErrors={setValidationErrors}
              productFormState={productFormState}
              firms={firmsSelect}
              onInputValueChange={handleProductInputValueChange}
              enabledLanguages={languageSettings.enabledLanguages}
              onListFieldItemDelete={handleListFieldItemDelete}
              productUUID={productUUID}
            />
          </Box>
        )}
      </Drawer>
      {isModalOpen && modalType === ModalType.createFirm ? <FirmDetailContainer /> : null}
      {isModalOpen && modalType === ModalType.productPricing ? <PricingDetailContainer /> : null}
    </AllQuestionnairesNodeIdsContextProvider>
  );
}
