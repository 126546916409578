import { ReactElement, useCallback, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { useTheme } from '../../../../Styles/themed-styled-components';

import { Skeleton, Box } from '@breathelife/mui';
import { User } from '@breathelife/types';

import { CloseButton } from '../../../../Components/Button/CloseButton';
import { ProfilePictureButton } from '../../../../Components/Button/ProfilePictureButton';
import Typography from '../../../../Components/Typography';
import { CloudinaryUpload } from '../../../../Components/Upload/Cloudinary/CloudinaryUpload';
import { useCarrierContext } from '../../../../Hooks';
import { ActionButtons } from './ActionButtons';

type UserDetailHeaderProps = {
  email: string;
  firstName: string;
  isLoading: boolean;
  lastName: string;
  isSso?: boolean;
  onClose: () => void;
  onOpenResendInvitationModal: (user: User) => void;
  onProfilePictureChange: (pictureUrl: string) => void;
  profilePicture?: string;
  toggleBlockUser: (user: User) => void;
  user?: User;
};

export function UserDetailHeader(props: UserDetailHeaderProps): ReactElement {
  const { t } = useTranslation();
  const theme = useTheme();
  const { ssoProfileFields } = useCarrierContext();
  const uploadButtonRef = useRef<HTMLButtonElement>(null);
  const {
    email: emailProp,
    firstName,
    isLoading,
    lastName,
    onClose,
    onOpenResendInvitationModal,
    onProfilePictureChange,
    profilePicture,
    toggleBlockUser,
    user,
    isSso,
  } = props;

  const userName = `${firstName} ${lastName}`;
  const userFullName = !user?.auth0Id && !firstName && !lastName ? t('userDetailDrawer.newProfile') : userName;
  const email = !user?.auth0Id && !emailProp ? t('userDetailDrawer.emailAddress') : emailProp;

  const onProfilePictureClick = useCallback(() => {
    if (!uploadButtonRef.current) return;
    // This is triggering the uploadButton click inside the CloudinaryUploader component
    uploadButtonRef.current.click();
  }, [uploadButtonRef]);

  return (
    <Box py={2} px={3} minHeight={120} display='flex' flexDirection='column' justifyContent='space-between'>
      <Box display='flex' justifyContent='space-between' alignItems='flex-start'>
        <Box display='flex' alignItems='center'>
          <Box>
            <ProfilePictureButton
              onClick={onProfilePictureClick}
              picture={profilePicture}
              width={56}
              height={56}
              disabled={isSso && ssoProfileFields.includes('picture')}
            />
            <CloudinaryUpload
              setPicture={onProfilePictureChange}
              buttonText={t('modals.editUser.uploadProfilePicture')}
              uploadButtonRef={uploadButtonRef}
              hideUploadButton
            />
          </Box>
          <Box ml={2}>
            {isLoading ? <Skeleton variant='rect' width={120} /> : <Typography variant='h1'>{userFullName}</Typography>}
            <Box mt={1}>
              {isLoading ? (
                <Skeleton variant='rect' width={150} />
              ) : (
                <Typography variant='body3' color={theme.colors.grey[70]}>
                  {email}
                </Typography>
              )}
            </Box>
          </Box>
        </Box>
        <CloseButton data-testid='createUserHeader-close' onClick={onClose} />
      </Box>
      <ActionButtons
        user={user}
        isLoading={isLoading}
        // TODO: we can use the user status here instead once the user status is available
        disableResendInvitationEmail={!user || !!user?.lastLogin}
        disableToggleBlockUser
        onOpenResendInvitationModal={onOpenResendInvitationModal}
        toggleBlockUser={toggleBlockUser}
      />
    </Box>
  );
}
