import { ReactElement, useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { EditorDropdown } from '../../Questionnaire/QuestionnaireEditor/Components/EditorDropdown';
import { useGetEntitiesQuery } from '../../../../ReactQuery/Admin/Entities/entities.queries';
import { useFetchQuestionnaireVersionQuery } from '../../../../ReactQuery/Admin/Questionnaire/questionnaireVersion.queries';
import { QuestionnaireVersionFilterContext } from '../../../../Context/QuestionnaireVersionFilterContextProvider';

type Props = {
  entityId: string;
  setEntity: (entityId: string) => void;
  onCreate: () => void;
};

const CREATE_OPTION_VALUE = '___create___';

export function CarrierEntityFilter(props: Props): ReactElement {
  const { questionnaireVersionId } = useContext(QuestionnaireVersionFilterContext);
  const { data: entities = [] } = useGetEntitiesQuery(questionnaireVersionId);
  const { data: questionnaireVersion } = useFetchQuestionnaireVersionQuery(questionnaireVersionId);
  const { t } = useTranslation();

  const onChange = (option: string | null): void => {
    if (!option) return;
    if (option === CREATE_OPTION_VALUE) {
      props.onCreate();
    } else {
      const id = option;
      props.setEntity(id);
    }
  };

  const entityNameOptions = entities.map((e) => ({ label: e.name, value: e.id }));
  const createEntityOption = { label: t('admin.entityMappings.createNewEntityOption'), value: CREATE_OPTION_VALUE };
  const options = questionnaireVersion?.isDraft ? [createEntityOption].concat(entityNameOptions) : entityNameOptions;
  const selectedOptionValue = entities.find((e) => e.id === props.entityId)?.id;

  return (
    <EditorDropdown
      disabled={!questionnaireVersionId}
      label={t('admin.entityMappings.nameDropdownLabel')}
      selectedOptionValue={selectedOptionValue}
      options={options}
      onChange={onChange}
    />
  );
}
