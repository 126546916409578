import { useTranslation } from 'react-i18next';
import { useMutation, useQueryClient, UseMutationResult, UseMutationOptions } from 'react-query';

import { Answers, CreateApplicationPayload } from '@breathelife/types';

import { useDispatch } from '../../Hooks';
import { getTranslationKeyFromErrorId } from '../../Localization/errorIdsToTranslationMap';
import { Application } from '../../Models/Application';
import { QueryId } from '../../ReactQuery/common/common.types';
import { notificationSlice } from '../../ReduxStore/Notification/NotificationSlice';
import {
  launchAssistedApplication,
  launchNewAssistedApplication,
  updateApplicationWithADOMinMax,
  updateAssistedApplicationAnswers,
} from '../../Services/AssistedApplicationsService';
import { useContext } from 'react';
import { LeadsPageDataContext } from '../../Pages/Leads/LeadsPageDataContextProvider';

export function useLaunchNewAssistedApplicationMutation(
  options?: UseMutationOptions<Application, unknown, CreateApplicationPayload>,
): UseMutationResult<Application, unknown, CreateApplicationPayload> {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const queryClient = useQueryClient();
  const { setIsLoadingApplication } = useContext(LeadsPageDataContext);
  return useMutation<Application, unknown, CreateApplicationPayload>(launchNewAssistedApplication, {
    ...options,
    onMutate: () => {
      setIsLoadingApplication(true);
    },
    onSettled: () => {
      setIsLoadingApplication(false);
    },
    onSuccess: async (data, variables, context) => {
      queryClient.setQueryData([QueryId.application, data.id], data);

      await queryClient.invalidateQueries([QueryId.lead, data.leadId]);
      await queryClient.invalidateQueries([QueryId.leads]);

      if (options?.onSuccess) {
        await options.onSuccess(data, variables, context);
      }
    },
    onError: async (error, variables, context) => {
      dispatch(
        notificationSlice.actions.setError({
          message: t('notifications.failedToCreateApplication'),
        }),
      );

      if (options?.onError) {
        await options.onError(error, variables, context);
      }
    },
  });
}

type LaunchAssistedApplicationParams = {
  applicationId: string;
  product?: string;
  coverageAmount?: number;
};

export function useLaunchAssistedApplicationMutation(
  options?: UseMutationOptions<Application, unknown, LaunchAssistedApplicationParams>,
): UseMutationResult<Application, unknown, LaunchAssistedApplicationParams> {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const queryClient = useQueryClient();
  return useMutation<Application, unknown, LaunchAssistedApplicationParams>(
    ({ applicationId, product, coverageAmount }) =>
      launchAssistedApplication(applicationId, {
        coverageAmount,
        product,
      }),
    {
      ...options,
      onSuccess: async (data, variables, context) => {
        queryClient.setQueryData([QueryId.application, data.id], data);

        if (options?.onSuccess) {
          await options.onSuccess(data, variables, context);
        }
      },
      onError: async (error, variables, context) => {
        dispatch(
          notificationSlice.actions.setError({
            message: t('notifications.failedToUpdateCoverageAmount'),
          }),
        );

        if (options?.onError) {
          await options.onError(error, variables, context);
        }
      },
    },
  );
}

type SaveAssistedApplicationAnswersParams = {
  applicationId: string;
  answers: Answers;
  answersV2: Answers;
  updatedNodeIds: string[];
  isClosing: boolean;
  isManuallySavingAnswers: boolean;
};

type SaveAssistedApplicationAnswersMutation = UseMutationResult<
  Application,
  unknown,
  SaveAssistedApplicationAnswersParams
>;

export type SaveAssistedApplicationAnswers = SaveAssistedApplicationAnswersMutation['mutateAsync'];

export function useSaveAssistedApplicationAnswersMutation(
  options?: UseMutationOptions<Application, unknown, SaveAssistedApplicationAnswersParams>,
): SaveAssistedApplicationAnswersMutation {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const queryClient = useQueryClient();
  return useMutation<Application, unknown, SaveAssistedApplicationAnswersParams>(
    ({ applicationId, answers, answersV2 }) =>
      updateAssistedApplicationAnswers(applicationId, {
        answers,
        answersV2,
      }),
    {
      ...options,
      onSuccess: async (application, variables, context) => {
        await queryClient.invalidateQueries([QueryId.participantProcessor, application.id]);

        if (options?.onSuccess) {
          await options.onSuccess(application, variables, context);
        }
      },
      onError: async (error, variables, context) => {
        dispatch(
          notificationSlice.actions.setError({
            message: t('notifications.failedToUpdateApplicationAnswers'),
          }),
        );

        if (options?.onError) {
          await options.onError(error, variables, context);
        }
      },
    },
  );
}

export type UpdateApplicationWithADOMinMaxMutation = UseMutationResult<Application, unknown, string>;

export function useUpdateApplicationWithADOMinMaxMutation(
  options?: UseMutationOptions<Application, unknown, string>,
): UpdateApplicationWithADOMinMaxMutation {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const queryClient = useQueryClient();
  return useMutation<Application, unknown, string>(updateApplicationWithADOMinMax, {
    ...options,
    onSuccess: async (application, variables, context) => {
      queryClient.setQueryData([QueryId.application, application.id], application);

      if (options?.onSuccess) {
        await options.onSuccess(application, variables, context);
      }
    },
    onError: async (error, variables, context) => {
      const errorTranslationKey =
        getTranslationKeyFromErrorId(error) || 'notifications.failedToUpdateApplicationAnswersWithADOMinMax';

      dispatch(
        notificationSlice.actions.setError({
          message: t(errorTranslationKey),
        }),
      );

      if (options?.onError) {
        await options.onError(error, variables, context);
      }
    },
  });
}
