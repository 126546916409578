import _ from 'lodash';
import { ReactElement, Fragment, useCallback, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useQueryClient } from 'react-query';
import { useNavigate } from 'react-router-dom';

import { hash } from '@breathelife/hash';
import { logger } from '@breathelife/monitoring-frontend';
import { TypewriterTracking } from '@breathelife/frontend-tracking';
import {
  Language,
  LaunchApplicationType,
  Permission,
  ProductsWidgetFeatureType,
  SignatureType,
} from '@breathelife/types';

import { RestrictedToUserPermissions } from '../Restricted/RestrictedToUserPermissions';

import { userHasPermission } from '../../Helpers/user';
import { useCarrierContext, useDispatch, useModalState, useNavigation, useSelector } from '../../Hooks';
import { generateLeadDetailUrl, generateLeadsListUrl } from '../../Navigation/Urls';
import { useGetApplicationQuery } from '../../ReactQuery/Application/application.queries';
import { QueryId } from '../../ReactQuery/common/common.types';
import { fetchAttachments } from '../../ReduxStore/Application/ApplicationOperations';
import { applicationSlice } from '../../ReduxStore/Application/ApplicationSlice';
import { sendIdentityVerificationRequest } from '../../ReduxStore/IdentityVerification/IdentityVerificationOperations';
import { identityVerificationSlice } from '../../ReduxStore/IdentityVerification/IdentityVerificationSlice';
import { paymentSlice } from '../../ReduxStore/Payment/PaymentSlice';
import * as ProductsOperations from '../../ReduxStore/Products/ProductsOperations';
import { AssistedApplicationContainer } from './AssistedApplicationContainer';
import { IdentityVerificationDrawer } from './Drawers/IdentityVerificationDrawer/IdentityVerificationDrawer';
import { FileAttachmentModalContainer } from './Modals/FileAttachment/FileAttachmentModalContainer';
import { SubmissionDetailsModal } from './Modals/SubmissionDetail/SubmissionDetailsModal';
import { SubmitApplicationModalContainer } from './Modals/SubmitApplication/SubmitApplicationModalContainer';
import { JetDecisionWidget } from './Widgets/JetDecision/JetDecisionWidget';
import { useFetchPaymentTransaction } from '../../ReactQuery/Payment/paymentTransaction.queries';
import { getPaymentProviderOperations } from '../../Helpers/payments';
import { AssistedApplicationQuestionnaireVersionContextProvider } from '../../Context/AssistedApplicationQuestionnaireVersionContextProvider';
import { WrappedWithFullPageLoader, useFullPageLoaderContext } from '../LoadingView/FullPageLoader';

export function AssistedApplicationChild(): ReactElement | null {
  const { leadTab, leadId, applicationId } = useNavigation();
  const { features } = useCarrierContext();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const queryClient = useQueryClient();
  const { i18n } = useTranslation();
  const { openFullPageLoader, isOpen: fullPageLoaderIsOpen, closeFullPageLoader } = useFullPageLoaderContext();

  const { applicationId: applicationIdFromProductStore } = useSelector((store) => store.leadPlatform.products);
  const { applicationId: applicationIdFromApplicationStore } = useSelector((store) => store.leadPlatform.application);
  const { disableTrackESignInfoModal } = useSelector((store) => store.leadPlatform.assistedApplication);
  const userPermissions = useSelector((store) => store.leadPlatform.authentication.user?.permissions ?? []);

  useEffect(() => {
    if (!fullPageLoaderIsOpen) {
      openFullPageLoader();
    }
    return () => {
      if (fullPageLoaderIsOpen) {
        closeFullPageLoader();
      }
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const { data: application } = useGetApplicationQuery(applicationId, {
    refetchOnMount: 'always',
  });

  const isProductWidgetEnabled = features.assistedApplication?.productsWidget?.enabled;
  const isApplicationLineOfBusinessAllowedToFetchProductAndPricing =
    application &&
    isProductWidgetEnabled &&
    features.assistedApplication?.productsWidget?.allowedLinesOfBusiness.includes(application.lineOfBusiness);

  const { isLoading: isLoadingFetchPaymentTransaction = false } = useFetchPaymentTransaction(
    applicationId ?? '',
    { withCardDetails: true, withToken: true },
    {
      enabled: features?.payments?.enabled ?? false,
      refetchOnMount: 'always',
      onError: (err) => {
        logger.error(err);
      },
      onSuccess: (paymentTransaction) => {
        if (features?.payments?.enabled && paymentTransaction) {
          const providerOperations = getPaymentProviderOperations(features?.payments?.serviceProvider);

          if (providerOperations) {
            const { validatePaymentTransactionCompletion } = providerOperations;

            dispatch(
              paymentSlice.actions.setIsPaymentReadyToSubmit(validatePaymentTransactionCompletion(paymentTransaction)),
            );
          }
        }
      },
    },
  );

  const isProductsWidgetTotalPremiumsEnabled =
    features.assistedApplication?.productsWidget?.enabled &&
    features.assistedApplication?.productsWidget?.type === ProductsWidgetFeatureType.totalPremiums;

  const [isInstantIdReportDrawerOpen, onOpenInstantIdReportHook, onCloseInstantIdReportDrawer] = useModalState();
  const [isSubmitApplicationModalOpen, onOpenSubmitApplicationModal, onCloseSubmitApplicationModal] = useModalState();
  const [isSubmissionDetailsModalOpen, onOpenSubmissionDetailsModal, onCloseSubmissionDetailsModal] = useModalState();
  const [isESignatureDetailsOpen, onOpenESignatureDetails, onCloseESignatureDetailsHook] = useModalState();
  const [
    isInfoMessageTrackESignatureModalOpen,
    onOpenInfoMessageTrackESignatureModal,
    onCloseInfoMessageTrackESignatureModal,
  ] = useModalState();
  const [isFileAttachmentModalOpen, onOpenFileAttachmentModalHook, onCloseFileAttachmentModal] = useModalState();

  const [proposedInsuredIndexToDelete, setProposedInsuredIndexToDelete] = useState(-1);
  const onOpenDeleteProposedInsuredModal = (index: number): void => setProposedInsuredIndexToDelete(index);
  const onCloseDeleteProposedInsuredModal = (): void => setProposedInsuredIndexToDelete(-1);

  const [hasInitiatedSubmission, setHasInitiatedSubmission] = useState(false);
  const [isMissingRequiredFiles, setIsMissingRequiredFiles] = useState(false);
  const [isMaxFilesSizeReached, setIsMaxFilesSizeReached] = useState(false);

  const isJetDecisionWidgetEnabled = useMemo(
    () =>
      features.assistedApplication?.jetDecisionWidget?.enabled &&
      userHasPermission(userPermissions, Permission.JetDecisionWidgetView),
    [userPermissions, features.assistedApplication?.jetDecisionWidget?.enabled],
  );

  const signatureType = useMemo(() => {
    if (
      features.launchApplication.enabled &&
      features.launchApplication.type === LaunchApplicationType.assistedApplication
    ) {
      return features.launchApplication.signatureType;
    }
    return undefined;
  }, [features]);

  const isSubmitButtonDisabled = useMemo(
    () =>
      !application ||
      (application.submitted && application.signed) ||
      isSubmitApplicationModalOpen ||
      isLoadingFetchPaymentTransaction ||
      isMaxFilesSizeReached,
    [application, isSubmitApplicationModalOpen, isLoadingFetchPaymentTransaction, isMaxFilesSizeReached],
  );

  const onOpenInstantIdReportDrawer = useCallback(() => {
    TypewriterTracking.clickedButton({
      buttonName: 'See Identity Verification report',
      hashedId: null,
    });
    onOpenInstantIdReportHook();
  }, [onOpenInstantIdReportHook]);

  const onOpenFileAttachmentModal = useCallback(() => {
    TypewriterTracking.clickedButton({
      buttonName: 'files',
      hashedId: application?.id ? hash(application.id) : null,
    });
    onOpenFileAttachmentModalHook();
  }, [application, onOpenFileAttachmentModalHook]);

  const onCloseESignatureDetails = useCallback(() => {
    onCloseESignatureDetailsHook();

    if (hasInitiatedSubmission) {
      // Display track e-sign information modal if the modal is not disabled and the application has been sent for signature
      if (!disableTrackESignInfoModal) {
        onOpenInfoMessageTrackESignatureModal();

        // Run delighted integration if enabled
      } else if (features.delighted.enabled) {
        document.dispatchEvent(new Event('show-delighted-survey'));
      }
    }
  }, [
    disableTrackESignInfoModal,
    features.delighted.enabled,
    hasInitiatedSubmission,
    onCloseESignatureDetailsHook,
    onOpenInfoMessageTrackESignatureModal,
  ]);

  const triggerIdentityVerification = useCallback(async () => {
    dispatch(identityVerificationSlice.actions.setIsLoading(true));
    return await dispatch(sendIdentityVerificationRequest(application?.id));
  }, [application, dispatch]);

  const closeAssistedApplication = useCallback(() => {
    dispatch(applicationSlice.actions.reset());
    dispatch(paymentSlice.actions.reset());

    void queryClient.invalidateQueries([QueryId.leads]);
    void queryClient.invalidateQueries([QueryId.application]);

    if (leadId) {
      void queryClient.invalidateQueries([QueryId.lead, leadId]);
      navigate(generateLeadDetailUrl(leadId, leadTab));
    } else {
      navigate(generateLeadsListUrl(leadTab));
    }
  }, [dispatch, leadId, leadTab, queryClient, navigate]);

  useEffect(() => {
    if (
      !application ||
      applicationIdFromProductStore === application.id ||
      !isApplicationLineOfBusinessAllowedToFetchProductAndPricing
    ) {
      return;
    }

    dispatch(identityVerificationSlice.actions.reset());

    if (isProductsWidgetTotalPremiumsEnabled) {
      void queryClient.invalidateQueries([QueryId.productsWidgetTotalPremiums, application.id]);
      return;
    }
    dispatch(ProductsOperations.resetProducts());
    void dispatch(ProductsOperations.fetchProducts(application.id, i18n.language as Language));
    void dispatch(ProductsOperations.fetchQuotes(application.id, application.coverageAmount));
  }, [
    application,
    applicationIdFromProductStore,
    i18n.language,
    dispatch,
    isProductsWidgetTotalPremiumsEnabled,
    queryClient,
    isApplicationLineOfBusinessAllowedToFetchProductAndPricing,
  ]);

  useEffect(() => {
    if (!application || applicationIdFromApplicationStore === application.id) return;

    if (userHasPermission(userPermissions, Permission.ApplicationFileRead)) {
      void dispatch(fetchAttachments(application.id));
    }
  }, [application, applicationIdFromApplicationStore, dispatch, userPermissions]);

  // TODO: Bring loader lower in the tree when the questionnaire is not loaded cuz the provider will be defined here
  if (!application) return null;

  return (
    <Fragment>
      <AssistedApplicationQuestionnaireVersionContextProvider
        applicationId={application.id}
        applicationContext={application.applicationContext}
        applicationTimezone={application.timezone}
        applicationMode={application.mode}
      >
        <AssistedApplicationContainer
          application={application}
          applicationSignatureType={signatureType}
          closeAssistedApplication={closeAssistedApplication}
          isMissingRequiredFiles={isMissingRequiredFiles}
          isMaxFilesSizeReached={isMaxFilesSizeReached}
          isSubmitButtonDisabled={isSubmitButtonDisabled}
          onOpenESignatureDetails={onOpenESignatureDetails}
          onOpenSubmissionDetailsModal={onOpenSubmissionDetailsModal}
          onOpenFileAttachmentModal={onOpenFileAttachmentModal}
          onChangeMissingRequiredFiles={setIsMissingRequiredFiles}
          onChangeIsMaxFilesSizeReached={setIsMaxFilesSizeReached}
          isESignatureDetailsOpen={isESignatureDetailsOpen}
          onCloseESignatureDetails={onCloseESignatureDetails}
          isInfoMessageTrackESignatureModalOpen={isInfoMessageTrackESignatureModalOpen}
          onCloseInfoMessageTrackESignatureModal={onCloseInfoMessageTrackESignatureModal}
          setHasInitiatedSubmission={setHasInitiatedSubmission}
          onOpenSubmitApplicationModal={onOpenSubmitApplicationModal}
          triggerIdentityVerification={triggerIdentityVerification}
          onOpenInstantIdReportDrawer={onOpenInstantIdReportDrawer}
          proposedInsuredIndexToDelete={proposedInsuredIndexToDelete}
          onOpenDeleteProposedInsuredModal={onOpenDeleteProposedInsuredModal}
          onCloseDeleteProposedInsuredModal={onCloseDeleteProposedInsuredModal}
        />
        <IdentityVerificationDrawer
          application={application}
          isOpen={isInstantIdReportDrawerOpen}
          onClose={onCloseInstantIdReportDrawer}
        />
        {application &&
          (signatureType === SignatureType.cryptoSignature || signatureType === SignatureType.externalSignature) && (
            <Fragment>
              <SubmitApplicationModalContainer
                signatureType={signatureType}
                isOpen={isSubmitApplicationModalOpen}
                closeModal={onCloseSubmitApplicationModal}
                application={application}
              />
              <SubmissionDetailsModal
                signatureType={signatureType}
                isOpen={isSubmissionDetailsModalOpen}
                closeModal={onCloseSubmissionDetailsModal}
                confirmationNumber={application.confirmationNumber}
                cryptoSignature={application.private?.signedDigest}
              />
            </Fragment>
          )}
        <RestrictedToUserPermissions
          requiredPermissions={[
            Permission.ApplicationFileCreate,
            Permission.ApplicationFileRead,
            Permission.ApplicationFileRemove,
          ]}
        >
          <FileAttachmentModalContainer
            isOpen={isFileAttachmentModalOpen}
            closeModal={onCloseFileAttachmentModal}
            application={application}
          />
        </RestrictedToUserPermissions>
        {isJetDecisionWidgetEnabled && <JetDecisionWidget application={application} />}
      </AssistedApplicationQuestionnaireVersionContextProvider>
    </Fragment>
  );
}

export const AssistedApplication = WrappedWithFullPageLoader(AssistedApplicationChild);
