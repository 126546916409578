import { ReactElement, useCallback, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { Box, FormHelperText, Grid } from '@breathelife/mui';
import { InsuranceProductPricingCreateRequest, PaymentFrequency } from '@breathelife/types';

import { SubmitButton } from '../../../../Components/Button/SubmitButton';
import { UploadButton } from '../../../../Components/Button/UploadButton';
import { NumberInput } from '../../../../Components/NumberInput/NumberInput';
import { Select } from '../../../../Components/Select/Select';
import Typography from '../../../../Components/Typography';
import {
  getFieldValidationError,
  productPricingFormSchema,
} from '../../../../Helpers/inputValidation/form/productPricing';
import { getOptionsFromEnum } from '../../../../Helpers/options';
import { useDispatch, useSelector } from '../../../../Hooks';
import { ModalLayout } from '../../../../Layouts/Modal/ModalLayout';
import { getLanguage } from '../../../../Localization/utils';
import * as ProductManagementOperations from '../../../../ReduxStore/Admin/ProductManagement/ProductManagementOperations';
import {
  getIsLoadingPricing,
  getSelectedProduct,
  getSelectedProductPricing,
} from '../../../../ReduxStore/Admin/ProductManagement/ProductManagementSelectors';
import { defaultState as defaultLayoutState, layoutSlice } from '../../../../ReduxStore/Layout/LayoutSlice';
import styled from '../../../../Styles/themed-styled-components';

const DEFAULT_MODAL_FACTOR_VALUE = 1;

const StyledLabel = styled.label`
  font-size: 13px;
  line-height: 18px;
  letter-spacing: 0px;
  color: ${(props) => props.theme.colors.grey[90]};
`;

export function PricingDetailContainer(): ReactElement {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const language = getLanguage();

  const selectedProduct = useSelector(getSelectedProduct);
  const selectedProductPricing = useSelector(getSelectedProductPricing)?.[0];
  const isLoadingPricing = useSelector(getIsLoadingPricing);

  const [rateSheetFile, setRateSheetFile] = useState<File | undefined>(undefined);
  const [modalFactorValue, setModalFactorValue] = useState<number | undefined>(DEFAULT_MODAL_FACTOR_VALUE);
  const [policyFee, setPolicyFee] = useState<number | undefined>(undefined);
  const [selectedPaymentFrequency, setSelectedPaymentFrequency] = useState<PaymentFrequency>(PaymentFrequency.monthly);
  const [validationErrors, setValidationErrors] = useState<
    Partial<Record<keyof InsuranceProductPricingCreateRequest, any>>
  >({});

  const paymentFrequencyOptions = getOptionsFromEnum<PaymentFrequency>(
    PaymentFrequency,
    'admin.productManagement.options.paymentFrequency',
  );

  useEffect(() => {
    if (selectedProductPricing) {
      const { modalFactor, policyFee } = selectedProductPricing;

      if (typeof policyFee === 'number') {
        setPolicyFee(policyFee);
      }

      if (modalFactor) {
        // only one paymentFrequency: value pair is supported for modal factors at the moment
        const [paymentFrequency, modalFactorValue] = Object.entries(modalFactor)[0];
        setModalFactorValue(modalFactorValue);
        setSelectedPaymentFrequency(paymentFrequency as PaymentFrequency);
      }
    }
  }, [selectedProductPricing]);

  const formData = useMemo(() => {
    return {
      rateSheetFile,
      insuranceProductPricingId: selectedProductPricing?.id,
      ...(typeof policyFee === 'number' ? { policyFee } : {}),
      ...(typeof modalFactorValue === 'number'
        ? { modalFactor: { [selectedPaymentFrequency]: modalFactorValue } }
        : {}),
    };
  }, [modalFactorValue, policyFee, rateSheetFile, selectedPaymentFrequency, selectedProductPricing]);

  const isFormValid = useMemo(() => {
    return Boolean(selectedProduct?.id) && productPricingFormSchema().isValidSync(formData);
  }, [selectedProduct, formData]);

  const onClose = useCallback(() => {
    void dispatch(layoutSlice.actions.setModalState({ modalState: defaultLayoutState.modalState }));
  }, [dispatch]);

  const onSubmit = useCallback(() => {
    if (!selectedProduct?.id) return;

    const submissionData = {
      ...formData,
      insuranceProductId: selectedProduct.id,
    };

    void dispatch(
      ProductManagementOperations.setProductPricing(submissionData as InsuranceProductPricingCreateRequest),
    );
  }, [selectedProduct, formData, dispatch]);

  return (
    <ModalLayout
      maxWidth='md'
      isOpen
      closeModal={onClose}
      title={t('admin.productManagement.productPricing', { name: selectedProduct?.name?.[language] ?? '' })}
      submitButton={
        <SubmitButton isLoading={isLoadingPricing} disabled={!isFormValid} onClick={onSubmit}>
          {t('cta.save')}
        </SubmitButton>
      }
    >
      <Grid container spacing={2}>
        <Grid item xs={12} key='productManagement.pricing'>
          <StyledLabel htmlFor='upload-button'>{`${t('admin.productManagement.labels.pricing')} ${
            !selectedProductPricing ? '*' : ''
          }`}</StyledLabel>
          <Box display='grid' alignItems='center' width='100%' gridGap={16} gridTemplateColumns='auto 1fr' mt={1}>
            <UploadButton
              onChange={(file) => {
                setRateSheetFile(file);
                setValidationErrors({
                  ...validationErrors,
                  rateSheetFile: getFieldValidationError('rateSheetFile', file),
                });
              }}
              extensions={['xlsx']}
              isLoading={isLoadingPricing}
              loaderProps={{
                color: 'secondary',
              }}
              variant='contained'
              color='primary'
              component='label'
            >
              {t('admin.productManagement.selectAFile')}
            </UploadButton>
            <Typography variant='body2'>
              {rateSheetFile && rateSheetFile.name
                ? t('admin.productManagement.selectedFile', { fileName: rateSheetFile.name })
                : t('admin.productManagement.noFileSelected')}
            </Typography>
          </Box>
          {validationErrors.rateSheetFile ? (
            <FormHelperText error>{validationErrors.rateSheetFile.message}</FormHelperText>
          ) : null}
        </Grid>
        <Grid item xs={6}>
          <NumberInput
            id='productManagement-policyFee'
            isCurrency
            inputVariant='outlined'
            label={t('admin.productManagement.labels.policyFee')}
            onNumberChange={(value) => {
              setPolicyFee(value);
              setValidationErrors({
                ...validationErrors,
                policyFee: getFieldValidationError('policyFee', value),
              });
            }}
            value={typeof policyFee !== 'undefined' ? policyFee : ''}
            error={Boolean(validationErrors.policyFee)}
            validationError={validationErrors.policyFee}
          />
        </Grid>
        <Grid item container xs={12} spacing={2}>
          <Grid item xs={6}>
            <Select
              id='product-type-select'
              label={t('admin.productManagement.labels.paymentFrequency')}
              value={selectedPaymentFrequency ?? ''}
              options={paymentFrequencyOptions}
              onChange={setSelectedPaymentFrequency}
            />
          </Grid>
          <Grid item xs={6}>
            <NumberInput
              id='productManagement-modalFactor'
              inputVariant='outlined'
              label={t('admin.productManagement.labels.modalFactor')}
              onNumberChange={(value) => {
                setModalFactorValue(value);
                setValidationErrors({
                  ...validationErrors,
                  modalFactor: getFieldValidationError('modalFactor', value),
                });
              }}
              value={modalFactorValue ?? ''}
              error={Boolean(validationErrors?.modalFactor)}
              validationError={validationErrors?.modalFactor}
            />
          </Grid>
        </Grid>
      </Grid>
    </ModalLayout>
  );
}
