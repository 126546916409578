import _ from 'lodash';
import { ReactElement, Fragment } from 'react';
import { useTranslation } from 'react-i18next';

import { Permission } from '@breathelife/types';
import { AddIcon, Box } from '@breathelife/mui';

import { ActionButton } from '../../../Components/Button/ActionButton';
import { ListFilterContainer } from '../../../Components/ListFilterContainer/ListFilterContainer';
import { RestrictedToUserPermissions } from '../../../Components/Restricted/RestrictedToUserPermissions';
import { ModalType } from '../../../Models/Layout';
import { ImportUsersModal } from '../../../Pages/Admin/UserManagement/ImportUsersModal/ImportUsersModal';
import { ConfirmDeleteModal } from './ConfirmDeleteModal/ConfirmDeleteModal';
import { SendInvitationEmailModal } from './SendInvitationEmailModal/SendInvitationEmailModal';
import UserDetailContainer from './UserDetailView/UserDetailContainer';
import { EmptySearchResultView } from './UsersTable/EmptySearchResultView';
import { UsersTableHeader } from './UsersTable/UsersTableHeader';
import { useUsersContext } from './UsersContextProvider';
import { UsersTable } from './UsersTable/UsersTable';

export function UserManagementView(): ReactElement {
  const { t } = useTranslation();
  const {
    onSearchChange,
    onFiltersChanged,
    onOpenCreateUser,
    onOpenImportUsers,
    shouldShowEmptySearchResultView,
    modalState,
    resetModalState,
  } = useUsersContext();
  return (
    <Fragment>
      <Box mx={2} flex flexGrow={1}>
        <ListFilterContainer>
          <UsersTableHeader onSearchChange={onSearchChange} onFiltersChanged={onFiltersChanged} />
          <Box display='flex'>
            <ActionButton
              data-testid='addNewUserButton'
              color='primary'
              variant='contained'
              onClick={onOpenCreateUser}
              startIcon={<AddIcon htmlColor='white' />}
            >
              {t('cta.addNew')}
            </ActionButton>
            <RestrictedToUserPermissions requiredPermissions={[Permission.UserBulkCreate]}>
              <Box ml={1}>
                <ActionButton
                  data-testid='importNewUsers'
                  color='primary'
                  variant='contained'
                  onClick={onOpenImportUsers}
                  startIcon={<AddIcon htmlColor='white' />}
                >
                  {t('modals.importUsers.importUsers')}
                </ActionButton>
              </Box>
            </RestrictedToUserPermissions>
          </Box>
        </ListFilterContainer>
        {shouldShowEmptySearchResultView ? <EmptySearchResultView /> : <UsersTable />}
      </Box>
      <UserDetailContainer />
      {modalState.user && (
        <SendInvitationEmailModal
          user={modalState.user}
          isOpen={modalState.isOpen && modalState.type === ModalType.userEmail}
          closeModal={resetModalState}
        />
      )}

      {modalState.user && (
        <ConfirmDeleteModal
          user={modalState.user}
          isOpen={modalState.isOpen && modalState.type === ModalType.deleteUser}
          closeModal={resetModalState}
        />
      )}

      <ImportUsersModal
        isOpen={modalState.isOpen && modalState.type === ModalType.importUsers}
        closeModal={resetModalState}
      />
    </Fragment>
  );
}
