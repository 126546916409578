import { Fragment, ReactElement, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';

import {
  Box,
  Divider,
  ExpandMoreIcon,
  ExpansionPanel,
  ExpansionPanelActions,
  ExpansionPanelDetails,
  ExpansionPanelSummary,
} from '@breathelife/mui';
import { Language, Localizable } from '@breathelife/types';
import { Input } from '@breathelife/ui-components';

import { Button } from '../../../Components/Button/Button';
import Typography from '../../../Components/Typography';

type Props = {
  consumerFooter: Localizable;
  enabledLanguages: Language[];
  isUpdating: boolean;
  onSaveClick: (consumerFooterData: Localizable) => void;
};

export function ConsumerFooterView({
  consumerFooter: consumerFooterProp,
  enabledLanguages,
  isUpdating,
  onSaveClick,
}: Props): ReactElement {
  const { t } = useTranslation();

  const defaultConsumerFooter = useMemo(
    () => getDefaultConsumerFooter(consumerFooterProp, enabledLanguages),
    [consumerFooterProp, enabledLanguages],
  );

  const [footer, setFooter] = useState<Localizable>(defaultConsumerFooter);

  const updateConsumerFooter = (language: string, value: string): void => {
    setFooter((prev) => ({ ...prev, [language]: value }));
  };

  useEffect(() => {
    setFooter(defaultConsumerFooter);
  }, [defaultConsumerFooter]);

  return (
    <ExpansionPanel defaultExpanded>
      <ExpansionPanelSummary
        expandIcon={<ExpandMoreIcon />}
        aria-controls='consumerFooter-content'
        id='consumerFooter--header'
      >
        <Box display='flex' flexDirection='row' mr={2}>
          <Typography variant='h2'>{t('admin.settingsManagement.consumerFooter.title')}</Typography>
        </Box>
      </ExpansionPanelSummary>
      <Divider />
      <ExpansionPanelDetails>
        <Box display='flex' flexWrap='wrap'>
          <Box mx={2} width='100%'>
            <Typography variant='small3'>{t('admin.settingsManagement.consumerFooter.subtitle')}</Typography>
          </Box>
          {enabledLanguages.map((language) => {
            return (
              <Fragment key={language}>
                <Box
                  key={`box${language}`}
                  display='flex'
                  flexDirection='row'
                  alignItems='center'
                  width='50%'
                  mx={2}
                  my={2}
                >
                  <Input
                    fullWidth
                    inputVariant='outlined'
                    key={`input${language}`}
                    name={`input.${language}`}
                    id={`input-${language}`}
                    value={footer?.[language] ?? ''}
                    label={t('admin.settingsManagement.consumerFooter.label', {
                      language: t(`language.${language}`),
                    })}
                    placeholder={t('admin.settingsManagement.consumerFooter.label', {
                      language: t(`language.${language}`),
                    })}
                    onChange={(event) => {
                      updateConsumerFooter(language, event.target.value);
                    }}
                  />
                </Box>
              </Fragment>
            );
          })}
        </Box>
      </ExpansionPanelDetails>
      <Divider />
      <ExpansionPanelActions>
        <Button
          data-testid='consumerFooterText-save'
          color='primary'
          variant='text'
          isLoading={isUpdating}
          onClick={() => {
            onSaveClick(footer);
          }}
        >
          {t('cta.save').toUpperCase()}
        </Button>
      </ExpansionPanelActions>
    </ExpansionPanel>
  );
}

function getDefaultConsumerFooter(consumerFooter: Localizable, enabledLanguages: Language[]): Localizable {
  if (typeof consumerFooter === 'undefined') {
    return enabledLanguages.reduce((obj, language) => ({ ...obj, [language]: '' }), {}) as Localizable;
  }
  return consumerFooter;
}
