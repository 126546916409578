import { ReactElement, useCallback } from 'react';

import { Button, GetAppIcon } from '@breathelife/mui';
import { Application } from '@breathelife/types';

import ApiService from '../../../Services/ApiService';

type Props = {
  application?: Application;
};

export function TestEntityMappingButton({ application }: Props): ReactElement {
  const onTest = useCallback(() => {
    if (!application) {
      return;
    }
    const test = async (): Promise<void> => {
      try {
        const response = await ApiService.admin.testEntityMapping({
          applicationId: application.id,
          entityName: 'Test',
        });
        if (response.data) {
          // eslint-disable-next-line no-console
          console.info('Tested entity mapping returns: ', response.data);
        }
      } catch (e) {
        // do stuff
      }
    };
    void test();
  }, [application]);

  return (
    <Button disabled={!application} variant='outlined' color='primary' onClick={onTest} startIcon={<GetAppIcon />}>
      Test entity mapping
    </Button>
  );
}
