import { ReactElement } from 'react';
import { useTranslation } from 'react-i18next';
import { useTheme } from '../../Styles/themed-styled-components';

import { logger } from '@breathelife/monitoring-frontend';
import { LineOfBusinessName } from '@breathelife/types';

import { LeadPlatformTheme } from '../../Styles/Types';
import { StyledBadge } from './Badge';

export type Props = {
  lineOfBusiness: LineOfBusinessName | undefined;
};

export function ApplicationLineOfBusinessBadge(props: Props): ReactElement {
  const theme = useTheme() as LeadPlatformTheme;
  const { t } = useTranslation();

  const ApplicationLineOfBusinessTextColors: {
    [lineOfBusiness in LineOfBusinessName]?: { textColor: string; backgroundColor: string };
  } & { default: { textColor: string; backgroundColor: string } } = {
    [LineOfBusinessName.annuity]: { textColor: theme.colors.green[50], backgroundColor: theme.colors.green[20] },
    [LineOfBusinessName.life]: { textColor: theme.colors.purple[50], backgroundColor: theme.colors.purple[20] },
    default: { textColor: theme.colors.grey[50], backgroundColor: theme.colors.grey[20] },
  };

  const lineOfBusinessLabel = t(`applicationLineOfBusiness.${props.lineOfBusiness || 'unknown'}`);
  const statusColors =
    (props.lineOfBusiness
      ? ApplicationLineOfBusinessTextColors[props.lineOfBusiness]
      : ApplicationLineOfBusinessTextColors.default) ?? ApplicationLineOfBusinessTextColors.default;

  if (!ApplicationLineOfBusinessTextColors[props.lineOfBusiness as LineOfBusinessName]) {
    logger.error(`Unknown application line of business: ${props.lineOfBusiness}`);
  }

  return (
    <StyledBadge
      backgroundColor={statusColors.backgroundColor}
      textColor={statusColors.textColor}
      label={lineOfBusinessLabel}
    />
  );
}
