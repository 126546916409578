import dayjs from 'dayjs';
import { ReactElement, Fragment, useCallback, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { CellProps } from 'react-table';

import {
  AdminToolActionType,
  AdminToolResourceName,
  AuditLog,
  SortParams,
  UserRolesWithAssignablePermissions,
  SortDirection,
  AuditLogsRowData,
} from '@breathelife/types';

import { Button } from '../../../Components/Button/Button';
import { Table } from '../../../Components/Table/Table';

type Props = {
  logs: AuditLog[];
  setIsOpen: (value: boolean) => void;
  setActionDetail: (value: Record<string, any> | null) => void;
  resourceName?: AdminToolResourceName;
  resourceId?: string;
  onSortingChange: (options: SortParams<AuditLogsRowData>) => void;
};

function formatDate(date: string): string {
  return dayjs(new Date(date)).format('D MMMM YYYY HH:MM A');
}

export function AuditLogsTable(props: Props): ReactElement {
  const { logs, setIsOpen, setActionDetail, onSortingChange } = props;
  const { t } = useTranslation();

  const showDetailsModal = useCallback(
    (actionDetail: Record<string, any> | null) => {
      setActionDetail(actionDetail);
      setIsOpen(true);
    },
    [setIsOpen, setActionDetail],
  );

  const handleSortingChange = useCallback(
    (columnId?: keyof AuditLogsRowData, sortDirection?: SortDirection) => {
      if (!columnId || !sortDirection) return;

      onSortingChange({ field: columnId, direction: sortDirection });
    },
    [onSortingChange],
  );

  const columns = useMemo<
    {
      Header: string;
      accessor: keyof AuditLogsRowData;
      Cell?: (props: CellProps<AuditLogsRowData>) => ReactElement;
      disableSortBy?: boolean;
    }[]
  >(
    () => [
      {
        Header: t('admin.auditLogs.resourceName'),
        accessor: 'resourceName',
        Cell: function ({ cell: { value } }: { cell: { value: AdminToolResourceName } }): ReactElement {
          return t(`admin.auditLogs.options.resourceName.${value}`);
        },
      },
      {
        Header: t('admin.auditLogs.resourceId'),
        accessor: 'resourceId',
        disableSortBy: true,
      },
      {
        Header: t('admin.auditLogs.actionType'),
        accessor: 'actionType',
        Cell: function ({ cell: { value } }: { cell: { value: AdminToolActionType } }): ReactElement {
          return t(`admin.auditLogs.options.actionType.${value}`);
        },
      },
      {
        Header: t('admin.auditLogs.userName'),
        accessor: 'user',
        Cell: ({ cell }: { cell: any }) =>
          cell.row.values.user?.firstName.concat(' ', cell.row.values.user.lastName) ?? '',
      },
      {
        Header: t('admin.auditLogs.userRole'),
        accessor: 'userRoles',
        Cell: function ({ cell: { value } }: { cell: { value: UserRolesWithAssignablePermissions } }): ReactElement {
          return t(`user.role.${value}`);
        },
      },
      {
        Header: t('admin.auditLogs.timestamp'),
        accessor: 'createdAt',
        Cell: function ({ cell: { value } }: { cell: { value: any } }): ReactElement {
          return <Fragment>{formatDate(value)}</Fragment>;
        },
      },
      {
        Header: t('admin.auditLogs.details'),
        accessor: 'actionDetail',
        Cell: function ({ cell }: { cell: any }) {
          return (
            <Button variant='outlined' onClick={() => showDetailsModal(cell.row.values.actionDetail)}>
              {t('admin.auditLogs.details')}
            </Button>
          );
        },
        disableSortBy: true,
      },
    ],
    [showDetailsModal, t],
  );

  return <Table<AuditLogsRowData> columns={columns} data={logs} onOrderChange={handleSortingChange} />;
}
