import { ReactElement, Fragment } from 'react';
import { useTranslation } from 'react-i18next';

import { Grid } from '@breathelife/mui';
import { EqualityConditionValue } from '@breathelife/types';

import {
  getConditionValidationError,
  ValidationValues,
} from '../../../Helpers/inputValidation/form/salesDecisionRules';
import { CollectionOperators } from '../Helpers/CollectionOperators';
import { ConditionTargetNodeAutocomplete } from '../Helpers/ConditionTargetNodeAutocomplete';
import { QuantifierRadioGroup } from '../Helpers/QuantifierRadioGroup';
import { StringValueOrNodeIdInput } from '../Helpers/StringValueOrNodeIdInput';
import { ValidationErrorText } from '../ValidationErrorText';
import { ConditionCriteriaProps } from '../types';

function EqualityQuantifierRadioGroup(props: { value: boolean; onChange: (value: boolean) => void }): ReactElement {
  const { value, onChange } = props;

  const { t } = useTranslation();

  const options = [
    { value: 'true', label: t('admin.conditions.options.equality.equal') },
    { value: 'false', label: t('admin.conditions.options.equality.notEqual') },
  ];

  return (
    <QuantifierRadioGroup
      label={t('admin.conditions.labels.equality')}
      name='condition-equality-radio-group'
      options={options}
      value={String(value)}
      onChange={(value: string) => {
        onChange(value === 'true');
      }}
    />
  );
}

export function EqualityConditionCriteria({
  condition,
  nodeIdList,
  nodeIdInCollectionMap,
  collectionContext,
  selectOptionsByNodeId,
  onConditionPropertiesChange,
  language,
  conditionIndex,
}: ConditionCriteriaProps<EqualityConditionValue>): ReactElement {
  const validationErrors = getConditionValidationError(condition, nodeIdList, nodeIdInCollectionMap);
  const collectionNodeIds = nodeIdInCollectionMap[condition.targetNodeId] ?? [];

  return (
    <Fragment>
      <Grid item xs={12}>
        <ConditionTargetNodeAutocomplete
          value={condition.targetNodeId}
          nodeIdList={nodeIdList}
          onSelect={(value) => {
            if (value !== null) {
              onConditionPropertiesChange({ targetNodeId: value, value: undefined });
            }
          }}
          validationError={validationErrors?.[ValidationValues.targetNodeId]}
          selectedLanguage={language}
        />
      </Grid>
      <Grid item xs={12}>
        <EqualityQuantifierRadioGroup
          value={condition.isEqual}
          onChange={(isEqual) => {
            onConditionPropertiesChange({ isEqual });
          }}
        />
        {validationErrors?.[ValidationValues.isEqual] && (
          <ValidationErrorText>{validationErrors[ValidationValues.isEqual].message}</ValidationErrorText>
        )}
      </Grid>
      <StringValueOrNodeIdInput
        value={condition.value}
        nodeIdList={nodeIdList}
        nodeIdOfValue={condition.nodeIdOfValue}
        condition={condition}
        onChange={({ value, nodeIdOfValue }) => {
          onConditionPropertiesChange({ value, nodeIdOfValue });
        }}
        language={language}
        validationErrors={validationErrors}
        selectOptionsByNodeId={selectOptionsByNodeId}
      />
      {validationErrors?.[ValidationValues.value] && (
        <ValidationErrorText>{validationErrors[ValidationValues.value].message}</ValidationErrorText>
      )}
      <CollectionOperators
        collectionContext={collectionContext}
        collectionNodeIds={collectionNodeIds}
        collectionOperators={condition.collectionOperators}
        onConditionPropertiesChange={onConditionPropertiesChange}
        conditionIndex={conditionIndex}
      />
    </Fragment>
  );
}
