import { Fragment, memo, ReactElement, useCallback, useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { Box, Divider, Skeleton } from '@breathelife/mui';
import {
  getSectionById,
  isRenderingRepeatedSectionGroup,
  RenderingQuestionnaire,
} from '@breathelife/questionnaire-engine';
import { ApplicationMode, ESignCeremonyStatus, ProductsEntity, SignatureType } from '@breathelife/types';

import {
  ActionBanner,
  ActionBannerVariant,
} from '../../../Components/AssistedApplication/AssistedApplicationView/ActionBanner/ActionBanner';
import {
  Direction,
  findSectionGroup,
  getSectionGroupWithIndicesId,
  SectionGroupIndices,
  updateVisitedSectionIndices,
} from '../../../Helpers/assistedApplication/sectionGroups';
import { isInsuredPeopleSectionGroup } from '../../../Helpers/questionnaireAnswers';
import { useCarrierContext, useNavigation } from '../../../Hooks';
import {
  useAllSections,
  useNextSectionData,
  usePreviousSectionData,
  useSectionViewPresets,
} from '../../../Hooks/Application/useSectionSelection';
import { useAssistedApplicationContext } from '../../../Hooks/useAssistedApplicationContext';
import { useCancelESignCeremonyMutation } from '../../../ReactQuery/ESignCeremony/eSignCeremony.mutations';
import { useGetESignCeremonyQuery } from '../../../ReactQuery/ESignCeremony/eSignCeremony.queries';
import { SkipLink } from '../../SkipLink/SkipLink';
import { DeleteProposedInsuredModal } from '../Modals/ProposedInsured/DeleteProposedInsuredModal';
import { ApplicationDetailsWidgetsContainer } from './ApplicationDetailsWidgets/ApplicationDetailsWidgetsContainer';
import { ApplicationDetailsWidgetsStickyWrapper } from './ApplicationDetailsWidgets/ApplicationDetailsWidgetsStickyWrapper';
import { SectionView } from './AssistedApplicationForm/SectionView';
import { AssistedApplicationNavigation } from './AssistedApplicationNavigation';
import { NavigationButton } from './NavigationButton';
import { ProposedInsuredTabBar } from './ProposedInsuredTabBar/ProposedInsuredTabBar';
import {
  FormContainer,
  NavigationContainer,
  ScrollContainer,
  ScrollContent,
  StyledNextNavigationButton,
  ViewContainer,
} from './Styles';
import { useFullPageLoaderContext } from '../../LoadingView/FullPageLoader';

type Props = {
  renderingQuestionnaire: RenderingQuestionnaire;
  setDisplayValidationErrors: (displayValidationErrors: boolean) => void;
  selectedProductId: string;
  coverageAmount?: number | null;
  isApplicationSubmitted: boolean;
  isApplicationSigned: boolean;
  applicationMode: ApplicationMode;
  isQuestionnaireCompleted: boolean;
  onOpenSubmissionDetailsModal: () => void;
  productsEntity: ProductsEntity<string> | null;
  proposedInsuredIndexToDelete: number;
  onOpenDeleteProposedInsuredModal: (index: number) => void;
  onCloseDeleteProposedInsuredModal: () => void;
  onLockApplication?: () => void;
  onUnlockApplication?: () => void;
  onOpenESignatureDetails?: () => void;
  isESignStatusChanging?: boolean;
  applicationSignatureType?: SignatureType;
  isReadOnly?: boolean;
};

function AssistedApplicationView(props: Props): ReactElement | null {
  const { features } = useCarrierContext();
  const { t } = useTranslation();
  const {
    renderingQuestionnaire,
    setDisplayValidationErrors,
    selectedProductId,
    coverageAmount,
    isApplicationSubmitted,
    isApplicationSigned,
    isQuestionnaireCompleted,
    isReadOnly,
    onOpenSubmissionDetailsModal,
    productsEntity,
    proposedInsuredIndexToDelete,
    onOpenDeleteProposedInsuredModal,
    onCloseDeleteProposedInsuredModal,
    onLockApplication,
    onUnlockApplication,
    onOpenESignatureDetails,
    isESignStatusChanging,
    applicationSignatureType,
    applicationMode,
  } = props;

  const {
    currentProposedInsuredIndex,
    currentSectionGroupId,
    proposedInsuredTabs,
    onSelectProposedInsured,
    onSwitchSectionGroupTab,
    activeSectionIndex,
    onSetActiveSectionIndex,
  } = useAssistedApplicationContext();

  const { closeFullPageLoader, isOpen: fullPageLoaderIsOpen } = useFullPageLoaderContext();

  const sectionGroup =
    findSectionGroup(renderingQuestionnaire, currentSectionGroupId, currentProposedInsuredIndex) ??
    renderingQuestionnaire[0];

  const [visitedSectionIndicesByGroup, setVisitedSectionsIndicesByGroup] = useState<SectionGroupIndices[]>();
  const sectionContainerRef = useRef<HTMLDivElement>(null);

  const allSections = useAllSections({ sectionGroup, visitedSectionIndicesByGroup });

  useSectionViewPresets(setDisplayValidationErrors, allSections[activeSectionIndex]);

  const nextSectionData = useNextSectionData(renderingQuestionnaire, allSections.length);
  const previousSectionData = usePreviousSectionData(renderingQuestionnaire);

  const navigateToSection = useCallback(
    (nextSectionIndex: number, direction?: Direction): void => {
      // Do nothing if user clicks on the current displayed section.
      if (nextSectionIndex === activeSectionIndex) return;

      const surrogateId = proposedInsuredTabs[currentProposedInsuredIndex]?.surrogateId ?? '';

      let sectionIndicesByGroupId = getSectionGroupWithIndicesId(currentSectionGroupId, surrogateId);

      updateVisitedSectionIndices({
        activeSectionIndex,
        sectionIndicesByGroupId,
        setVisitedSectionsIndicesByGroup,
        visitedSectionIndicesByGroup,
      });

      if (direction) {
        const section = direction === Direction.previous ? previousSectionData : nextSectionData;
        const groupId = section?.groupId ?? currentSectionGroupId;
        const proposedInsuredIndex = section?.proposedInsuredIndex ?? currentProposedInsuredIndex;
        onSwitchSectionGroupTab(groupId);
        onSelectProposedInsured(proposedInsuredIndex);
        sectionIndicesByGroupId = getSectionGroupWithIndicesId(groupId, surrogateId);
      }

      const nextSectionGroup = visitedSectionIndicesByGroup?.find(
        (sectionGroup) => sectionGroup.id === sectionIndicesByGroupId,
      );

      // Show errors if the next section has already been visited before, hide them otherwise
      setDisplayValidationErrors(nextSectionGroup?.indices.includes(nextSectionIndex) || false);

      // Activate the next section
      onSetActiveSectionIndex(nextSectionIndex);

      sectionContainerRef?.current?.scrollIntoView(true);
    },
    [
      activeSectionIndex,
      currentProposedInsuredIndex,
      currentSectionGroupId,
      nextSectionData,
      onSelectProposedInsured,
      onSwitchSectionGroupTab,
      previousSectionData,
      proposedInsuredTabs,
      setDisplayValidationErrors,
      visitedSectionIndicesByGroup,
      onSetActiveSectionIndex,
    ],
  );

  // -- enableDecoupleESignFlow feature --
  // Check for the completion of the question to render prompt to lock the application
  const { applicationId } = useNavigation();
  const { data: eSignCeremony } = useGetESignCeremonyQuery(applicationId, applicationSignatureType);
  const cancelESignCeremonyMutation = useCancelESignCeremonyMutation();

  const cancelESignCeremony = useCallback(() => {
    if (applicationId) {
      cancelESignCeremonyMutation.mutate(applicationId);
    }
  }, [applicationId, cancelESignCeremonyMutation]);

  const ENABLE_DECOUPLE_ESIGN_FLOW = !!features.enableDecoupleESignFlow?.enabled;

  let TopBanner: ReactElement | null = null;

  if (ENABLE_DECOUPLE_ESIGN_FLOW) {
    const isAppReadyForReview =
      isQuestionnaireCompleted && !isApplicationSubmitted && eSignCeremony?.status === ESignCeremonyStatus.DRAFT;
    const isAppInReview =
      eSignCeremony?.status === ESignCeremonyStatus.IN_PROGRESS || eSignCeremony?.status === ESignCeremonyStatus.READY;
    const isAppSent = eSignCeremony?.status === ESignCeremonyStatus.SENT;

    // This is to keep track of which statuses we do not handle in the UI
    // As of now we do not return anything when we enter this use case
    const isESignCeremonyStatusUnhandled =
      !!eSignCeremony &&
      ![
        ESignCeremonyStatus.DRAFT,
        ESignCeremonyStatus.READY,
        ESignCeremonyStatus.IN_PROGRESS,
        ESignCeremonyStatus.SENT,
        ESignCeremonyStatus.CANCELLED,
      ].includes(eSignCeremony.status);

    if (isESignCeremonyStatusUnhandled) {
      TopBanner = <Fragment></Fragment>;
    } else if (isESignStatusChanging || cancelESignCeremonyMutation.isLoading) {
      TopBanner = (
        <Box mt={1} display='flex' flexDirection='column'>
          <Skeleton height={130} />
        </Box>
      );
    } else {
      if (isAppReadyForReview) {
        TopBanner = <ActionBanner variant={ActionBannerVariant.readyForReview} onLockApplication={onLockApplication} />;
      } else if (isAppInReview) {
        TopBanner = (
          <Box mt={5}>
            <ActionBanner
              variant={ActionBannerVariant.inReview}
              onSendToSignature={onOpenESignatureDetails}
              onEditApplication={onUnlockApplication}
            />
          </Box>
        );
      } else if (isAppSent) {
        TopBanner = (
          <Box mt={5}>
            <ActionBanner variant={ActionBannerVariant.sent} onCancelSignature={cancelESignCeremony} />
          </Box>
        );
      }
    }
  }
  // ------------------------------------

  const section = getSectionById(sectionGroup, allSections[activeSectionIndex]?.id);

  useEffect(() => {
    if (section && fullPageLoaderIsOpen) {
      closeFullPageLoader();
    }
  }, [section, fullPageLoaderIsOpen, closeFullPageLoader]);

  if (!section) return null;

  const showTabBar =
    (features.assistedApplication?.multiInsured?.enabled ?? false) && isInsuredPeopleSectionGroup(sectionGroup);
  const maxTabs =
    isRenderingRepeatedSectionGroup(sectionGroup) && sectionGroup.options?.maxRepetitions
      ? sectionGroup.options?.maxRepetitions
      : 1;

  const skipToAssistedApplicationFormId = 'assistedApplicationForm';

  return (
    <Fragment>
      {showTabBar && (
        <Fragment>
          <ProposedInsuredTabBar
            maxTabs={maxTabs}
            onOpenDeleteProposedInsuredModal={onOpenDeleteProposedInsuredModal}
          />
          <DeleteProposedInsuredModal
            isOpen={proposedInsuredIndexToDelete !== -1}
            onClose={onCloseDeleteProposedInsuredModal}
            proposedInsuredIndexToDelete={proposedInsuredIndexToDelete}
          />
        </Fragment>
      )}

      <ViewContainer withTabBar={showTabBar}>
        <NavigationContainer>
          <SkipLink href={`#${skipToAssistedApplicationFormId}`} title={t('skipLinks.navigation')} />
          <AssistedApplicationNavigation
            activeSectionIndex={activeSectionIndex}
            onSectionClick={navigateToSection}
            hasSectionEndIcons={applicationMode !== ApplicationMode.paper && !isReadOnly}
            allSections={allSections}
          />
        </NavigationContainer>
        <ScrollContainer>
          <ScrollContent>
            <FormContainer
              id={skipToAssistedApplicationFormId}
              flex={2}
              mx={8}
              {...{ ref: sectionContainerRef }}
              role='form'
            >
              {TopBanner}
              <SectionView section={section} />
              <Divider />
              <Box display='flex' py={3}>
                {previousSectionData?.section && (
                  <NavigationButton
                    direction={Direction.previous}
                    onClick={() => navigateToSection(previousSectionData.index, Direction.previous)}
                  >
                    {previousSectionData.section.title}
                  </NavigationButton>
                )}
                {nextSectionData?.section && (
                  <StyledNextNavigationButton
                    direction={Direction.next}
                    onClick={() => navigateToSection(nextSectionData.index, Direction.next)}
                  >
                    {nextSectionData.section.title}
                  </StyledNextNavigationButton>
                )}
              </Box>
            </FormContainer>
            <ApplicationDetailsWidgetsStickyWrapper flex={1} mt={5} ml={3} mr={3} role='region'>
              <ApplicationDetailsWidgetsContainer
                selectedProductId={selectedProductId}
                coverageAmount={coverageAmount}
                isApplicationSubmitted={isApplicationSubmitted}
                isApplicationSigned={isApplicationSigned}
                onOpenSubmissionDetailsModal={onOpenSubmissionDetailsModal}
                productsEntity={productsEntity}
              />
            </ApplicationDetailsWidgetsStickyWrapper>
          </ScrollContent>
        </ScrollContainer>
      </ViewContainer>
    </Fragment>
  );
}

export const MemoizedAssistedApplicationView = memo(AssistedApplicationView);
