import { LeadDetailTab } from '@breathelife/types';
import _ from 'lodash';
import { ReactElement, ChangeEvent } from 'react';
import { useTranslation } from 'react-i18next';

import { Tabs, TabType } from '../../../../Components/Tabs/Tabs';
import { useCarrierContext } from '../../../../Hooks';
import { DetailViewType } from '../../../../Models/Layout';

export type DetailTabsProps = {
  selectedTab: number | undefined;
  onSelectedTabChange: (event: ChangeEvent<{}>, value: number) => void;
  type: DetailViewType;
  hideNotes?: boolean;
  hideNeedsAnalysis?: boolean;
  hideTransactionFlow?: boolean;
  disabled?: boolean;
};

export function LeadDetailTabs(props: DetailTabsProps): ReactElement {
  const { t } = useTranslation();
  const { enabledTabs, features } = useCarrierContext();

  const personalInfoTab = enabledTabs[LeadDetailTab.personalInfo];
  const needsAnalysisTab = enabledTabs[LeadDetailTab.needsAnalysis];
  const productsTab = enabledTabs[LeadDetailTab.products];
  const notesTab = enabledTabs[LeadDetailTab.notes];
  const historyTab = enabledTabs[LeadDetailTab.history];
  const applicationsTab = enabledTabs[LeadDetailTab.applications];
  const isCreateLeadView = props.type === DetailViewType.create;

  const tabs: TabType[] = [];

  if (personalInfoTab)
    tabs.push({ label: personalInfoTab.title || t('personalInformation'), disabled: props.disabled });

  if (!isCreateLeadView) {
    if (needsAnalysisTab) {
      tabs.push({
        label:
          needsAnalysisTab.title ?? (features.needsAnalysis.enabled && features.needsAnalysis.optional)
            ? t('questionnaire')
            : t('needsAnalysis'),
        hidden: props.hideNeedsAnalysis,
        disabled: props.type === DetailViewType.create || props.disabled,
      });
    }
    if (productsTab) {
      tabs.push({
        label: productsTab.title ?? features.productHub.enabled ? t('products') : t('applications'),
        disabled: props.type === DetailViewType.create || props.disabled,
      });
    }
    if (applicationsTab) {
      tabs.push({
        label: applicationsTab.title ?? t('applications'),
        disabled: props.type === DetailViewType.create || props.disabled,
      });
    }
    if (notesTab) {
      tabs.push({
        label: notesTab.title ?? t('notes'),
        hidden: props.hideNotes,
        disabled: props.type === DetailViewType.create || props.disabled,
      });
    }
    if (historyTab) {
      tabs.push({
        label: historyTab.title ?? t('history'),
        disabled: props.type === DetailViewType.create || props.disabled,
      });
    }
  }

  return (
    <Tabs
      value={!_.isNil(props.selectedTab) ? props.selectedTab : false}
      onChange={props.onSelectedTabChange}
      tabs={tabs}
    />
  );
}
