import { ReactElement, createContext, PropsWithChildren, useCallback, useState, useMemo } from 'react';

import {
  PageQueryOptions,
  Questionnaire,
  QuestionnaireVersionInfo,
  QuestionnaireVersionRowData,
  SortParams,
} from '@breathelife/types';

import { useFetchQuestionnaireVersionsQuery } from '../../../../ReactQuery/Admin/Questionnaire/questionnaireVersion.queries';
import { useFetchAllQuestionnairesQuery } from '../../../../ReactQuery/Admin/Questionnaire/questionnaire.queries';

export const PER_PAGE_OPTIONS = [
  { label: 10, value: 10 },
  { label: 15, value: 15 },
  { label: 25, value: 25 },
  { label: 50, value: 50 },
  { label: 100, value: 100 },
];

type QuestionnaireVersionsContextValue = {
  questionnaires: Questionnaire[];
  latestQuestionnaireId: string;
  questionnaireVersions: QuestionnaireVersionInfo[];
  total: number;
  currentPage: number;
  perPage: number;
  questionnaireVersionsIsFetching: boolean;
  questionnairesIsFetching: boolean;
  onPageChange: ($page: number) => void;
  onLimitPerPageChange: ($limit: number) => void;
  onSortingChange: ($sort: SortParams<QuestionnaireVersionRowData>) => void;
  manuallyRefetchQuestionnaireVersions: (questionnaireId: string) => void;
};

type QuestionnaireQueryOptions = Partial<PageQueryOptions<QuestionnaireVersionRowData>>;

export const QuestionnaireVersionsContext = createContext<QuestionnaireVersionsContextValue>({
  questionnaires: [],
  latestQuestionnaireId: '',
  questionnaireVersions: [],
  total: 0,
  currentPage: 1,
  perPage: 0,
  questionnairesIsFetching: false,
  questionnaireVersionsIsFetching: false,
  onPageChange: () => {},
  onLimitPerPageChange: () => {},
  onSortingChange: () => {},
  manuallyRefetchQuestionnaireVersions: () => {},
});

export function QuestionnaireVersionsContextProvider(props: PropsWithChildren<{}>): ReactElement {
  const [questionnaireQueryOptions, setQuestionnaireQueryOptions] = useState<QuestionnaireQueryOptions>({
    $page: 1,
    $limit: PER_PAGE_OPTIONS[1].value,
    $sort: undefined,
  });

  const { data: questionnaires, isFetching: questionnairesIsFetching } = useFetchAllQuestionnairesQuery();

  const [questionnaireId, setQuestionnaireId] = useState<string>('');

  const computedQuestionnaireId = useMemo(() => {
    return questionnaireId || questionnaires?.[0]?.id;
  }, [questionnaireId, questionnaires]);

  const {
    data: questionnaireVersionsPage,
    isFetching: questionnaireVersionsIsFetching,
    refetch: refetchQuestionnaireVersions,
  } = useFetchQuestionnaireVersionsQuery(questionnaireQueryOptions, computedQuestionnaireId, {
    enabled: !!computedQuestionnaireId,
  });

  const manuallyRefetchQuestionnaireVersions = useCallback(
    (questionnaireId: string) => {
      setQuestionnaireId(questionnaireId);
      void refetchQuestionnaireVersions();
    },
    [refetchQuestionnaireVersions],
  );

  const onPageChange = useCallback(($page: number): void => {
    setQuestionnaireQueryOptions((prevValue: QuestionnaireQueryOptions) => ({ ...prevValue, $page }));
  }, []);

  const onLimitPerPageChange = useCallback(($limit: number): void => {
    setQuestionnaireQueryOptions((prevValue: QuestionnaireQueryOptions) => ({ ...prevValue, $limit }));
  }, []);

  const onSortingChange = useCallback(($sort: SortParams<QuestionnaireVersionRowData>) => {
    setQuestionnaireQueryOptions((prevValue: QuestionnaireQueryOptions) => ({ ...prevValue, $sort }));
  }, []);

  return (
    <QuestionnaireVersionsContext.Provider
      value={{
        questionnaires: questionnaires || [],
        latestQuestionnaireId: questionnaires ? questionnaires[0]?.id : '',
        questionnaireVersions: questionnaireVersionsPage?.data || [],
        total: questionnaireVersionsPage?.total || 0,
        currentPage: questionnaireQueryOptions.$page || 1,
        perPage: questionnaireQueryOptions.$limit || PER_PAGE_OPTIONS[1].value,
        questionnaireVersionsIsFetching,
        questionnairesIsFetching,
        onPageChange,
        onLimitPerPageChange,
        onSortingChange,
        manuallyRefetchQuestionnaireVersions,
      }}
    >
      {props.children}
    </QuestionnaireVersionsContext.Provider>
  );
}
