import { Box } from '@breathelife/mui';
import { ReactElement } from 'react';
import { useTranslation } from 'react-i18next';

import { BaseESignSigner } from '@breathelife/types';

import { GreyContainer } from '../../../../../Components/AssistedApplication/Styles';
import Typography from '../../../../../Components/Typography';

type SignerInfoHeaderProps = {
  signer: BaseESignSigner;
};

export function SignerInfoHeader(props: SignerInfoHeaderProps): ReactElement {
  const { signer } = props;
  const { firstName, lastName, roles } = signer;

  const { t } = useTranslation();

  return (
    <GreyContainer display='flex' py={1} px={2} mb={2}>
      <Box display='flex' alignItems='center'>
        <Typography variant='h4' component='span'>
          {firstName} {lastName} :
        </Typography>
      </Box>
      <Box ml={1} display='flex' alignItems='center'>
        <Typography variant='body1' component='span'>
          {roles.map((role) => t(`participantRole.${role.type}`)).join(', ')}
        </Typography>
      </Box>
    </GreyContainer>
  );
}
