import { NodeIds } from '@breathelife/insurance-form-builder';
import { QuestionnaireEngine } from '@breathelife/questionnaire-engine';
import { CollectionInstanceIdentifiers, VersionedAnswers } from '@breathelife/types';

export type ShouldFetchADOParams = {
  answers: VersionedAnswers;
  featureIsEnabled?: boolean;
  updatedNodeIds: string[];
  currentProposedInsuredIndex: number;
  questionnaireEngine: QuestionnaireEngine;
};

export type NodesToRefreshParams = {
  answers: VersionedAnswers;
  featureIsEnabled?: boolean;
  currentProposedInsuredIndex: number;
  questionnaireEngine: QuestionnaireEngine;
};

type NodeToRefresh = {
  nodeId: string;
  value: number;
  collectionInstanceIdentifiers: CollectionInstanceIdentifiers;
};

// Checks whether the conditions are met to fetch ADO values
export function shouldFetchADO({
  answers,
  featureIsEnabled,
  updatedNodeIds,
  currentProposedInsuredIndex,
  questionnaireEngine,
}: ShouldFetchADOParams): boolean {
  if (!featureIsEnabled) return false;

  const adoOptionNodeId = 'ado-option';
  const collectionInstanceIdentifiers: CollectionInstanceIdentifiers = {
    [NodeIds.insuredPeople]: currentProposedInsuredIndex,
  };

  const adoOption = questionnaireEngine.getAnswer(answers, adoOptionNodeId, collectionInstanceIdentifiers);

  // Don't fetch if ADO is not toggled on and that node is not being updated
  if ((!adoOption || adoOption === 'no') && !updatedNodeIds.includes(adoOptionNodeId)) return false;

  // Don't fetch if ADO is toggled on and that node is being updated (meaning it is being toggled off)
  if (adoOption === 'additionalDepositOption' && updatedNodeIds.includes(adoOptionNodeId)) return false;

  return true;
}

// Checks whether the conditions are met to fetch ADO values
export function adoNodesToRefresh({
  answers,
  featureIsEnabled,
  currentProposedInsuredIndex,
  questionnaireEngine,
}: NodesToRefreshParams): NodeToRefresh[] {
  if (!featureIsEnabled) return [];

  const adoMaxNodeId = 'ado-max';
  const adoMinNodeId = 'ado-min';
  const collectionInstanceIdentifiers: CollectionInstanceIdentifiers = {
    [NodeIds.insuredPeople]: currentProposedInsuredIndex,
  };
  const adoMax = questionnaireEngine.getAnswer(answers, adoMaxNodeId, collectionInstanceIdentifiers) as number;
  const adoMin = questionnaireEngine.getAnswer(answers, adoMinNodeId, collectionInstanceIdentifiers) as number;
  return [
    { nodeId: adoMaxNodeId, value: adoMax, collectionInstanceIdentifiers },
    { nodeId: adoMinNodeId, value: adoMin, collectionInstanceIdentifiers },
  ];
}
