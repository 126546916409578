import { Dispatch, ReactElement, SetStateAction, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import {
  Box,
  Divider,
  ExpandMoreIcon,
  ExpansionPanel,
  ExpansionPanelDetails,
  ExpansionPanelSummary,
  Typography,
} from '@breathelife/mui';
import { NavigationSidebarVariants, Theme } from '@breathelife/types';

import { Select } from '../../../Components/Select/Select';
import { getOptionsFromEnum } from '../../../Helpers/options';

type Props = {
  navigationSidebarVariant: NavigationSidebarVariants;
  updatedThemeData: Partial<Theme>;
  setUpdatedThemeData: Dispatch<SetStateAction<Partial<Theme>>>;
};

export function SidebarVariantEditView({
  navigationSidebarVariant: navigationSidebarVariantProp,
  updatedThemeData,
  setUpdatedThemeData,
}: Props): ReactElement {
  const { t } = useTranslation();
  const [navigationSidebarVariant, setNavigationSidebarVariant] =
    useState<NavigationSidebarVariants>(navigationSidebarVariantProp);

  useEffect(() => {
    setNavigationSidebarVariant(navigationSidebarVariantProp);
  }, [navigationSidebarVariantProp]);

  const navigationSidebarOptions = getOptionsFromEnum(
    NavigationSidebarVariants,
    'admin.themeManagement.navigationSidebarVariant.options',
  );

  return (
    <ExpansionPanel defaultExpanded>
      <ExpansionPanelSummary
        expandIcon={<ExpandMoreIcon />}
        aria-controls='navigationSidebarVariant-content'
        id='navigationSidebarVariant-header'
      >
        <Box display='flex' flexDirection='row' alignItems='center' mr={2}>
          <Typography variant='h2'>{t('admin.themeManagement.navigationSidebarVariant.title')}</Typography>
        </Box>
      </ExpansionPanelSummary>
      <Divider />
      <ExpansionPanelDetails>
        <Box display='flex' flexDirection='column' alignItems='center'>
          <Select
            id='sidebar-theme-dropdown'
            value={navigationSidebarVariant}
            onChange={(value) => {
              setNavigationSidebarVariant(value as NavigationSidebarVariants);
              setUpdatedThemeData({ ...updatedThemeData, navigationSidebarVariant: value });
            }}
            options={navigationSidebarOptions}
          />
        </Box>
      </ExpansionPanelDetails>
    </ExpansionPanel>
  );
}
