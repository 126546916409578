import { ReactElement, useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import { logger } from '@breathelife/monitoring-frontend';
import { Tooltip } from '@breathelife/mui';
import { IconName, OutcomeCode } from '@breathelife/types';
import { Icon } from '@breathelife/ui-components';

import { useTheme } from '../../Styles/themed-styled-components';
import { LeadPlatformTheme } from '../../Styles/Types';
import { BadgeConfig, BadgeUI, StyledBadge, StyledBadgeContainer, StyledBadgeIconContainer } from './Badge';

type ValidOutcomeCode = Extract<
  OutcomeCode,
  | OutcomeCode.accepted
  | OutcomeCode.blocked
  | OutcomeCode.blockedAtQuoter
  | OutcomeCode.conditional
  | OutcomeCode.denied
  | OutcomeCode.referred
  | OutcomeCode.notApplicable
  | OutcomeCode.unknown
>;

export type OutcomeBadgeProps = {
  badgeContentTestId?: string;
  iconContainerTestId?: string;
  outcome: OutcomeCode;
  overrideOutcome?: string;
  withIcon?: boolean;
};

export function OutcomeBadge({
  badgeContentTestId = 'outcome-badge-content',
  iconContainerTestId = 'outcome-badge-icon-container',
  outcome,
  overrideOutcome,
  withIcon = false,
}: OutcomeBadgeProps): ReactElement | null {
  const theme = useTheme() as LeadPlatformTheme;
  const { t } = useTranslation();

  const badgeConfig = useMemo<(BadgeConfig & { tooltip: string }) | null>(() => {
    const label = overrideOutcome ? t(`outcomes.${overrideOutcome}.label`) : t(`outcomes.${outcome}.label`);
    const tooltip = overrideOutcome ? t(`outcomes.${overrideOutcome}.tooltip`) : t(`outcomes.${outcome}.tooltip`);
    const outcomeUIMap: Record<ValidOutcomeCode, BadgeUI> = {
      [OutcomeCode.accepted]: {
        icon: <Icon name={IconName.circledApplication} size='20' />,
        styles: {
          backgroundColor: theme.colors.green[20],
          color: theme.colors.green[60],
        },
      },
      [OutcomeCode.blocked]: {
        icon: <Icon name={IconName.circledApplication} size='20' />,
        styles: {
          backgroundColor: theme.colors.blue[20],
          color: theme.colors.blue[60],
        },
      },
      [OutcomeCode.blockedAtQuoter]: {
        icon: <Icon name={IconName.circledApplication} size='20' />,
        styles: {
          backgroundColor: theme.colors.purple[20],
          color: theme.colors.purple[60],
        },
      },
      [OutcomeCode.conditional]: {
        icon: <Icon name={IconName.circledApplication} size='20' />,
        styles: {
          backgroundColor: theme.colors.yellow[20],
          color: theme.colors.yellow[60],
        },
      },
      [OutcomeCode.denied]: {
        icon: <Icon name={IconName.circledApplication} size='20' />,
        styles: {
          backgroundColor: theme.colors.red[20],
          color: theme.colors.red[60],
        },
      },
      [OutcomeCode.referred]: {
        icon: <Icon name={IconName.circledApplication} size='20' />,
        styles: {
          backgroundColor: theme.colors.orange[20],
          color: theme.colors.orange[60],
        },
      },
      [OutcomeCode.notApplicable]: {
        icon: <Icon name={IconName.circledApplication} size='20' />,
        styles: {
          backgroundColor: theme.colors.purple[20],
          color: theme.colors.purple[60],
        },
      },
      [OutcomeCode.unknown]: {
        icon: <Icon name={IconName.circledApplication} size='20' />,
        styles: {
          backgroundColor: theme.colors.grey[20],
          color: theme.colors.grey[60],
        },
      },
    };

    const styles = outcomeUIMap[outcome as keyof typeof outcomeUIMap];

    return styles ? { ...styles, label, tooltip } : null;
  }, [outcome, overrideOutcome, t, theme]);

  if (!badgeConfig) {
    logger.error(`Unknown outcome code: ${outcome}`);
    return null;
  }

  const { icon, label, styles, tooltip } = badgeConfig;

  return (
    <Tooltip arrow placement='right' title={tooltip}>
      <StyledBadgeContainer alignItems='center' display='flex'>
        {withIcon && icon ? (
          <StyledBadgeIconContainer data-testid={iconContainerTestId}>{icon}</StyledBadgeIconContainer>
        ) : null}
        <StyledBadge
          data-testid={badgeContentTestId}
          backgroundColor={styles.backgroundColor}
          label={label}
          textColor={styles.color}
        />
      </StyledBadgeContainer>
    </Tooltip>
  );
}
