import { ReactComponentElement, ReactElement, ChangeEvent } from 'react';

import { Box, Tab, Tabs as MuiTabs } from '@breathelife/mui';

import styled from '../../Styles/themed-styled-components';

const StyledTab = styled(Tab)`
  &&& {
    padding: 0;
    min-width: unset;
    margin-left: 20px;

    &:first-child {
      margin-left: unset;
    }
  }
`;

export type TabType = {
  label: string;
  hidden?: boolean;
  disabled?: boolean;
};

export type Props = {
  tabs: TabType[];
  value: number | false;
  onChange: (event: ChangeEvent<{}>, value: number) => void;
};

type TabPanelProps = {
  selectedIndex: number | undefined;
  tabIndex: number;
  children: ReactComponentElement<any> | null;
};

export function TabPanel(props: TabPanelProps): ReactElement | null {
  return props.selectedIndex === props.tabIndex ? props.children : null;
}
export function Tabs(props: Props): ReactElement {
  return (
    <MuiTabs variant='scrollable' indicatorColor='primary' textColor='primary' {...props}>
      {props.tabs.map((tab, index) => (
        <StyledTab
          key={`rightPanelTab-${index}`}
          label={tab.label}
          data-testid={`tab-${tab.label.toLowerCase().replace(' ', '-')}`}
          hidden={tab.hidden}
          disabled={tab.disabled}
        />
      ))}
    </MuiTabs>
  );
}

export const TabsContainer = styled(Box)`
  && {
    border-color: ${(props) => props.theme.colors.grey[40]};
    box-shadow: 0 1px 5px rgba(9, 30, 66, 0.05);
  }
`;
