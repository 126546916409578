import ReactHtmlParser from 'html-react-parser';
import { ReactElement, Fragment, useMemo, useContext } from 'react';
import { useTranslation } from 'react-i18next';

import { NodeIds } from '@breathelife/insurance-form-builder';
import {
  AgeRoundingType,
  BlueprintCollectionOperator,
  BlueprintConditionValue,
  BlueprintSingleConditionValue,
  BooleanOperator,
  ConditionBlueprintType,
  DateUnit,
  isBlueprintConditionsValue,
  Language,
  NumberComparisonConditionOperator,
} from '@breathelife/types';

import Typography from '../../Components/Typography';
import { BooleanOperatorView } from './Helpers/BooleanOperator';

import { QuestionnaireVersionDataContext } from '../../Pages/Admin/Questionnaire/ContextProvider/QuestionnaireVersionDataContextProvider';

type SingleConditionProps = {
  condition: BlueprintSingleConditionValue;
  selectedLanguage: Language;
};

type ConditionsProps = {
  condition?: BlueprintConditionValue;
  selectedLanguage: Language;
};

const ROUNDING_TYPES_LABEL_KEYS = {
  [AgeRoundingType.closestBirthday]: 'admin.conditions.labels.roundingTypeClosestBirthday',
  [AgeRoundingType.lastBirthday]: 'admin.conditions.labels.roundingTypeLastBirthday',
  [AgeRoundingType.nextBirthday]: 'admin.conditions.labels.roundingTypeNextBirthday',
  [AgeRoundingType.none]: 'admin.conditions.labels.roundingTypeNone',
};

const UNIT_LABEL_KEYS = {
  [DateUnit.year]: 'admin.conditions.labels.years',
  [DateUnit.month]: 'admin.conditions.labels.months',
  [DateUnit.day]: 'admin.conditions.labels.days',
};

function SingleConditionView({ condition, selectedLanguage }: SingleConditionProps): ReactElement {
  const { t } = useTranslation();
  const { questionnaireVersionData } = useContext(QuestionnaireVersionDataContext);
  const { questionnaireNodeIds } = questionnaireVersionData || {};
  const operatorKey = useMemo(() => {
    // Note: This ignores any aggregate conditions on insured people. This should be adapted someday to display that information.

    const firstNonInsuredCollectionNodeId = condition.collectionOperators
      ? Object.keys(condition.collectionOperators).find(
          (collectionNodeId) => collectionNodeId !== NodeIds.insuredPeople,
        )
      : undefined;
    const firstNonInsuredCollectionOperator = firstNonInsuredCollectionNodeId
      ? condition.collectionOperators?.[firstNonInsuredCollectionNodeId]
      : undefined;

    return firstNonInsuredCollectionOperator &&
      firstNonInsuredCollectionOperator !== BlueprintCollectionOperator.thisItem
      ? firstNonInsuredCollectionOperator
      : 'default';
  }, [condition.collectionOperators]);

  switch (condition.type) {
    case ConditionBlueprintType.ageRange: {
      const textKey = `admin.conditions.labels.conditionSummary.${condition.type}.${operatorKey}`;

      return (
        <Typography variant='body1'>
          {ReactHtmlParser(
            t(textKey, {
              fieldName: condition.targetBirthdateNodeId,
              minAge: condition.value.minAge,
              maxAge: condition.value.maxAge,
              unit: t(UNIT_LABEL_KEYS[condition.value.unit]).toLowerCase(),
              roundingType: condition.roundingType
                ? t(ROUNDING_TYPES_LABEL_KEYS[condition.roundingType]).toLowerCase()
                : '',
            }),
          )}
        </Typography>
      );
    }

    case ConditionBlueprintType.bmiRange: {
      const textKey = `admin.conditions.labels.conditionSummary.${condition.type}.${operatorKey}`;

      return (
        <Typography variant='body1'>
          {ReactHtmlParser(
            t(textKey, {
              minBmi: condition.value.minBMI,
              maxBmi: condition.value.maxBMI,
              heightField: condition.heightNodeId,
              weightField: condition.weightNodeId,
            }),
          )}
        </Typography>
      );
    }

    case ConditionBlueprintType.characterCountInBetween: {
      const textKey = `admin.conditions.labels.conditionSummary.${condition.type}.${operatorKey}`;

      return (
        <Typography variant='body1'>
          {ReactHtmlParser(
            t(textKey, {
              fieldName: condition.targetNodeId,
              minLength: condition.value.minLength || '0',
              maxLength: condition.value.maxLength || t('admin.conditions.labels.noUpperLimit'),
            }),
          )}
        </Typography>
      );
    }

    case ConditionBlueprintType.countEqual: {
      const prefixKey = `admin.conditions.labels.conditionSummary.${condition.type}.prefix.${operatorKey}`;
      const suffixKey = `admin.conditions.labels.conditionSummary.${condition.type}.suffix.${condition.operator}`;

      return (
        <Typography variant='body1'>
          {ReactHtmlParser(
            t(prefixKey, {
              targetNodeId: condition.targetNodeId,
              controlValue: condition.controlValue,
            }),
          )}
          {ReactHtmlParser(t(suffixKey, { value: condition.value }))}
        </Typography>
      );
    }

    case ConditionBlueprintType.dateComparison: {
      const prefixKey = `admin.conditions.labels.conditionSummary.${condition.type}.prefix.${operatorKey}`;
      const suffixKey = `admin.conditions.labels.conditionSummary.${condition.type}.suffix.${condition.operator}`;
      const dateUnit = t(`admin.conditions.options.dateUnit.${condition.unit}`).toLowerCase();

      return (
        <Typography variant='body1'>
          {ReactHtmlParser(
            t(prefixKey, {
              startDateFieldName: condition.startDateNodeId,
              endDateFieldName: condition.endDateNodeId,
            }),
          )}
          {ReactHtmlParser(t(suffixKey, { value: condition.value, dateUnit }))}
        </Typography>
      );
    }

    case ConditionBlueprintType.emptiness: {
      const textKey = `admin.conditions.labels.conditionSummary.${condition.type}.${operatorKey}.${condition.isEmpty}`;

      return (
        <Typography variant='body1'>
          {ReactHtmlParser(
            t(textKey, {
              fieldName: condition.targetNodeId,
            }),
          )}
        </Typography>
      );
    }

    case ConditionBlueprintType.equality: {
      const textKey = `admin.conditions.labels.conditionSummary.${condition.type}.${operatorKey}.${condition.isEqual}`;
      const selectOptionsByNodeId = questionnaireNodeIds?.selectOptionsByNodeId;
      const selectOptions = selectOptionsByNodeId?.[condition.targetNodeId];
      let valueToDisplay: string | undefined;
      const value = condition.value;
      if (typeof value === 'object' && value._tag === 'selectOptionReference') {
        valueToDisplay = selectOptions?.find((selectOption) => selectOption.id === value?.selectOptionId)?.optionLabel[
          selectedLanguage
        ];
      } else {
        valueToDisplay = (value as string) || condition.nodeIdOfValue;
      }

      const textOptions = {
        fieldName: condition.targetNodeId,
        value: valueToDisplay,
      };

      return <Typography variant='body1'>{ReactHtmlParser(t(textKey, textOptions))}</Typography>;
    }

    case ConditionBlueprintType.futureDate: {
      const prefixKey = `admin.conditions.labels.conditionSummary.${condition.type}.prefix.${operatorKey}`;
      const suffixKey = `admin.conditions.labels.conditionSummary.${condition.type}.suffix.${condition.operator}`;

      return (
        <Typography variant='body1'>
          {ReactHtmlParser(t(prefixKey, { fieldName: condition.targetNodeId }))}
          {ReactHtmlParser(t(suffixKey, { value: condition.value, dateUnit: condition.unit }))}
        </Typography>
      );
    }

    case ConditionBlueprintType.lastIncidentDate: {
      const prefixKey = `admin.conditions.labels.conditionSummary.${condition.type}.prefix.${operatorKey}`;
      const suffixKey = `admin.conditions.labels.conditionSummary.${condition.type}.suffix.${condition.operator}`;

      return (
        <Typography variant='body1'>
          {ReactHtmlParser(t(prefixKey, { fieldName: condition.targetNodeId }))}
          {ReactHtmlParser(t(suffixKey, { value: condition.value, dateUnit: condition.unit }))}
        </Typography>
      );
    }

    case ConditionBlueprintType.matches: {
      const textKey = `admin.conditions.labels.conditionSummary.${condition.type}.${operatorKey}.${condition.quantifier}`;
      const selectOptionsByNodeId = questionnaireNodeIds?.selectOptionsByNodeId;
      const selectOptions = selectOptionsByNodeId?.[condition.targetNodeId];
      const displayedValues = condition.value.map((val) => {
        if (typeof val === 'object' && val._tag === 'selectOptionReference') {
          return selectOptions?.find((selectOption) => selectOption.id === val.selectOptionId)?.optionLabel[
            selectedLanguage
          ];
        }
        return val;
      });

      return (
        <Typography variant='body1'>
          {ReactHtmlParser(
            t(textKey, {
              fieldName: condition.targetNodeId,
              formattedValues: displayedValues.join(', '),
            }),
          )}
        </Typography>
      );
    }

    case ConditionBlueprintType.matchesRegex: {
      const key = `admin.conditions.labels.conditionSummary.${condition.type}.${operatorKey}`;

      return (
        <Typography variant='body1'>
          {ReactHtmlParser(
            t(key, {
              fieldName: condition.targetNodeId,
              regex: condition.regex,
            }),
          )}
        </Typography>
      );
    }

    case ConditionBlueprintType.mathOperator: {
      const prefixKey = `admin.conditions.labels.conditionSummary.${condition.type}.prefix.${operatorKey}.${condition.mathOperator}`;
      const suffixKey = `admin.conditions.labels.conditionSummary.${condition.type}.suffix.${condition.operator}`;
      const fieldNames = condition.nodeIds.join(', ');

      return (
        <Typography variant='body1'>
          {ReactHtmlParser(t(prefixKey, { fieldNames }))}
          {ReactHtmlParser(t(suffixKey, { value: condition.value || condition.nodeIdOfValue }))}
        </Typography>
      );
    }

    case ConditionBlueprintType.numberComparison: {
      const textKey = `admin.conditions.labels.conditionSummary.${condition.type}.${operatorKey}`;
      const numberComparisonOperatorKey = `admin.conditions.options.numberComparison.${condition.operator}`;

      const textOptions = {
        fieldName: condition.targetNodeId,
        operator: t(numberComparisonOperatorKey).toLowerCase(),
        conjunction: [
          NumberComparisonConditionOperator.equal,
          NumberComparisonConditionOperator.lessThanOrEqual,
          NumberComparisonConditionOperator.greaterThanOrEqual,
        ].includes(condition.operator)
          ? t('to')
          : null,
        value: condition.value || condition.nodeIdOfValue,
      };

      return <Typography variant='body1'>{ReactHtmlParser(t(textKey, textOptions))}</Typography>;
    }

    case ConditionBlueprintType.percentOf: {
      const prefixKey = `admin.conditions.labels.conditionSummary.${condition.type}.prefix.${operatorKey}`;
      const suffixKey = `admin.conditions.labels.conditionSummary.${condition.type}.suffix.${condition.operator}`;

      return (
        <Typography variant='body1'>
          {ReactHtmlParser(
            t(prefixKey, {
              targetNodeId: condition.targetNodeId,
              percent: condition.percent,
            }),
          )}
          {ReactHtmlParser(t(suffixKey, { value: condition.value || condition.nodeIdOfValue }))}
        </Typography>
      );
    }

    case ConditionBlueprintType.reflexive: {
      const textKey = `admin.conditions.labels.conditionSummary.${condition.type}.${operatorKey}`;
      const valueLabel = t(`admin.conditions.options.yesNo.${condition.value}`);

      return (
        <Typography variant='body1'>
          {ReactHtmlParser(
            t(textKey, {
              fieldName: condition.targetNodeId,
              value: valueLabel,
            }),
          )}
        </Typography>
      );
    }
  }
  return <Fragment />;
}

export function ConditionView({
  condition,
  depth = 0,
  selectedLanguage,
}: ConditionsProps & { depth?: number }): ReactElement | null {
  const { t } = useTranslation();
  if (!condition) {
    return null;
  }

  if (isBlueprintConditionsValue(condition)) {
    return (
      <span>
        {depth > 0 && condition.booleanOperator !== BooleanOperator.not ? '(' : null}
        {condition.booleanOperator === BooleanOperator.not ? `${t(condition.booleanOperator).toUpperCase()} (` : null}
        {condition.conditions.map((nestedCondition, index) => {
          return (
            <Fragment key={`condition-${depth}.${index}`}>
              <ConditionView condition={nestedCondition} depth={depth + 1} selectedLanguage={selectedLanguage} />
              <BooleanOperatorView conditions={condition} index={index} />
            </Fragment>
          );
        })}
        {depth > 0 || condition.booleanOperator === BooleanOperator.not ? ')' : null}
      </span>
    );
  }

  return <SingleConditionView condition={condition} selectedLanguage={selectedLanguage} />;
}
