import _ from 'lodash';
import { ReactElement, useCallback, useEffect, useState, ReactNode } from 'react';
import { FileWithPath, useDropzone } from 'react-dropzone';
import { useTranslation } from 'react-i18next';
import styled, { useTheme } from '../../../../../Styles/themed-styled-components';

import { Loader } from '@breathelife/ui-components';

import { SubmitButton } from '../../../../../Components/Button/SubmitButton';
import { ModalLayout } from '../../../../../Layouts/Modal/ModalLayout';
import { LeadPlatformTheme } from '../../../../../Styles/Types';

type GetColorProps = {
  isDragAccept?: boolean;
  isDragReject?: boolean;
  isDragActive?: boolean;
  theme: LeadPlatformTheme;
};

const getColor = (props: GetColorProps): string => {
  if (props.isDragAccept) {
    return props.theme.colors.green[50];
  }
  if (props.isDragReject) {
    return props.theme.colors.red[50];
  }
  if (props.isDragActive) {
    return props.theme.colors.blue[50];
  }
  return props.theme.colors.grey[30];
};

const StyledContainer = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 24px;
  border-width: 2px;
  border-radius: 4px;
  border-color: ${(props: GetColorProps) => getColor(props)};
  border-style: dashed;
  background-color: ${(props: GetColorProps) => props.theme.colors.grey[10]};
  color: ${(props: GetColorProps) => props.theme.colors.grey[50]};
  outline: none;
  transition: border 0.24s ease-in-out;
`;

const StyledFileDetails = styled.div`
  padding-top: 16px;
`;

const StyledFileList = styled.div`
  margin: 0;
  padding-left: 16px;
`;

type Props = {
  title: string;
  isOpen: boolean;
  onClose: () => void;
  handleFileUpload: (file: File[]) => void;
  accept: string | string[];
  renderCustomContent?: () => ReactElement | void;
  isLoading?: boolean;
  isSuccess?: boolean;
  hideCancel?: boolean;
};

export function DragAndDropUpload(props: Props): ReactElement {
  const { handleFileUpload, accept, isOpen, onClose, title, isSuccess, isLoading, renderCustomContent } = props;
  const { t } = useTranslation();
  const theme = useTheme();
  const [acceptedFileItems, setAcceptedFileItems] = useState<ReactNode[]>([]);

  const {
    acceptedFiles,
    getRootProps,
    getInputProps,
    isDragActive,
    isDragAccept,
    isDragReject,
    open: openSystemFilePicker,
  } = useDropzone({
    accept,
    maxFiles: 1,
    multiple: false,
  });

  const onUploadAreaClick = useCallback(
    (event) => {
      event.stopPropagation();
      openSystemFilePicker();
    },
    [openSystemFilePicker],
  );

  const onModalSubmit = useCallback(() => {
    handleFileUpload(acceptedFiles);
    setAcceptedFileItems([]);
  }, [acceptedFiles, handleFileUpload, setAcceptedFileItems]);

  const onModalClose = useCallback(() => {
    setAcceptedFileItems([]);
    onClose();
  }, [setAcceptedFileItems, onClose]);

  useEffect(() => {
    setAcceptedFileItems(
      acceptedFiles.map((file: FileWithPath) => (
        <li key={file.path}>
          {file.path} - {file.size} {t('dragAndDrop.bytes')}
        </li>
      )),
    );
    return;
  }, [acceptedFiles, t]);

  const isValidForSubmit = acceptedFileItems.length > 0;

  return (
    <ModalLayout
      maxWidth='lg'
      title={title}
      isOpen={isOpen}
      hideCancel
      closeModal={onModalClose}
      submitButton={
        isLoading ? (
          <Loader size='50px' />
        ) : !isSuccess ? (
          <SubmitButton disabled={!isValidForSubmit} onClick={onModalSubmit}>
            {t('cta.import')}
          </SubmitButton>
        ) : undefined
      }
    >
      {!isSuccess && (
        <StyledContainer
          {...getRootProps({ isDragActive, isDragAccept, isDragReject })}
          onClick={_.debounce(onUploadAreaClick, 300)}
          theme={theme}
        >
          <input {...getInputProps()} />
          <p>{t('dragAndDrop.dropFile')}</p>
        </StyledContainer>
      )}

      {renderCustomContent?.()}

      {!isLoading && !isSuccess && isValidForSubmit && (
        <StyledFileDetails>
          <h4>{t('dragAndDrop.acceptedFiles')}</h4>
          <StyledFileList>{acceptedFileItems}</StyledFileList>
        </StyledFileDetails>
      )}
    </ModalLayout>
  );
}
