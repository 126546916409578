import { Fragment, ReactElement, useContext, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { Tooltip } from '@breathelife/mui';
import {
  IconName,
  isFieldBlueprint,
  isQuestionBlueprint,
  PartIdentifier,
  PartIdentifierTag,
  QuestionnaireElementBlueprint,
} from '@breathelife/types';
import { AutocompleteOption, Icon, TreeViewItemRenderChildProps } from '@breathelife/ui-components';

import { Icon as LeadPlatformIcon } from '../../../../../Components/Icons';
import { MenuAction } from '../../../../../Components/PopupMenu/PopupMenu';
import Typography, { MonospaceTypography, Strikeable } from '../../../../../Components/Typography';
import { CarrierContext } from '../../../../../Context/CarrierContext';
import { getStartIndicesOfTermInString } from '../../../../../Helpers/questionnaireEditor/questionnaireSearch';
import {
  ActionButtons,
  RemovableResources,
} from '../../../../../Pages/Admin/Questionnaire/QuestionnaireEditor/Components/ActionButtons';
import styled, { useTheme } from '../../../../../Styles/themed-styled-components';
import { CopyDeckUnhandledRows } from '../Components/CopyDeckUnhandledRows';
import { DragAndDropUpload } from '../Components/DragAndDropUpload';
import { CreateQuestionnaireElement } from '../Creators/CreateQuestionnaireElement';
import { useCopyDeckImport } from '../Hooks/useCopyDeckImport';
import { QuestionnaireTreeViewDragContext } from './QuestionnaireTreeViewDragContext';
import { QuestionnaireTreeViewSearchContext } from './QuestionnaireTreeViewSearchContextProvider';
import { SearchInstanceTypographyContent } from './SearchInstanceTypographyContent';

const NumberOfSearchTermsInSelfAndChildrenIcon = styled.div`
  height: 16px;
  width: 16px;
  border-radius: 100%;
  background-color: ${({ theme }) => theme.colors.yellow[30]};
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 10px;
`;

const ActionButtonsShowOnHoverContainer = styled.div`
  opacity: 0;
  display: none;
  transition: 0.25s;
  display: flex;
`;

const QuestionnaireTreeViewItemWrapper = styled.a<{
  selected?: boolean;
  isUserDragging: boolean;
  isBeingDraggedToValidLocation: boolean;
  isBeingDraggedToInvalidLocation: boolean;
}>`
  position: relative;
  cursor: pointer;
  max-width: 100%;
  border-radius: 3px;
  height: 100%;
  display: flex;
  align-items: center;
  padding: 0 10px;
  background-color: ${({ selected, theme, isBeingDraggedToValidLocation, isBeingDraggedToInvalidLocation }) => {
    if (isBeingDraggedToValidLocation) {
      return theme.colors.green[20];
    }
    if (isBeingDraggedToInvalidLocation) {
      return theme.colors.red[30];
    }
    return selected ? theme.colors.grey[30] : 'transparent';
  }};
  margin-right: 10px;
  min-width: 0;
  flex-grow: 1;
  transition: 0.25s;
  justify-content: space-between;
  color: ${({ selected, theme }) => (selected ? theme.colors.primary.default : theme.colors.grey[90])};
  &:hover {
    background-color: ${({ theme, isUserDragging, selected }) =>
      isUserDragging && !selected ? 'inherit' : theme.colors.grey[30]};
    color: ${({ theme, selected }) => (selected ? theme.colors.primary.default : theme.colors.grey[80])};
  }
  &:active {
    background-color: ${({ theme }) => theme.colors.grey[40]};
  }
  &:hover ${ActionButtonsShowOnHoverContainer} {
    opacity: ${({ isUserDragging }) => (isUserDragging ? 0 : 1)};
  }
`;

const QuestionnaireTreeViewItemInfoWrapper = styled.div`
  display: grid;
  grid-gap: 10px;
  grid-auto-flow: column;
  align-items: center;
`;
const Label = styled.div`
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  & span {
    line-height: 1.1;
  }
`;

export type SearchInfo = {
  numberOfSearchTermsInSelfAndChildren?: number;
  numberOfSearchTermsInSelf?: number;
};

type QuestionnaireTreeViewItemProps = {
  label: string;
  type: PartIdentifierTag;
  dataLabelOptions: AutocompleteOption[];
  childType?: PartIdentifierTag;
  selected?: boolean;
  selectable?: boolean;
  onClick?: () => void;
  onCopy?: () => void;
  canPaste?: boolean;
  onPaste?: () => void;
  onHide: () => void;
  onRemove?: () => void;
  onAddChild?: (blueprint: QuestionnaireElementBlueprint) => void;
  onImportFromCopyDeck?: () => void;
  removableResourceName?: RemovableResources;
  isEditDisabled?: boolean;
  areParentsHidden?: boolean;
  blueprint?: QuestionnaireElementBlueprint;
  dragHandleProps?: TreeViewItemRenderChildProps['dragHandleProps'];
  isCurrentlyDragged?: boolean;
  sectionGroupCollectionNodeId: string | undefined;
  searchInfo?: SearchInfo;
  partIdentifier: PartIdentifier;
};

export function QuestionnaireTreeViewIcon({ type }: { type: PartIdentifierTag }): ReactElement | null {
  const theme = useTheme();
  const color = theme.colors.grey[60];
  const size = '20px';

  switch (type) {
    case PartIdentifierTag.section:
      return <Icon name={IconName.folder} size={size} color={{ primary: color }} />;
    case PartIdentifierTag.subsection:
      return <Icon name={IconName.fileMenu} size={size} color={{ primary: color }} />;
    case PartIdentifierTag.question:
      return <Icon name={IconName.list} size={size} color={{ primary: color }} />;
    case PartIdentifierTag.field:
      return <Icon name={IconName.question} size={size} color={{ primary: color }} />;
    default:
      return null;
  }
}

export function QuestionnaireTreeViewItem({
  label,
  onClick,
  selected,
  selectable = true,
  onHide,
  removableResourceName,
  onRemove,
  onAddChild,
  onCopy,
  canPaste,
  onPaste,
  type,
  dataLabelOptions,
  childType,
  isEditDisabled,
  areParentsHidden,
  blueprint,
  dragHandleProps,
  isCurrentlyDragged,
  partIdentifier,
  searchInfo,
}: QuestionnaireTreeViewItemProps): ReactElement {
  const { t } = useTranslation();
  const { searchTerm } = useContext(QuestionnaireTreeViewSearchContext);

  const { features } = useContext(CarrierContext);

  const { isUserDragging, isCurrentDragValid } = useContext(QuestionnaireTreeViewDragContext);
  const {
    isCopyDeckImportModalOpen,
    setIsCopyDeckImportModalOpen,
    handleFileUpload,
    isLoading: copyDeckImportIsLoading,
    isSuccess: copyDeckImportIsSuccess,
    unhandledRows,
  } = useCopyDeckImport({
    parentPartIdentifier: partIdentifier,
  });

  const [isModalOpen, setIsModalOpen] = useState<boolean>(false);

  const buttonsText = useMemo(() => {
    if (type === PartIdentifierTag.section) {
      return {
        copy: t('admin.questionnaireManagement.clipboard.copySection'),
        paste: t('admin.questionnaireManagement.clipboard.pasteSubsection'),
        add: t('admin.questionnaireManagement.input.addSubsection'),
      };
    }
    if (type === PartIdentifierTag.subsection) {
      return {
        copy: t('admin.questionnaireManagement.clipboard.copySubsection'),
        paste: t('admin.questionnaireManagement.clipboard.pasteFieldGroup'),
        add: t('admin.questionnaireManagement.input.addQuestion'),
      };
    }
    if (type === PartIdentifierTag.question) {
      return {
        copy: t('admin.questionnaireManagement.clipboard.copyFieldGroup'),
        paste: t('admin.questionnaireManagement.clipboard.pasteField'),
        add: t('admin.questionnaireManagement.input.addField'),
      };
    }
    if (type === PartIdentifierTag.field) {
      return {
        copy: t('admin.questionnaireManagement.clipboard.copyField'),
      };
    }
    return {};
  }, [type, t]);

  const canAddChild = !!onAddChild && childType;

  const nodeId = useMemo(() => {
    if (!blueprint) {
      return undefined;
    }
    if (isFieldBlueprint(blueprint)) {
      return blueprint.answerNodeId;
    }
    if (isQuestionBlueprint(blueprint)) {
      return blueprint.repeatable?.repeatableAnswerNodeId;
    }
    return undefined;
  }, [blueprint]);

  const additionalActions: MenuAction[] = useMemo(() => {
    const actions: MenuAction[] = [];

    if (onCopy) {
      actions.push({
        tag: 'MenuAction',
        onClick: onCopy,
        icon: <LeadPlatformIcon name='copy' width='16px' height='16px' />,
        label: buttonsText?.copy || '',
        disabled: isEditDisabled,
      });
    }
    if (onPaste) {
      actions.push({
        tag: 'MenuAction',
        onClick: onPaste,
        icon: <LeadPlatformIcon name='files' width='16px' height='16px' />,
        disabled: !canPaste || isEditDisabled || areParentsHidden,
        label: buttonsText?.paste || '',
      });
    }
    if (onAddChild && canAddChild) {
      actions.push({
        tag: 'MenuAction',
        onClick: () => setIsModalOpen(true),
        icon: <LeadPlatformIcon name='addAssured' width='16px' height='16px' />,
        label: buttonsText?.add || '',
        disabled: isEditDisabled || areParentsHidden,
      });
    }
    if (partIdentifier.tag === PartIdentifierTag.section && features.importCopyDeck?.enabled) {
      actions.push({
        tag: 'MenuAction',
        onClick: () => setIsCopyDeckImportModalOpen(true),
        icon: <LeadPlatformIcon name='addAssured' width='16px' height='16px' />,
        label: t('admin.questionnaireManagement.importFromCopyDeck'),
        disabled: isEditDisabled || areParentsHidden,
      });
    }

    return actions;
  }, [
    onCopy,
    onPaste,
    onAddChild,
    canPaste,
    buttonsText,
    canAddChild,
    setIsModalOpen,
    isEditDisabled,
    areParentsHidden,
    partIdentifier,
    setIsCopyDeckImportModalOpen,
    features.importCopyDeck,
    t,
  ]);

  const isItemHidden = !!blueprint?.hidden;
  const areActionItemsDisabled = isEditDisabled || !!areParentsHidden;

  const indicesOfSearchTermInTitle = getStartIndicesOfTermInString(label.toLowerCase(), searchTerm.toLowerCase());
  const indicesOfSearchTermInNodeId = getStartIndicesOfTermInString(nodeId?.toLowerCase(), searchTerm.toLowerCase());

  return (
    <Fragment>
      <QuestionnaireTreeViewItemWrapper
        onClick={onClick}
        selected={selected}
        isUserDragging={isUserDragging}
        isBeingDraggedToValidLocation={!!(isCurrentlyDragged && isCurrentDragValid)}
        isBeingDraggedToInvalidLocation={!!(isCurrentlyDragged && isCurrentDragValid === false)}
      >
        <QuestionnaireTreeViewItemInfoWrapper>
          {searchInfo?.numberOfSearchTermsInSelf ? (
            <NumberOfSearchTermsInSelfAndChildrenIcon>
              {searchInfo.numberOfSearchTermsInSelf}
            </NumberOfSearchTermsInSelfAndChildrenIcon>
          ) : undefined}
          <QuestionnaireTreeViewIcon type={type} />
          <Strikeable strike={isItemHidden}>
            <Tooltip title={label} placement='bottom-start'>
              <Label>
                <Typography variant='body3' noWrap display='block'>
                  {indicesOfSearchTermInTitle ? (
                    <SearchInstanceTypographyContent
                      text={label}
                      searchTermCharacterLength={searchTerm.length}
                      startIndices={indicesOfSearchTermInTitle}
                    />
                  ) : (
                    label
                  )}
                </Typography>

                {nodeId && (
                  <MonospaceTypography variant='small1' noWrap display='block'>
                    {indicesOfSearchTermInNodeId ? (
                      <SearchInstanceTypographyContent
                        text={nodeId}
                        searchTermCharacterLength={searchTerm.length}
                        startIndices={indicesOfSearchTermInNodeId}
                      />
                    ) : (
                      nodeId
                    )}
                  </MonospaceTypography>
                )}
              </Label>
            </Tooltip>
          </Strikeable>
        </QuestionnaireTreeViewItemInfoWrapper>
        <ActionButtonsShowOnHoverContainer>
          <ActionButtons
            disabled={!selectable}
            hideItemButtonProps={{
              isItemHidden: isItemHidden,
              onHideItemButtonClick: onHide,
              disabled: areActionItemsDisabled,
            }}
            removeItemButtonProps={
              onRemove
                ? {
                    resourceName: removableResourceName,
                    onRemoveItemClick: onRemove,
                    disabled: areActionItemsDisabled,
                  }
                : undefined
            }
            additionalActions={additionalActions.length > 0 ? additionalActions : undefined}
          />
          {dragHandleProps && (
            <div {...dragHandleProps} style={{ display: 'flex', alignItems: 'center' }}>
              <Icon name={IconName.dragHandle} size='20px' />
            </div>
          )}
        </ActionButtonsShowOnHoverContainer>
      </QuestionnaireTreeViewItemWrapper>
      {childType && !!onAddChild && isModalOpen && !isEditDisabled && (
        <CreateQuestionnaireElement
          parentPartIdentifier={partIdentifier}
          type={childType}
          dataLabelOptions={dataLabelOptions}
          onClose={() => setIsModalOpen(false)}
          onCreate={(blueprint) => onAddChild(blueprint)}
          parentBlueprint={blueprint}
        />
      )}
      {partIdentifier.tag === PartIdentifierTag.section && features.importCopyDeck?.enabled && (
        <DragAndDropUpload
          hideCancel
          accept={'text/csv'}
          title={
            copyDeckImportIsSuccess
              ? t('admin.questionnaireManagement.importFromCopyDeckSuccess')
              : t('admin.questionnaireManagement.importFromCopyDeck')
          }
          isOpen={isCopyDeckImportModalOpen}
          handleFileUpload={handleFileUpload}
          onClose={() => setIsCopyDeckImportModalOpen(false)}
          isLoading={copyDeckImportIsLoading}
          isSuccess={copyDeckImportIsSuccess}
          renderCustomContent={
            copyDeckImportIsSuccess && unhandledRows ? () => <CopyDeckUnhandledRows items={unhandledRows} /> : undefined
          }
        />
      )}
    </Fragment>
  );
}
