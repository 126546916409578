import { ReactElement, useCallback } from 'react';
import { useTranslation } from 'react-i18next';

import { Box, ListItemText } from '@breathelife/mui';
import { ESignSigner2FAInfo, ExpandableFileTemplate, StoredFile } from '@breathelife/types';
import { ImageTypes, Loader } from '@breathelife/ui-components';

import { IconButton } from '../../../../../Components/Controls';
import { Icon } from '../../../../../Components/Icons';
import { getFileTemplateSignatureRoles } from '../../../../../Helpers/files/getFileTemplateSignatureRoles';
import { useGetApplicationFile, useLocale } from '../../../../../Hooks';
import { Application } from '../../../../../Models/Application';
import { DisplayNameTypography } from '../../Documents/Files/Styles';

type Props = {
  application: Application;
  template: ExpandableFileTemplate;
  storedFile: StoredFile | undefined;
  participants: ESignSigner2FAInfo[];
};

export function DocumentToSign(props: Props): ReactElement {
  const { application, template, storedFile, participants } = props;
  const { t } = useTranslation();
  const platformLanguage = useLocale();

  const { fetchFile: fetchFileHook, fileToView } = useGetApplicationFile(
    application.id,
    storedFile?.id ?? '',
    useCallback(({ signedUrl }) => {
      window.open(signedUrl, '_blank')?.focus();
    }, []),
  );

  const fetchFile = useCallback(() => {
    if (!storedFile?.id) return;
    fetchFileHook();
  }, [fetchFileHook, storedFile?.id]);

  const signatureRequiredLabel = t('assistedApplication.signatureDrawer.signatureRequired');

  const signatureRoles = getFileTemplateSignatureRoles(template.fileTemplateRecipients, participants);
  const signatureRequiredValue = signatureRoles
    .map(({ type: roleType }) => t(`participantRole.${roleType}`))
    .join(', ');

  const localizedFileTemplateName = template.localizedName[platformLanguage] ?? template.localizedName.en;

  const isLoading: boolean = fileToView.isLoading;

  return (
    <ListItemText>
      <Box display='flex' flexDirection='row' my={3}>
        <Box mr={2}>
          {isLoading && <Loader size='24' />}
          {!isLoading && storedFile ? (
            <Icon
              name='success'
              variant='green'
              inverted
              imageType={ImageTypes.meaningful}
              ariaLabel={t('assistedApplication.success')}
            />
          ) : (
            <Icon
              name='warning'
              variant='yellow'
              fill='none'
              imageType={ImageTypes.meaningful}
              ariaLabel={t('assistedApplication.warning')}
            />
          )}
        </Box>
        <Box display='flex' flexDirection='row' width='100%' justifyContent='space-between'>
          <Box>
            <Box>
              <DisplayNameTypography variant='h4'>{localizedFileTemplateName}</DisplayNameTypography>
            </Box>
            {storedFile && (
              <Box>
                <DisplayNameTypography variant='body3'>{storedFile.displayName}</DisplayNameTypography>
              </Box>
            )}
            <Box>
              <DisplayNameTypography variant='body3'>
                {signatureRequiredLabel}: <strong>{signatureRequiredValue}</strong>
              </DisplayNameTypography>
            </Box>
          </Box>
          <Box display='flex' flexWrap='no-wrap' alignItems='center'>
            <Box mx={1}>
              <IconButton
                icon={
                  <Icon
                    name='eye'
                    imageType={ImageTypes.meaningful}
                    ariaLabel={t('modals.assistedApplication.fileAttachment.openAttachment')}
                  />
                }
                disabled={isLoading}
                onClick={fetchFile}
              />
            </Box>
          </Box>
        </Box>
      </Box>
    </ListItemText>
  );
}
