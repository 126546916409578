import { useMutation, UseMutationOptions, UseMutationResult, useQueryClient } from 'react-query';

import { EntityMappingDependencies, EntityMapping, GetEntityMappingReturnValue } from '@breathelife/types';

import { QueryId } from '../../../ReactQuery/common/common.types';
import { updateEntityMapping } from '../../../Services/EntityMappingService';
import { useDispatch } from 'react-redux';
import { notificationSlice } from '../../../ReduxStore/Notification/NotificationSlice';
import ApiService from '../../../Services/ApiService';
import { useTranslation } from 'react-i18next';

export function useUpdateEntityMappingMutation(
  options?: UseMutationOptions<EntityMapping, unknown, EntityMapping>,
): UseMutationResult<EntityMapping, unknown, EntityMapping> {
  const queryClient = useQueryClient();
  const dispatch = useDispatch();
  const { t } = useTranslation();

  return useMutation<EntityMapping, unknown, EntityMapping, unknown>(updateEntityMapping, {
    ...options,
    onSuccess: async (data, variables, context): Promise<void> => {
      const query = [QueryId.entityMappings, data.mapping, data.namespace, data.questionnaireVersionId];

      const getEntityMappingReturnValue = toGetEntityMappingReturnValue(data, queryClient.getQueryData(query));

      queryClient.setQueryData(query, getEntityMappingReturnValue);

      dispatch(
        notificationSlice.actions.setSuccess({
          message: t('admin.entityMappings.saved'),
        }),
      );

      if (options?.onSuccess) {
        await options.onSuccess(data, variables, context);
      }
    },
  });
}

function toGetEntityMappingReturnValue(
  entityMapping: EntityMapping,
  previousGetEntityMappingReturnValue?: GetEntityMappingReturnValue,
): GetEntityMappingReturnValue {
  return {
    entityMapping,
    typeDefinitions: previousGetEntityMappingReturnValue?.typeDefinitions,
  };
}

type UseAddEntityMappingDependencyMutation = () => UseMutationResult<
  EntityMappingDependencies,
  unknown,
  { entityMapping: EntityMapping; entityMappingDependencies: EntityMappingDependencies }
>;
export const useAddEntityMappingDependencyMutation: UseAddEntityMappingDependencyMutation = () => {
  const queryClient = useQueryClient();
  const dispatch = useDispatch();
  const { t } = useTranslation();

  return useMutation({
    mutationFn: async ({ entityMappingDependencies }): Promise<EntityMappingDependencies> => {
      const response = await ApiService.admin.addEntityMappingDependency(entityMappingDependencies);
      return response.data;
    },

    onSuccess: async (data, variables) => {
      const message = t('admin.entityMappings.addEntityMappingDependencySuccess');
      dispatch(notificationSlice.actions.setSuccess({ message }));

      await queryClient.invalidateQueries([QueryId.entityMappingDependencies, data.entityMappingId]);

      await queryClient.invalidateQueries([
        QueryId.entityMappings,
        variables.entityMapping.mapping,
        variables.entityMapping.namespace,
        variables.entityMapping.questionnaireVersionId,
      ]);
    },

    onError: () => {
      const message = t('admin.entityMappings.addEntityMappingDependencyFail');
      dispatch(notificationSlice.actions.setError({ message }));
    },
  });
};

type UseRemoveEntityMappingDependencyMutation = () => UseMutationResult<
  EntityMappingDependencies,
  unknown,
  { entityMapping: EntityMapping; entityMappingDependencies: EntityMappingDependencies }
>;
export const useRemoveEntityMappingDependencyMutation: UseRemoveEntityMappingDependencyMutation = () => {
  const queryClient = useQueryClient();
  const dispatch = useDispatch();
  const { t } = useTranslation();

  return useMutation({
    mutationFn: async ({ entityMappingDependencies }): Promise<EntityMappingDependencies> => {
      const response = await ApiService.admin.removeEntityMappingDependency(entityMappingDependencies);
      return response.data;
    },

    onSuccess: async (_data, variables) => {
      const message = t('admin.entityMappings.removeEntityMappingDependencySuccess');
      dispatch(notificationSlice.actions.setSuccess({ message }));

      await queryClient.invalidateQueries([
        QueryId.entityMappingDependencies,
        variables.entityMappingDependencies.entityMappingId,
      ]);

      await queryClient.invalidateQueries([
        QueryId.entityMappings,
        variables.entityMapping.mapping,
        variables.entityMapping.namespace,
        variables.entityMapping.questionnaireVersionId,
      ]);
    },

    onError: () => {
      const message = t('admin.entityMappings.removeEntityMappingDependencyFail');
      dispatch(notificationSlice.actions.setError({ message }));
    },
  });
};
