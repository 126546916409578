import { UseQueryResult, useQuery } from 'react-query';
import { Entity } from '@breathelife/types';
import ApiService from '../../../Services/ApiService';
import { AxiosError } from 'axios';
import { QueryId } from '../../common/common.types';

export const useGetEntityQuery = (id: string): UseQueryResult<Entity> => {
  return useQuery({
    enabled: Boolean(id),

    queryKey: [QueryId.entity, id],

    queryFn: async (): Promise<Entity> => {
      const response = await ApiService.admin.getEntity(id);
      return response.data;
    },
  });
};

export const useGetEntitiesQuery = (questionnaireVersionId: string): UseQueryResult<Entity[], AxiosError> => {
  return useQuery({
    enabled: Boolean(questionnaireVersionId),

    queryKey: [QueryId.entities, questionnaireVersionId],

    queryFn: async (): Promise<Entity[]> => {
      const response = await ApiService.admin.getEntities({ questionnaireVersionId });
      return response.data;
    },
  });
};
