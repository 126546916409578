import { Fragment, ReactElement, useCallback, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { Alert, Box, Collapse } from '@breathelife/mui';
import { ApplicationPointOfSaleDecisions, ESignCeremony, ESignSigner2FAInfo, OutcomeCode } from '@breathelife/types';

import LoadingView from '../../../../../Components/LoadingView/LoadingView';
import { Tabs, TabsContainer, TabType } from '../../../../../Components/Tabs/Tabs';
import { getSignerForParticipant } from '../../../../../Helpers/eSignSigners/insuredEntitiesToESignSigner';
import { useDispatch, useSelector } from '../../../../../Hooks';
import { RequiredFile } from '../../../../../Hooks/Application';
import { Application } from '../../../../../Models/Application';
import { useGetPointOfSaleDecisions } from '../../../../../ReactQuery/PointOfSaleDecisions/pointOfSaleDecisions.queries';
import { assistedApplicationSlice } from '../../../../../ReduxStore/AssistedApplication/AssistedApplicationSlice';
import { DocumentToSign } from './DocumentToSign';
import { SignerInfo } from './SignerInfo';
import { SignerInfoForm } from './SignerInfoForm';

type SignerInfoViewProps = {
  isEditMode: boolean;
  isLoading: boolean;
  application: Application;
  onUpdateSigner: (signer: ESignSigner2FAInfo, idx: number) => void;
  requiredFiles: RequiredFile[];
  participants: ESignSigner2FAInfo[];
  eSignCeremony: ESignCeremony | null | undefined;
};

export function SignerInfoView(props: SignerInfoViewProps): ReactElement | null {
  const { application, isEditMode, isLoading, onUpdateSigner, requiredFiles, participants, eSignCeremony } = props;
  const dispatch = useDispatch();
  const { t } = useTranslation();

  const { data: pointOfSaleDecisions } = useGetPointOfSaleDecisions(application.id, {
    enabled: !isLoading, // Refetch when eSignCeremony status changes and/or when submitting
    staleTime: 0, // Always refetch.
  });

  const { isDocumentSignInfoBoxOpen } = useSelector((state) => state.leadPlatform.assistedApplication);

  const [tabValue, setTabValue] = useState(0);

  const tabs: TabType[] = [
    { label: t('assistedApplication.signatureDrawer.signersInformation') },
    { label: t('assistedApplication.signatureDrawer.documentsToSign') },
  ];

  const handleTabChange = useCallback((_, newValue: number) => {
    setTabValue(newValue);
  }, []);

  const closeInfoBox = useCallback(() => {
    void dispatch(assistedApplicationSlice.actions.setIsDocumentSignInfoBoxOpen(false));
  }, [dispatch]);

  if (isLoading) {
    return <LoadingView />;
  }

  if (participants.length == 0) {
    return null;
  }

  return (
    <Box>
      <TabsContainer borderBottom={1} borderTop={1}>
        <Box px={4}>
          <Tabs value={tabValue} onChange={handleTabChange} tabs={tabs} />
        </Box>
      </TabsContainer>

      <Box py={2}>
        {tabValue === 0 && (
          <Fragment>
            {isEditMode
              ? participants.map((participant, idx) => {
                  return (
                    <Box key={`signer-${participant.partyId}`} mt={4}>
                      <SignerInfoForm
                        disabled={isLoading}
                        participant={participant}
                        onUpdateSigner={(signer) => onUpdateSigner(signer, idx)}
                      />
                    </Box>
                  );
                })
              : participants.map((participant) => {
                  const signer = getSignerForParticipant(participant, eSignCeremony?.eSignSigners);
                  return signer ? (
                    <Box key={`signer-${participant.partyId}`} mt={4}>
                      <SignerInfo {...getOutcomeForParticipant(participant, pointOfSaleDecisions)} signer={signer} />
                    </Box>
                  ) : null;
                })}
          </Fragment>
        )}
        {tabValue === 1 && (
          <Fragment>
            <Collapse in={isDocumentSignInfoBoxOpen}>
              <Alert onClose={closeInfoBox} severity='info'>
                {t('assistedApplication.signatureDrawer.infoBoxMessage')}
              </Alert>
            </Collapse>

            <Box py={2}>
              {requiredFiles.map((file) => (
                <DocumentToSign
                  key={file.template.id}
                  participants={participants}
                  application={application}
                  template={file.template}
                  storedFile={file.storedFile}
                />
              ))}
            </Box>
          </Fragment>
        )}
      </Box>
    </Box>
  );
}

function getOutcomeForParticipant(
  participant: ESignSigner2FAInfo,
  pointOfSaleDecisions: ApplicationPointOfSaleDecisions | undefined,
): {
  outcome: OutcomeCode | undefined;
  overrideOutcome: string | undefined;
} {
  let outcome = undefined;
  let overrideOutcome = undefined;
  if (pointOfSaleDecisions && participant?.partyId) {
    outcome = pointOfSaleDecisions.decisions[participant.partyId]?.outcome;
    overrideOutcome = pointOfSaleDecisions.decisions[participant.partyId]?.overrideOutcome;
  }
  return { outcome, overrideOutcome };
}
