import { ReactElement, useState, PropsWithChildren } from 'react';
import styled from '../../../../Styles/themed-styled-components';
import { IconName } from '@breathelife/types';
import { Icon } from '@breathelife/ui-components';
import { IconButton } from '../../../../Components/Controls';

const Container = styled.div<{ isOpen: boolean }>`
  width: ${({ isOpen }) => (isOpen ? '350px' : '65px')};
  border-right: 1px solid ${({ theme }) => theme.colors.grey[30]};
  min-height: 100%;
  left: 0;
  top: 0;
  background-color: ${({ theme }) => theme.colors.grey[20]};
  padding: 10px;
`;

const Header = styled.div`
  display: flex;
  justify-content: end;
`;

const Content = styled.div<{ isVisible: boolean }>`
  display: ${({ isVisible }) => (isVisible ? 'grid' : 'none')};
  grid-auto-flow: row;
  grid-gap: 15px;
`;

export function Sidebar(props: PropsWithChildren<unknown>): ReactElement {
  const [isOpen, setIsOpen] = useState(true);
  const toggleSidebar = (): void => setIsOpen((prev) => !prev);

  return (
    <Container isOpen={isOpen}>
      <Header>
        <IconButton icon={<Icon name={IconName.leftArrow} size='15px' />} onClick={toggleSidebar} />
      </Header>

      <Content isVisible={isOpen}>{props.children}</Content>
    </Container>
  );
}
