import { Box } from '@breathelife/mui';
import { ReactElement, Fragment } from 'react';
import { useTranslation } from 'react-i18next';

import { Permission } from '@breathelife/types';
import { Input as TextInput } from '@breathelife/ui-components';

import { Icon } from '../../../../Components/Icons';
import { RestrictedToUserPermissions } from '../../../../Components/Restricted/RestrictedToUserPermissions';
import Typography from '../../../../Components/Typography';
import { useCarrierContext, useSelector } from '../../../../Hooks';
import { CopyLinkSection } from './CopyLinkSection';

type SharingPreferencesProps = {
  isCompletingProfile: boolean;
  publicLink?: string;
  securityDisclosure?: string;
  onSecurityDisclosureChange: (value: string) => void;
  onCopyAgentLink: () => void;
  isSso?: boolean;
};

export function SharingPreferences(props: SharingPreferencesProps): ReactElement {
  const { isCompletingProfile, onCopyAgentLink, publicLink, isSso, securityDisclosure, onSecurityDisclosureChange } =
    props;
  const { t } = useTranslation();
  const { ssoProfileFields, features } = useCarrierContext();
  const isPublicLinkEnabled = useSelector((store) => store.leadPlatform.settings.settings?.data.publicLink.enabled);

  return (
    <Fragment>
      <Box mb={1}>
        <Typography variant='h3' grey={90}>
          {t('modals.editUser.mySharingPreferences')}
        </Typography>
      </Box>

      {isPublicLinkEnabled && (
        <RestrictedToUserPermissions requiredPermissions={[Permission.LeadInvitePublic]}>
          <CopyLinkSection
            isCompletingProfile={isCompletingProfile}
            onCopyAgentLink={onCopyAgentLink}
            publicLink={publicLink}
          />
        </RestrictedToUserPermissions>
      )}

      {features.userSecurityDisclosure.enabled && (
        <Box mt={1}>
          <TextInput
            fullWidth
            multiline
            rowsMax={6}
            id='userEditModal-security-disclosure'
            inputVariant='outlined'
            label={t('inputs.securityDisclosure')}
            labelTooltip={{
              icon: <Icon name='info' variant='default' width='15' height='15' />,
              text: t('modals.editUser.securityDisclosureToolTip'),
            }}
            value={securityDisclosure}
            disabled={isSso && ssoProfileFields.includes('securityDisclosure')}
            onChange={(event) => onSecurityDisclosureChange(event.target.value)}
          />
        </Box>
      )}
    </Fragment>
  );
}
