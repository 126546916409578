import { Fragment, ReactElement, useCallback, useContext, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

import {
  ApplicationMode,
  ApplicationType,
  CreateApplicationPayload,
  LineOfBusinessName,
  Permission,
} from '@breathelife/types';
import { AddIcon } from '@breathelife/mui';

import { useCreateLeadMutation, useUpdateLeadMutation } from '../../ReactQuery/Lead/lead.mutations';
import { useLaunchNewAssistedApplicationMutation } from '../../ReactQuery/AssistedApplication/assistedApplication.mutations';

import { ActionButton } from '../../Components/Button/ActionButton';
import { useCarrierContext, useSelector } from '../../Hooks';

import { userHasPermission } from '../../Helpers/user';
import { generateApplicationUrl } from '../../Navigation/Urls';
import { LeadsPageDataContext } from '../../Pages/Leads/LeadsPageDataContextProvider';
import { useGetQuestionnaires } from '../../ReactQuery/Questionnaire/questionnaire.queries';
import { LaunchApplicationModal } from '../../Pages/Home/Modals/LaunchApplicationModal';
import { getMgaFromUserGroups } from '../../Helpers/userGroups';
import { WrappedWithFullPageLoader, useFullPageLoaderContext } from '../LoadingView/FullPageLoader';

export function CreateApplicationWithLeadAndLaunchButtonChild(): ReactElement {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const { languageSettings, carrierInfo, features } = useCarrierContext();
  const { isLoadingApplication } = useContext(LeadsPageDataContext);
  const user = useSelector((store) => store.leadPlatform.authentication.user);

  const { closeFullPageLoader, openFullPageLoader } = useFullPageLoaderContext();
  const {
    data: questionnaires = [],
    isLoading: getQuestionnairesIsLoading,
    isError: getQuestionnairesIsError,
  } = useGetQuestionnaires();

  const [isCreateButtonModalOpen, setIsCreateButtonModalOpen] = useState(false);
  const lineOfBusinessRef = useRef<LineOfBusinessName>(LineOfBusinessName.life);
  const applicationModeRef = useRef<ApplicationMode>(ApplicationMode.digital);

  const lineOfBusiness = carrierInfo.lineOfBusiness;

  const isMultiBrandingEnabled = features.multiBranding.enabled;
  const isPaperAppModeEnabled =
    features.questionnaireScreen?.enabled && userHasPermission(user?.permissions, [Permission.PaperApplicationCreate]);
  const hasMultipleLineOfBusiness = lineOfBusiness.length > 1;

  const updateLeadMutation = useUpdateLeadMutation({});

  const launchNewAssistedApplicationMutation = useLaunchNewAssistedApplicationMutation({
    onSuccess: (application) => {
      if (application && application.leadId) {
        updateLeadMutation.mutate({
          id: application.leadId,
        });
        navigate(generateApplicationUrl(application.id, application.leadId));
      }
    },
    onError: () => {
      closeFullPageLoader();
    },
  });

  const createLeadMutation = useCreateLeadMutation(
    {
      onSuccess: (createdLead) => {
        const payload: CreateApplicationPayload = {
          leadId: createdLead.id,
          type: ApplicationType.transactionFlow,
          mode: applicationModeRef.current,
          lineOfBusiness: lineOfBusinessRef.current,
          questionnaireId: questionnaires?.[0]?.id,
        };
        if (isMultiBrandingEnabled && user?.groups) {
          const mgaId = getMgaFromUserGroups(user?.groups)?.id;
          if (mgaId) payload.mgaId = mgaId;
        }
        launchNewAssistedApplicationMutation.mutate(payload);
      },
      onError: () => {
        closeFullPageLoader();
      },
    },
    true,
  );

  const isCreatingLeadAndLaunchingApplication = createLeadMutation.isLoading || isLoadingApplication;

  const onSubmit = useCallback(
    (mode: ApplicationMode, lineOfBusiness: LineOfBusinessName) => {
      lineOfBusinessRef.current = lineOfBusiness;
      applicationModeRef.current = mode;
      openFullPageLoader(3000);
      createLeadMutation.mutate({
        communicationLanguage: languageSettings.default,
      });
    },
    [languageSettings.default, createLeadMutation, openFullPageLoader],
  );

  const onCloseModal = useCallback(() => {
    setIsCreateButtonModalOpen(false);
  }, []);

  return (
    <Fragment>
      <ActionButton
        data-testid='create-application-with-lead-and-launch-btn'
        color='primary'
        variant='contained'
        onClick={() => {
          if (!isPaperAppModeEnabled && !hasMultipleLineOfBusiness) {
            onSubmit(ApplicationMode.digital, lineOfBusiness[0]);
          } else {
            setIsCreateButtonModalOpen(true);
          }
        }}
        startIcon={<AddIcon htmlColor='white' />}
        disabled={
          !user || getQuestionnairesIsLoading || getQuestionnairesIsError || isCreatingLeadAndLaunchingApplication
        }
      >
        {t('cta.launchApplication')}
      </ActionButton>
      {isCreateButtonModalOpen && (
        <LaunchApplicationModal
          isOpen={isCreateButtonModalOpen}
          closeModal={onCloseModal}
          onSubmitClick={(data) => {
            onSubmit(data.mode, data.lineOfBusiness);
          }}
          disableSubmitButton={isCreatingLeadAndLaunchingApplication}
          requireApplicationMode={!!isPaperAppModeEnabled}
          requireQuestionnaireId={false}
        />
      )}
    </Fragment>
  );
}

export const CreateApplicationWithLeadAndLaunchButton = WrappedWithFullPageLoader(
  CreateApplicationWithLeadAndLaunchButtonChild,
);
