import _ from 'lodash';
import { Fragment, ReactElement, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';

import {
  Box,
  Divider,
  ExpandMoreIcon,
  ExpansionPanel,
  ExpansionPanelActions,
  ExpansionPanelDetails,
  ExpansionPanelSummary,
} from '@breathelife/mui';
import { ValidationError } from '@breathelife/questionnaire-engine';
import { CarrierNames, Language } from '@breathelife/types';
import { Input } from '@breathelife/ui-components';

import { Button } from '../../../Components/Button/Button';
import Typography from '../../../Components/Typography';

type Props = {
  carrierNames: CarrierNames;
  enabledLanguages: Language[];
  isUpdating: boolean;
  onSaveClick: (carrierNameData: CarrierNames) => void;
};

export function CarrierNameEditView({
  carrierNames: carrierNamesProp,
  enabledLanguages,
  isUpdating,
  onSaveClick,
}: Props): ReactElement {
  const { t } = useTranslation();

  const defaultCarrierNames = useMemo(
    () => getDefaultCarrierNamesMap(carrierNamesProp, enabledLanguages),
    [carrierNamesProp, enabledLanguages],
  );

  const [carrierNameState, setCarrierNameState] = useState<CarrierNames>(defaultCarrierNames);
  const [validationError, setValidationError] = useState<ValidationError | undefined>(undefined);

  const updateCarrierName = (language: string, value: string): void => {
    setCarrierNameState((prevState) => ({ ...prevState, [language]: value }));
  };

  useEffect(() => {
    setCarrierNameState(defaultCarrierNames);
  }, [defaultCarrierNames]);

  useEffect(() => {
    const isCarrierNameEmpty = Object.values(carrierNameState).every((name) => name === '');

    if (isCarrierNameEmpty) {
      setValidationError({ message: t('admin.settingsManagement.carrierNames.carrierNameEmpty') });
    } else {
      setValidationError(undefined);
    }
  }, [carrierNameState, t]);

  return (
    <ExpansionPanel defaultExpanded>
      <ExpansionPanelSummary
        expandIcon={<ExpandMoreIcon />}
        aria-controls='carrierName-content'
        id='carrierName--header'
      >
        <Box display='flex' flexDirection='row' mr={2}>
          <Typography variant='h2'>{t('admin.settingsManagement.carrierNames.title')}</Typography>
        </Box>
      </ExpansionPanelSummary>
      <Divider />
      <ExpansionPanelDetails>
        {_.map(enabledLanguages, (language) => {
          return (
            <Fragment key={language}>
              <Box key={`box${language}`} display='flex' flexDirection='row' alignItems='center' width='50%' mx={2}>
                <Input
                  fullWidth
                  inputVariant='outlined'
                  key={`input${language}`}
                  name={`input.${language}`}
                  id={`input-${language}`}
                  value={carrierNameState?.[language] ?? ''}
                  label={t('admin.settingsManagement.carrierNames.label', {
                    language: t(`language.${language}`),
                  })}
                  placeholder={t('admin.settingsManagement.carrierNames.placeholder', {
                    language: t(`language.${language}`),
                  })}
                  onChange={(event) => {
                    updateCarrierName(language, event.target.value);
                  }}
                  validationError={validationError}
                />
              </Box>
            </Fragment>
          );
        })}
      </ExpansionPanelDetails>
      <Divider />
      <ExpansionPanelActions>
        <Button
          data-testid='carrierNameSettings-save'
          color='primary'
          variant='text'
          isLoading={isUpdating}
          disabled={Boolean(validationError?.message)}
          onClick={() => {
            onSaveClick({
              ...carrierNameState,
            });
          }}
        >
          {t('cta.save').toUpperCase()}
        </Button>
      </ExpansionPanelActions>
    </ExpansionPanel>
  );
}

function getDefaultCarrierNamesMap(carrierNames: CarrierNames, enabledLanguages: Language[]): CarrierNames {
  if (typeof carrierNames === 'undefined') {
    return enabledLanguages.reduce((obj, language) => ({ ...obj, [language]: '' }), {}) as CarrierNames;
  }
  return carrierNames;
}
