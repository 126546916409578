import { hash } from '@breathelife/hash';
import { PdfDocumentType, ESignSigner2FAInfo, Signer } from '@breathelife/types';

export function participantProcessor(
  participantSignerData: Partial<Record<PdfDocumentType, Signer[]>>,
): ESignSigner2FAInfo[] {
  const signerMap = new Map<string, ESignSigner2FAInfo>();

  let pdfDocumentType: keyof typeof participantSignerData;

  for (pdfDocumentType in participantSignerData) {
    const signerDataForDocumentType = participantSignerData[pdfDocumentType];
    signerDataForDocumentType?.forEach((participantSigner) => {
      const partyId = partyIdFromPartyData(participantSigner);
      let signer = signerMap.get(partyId);

      if (signer) {
        if (!signer.documentTypes) {
          signer.documentTypes = [];
        }
        signer.documentTypes.push(pdfDocumentType);
      } else if (participantSigner.party?.person) {
        const {
          party: {
            person: { firstName, lastName },
            communication,
          },
          roles,
        } = participantSigner;

        const email = communication?.email;
        const phoneNumber = communication?.phoneNumber;

        signer = {
          partyId,
          firstName: firstName || '',
          lastName: lastName || '',
          email: email || '',
          cellphone: phoneNumber || '',
          roles,
          documentTypes: [pdfDocumentType],
        };
      } else if (participantSigner.party?.organization) {
        const {
          party: {
            organization: { legalEntityName, legalEntityOwnerName },
            communication,
          },
          roles,
        } = participantSigner;

        const email = communication?.email;
        const phoneNumber = communication?.phoneNumber;

        signer = {
          partyId,
          firstName: legalEntityName || '',
          lastName: legalEntityOwnerName || '',
          email: email || '',
          cellphone: phoneNumber || '',
          roles,
          documentTypes: [pdfDocumentType],
        };
      }
      if (signer) signerMap.set(partyId, signer);
    });
  }

  return Array.from(signerMap.values());
}

// Todo: enhance the id with more user data or a UUID - https://breathelife.atlassian.net/browse/HOT-3671
function partyIdFromPartyData(participantSigner: Signer): string {
  return (
    participantSigner.partyId ||
    hash(
      `${participantSigner.party.person?.firstName}-${participantSigner.party.person?.lastName}-${participantSigner.party.person?.dob}`,
    )
  );
}
