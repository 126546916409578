import { ReactElement } from 'react';
import { Entity } from '@breathelife/types';
import { SubmitButton } from '../../../../Components/Button/SubmitButton';
import { ModalLayout } from '../../../../Layouts/Modal/ModalLayout';
import { useDeleteEntityMutation } from '../../../../ReactQuery/Admin/Entities/entities.mutations';
import { useTranslation } from 'react-i18next';

type Props = {
  isOpen: boolean;
  entity?: Entity;
  onCancel: () => void;
  onDelete: () => void;
};

export function DeleteEntityModal(props: Props): ReactElement {
  const { mutate: deleteEntityMutation, isLoading } = useDeleteEntityMutation();
  const { t } = useTranslation();

  const onDeleteMutation = (): void => {
    deleteEntityMutation(
      {
        id: props.entity?.id || '',
        name: props.entity?.name || '',
      },
      {
        onSuccess: () => {
          props.onDelete();
        },
      },
    );
  };

  const submitButton = (
    <SubmitButton isLoading={isLoading} onClick={onDeleteMutation}>
      {t('admin.entityMappings.deleteButtonLabel')}
    </SubmitButton>
  );

  return (
    <ModalLayout
      isOpen={props.isOpen}
      title={t('admin.entityMappings.deleteEntityModal.title')}
      submitButton={submitButton}
      closeModal={props.onCancel}
      maxWidth='sm'
    >
      {t('admin.entityMappings.deleteEntityModal.message', { name: props.entity?.name })}
    </ModalLayout>
  );
}
