import { ReactElement, Fragment, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { makePartNameFromString } from '@breathelife/insurance-form-builder';
import { Language, Localizable, SelectOptionBlueprint } from '@breathelife/types';
import { Input as TextInput, Box } from '@breathelife/ui-components';
import { AddAlternativeIcon, Grid } from '@breathelife/mui';

import { ActionButton } from '../../../../../Components/Button/ActionButton';
import { SubmitButton } from '../../../../../Components/Button/SubmitButton';
import {
  createSelectOptionFormSchema,
  getOptionIdFieldValidationError,
} from '../../../../../Helpers/inputValidation/form/createSelectOption';
import { useCarrierContext } from '../../../../../Hooks';
import { ModalLayout } from '../../../../../Layouts/Modal/ModalLayout';
import { v4 as uuid } from 'uuid';

type Props = {
  onCreate: (selectOptionBlueprint: SelectOptionBlueprint) => void;
  existingOptionIds: string[];
  disabled: boolean;
  isOrderable?: boolean;
};

export function CreateSelectOption(props: Props): ReactElement | null {
  const { onCreate, existingOptionIds, disabled, isOrderable } = props;

  const { t } = useTranslation();
  const [isModalOpen, setIsModalOpen] = useState(false);

  return (
    <Fragment>
      <ActionButton
        onClick={() => setIsModalOpen(true)}
        data-testid='addNewSelectOptionButton'
        color='primary'
        variant='outlined'
        startIcon={<AddAlternativeIcon htmlColor='white' />}
        disabled={disabled}
      >
        {t('admin.questionnaireManagement.input.addOption')}
      </ActionButton>
      {isModalOpen && (
        <CreateSelectOptionModal
          onCloseModal={() => setIsModalOpen(false)}
          onCreateSelectOption={onCreate}
          existingOptionIds={existingOptionIds}
          isOrderable={isOrderable}
        />
      )}
    </Fragment>
  );
}

function CreateSelectOptionModal(props: {
  onCloseModal: () => void;
  onCreateSelectOption: (selectOptionBlueprint: SelectOptionBlueprint) => void;
  existingOptionIds: string[];
  isOrderable?: boolean;
}): ReactElement {
  const { onCloseModal, onCreateSelectOption, existingOptionIds, isOrderable } = props;
  const { t } = useTranslation();
  const { languageSettings } = useCarrierContext();
  const enabledLanguages = languageSettings.enabledLanguages;
  const [optionId, setOptionId] = useState('');
  const [text, setText] = useState<Partial<Localizable>>({});

  const [validationErrors, setValidationErrors] = useState<Partial<Record<'text' | 'optionId', any>>>({});

  // Set id from only enabled language, if more than one is enabled: use english .
  const onlyEnabledLanguage = enabledLanguages.length === 1 ? enabledLanguages[0] : undefined;
  const shouldUpdateOptionIdOnTextChange: Record<Partial<Language>, boolean> = enabledLanguages.reduce(
    (shouldUpdateForLanguage, language) => {
      const shouldUpdateId = (onlyEnabledLanguage && onlyEnabledLanguage === language) || language === Language.en;
      shouldUpdateForLanguage[language] = shouldUpdateId;

      return shouldUpdateForLanguage;
    },
    {} as Record<Partial<Language>, boolean>,
  );

  let isFormValid = true;
  try {
    createSelectOptionFormSchema(existingOptionIds, enabledLanguages).validateSync({
      text,
      optionId,
    });
  } catch {
    isFormValid = false;
  }

  return (
    <ModalLayout
      maxWidth='md'
      isOpen={true}
      closeModal={onCloseModal}
      title={t('admin.questionnaireManagement.input.createOption.title')}
      submitButton={
        <SubmitButton
          disabled={!isFormValid}
          onClick={() => {
            const selectOptionBlueprint: SelectOptionBlueprint = {
              id: uuid(),
              partName: optionId,
              text,
              isCustom: true,
            };
            if (isOrderable) selectOptionBlueprint.orderingIndex = 1;

            onCreateSelectOption(selectOptionBlueprint);

            onCloseModal();
          }}
          data-testid='questionnaire-editor-select-option-create'
        >
          {t('cta.save')}
        </SubmitButton>
      }
    >
      <Grid container spacing={2}>
        {enabledLanguages.map((language) => (
          <Grid item xs={6} key={`createSelectOption-text-${language}`}>
            <TextInput
              required
              inputVariant='outlined'
              label={t('admin.questionnaireManagement.input.textAndLanguage', { language: t(`language.${language}`) })}
              value={text[language] ?? ''}
              onChange={(event) => {
                const value = event.target.value;
                setText((previousValue) => ({ ...previousValue, [language]: value }));

                const shouldUpdateOptionId = shouldUpdateOptionIdOnTextChange[language];
                let updatedOptionId: string | undefined = undefined;
                if (shouldUpdateOptionId) {
                  updatedOptionId = makePartNameFromString(value);

                  setOptionId(updatedOptionId);

                  setValidationErrors({
                    ...validationErrors,
                    optionId: getOptionIdFieldValidationError(updatedOptionId, existingOptionIds, enabledLanguages),
                  });
                }
              }}
            />
          </Grid>
        ))}
      </Grid>
      <Box pt={1}>
        <TextInput
          required
          inputVariant='outlined'
          label={t('admin.questionnaireManagement.input.createOption.optionId')}
          value={optionId}
          error={Boolean(validationErrors.optionId)}
          validationError={validationErrors.optionId}
          onChange={(event) => {
            const value = event.target.value;
            setOptionId(value);

            setValidationErrors({
              ...validationErrors,
              optionId: getOptionIdFieldValidationError(value, existingOptionIds, enabledLanguages),
            });
          }}
        />
      </Box>
    </ModalLayout>
  );
}
