import { ReactElement, useContext, useEffect, useState } from 'react';
import { Input } from '@breathelife/ui-components';
import { ModalLayout } from '../../../../Layouts/Modal/ModalLayout';
import { SubmitButton } from '../../../../Components/Button/SubmitButton';
import { useCreateEntityMutation } from '../../../../ReactQuery/Admin/Entities/entities.mutations';
import { useTranslation } from 'react-i18next';
import { QuestionnaireVersionFilterContext } from '../../../../Context/QuestionnaireVersionFilterContextProvider';

type Props = {
  isOpen: boolean;
  onCreate: (entityId: string) => void;
  onCancel: () => void;
};

export function CreateEntityModal(props: Props): ReactElement {
  const { questionnaireVersionId } = useContext(QuestionnaireVersionFilterContext);
  const { mutate: createEntityMutation, isLoading } = useCreateEntityMutation();
  const [name, setName] = useState('');
  const { t } = useTranslation();

  const onCreateMutation = (): void => {
    createEntityMutation(
      {
        name,
        questionnaireVersionId,
      },
      {
        onSuccess: (entity) => {
          props.onCreate(entity.id);
        },
      },
    );
  };

  useEffect(() => {
    if (!props.isOpen) setName('');
  }, [props.isOpen]);

  const submitButton = (
    <SubmitButton disabled={isLoading} onClick={onCreateMutation}>
      {t('admin.entityMappings.createEntityModal.submitLabel')}
    </SubmitButton>
  );

  return (
    <ModalLayout
      isOpen={props.isOpen}
      title={t('admin.entityMappings.createEntityModal.title')}
      submitButton={submitButton}
      closeModal={props.onCancel}
      maxWidth='sm'
    >
      <Input
        label={t('admin.entityMappings.createEntityModal.nameLabel')}
        value={name}
        onChange={(e) => setName(e.target.value)}
      />
    </ModalLayout>
  );
}
