import { useQuery, UseQueryOptions, UseQueryResult } from 'react-query';

import { QueryId } from '../../../ReactQuery/common/common.types';
import { User, UserPage } from '@breathelife/types';

import { useDispatch } from 'react-redux';

import { logger } from '@breathelife/monitoring-frontend';

import { notificationSlice } from '../../../ReduxStore/Notification/NotificationSlice';
import UsersService, { getUsers, GetUsersOptions } from '../../../Services/Users/UsersService';

export function useUsers(getOptions?: GetUsersOptions, options?: UseQueryOptions<UserPage>): UseQueryResult<UserPage> {
  const dispatch = useDispatch();

  return useQuery<UserPage>(
    [QueryId.users, getOptions || {}],

    async () => {
      return getUsers({
        ...getOptions,
        includeAssociationCounts: true,
        fields: [
          'id',
          'firstName',
          'lastName',
          'emailLogin',
          'createdAt',
          'auth0Id',
          'lastLogin',
          'roles',
          'picture',
          'groupId',
          'phoneNumber',
          'customId',
          'category',
          'type',
          'status',
          'isSso',
        ],
      });
    },
    {
      ...options,
      onError(err: any) {
        dispatch(
          notificationSlice.actions.setError({
            message: err.message,
          }),
        );
        logger.error(err);
        options?.onError?.(err);
      },
    },
  );
}

export function useUser(
  userId?: string,
  options?: UseQueryOptions<User | undefined>,
): UseQueryResult<User | undefined> {
  const dispatch = useDispatch();

  return useQuery<User | undefined>(
    [QueryId.users, { userId }],
    async () => {
      if (!userId) {
        return undefined;
      }
      return UsersService.getUser(userId);
    },
    {
      ...options,
      onError(err: any) {
        dispatch(
          notificationSlice.actions.setError({
            message: err.message,
          }),
        );
        logger.error(err);
        options?.onError?.(err);
      },
    },
  );
}
