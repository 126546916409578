import { LeadStatusEnum, Permission } from '@breathelife/types';
import { ReactElement, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { useCarrierContext } from '../../../../../Hooks';
import { ActionItem } from './ActionItem';
import { StyledList } from './ActionsWidgetStyles';
import { RestrictedToUserPermissions } from '../../../../../Components/Restricted/RestrictedToUserPermissions';
import { ManualSubmissionModal } from '../modals/ManualSubmissionModal';

type ActionsListProps = {
  applicationId: string;
  refNo: string;
  applicationStatus: string | undefined;
  onOpenResetSubmissonStatusConfirmationModal: () => void;
};

export function ActionsList(props: ActionsListProps): ReactElement {
  const { t } = useTranslation();
  const { features } = useCarrierContext();
  const { applicationStatus, applicationId, refNo, onOpenResetSubmissonStatusConfirmationModal } = props;

  const [manualSubmissionModalOpened, setManualSubmissionModalOpened] = useState(false);

  return (
    <StyledList>
      <ActionItem
        label={t('applicationSupport.applicationOverview.actionsWidgetItems.resetApplicationStatus')}
        iconName={'copy'}
        action={onOpenResetSubmissonStatusConfirmationModal}
        isVisible={features.resetApplicationStatus?.enabled ?? false}
        disabled={
          applicationStatus === LeadStatusEnum.submissionCompleted ||
          applicationStatus === LeadStatusEnum.submissionManuallyCompleted
        }
        tooltip={
          applicationStatus === LeadStatusEnum.submissionCompleted ||
          applicationStatus === LeadStatusEnum.submissionManuallyCompleted
            ? t('applicationSupport.applicationOverview.actionsWidgetItems.applicationCompletedTitle')
            : ''
        }
      />
      <ManualSubmissionModal
        applicationId={applicationId}
        refNo={refNo}
        isOpen={manualSubmissionModalOpened}
        closeModal={() => setManualSubmissionModalOpened(false)}
      />
      <RestrictedToUserPermissions requiredPermissions={[Permission.ManualSubmission]}>
        <ActionItem
          label={t('applicationSupport.applicationOverview.actionsWidgetItems.manualSubmission')}
          iconName={'files'}
          action={() => setManualSubmissionModalOpened(true)}
          isVisible={true}
          disabled={applicationStatus !== LeadStatusEnum.submissionStarted}
          tooltip={
            applicationStatus === LeadStatusEnum.submissionStarted
              ? t('applicationSupport.applicationOverview.actionsWidgetItems.manualSubmissionAllowed')
              : t('applicationSupport.applicationOverview.actionsWidgetItems.manualSubmissionNotAllowed')
          }
        />
      </RestrictedToUserPermissions>
    </StyledList>
  );
}
