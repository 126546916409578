import { Box } from '@breathelife/mui';
import _ from 'lodash';
import { ReactElement, Fragment, useCallback, useState, useEffect, useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import { logger } from '@breathelife/monitoring-frontend';
import { ESignFieldSetting, ESignFieldSettingAbsolute, ESignFieldSettingPlacementType } from '@breathelife/types';

import { SubmitButton } from '../../../../../../Components/Button/SubmitButton';
import { eSignFieldSettingValidation } from '../../../../../../Helpers/inputValidation/form/eSignFieldSetting';
import { useDispatch } from '../../../../../../Hooks';
import {
  useUpdateESignFieldSettingMutation,
  useDeleteESignFieldSettingMutation,
} from '../../../../../../ReactQuery/ESignFieldSettings/eSignFieldSettings.mutations';
import { notificationSlice } from '../../../../../../ReduxStore/Notification/NotificationSlice';
import { ESignFieldSettingForm, ESignFieldSettingFormData } from './ESignFieldSettingForm';
import { ESignFieldSettingUtils } from './utils';

type Props = {
  signerSettingId: string;
  eSignFieldSetting: ESignFieldSetting;
};

function isESignFieldSettingAbsolute(data: ESignFieldSetting): data is ESignFieldSettingAbsolute {
  return data.placementType === ESignFieldSettingPlacementType.ABSOLUTE;
}

const transformToFormData = (data: ESignFieldSetting): ESignFieldSettingFormData => {
  const isAbsolute = isESignFieldSettingAbsolute(data);
  return {
    ...data,
    top: data.top.toString(),
    left: data.left.toString(),
    width: data.width.toString(),
    height: data.height.toString(),
    fieldBinding: data.fieldBinding ?? null,
    defaultValue: data.defaultValue ?? null,
    page: isAbsolute ? data['page']?.toString() : null,
    anchorText: !isAbsolute ? data['anchorText'] : null,
    anchorPoint: !isAbsolute ? data['anchorPoint'] : null,
    anchorIndex: !isAbsolute ? data['anchorIndex']?.toString() : null,
    anchorCharacterIndex: !isAbsolute ? data['anchorCharacterIndex']?.toString() : null,
  };
};

export function EditESignFieldSetting(props: Props): ReactElement {
  const dispatch = useDispatch();
  const { t } = useTranslation();

  const [eSignFieldSetting, setESignFieldSetting] = useState<ESignFieldSettingFormData>(
    transformToFormData(props.eSignFieldSetting),
  );

  // Update Mutation
  const updateDocumentFieldMutation = useUpdateESignFieldSettingMutation();
  const deleteDocumentFieldMutation = useDeleteESignFieldSettingMutation();

  const deleteESignFieldSettingHandler = useCallback(
    async (templateId) => {
      await deleteDocumentFieldMutation.mutateAsync(templateId);
    },
    [deleteDocumentFieldMutation],
  );

  const updateESignFieldSetting = useCallback(
    async (eSignFieldSetting: ESignFieldSettingFormData): Promise<void> => {
      const isESignFieldSettingFormValid = eSignFieldSettingValidation.getFormSchema().isValidSync(eSignFieldSetting);
      if (!isESignFieldSettingFormValid) {
        return;
      }

      const submissionData = ESignFieldSettingUtils.transformFormData(eSignFieldSetting);
      if (submissionData === undefined) {
        logger.error({
          message: 'Unable to construct a request payload to the eSignFieldSetting endpoint.',
          details: eSignFieldSetting,
          BLCode: 'UpdateESignFieldSettingError',
        });
        dispatch(
          notificationSlice.actions.setError({
            message: t('notifications.failedToUpdateSettings'),
          }),
        );
        return;
      }

      await updateDocumentFieldMutation.mutateAsync({
        data: submissionData,
        id: props.eSignFieldSetting.id,
      });
    },
    [dispatch, props.eSignFieldSetting.id, t, updateDocumentFieldMutation],
  );

  const debouncedUpdateESignFieldSetting = useMemo(
    () => _.debounce(updateESignFieldSetting, 500),
    [updateESignFieldSetting],
  );

  useEffect(() => {
    if (deleteDocumentFieldMutation.isLoading) {
      debouncedUpdateESignFieldSetting.cancel();
    }
  }, [debouncedUpdateESignFieldSetting, deleteDocumentFieldMutation.isLoading]);

  return (
    <Fragment>
      <ESignFieldSettingForm
        data={eSignFieldSetting}
        onChange={(data) => {
          setESignFieldSetting(data);
          void debouncedUpdateESignFieldSetting(data);
        }}
        disabled={deleteDocumentFieldMutation.isLoading}
      />

      <Box display='flex' justifyContent='flex-end' width='100%' p={2}>
        <SubmitButton
          isLoading={deleteDocumentFieldMutation.isLoading}
          onClick={() => deleteESignFieldSettingHandler(props.eSignFieldSetting.id)}
        >
          {t('cta.delete')}
        </SubmitButton>
      </Box>
    </Fragment>
  );
}
