import _ from 'lodash';
import { ReactElement, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';

import {
  Box,
  Divider,
  ExpandMoreIcon,
  ExpansionPanel,
  ExpansionPanelActions,
  ExpansionPanelDetails,
  ExpansionPanelSummary,
  Grid,
} from '@breathelife/mui';
import { Language, SettingsData } from '@breathelife/types';

import { Button } from '../../../Components/Button/Button';
import { Checkbox, CheckboxGroup } from '../../../Components/Checkbox/Checkbox';
import RadioGroup, { Radio } from '../../../Components/RadioGroup/RadioGroup';
import Typography from '../../../Components/Typography';
import { getOptionsFromEnum } from '../../../Helpers/options';
import { StyledGrid } from './Styles';

export type LanguageSettings = Pick<SettingsData, 'enabledLanguages' | 'defaultLanguage'>;

type Props = {
  enabledLanguages: Language[];
  defaultLanguage: Language;
  isUpdating: boolean;
  onSaveClick: (newLanguageSettings: LanguageSettings) => void;
};

export function LanguagesEditView({
  enabledLanguages: enabledLanguagesProp,
  defaultLanguage: defaultLanguageProp,
  isUpdating,
  onSaveClick,
}: Props): ReactElement {
  const { t } = useTranslation();
  const languageOptions = getOptionsFromEnum(Language, 'admin.settingsManagement.languages.options');
  const enabledLanguagesMap = useMemo(() => getEnabledLanguagesMap(enabledLanguagesProp), [enabledLanguagesProp]);

  const [enabledLanguages, setEnabledLanguages] = useState<Record<Language, boolean>>(enabledLanguagesMap);
  const [defaultLanguage, setDefaultLanguage] = useState<Language>(defaultLanguageProp);
  const [validationError, setValidationError] = useState<string>('');

  useEffect(() => {
    setEnabledLanguages(enabledLanguagesMap);
    setDefaultLanguage(defaultLanguageProp);
  }, [enabledLanguagesMap, defaultLanguageProp]);

  useEffect(() => {
    const languages = Object.keys(enabledLanguages) as Array<keyof typeof enabledLanguages>;
    const enabledLanguagesList = languages.filter((language) => enabledLanguages[language]);

    if (enabledLanguagesList.length && !enabledLanguages[defaultLanguage]) {
      setValidationError(t('admin.settingsManagement.languages.defaultLanguageError'));
    } else if (enabledLanguagesList.length === 0) {
      setValidationError(t('admin.settingsManagement.languages.enabledLanguagesError'));
    } else {
      setValidationError('');
    }
  }, [enabledLanguages, defaultLanguage, t]);

  return (
    <ExpansionPanel defaultExpanded>
      <ExpansionPanelSummary
        expandIcon={<ExpandMoreIcon />}
        aria-controls='enabledLanguages-content'
        id='enabledLanguages-header'
      >
        <Box display='flex' flexDirection='row' mr={2}>
          <Typography variant='h2'>{t('admin.settingsManagement.languages.title')}</Typography>
        </Box>
      </ExpansionPanelSummary>
      <Divider />
      <ExpansionPanelDetails>
        <Box display='flex' flexDirection='row' alignItems='center' width='50%' mx={2} my={2}>
          <CheckboxGroup
            label={t('admin.settingsManagement.languages.enabledLanguagesLabel')}
            validationError={validationError}
          >
            <Grid container spacing={1}>
              {_.map(languageOptions, (choice) => {
                return (
                  <StyledGrid item xs={3} sm={3} key={choice.value}>
                    <Checkbox
                      color='primary'
                      label={choice.label}
                      checked={Boolean(enabledLanguages[choice.value])}
                      onChange={() =>
                        setEnabledLanguages({
                          ...enabledLanguages,
                          [choice.value]: !enabledLanguages[choice.value],
                        })
                      }
                    />
                  </StyledGrid>
                );
              })}
            </Grid>
          </CheckboxGroup>
        </Box>
        <Box display='flex' flexDirection='row' alignItems='center' width='50%' mx={2}>
          <RadioGroup
            label={t('admin.settingsManagement.languages.defaultLanguageLabel')}
            name='UserForm-defaultLanguage'
            onChange={(value) => setDefaultLanguage(value as Language)}
            value={defaultLanguage}
          >
            <Grid container spacing={1}>
              {_.map(languageOptions, (choice) => {
                return (
                  <StyledGrid item xs={3} sm={3} key={choice.value}>
                    <Radio value={choice.value} label={choice.label} />
                  </StyledGrid>
                );
              })}
            </Grid>
          </RadioGroup>
        </Box>
      </ExpansionPanelDetails>
      <Divider />
      <ExpansionPanelActions>
        <Button
          data-testid='tenabledLanguagesEdit-save'
          color='primary'
          variant='text'
          isLoading={isUpdating}
          disabled={Boolean(validationError)}
          onClick={() =>
            onSaveClick({
              enabledLanguages: (Object.keys(enabledLanguages) as Array<keyof typeof enabledLanguages>).filter(
                (language) => enabledLanguages[language],
              ) as Language[],
              defaultLanguage,
            })
          }
        >
          {t('cta.save').toUpperCase()}
        </Button>
      </ExpansionPanelActions>
    </ExpansionPanel>
  );
}

function getEnabledLanguagesMap(enabledLanguages: Language[]): Record<Language, boolean> {
  return Object.values(Language).reduce(
    (acc, language) => ({ ...acc, [language]: enabledLanguages.includes(language) }),
    {},
  ) as Record<Language, boolean>;
}
