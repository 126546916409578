import { Fragment, ReactElement, useCallback, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { AddIcon, EditIcon, Grid, IconButton, RemoveIcon } from '@breathelife/mui';
import { BlueprintConditionValue, BooleanOperator, Language } from '@breathelife/types';
import { Box, ReadonlyFormControlLabel } from '@breathelife/ui-components';

import { ActionButton } from '../../../../../Components/Button/ActionButton';
import { SubmitButton } from '../../../../../Components/Button/SubmitButton';
import {
  ConditionsEditor as ConditionsEditorView,
  defaultSingleConditionBlueprintValue,
} from '../../../../../Components/Conditions';
import { ConditionView } from '../../../../../Components/Conditions/ConditionView';
import {
  addConditionToBlueprint,
  addNestedConditionToBlueprint,
  removeConditionFromBlueprint,
  updateConditionBlueprint,
  updateConditionBooleanOperator,
} from '../../../../../Helpers/conditions/blueprintHelpers';
import { validateBlueprint } from '../../../../../Helpers/inputValidation/form/salesDecisionRules';
import { QuestionnaireNodeIds } from '../../../../../Helpers/questionnaireEditor/questionnaireNodeIds';
import { ModalLayout } from '../../../../../Layouts/Modal/ModalLayout';
import { useQuestionnaireVersionDetailWithNodeIdInfo } from '../../ContextProvider/QuestionnaireVersionDataContextProvider';
import { SummaryText } from '../Components/SummaryText';

type Props = {
  label: string;
  editHeadingText: string;
  questionnaireNodeIds: QuestionnaireNodeIds;
  collectionContext: string[];
  saveCondition: (condition?: BlueprintConditionValue) => void;
  condition?: BlueprintConditionValue;
  isReadOnly: boolean;
  selectedLanguage: Language;
};

export function ConditionsEditor(props: Props): ReactElement {
  const {
    questionnaireNodeIds,
    collectionContext,
    condition,
    label,
    editHeadingText,
    isReadOnly,
    saveCondition,
    selectedLanguage,
  } = props;

  const [isModalOpen, setIsModalOpen] = useState(false);

  const closeModal = useCallback(() => setIsModalOpen(false), [setIsModalOpen]);

  return (
    <Box pt={1} pr={1}>
      {isModalOpen && (
        <ConditionsEditorModal
          save={saveCondition}
          closeModal={closeModal}
          questionnaireNodeIds={questionnaireNodeIds}
          collectionContext={collectionContext}
          existingCondition={condition}
          editHeadingText={editHeadingText}
          selectedLanguage={selectedLanguage}
        />
      )}
      <ConditionsSummaryWithEditButton
        condition={condition}
        setIsModalOpen={setIsModalOpen}
        label={label}
        isReadOnly={isReadOnly}
        selectedLanguage={selectedLanguage}
      />
    </Box>
  );
}

function ConditionsSummaryWithEditButton(props: {
  label: string;
  setIsModalOpen: (isOpen: boolean) => void;
  condition?: BlueprintConditionValue;
  isReadOnly: boolean;
  selectedLanguage: Language;
}): ReactElement {
  const { condition, setIsModalOpen, label, isReadOnly } = props;
  const { t } = useTranslation();

  const conditionSummaryControl = useMemo(
    () =>
      condition ? (
        <ConditionView condition={condition} selectedLanguage={props.selectedLanguage} />
      ) : (
        <Box pr={1}>
          <SummaryText fallbackText={t('admin.questionnaireManagement.input.noneSet')} />
        </Box>
      ),
    [condition, t, props.selectedLanguage],
  );

  return (
    <Grid container alignItems='flex-end' justify='space-between'>
      <Grid item xs={10}>
        <ReadonlyFormControlLabel
          label={label}
          showError={false}
          labelPlacement='top'
          control={conditionSummaryControl}
        />
      </Grid>
      <Grid item>
        {!isReadOnly && (
          <IconButton onClick={() => setIsModalOpen(true)}>
            <EditIcon />
          </IconButton>
        )}
      </Grid>
    </Grid>
  );
}

export function ConditionsEditorModal(props: {
  closeModal: () => void;
  save: (updatedCondition?: BlueprintConditionValue) => void;
  questionnaireNodeIds: QuestionnaireNodeIds;
  collectionContext: string[];
  existingCondition?: BlueprintConditionValue;
  editHeadingText: string;
  selectedLanguage: Language;
}): ReactElement | null {
  const {
    closeModal,
    save,
    questionnaireNodeIds,
    collectionContext,
    existingCondition,
    editHeadingText,
    selectedLanguage,
  } = props;

  const { t } = useTranslation();
  const { nodeIdInCollectionMap } = useQuestionnaireVersionDetailWithNodeIdInfo() || {};

  const [condition, setCondition] = useState<BlueprintConditionValue | undefined>(existingCondition);

  const isFormValid = useMemo(() => {
    if (!nodeIdInCollectionMap) {
      return false;
    }
    return validateBlueprint(condition, questionnaireNodeIds, nodeIdInCollectionMap, { allowNoConditions: true });
  }, [condition, nodeIdInCollectionMap, questionnaireNodeIds]);

  const onBooleanOperatorChange = useCallback((booleanOperator: BooleanOperator, path: string) => {
    setCondition((prevState) => {
      const updatedCondition = updateConditionBooleanOperator(prevState, path, booleanOperator);
      return updatedCondition;
    });
  }, []);

  const onConditionChange = useCallback((data: BlueprintConditionValue, path: string) => {
    setCondition((prevState) => {
      const updatedCondition = updateConditionBlueprint(prevState, path, data);
      return updatedCondition;
    });
  }, []);

  const onAddCondition = useCallback((path: string) => {
    setCondition((prevState) => {
      const updatedCondition = addConditionToBlueprint(prevState, path);
      return updatedCondition;
    });
  }, []);

  const onAddNestedCondition = useCallback((path: string) => {
    setCondition((prevState) => {
      const updatedCondition = addNestedConditionToBlueprint(prevState, path);
      return updatedCondition;
    });
  }, []);

  const onRemoveCondition = useCallback((path: string) => {
    setCondition((prevState) => {
      const updatedCondition = removeConditionFromBlueprint(prevState, path);
      return updatedCondition;
    });
  }, []);

  if (!nodeIdInCollectionMap) {
    return null;
  }

  return (
    <ModalLayout
      maxWidth='lg'
      isOpen={true}
      closeModal={closeModal}
      title={editHeadingText}
      submitButton={
        <SubmitButton
          disabled={!isFormValid}
          onClick={() => {
            save(condition);
            closeModal();
          }}
          data-testid='questionnaire-editor-save-conditions'
        >
          {t('cta.save')}
        </SubmitButton>
      }
    >
      {!condition && (
        <ActionButton
          onClick={() => {
            setCondition({
              booleanOperator: BooleanOperator.and,
              conditions: [defaultSingleConditionBlueprintValue()],
            });
          }}
          data-testid='addDefaultConditionButton'
          color='primary'
          variant='outlined'
          startIcon={<AddIcon htmlColor='white' />}
        >
          {t('admin.questionnaireManagement.rules.addCondition')}
        </ActionButton>
      )}
      {condition && (
        <Fragment>
          <ConditionsEditorView
            questionnaireNodeIds={questionnaireNodeIds}
            collectionContext={collectionContext}
            condition={condition}
            onConditionChange={onConditionChange}
            onBooleanOperatorChange={onBooleanOperatorChange}
            onAddCondition={onAddCondition}
            onAddNestedCondition={onAddNestedCondition}
            onRemoveCondition={onRemoveCondition}
            language={selectedLanguage}
            nodeIdInCollectionMap={nodeIdInCollectionMap}
          />
          <Box pt={1}>
            <ActionButton
              onClick={() => {
                setCondition(undefined);
              }}
              data-testid='removeDefaultConditionButton'
              color='secondary'
              variant='outlined'
              startIcon={<RemoveIcon />}
            >
              {t('admin.questionnaireManagement.rules.removeCondition')}
            </ActionButton>
          </Box>
        </Fragment>
      )}
    </ModalLayout>
  );
}
