import _ from 'lodash';
import { Box } from '@breathelife/mui';
import { SetStateAction, ReactElement, Fragment, useCallback, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useQueryClient } from 'react-query';
import { useNavigationType } from 'react-router-dom';
import dayjs from 'dayjs';
import { getAge } from '@breathelife/date-helpers';
import { hash } from '@breathelife/hash';
import { InsuranceEntities } from '@breathelife/insurance-entities';
import { isRenderingQuestionnaireComplete } from '@breathelife/questionnaire-engine';
import { TypewriterTracking } from '@breathelife/frontend-tracking';
import {
  LocalizedInsuranceProduct,
  Permission,
  RenderingType,
  SignatureType,
  ESignSigner2FAInfo,
  ESignCeremonyStatus,
  ApplicationMode,
  AgeRoundingType,
  TimeMeasurementUnits,
  Language,
  ProductsWidgetFeatureType,
  VersionedAnswers,
  CollectionInstanceIdentifiers,
} from '@breathelife/types';
import { GoogleMapsHelmet } from '@breathelife/ui-components';

import { MemoizedAssistedApplicationHeader as AssistedApplicationHeader } from '../../Components/AssistedApplication/AssistedApplicationView/AssistedApplicationHeader';
import { MemoizedAssistedApplicationView as AssistedApplicationView } from '../../Components/AssistedApplication/AssistedApplicationView/AssistedApplicationView';
import * as AssistedApplicationOperations from '../../ReduxStore/AssistedApplication/AssistedApplicationOperations';
import * as ProductsOperations from '../../ReduxStore/Products/ProductsOperations';
import { RestrictedToUserPermissions } from '../Restricted/RestrictedToUserPermissions';
import { AssistedApplicationContext } from '../../Context/AssistedApplicationContext';
import { getInsuredPeopleTabs } from '../../Helpers/assistedApplication/tabs';
import { getApplicantFullName, getInsuredDateOfBirth, SectionGroupId } from '../../Helpers/questionnaireAnswers';
import {
  useCarrierContext,
  useLocale,
  useModalState,
  useSelector,
  useDispatch,
  useApplicationStoredFiles,
  useAAQuestionnaireVersionContext,
} from '../../Hooks';
import { Application } from '../../Models/Application';
import { useSubmitInsuranceApplication } from '../../ReactQuery/Application/application.mutations';
import { useGetApplicationQuery } from '../../ReactQuery/Application/application.queries';
import {
  useSaveAssistedApplicationAnswersMutation,
  useUpdateApplicationWithADOMinMaxMutation,
} from '../../ReactQuery/AssistedApplication/assistedApplication.mutations';
import {
  useSendESignCeremonyMutation,
  useUpdateESignCeremony,
} from '../../ReactQuery/ESignCeremony/eSignCeremony.mutations';
import { useGetESignCeremonyQuery } from '../../ReactQuery/ESignCeremony/eSignCeremony.queries';
import { useProcessParticipantsQuery } from '../../ReactQuery/ParticipantProcessor/participantProcessor.queries';
import { useGetPointOfSaleDecisions } from '../../ReactQuery/PointOfSaleDecisions/pointOfSaleDecisions.queries';
import { QueryId } from '../../ReactQuery/common/common.types';
import {
  processApplicationSubmission,
  processPreviewApplicationSubmission,
} from '../../ReduxStore/Application/ApplicationOperations';
import { notificationSlice } from '../../ReduxStore/Notification/NotificationSlice';
import { getQuotedProducts } from '../../ReduxStore/Products/ProductsSelectors';
import { SkipLink } from '../SkipLink/SkipLink';
import { DocumentsDrawer } from './Drawers/Documents/DocumentsDrawer';
import { ESignatureDetailsContainer } from './Drawers/ESignatureDetails/ESignatureDetailsContainer';
import { InfoMessageTrackESignatureModal } from './Modals/ESignature/InfoMessageTrackESignatureModal';
import { SubmitPaperAppModal } from './Modals/SubmitPaperApp/SubmitPaperAppModal';
import { useAutoSaveAnswers } from './useAutoSaveAnswers';
import { useStoredSectionState } from './useStoredSectionState';
import { logger } from '@breathelife/monitoring-frontend';
import { shouldRefreshAllAnswers } from '../../Helpers/assistedApplication/answers';
import {
  adoNodesToRefresh,
  NodesToRefreshParams,
  shouldFetchADO,
  ShouldFetchADOParams,
} from '../../Helpers/assistedApplication/ado';
import { canOnlyReadApplication } from '../../Helpers/permissions';
import { userHasPermission } from '../../Helpers/user';
import { useGetLeadQuery } from '../../ReactQuery/Lead/lead.queries';
import { NodeIds } from '@breathelife/insurance-form-builder';
import { deleteAllOutcomesForParticipant } from '../../Services/ApplicationOutcomesService';

type Props = {
  application: Application;
  applicationSignatureType?: SignatureType;
  closeAssistedApplication: () => void;
  isMissingRequiredFiles: boolean;
  isMaxFilesSizeReached: boolean;
  isSubmitButtonDisabled: boolean;
  onOpenESignatureDetails: () => void;
  onOpenSubmissionDetailsModal: () => void;
  onOpenFileAttachmentModal: () => void;
  onChangeMissingRequiredFiles: (isMissingRequiredFiles: boolean) => void;
  onChangeIsMaxFilesSizeReached: (isMaxFilesSizeReached: boolean) => void;
  isESignatureDetailsOpen: boolean;
  onCloseESignatureDetails: () => void;
  isInfoMessageTrackESignatureModalOpen: boolean;
  onCloseInfoMessageTrackESignatureModal: () => void;
  setHasInitiatedSubmission: (value: SetStateAction<boolean>) => void;
  onOpenSubmitApplicationModal: () => void;
  triggerIdentityVerification: () => void;
  onOpenInstantIdReportDrawer: () => void;
  proposedInsuredIndexToDelete: number;
  onOpenDeleteProposedInsuredModal: (index: number) => void;
  onCloseDeleteProposedInsuredModal: () => void;
};

export function AssistedApplicationContainer(props: Props): ReactElement | null {
  const { t, i18n } = useTranslation();
  const { carrierInfo, features, googleMapsPlaces: googleMapsApiKey } = useCarrierContext();
  const { nodeIdToAnswerPathMap, entitySelectors, questionnaireEngine } = useAAQuestionnaireVersionContext();
  const locale = useLocale();
  const navigationType = useNavigationType();
  const dispatch = useDispatch();
  const queryClient = useQueryClient();

  const {
    application,
    applicationSignatureType,
    closeAssistedApplication,
    isMissingRequiredFiles,
    isMaxFilesSizeReached,
    isSubmitButtonDisabled: isSubmitButtonDisabledProps,
    onOpenSubmitApplicationModal,
    onOpenESignatureDetails,
    onOpenSubmissionDetailsModal,
    onOpenFileAttachmentModal,
    onChangeMissingRequiredFiles,
    onChangeIsMaxFilesSizeReached,
    isESignatureDetailsOpen,
    onCloseESignatureDetails,
    setHasInitiatedSubmission,
    isInfoMessageTrackESignatureModalOpen,
    onCloseInfoMessageTrackESignatureModal,
    triggerIdentityVerification,
    onOpenInstantIdReportDrawer,
    proposedInsuredIndexToDelete,
    onOpenDeleteProposedInsuredModal,
    onCloseDeleteProposedInsuredModal,
  } = props;

  const { isSubmittingApplication } = useSelector((state) => state.leadPlatform.submission);
  const { products, quotes, isLoadingQuotes } = useSelector((state) => state.leadPlatform.products);
  const userPermissions = useSelector((store) => store.leadPlatform.authentication.user?.permissions ?? []);
  const quotedProducts = useSelector(getQuotedProducts);
  const isIdentityVerificationCheckCompleted = useSelector(
    (store) => store.leadPlatform.identityVerification.isIdentityVerificationCheckCompleted,
  );

  const sendESignCeremonyMutation = useSendESignCeremonyMutation();
  const updateESignCeremonyStatusMutation = useUpdateESignCeremony();
  const submitInsuranceApplicationMutation = useSubmitInsuranceApplication();
  const { data: eSignCeremony, isFetching: isGetESignCeremonyFetching } = useGetESignCeremonyQuery(
    application.id,
    applicationSignatureType,
  );
  const { isFetching: isGetApplicationFetching } = useGetApplicationQuery(application.id);
  const { isFetching: isPointOfSalesDecisionsFetching } = useGetPointOfSaleDecisions(application.id);

  const [isDocumentDrawerOpen, onOpenDocumentDrawerHook, onCloseDocumentDrawer] = useModalState();
  const [isSubmitPaperAppModalOpen, onOpenSubmitPaperAppModal, onCloseSubmitPaperAppModal] = useModalState();
  const [storedSectionIndices, setStoredSectionIndices] = useStoredSectionState(application?.id);

  const [displayValidationErrors, setDisplayValidationErrors] = useState(false);
  const [openESignatureDrawerRequested, setOpenESignatureDrawerRequested] = useState(false);
  const [shouldPointOfSalesTriggerLoadingStatus, setShouldPointOfSalesTriggerLoadingStatus] = useState(true);
  const [shouldRefreshAnswers, setShouldRefreshAnswers] = useState(false);
  const [shouldShowADONotification, setShouldShowADONotification] = useState(false);
  const [nodesToRefresh, setNodesToRefresh] = useState<Record<string, unknown>[]>([]);
  const [shouldDisableFields, setShouldDisableFields] = useState(false);

  const { data: lead } = useGetLeadQuery(application.leadId, {});
  const userCanOnlyReadApplication = canOnlyReadApplication(lead);

  const isIdentityVerificationCheckEnabled = !!features.enableInstantIdRequest?.enabled;
  const isMissingIdentityVerificationCheck =
    isIdentityVerificationCheckEnabled && !isIdentityVerificationCheckCompleted;
  const minorAge = carrierInfo.minorAge ?? 0;

  const { activeSectionIndex, previousSectionIndex, currentSectionGroupId, currentProposedInsuredIndex } =
    storedSectionIndices;

  const isESignStatusChanging =
    updateESignCeremonyStatusMutation.isLoading ||
    submitInsuranceApplicationMutation.isLoading ||
    sendESignCeremonyMutation.isLoading ||
    isGetApplicationFetching ||
    (isPointOfSalesDecisionsFetching && shouldPointOfSalesTriggerLoadingStatus) ||
    (isGetESignCeremonyFetching && eSignCeremony?.status !== ESignCeremonyStatus.IN_PROGRESS);

  const isProductsWidgetTotalPremiumsEnabled =
    features.assistedApplication?.productsWidget?.enabled &&
    features.assistedApplication?.productsWidget?.type === ProductsWidgetFeatureType.totalPremiums;

  const isProductWidgetEnabled = features.assistedApplication?.productsWidget?.enabled;
  const isApplicationLineOfBusinessAllowedToFetchProductAndPricing =
    application &&
    isProductWidgetEnabled &&
    features.assistedApplication?.productsWidget?.allowedLinesOfBusiness.includes(application.lineOfBusiness);

  const updateApplicationWithADOMinMaxMutation = useUpdateApplicationWithADOMinMaxMutation({
    onMutate: () => {
      if (shouldShowADONotification) {
        dispatch(
          notificationSlice.actions.setInfo({
            message: t('notifications.fetchingADOMinMax'),
            autoHideDuration: 3000,
          }),
        );
      }
    },
    onSuccess: (data) => {
      // TODO: FIXME: See HOT-3513. Backend should return an array of nodeIds to update { nodeId: 'my-node-id', value: 'value' }
      // The frontend will set the value in the answers using the questionnaireEngine.updateAnswer and avoid needing to sync with the backend
      const adoNodesToRefreshParams: NodesToRefreshParams = {
        answers: new VersionedAnswers({ v1: data.answers, v2: data.answersV2 }),
        featureIsEnabled: features.assistedApplication?.adoMinMax?.enabled,
        currentProposedInsuredIndex,
        questionnaireEngine,
      };
      setNodesToRefresh(adoNodesToRefresh(adoNodesToRefreshParams));
      if (shouldShowADONotification) {
        dispatch(
          notificationSlice.actions.setSuccess({
            message: t('notifications.updatedADOMinMax'),
            autoHideDuration: 3000,
          }),
        );
      }
    },
  });

  const isJetDecisionWidgetEnabled = useMemo(
    () =>
      features.assistedApplication?.jetDecisionWidget?.enabled &&
      userHasPermission(userPermissions, Permission.JetDecisionWidgetView),
    [userPermissions, features.assistedApplication?.jetDecisionWidget?.enabled],
  );

  const refreshPricingAuto = useCallback(
    async (updatedNodeIds: string[]) => {
      if (!application || !isApplicationLineOfBusinessAllowedToFetchProductAndPricing) return;

      const shouldRefreshProducts =
        _.isArray(features.assistedApplication?.nodeIdsAffectingProducts) &&
        _.intersection(features.assistedApplication?.nodeIdsAffectingProducts, updatedNodeIds).length > 0;

      if (shouldRefreshProducts) {
        void dispatch(ProductsOperations.fetchProducts(application.id, i18n.language as Language));
      }

      const shouldRefreshQuotes =
        _.isArray(features.assistedApplication?.nodeIdsAffectingPricing) &&
        _.intersection(features.assistedApplication?.nodeIdsAffectingPricing, updatedNodeIds).length > 0;

      if (shouldRefreshQuotes) {
        if (isProductsWidgetTotalPremiumsEnabled) {
          void queryClient.invalidateQueries([QueryId.productsWidgetTotalPremiums, application.id]);
          return;
        }

        const shouldFetchADOParams: ShouldFetchADOParams = {
          answers: new VersionedAnswers({ v1: application.answers, v2: application.answersV2 }),
          featureIsEnabled: features.assistedApplication?.adoMinMax?.enabled,
          updatedNodeIds,
          currentProposedInsuredIndex,
          questionnaireEngine,
        };
        if (shouldFetchADO(shouldFetchADOParams)) {
          setShouldShowADONotification(true);
          await updateApplicationWithADOMinMaxMutation.mutateAsync(application.id);
          setShouldShowADONotification(false);
        }
        await dispatch(AssistedApplicationOperations.fetchQuotes(application.id, application.coverageAmount));
      }
    },
    [
      application,
      features,
      dispatch,
      i18n.language,
      questionnaireEngine,
      currentProposedInsuredIndex,
      updateApplicationWithADOMinMaxMutation,
      isProductsWidgetTotalPremiumsEnabled,
      queryClient,
      isApplicationLineOfBusinessAllowedToFetchProductAndPricing,
    ],
  );

  const refreshPricingManual = useCallback(async () => {
    if (!application || !isApplicationLineOfBusinessAllowedToFetchProductAndPricing) return;

    if (isProductsWidgetTotalPremiumsEnabled) {
      void queryClient.invalidateQueries([QueryId.productsWidgetTotalPremiums, application.id]);
      return;
    }

    if (features.assistedApplication?.adoMinMax?.enabled) {
      await updateApplicationWithADOMinMaxMutation.mutateAsync(application.id);
    }

    await dispatch(AssistedApplicationOperations.fetchQuotes(application.id, application.coverageAmount));
  }, [
    application,
    features,
    dispatch,
    updateApplicationWithADOMinMaxMutation,
    isProductsWidgetTotalPremiumsEnabled,
    queryClient,
    isApplicationLineOfBusinessAllowedToFetchProductAndPricing,
  ]);

  const saveAssistedApplicationAnswersMutation = useSaveAssistedApplicationAnswersMutation({
    onMutate: ({ isManuallySavingAnswers, updatedNodeIds }) => {
      const refreshAllAnswers = shouldRefreshAllAnswers({
        answers: new VersionedAnswers({ v1: application.answers, v2: application.answersV2 }),
        currentProposedInsuredIndex,
        questionnaireEngine,
        nodeIdsSkippingDebounce: features.assistedApplication?.nodeIdsSkippingDebounce,
        updatedNodeIds,
      });
      if (refreshAllAnswers || isManuallySavingAnswers) {
        setShouldDisableFields(true);
      }
    },
    onSuccess: async (__application, { isManuallySavingAnswers, isClosing, updatedNodeIds }) => {
      const refreshAllAnswers = shouldRefreshAllAnswers({
        answers: new VersionedAnswers({ v1: application.answers, v2: application.answersV2 }),
        currentProposedInsuredIndex,
        questionnaireEngine,
        nodeIdsSkippingDebounce: features.assistedApplication?.nodeIdsSkippingDebounce,
        updatedNodeIds,
      });
      if (refreshAllAnswers) {
        setShouldRefreshAnswers(true);
      } else if (isManuallySavingAnswers) {
        await refreshPricingManual();
      } else if (!isClosing) {
        void refreshPricingAuto(updatedNodeIds);
      }

      if (isJetDecisionWidgetEnabled && application) {
        void queryClient.invalidateQueries([QueryId.jetDecisionOutcomes, application.id]);
      }

      void queryClient.invalidateQueries([QueryId.leads]);
    },
    onSettled(__application, __error, { isManuallySavingAnswers }) {
      if (isManuallySavingAnswers) {
        setShouldDisableFields(false);
      }
    },
  });

  const isSavingAnswers =
    saveAssistedApplicationAnswersMutation.isLoading || updateApplicationWithADOMinMaxMutation.isLoading;

  const onAfterRefreshAnswers = (): void => {
    setShouldRefreshAnswers(false);
    setShouldDisableFields(false);
    setNodesToRefresh([]);
  };

  const onSelectProposedInsuredIndex = useCallback(
    (newProposedInsuredIndex: number): void => {
      setStoredSectionIndices((prev) => {
        return {
          ...prev,
          currentProposedInsuredIndex: newProposedInsuredIndex,
        };
      });
    },
    [setStoredSectionIndices],
  );

  const {
    answers: versionedAnswers,
    onAnswerChange,
    onBulkAnswerClear,
    hasUnsavedChanges,
    manuallySaveAnswers,
    removeItemFromCollection,
  } = useAutoSaveAnswers({
    application,
    questionnaireEngine,
    saveAnswers: saveAssistedApplicationAnswersMutation.mutateAsync,
    isSavingAnswers,
    shouldRefreshAnswers,
    nodesToRefresh,
    onAfterRefreshAnswers,
  });

  const areAllFieldsDisabled = useMemo(
    () =>
      application.signed ||
      application.submitted ||
      isSubmittingApplication ||
      shouldDisableFields ||
      isESignStatusChanging ||
      userCanOnlyReadApplication,
    [
      application.signed,
      application.submitted,
      isSubmittingApplication,
      shouldDisableFields,
      isESignStatusChanging,
      userCanOnlyReadApplication,
    ],
  );

  const renderingQuestionnaire = useMemo(() => {
    const start = performance.now();
    const renderingQuestionnaire = questionnaireEngine.generateRenderingQuestionnaire(versionedAnswers, locale, t, {
      renderingType: RenderingType.web,
      shouldValidateAllAnswers: displayValidationErrors,
      allFieldsCompleted: areAllFieldsDisabled || isESignStatusChanging,
      loadingFields: shouldDisableFields || isESignStatusChanging,
    });
    const end = performance.now();

    logger.keep({
      tag: 'TimeMeasurement',
      name: 'TimeToGenerateNextRenderingQuestionnaire',
      value: end - start,
      unit: TimeMeasurementUnits.millisecond,
    });

    return renderingQuestionnaire;
  }, [
    questionnaireEngine,
    versionedAnswers,
    locale,
    t,
    displayValidationErrors,
    areAllFieldsDisabled,
    isESignStatusChanging,
    shouldDisableFields,
  ]);

  const isQuestionnaireCompleted = useMemo(() => {
    return isRenderingQuestionnaireComplete(renderingQuestionnaire);
  }, [renderingQuestionnaire]);

  const applicantName = useMemo(() => {
    return getApplicantFullName(versionedAnswers, questionnaireEngine, application.lineOfBusiness);
  }, [versionedAnswers, questionnaireEngine, application.lineOfBusiness]);

  const applicantDateOfBirth = useMemo(() => {
    return getInsuredDateOfBirth(versionedAnswers, questionnaireEngine);
  }, [versionedAnswers, questionnaireEngine]);

  const isApplicantMinor = useMemo(() => {
    if (minorAge === 0) return false;
    const applicantDateOfBirthObject = dayjs(applicantDateOfBirth);
    const applicantAge = getAge(applicantDateOfBirthObject.toDate(), AgeRoundingType.lastBirthday);
    const isMinor = applicantAge < minorAge;
    return isMinor;
  }, [applicantDateOfBirth, minorAge]);

  const insuranceEntities = useMemo(() => {
    return new InsuranceEntities(nodeIdToAnswerPathMap, entitySelectors);
  }, [nodeIdToAnswerPathMap, entitySelectors]);

  const productsEntity = useMemo(() => {
    if (!features.loadProductInformationFromDb?.enabled) return null;
    if (!features.assistedApplication?.productsWidget?.enabled) return null;

    const hideRidersPremium = features.assistedApplication?.productsWidget?.hideRidersPremium;

    return insuranceEntities.getProducts<string>(
      versionedAnswers.v1,
      products as LocalizedInsuranceProduct[],
      quotes,
      hideRidersPremium,
    );
  }, [features, insuranceEntities, versionedAnswers, products, quotes]);

  const proposedInsuredTabs = useMemo(() => {
    const getRepetitionCount = (
      answers: VersionedAnswers,
      nodeId: string,
      collectionInstanceIdentifiers: CollectionInstanceIdentifiers,
    ): number | undefined => questionnaireEngine.getRepetitionCount(answers, nodeId, collectionInstanceIdentifiers);

    return getInsuredPeopleTabs(insuranceEntities, versionedAnswers, getRepetitionCount);
  }, [versionedAnswers, questionnaireEngine, insuranceEntities]);

  const hashedApplicationId = useMemo(() => hash(application.id), [application.id]);

  /** TODO: see DEV-7251
   * We should have a better way of keeping track of an application's signature status regardless of the type of signature used
   * hasSignature is used to know if something is signed in the context of cryptoSignature
   * in the context of eSignature, we only check if the submission was created (application.submitted)
   * */
  const hasNoSignature =
    application.mode !== ApplicationMode.paper &&
    applicationSignatureType !== SignatureType.eSignature &&
    !application.signed;
  const DECOUPLE_ESIGN_SUBMISSION_ENABLED = !!features.enableDecoupleESignFlow?.enabled;

  // Application is being edited by the user
  const isApplicationInEditMode =
    DECOUPLE_ESIGN_SUBMISSION_ENABLED && eSignCeremony?.status === ESignCeremonyStatus.DRAFT;

  // Application has been locked for review
  const isApplicationInReview =
    DECOUPLE_ESIGN_SUBMISSION_ENABLED &&
    // application has been submitted but eSignCeremony status is still in draft
    ((application.submitted && eSignCeremony?.status === ESignCeremonyStatus.DRAFT) ||
      eSignCeremony?.status === ESignCeremonyStatus.IN_PROGRESS ||
      eSignCeremony?.status === ESignCeremonyStatus.READY);

  const isSubmitButtonVisible = isApplicationInReview || !application.submitted || hasNoSignature;

  const isSubmitButtonDisabled =
    isSubmitButtonDisabledProps ||
    !isQuestionnaireCompleted ||
    hasUnsavedChanges ||
    (isMissingIdentityVerificationCheck && !isApplicantMinor) ||
    isSavingAnswers ||
    isLoadingQuotes ||
    isApplicationInEditMode ||
    userCanOnlyReadApplication;

  const DOCUMENTS_DRAWER_ENABLED = features.assistedApplication?.documentsDrawer?.enabled === true;

  const [requiredFiles, otherFiles] = useApplicationStoredFiles(
    application,
    versionedAnswers.v1,
    nodeIdToAnswerPathMap,
  );

  const onClose = useCallback(async () => {
    const isSavingApplication = hasUnsavedChanges && !userCanOnlyReadApplication && (await manuallySaveAnswers(true));
    isSavingApplication &&
      dispatch(
        notificationSlice.actions.setSuccess({
          message: t('notifications.saveApplicationSuccess'),
          autoHideDuration: 3000,
        }),
      );
  }, [hasUnsavedChanges, userCanOnlyReadApplication, manuallySaveAnswers, dispatch, t]);

  const onAddProposedInsured = useCallback(async (): Promise<void> => {
    if (areAllFieldsDisabled) return;
    hasUnsavedChanges && (await manuallySaveAnswers());

    if (!application) return;

    const insuredPeopleCount = questionnaireEngine.getRepetitionCount(versionedAnswers, NodeIds.insuredPeople, {});

    onAnswerChange(
      NodeIds.insuredSurrogateId,
      '',
      undefined,
      {
        [NodeIds.insuredPeople]: insuredPeopleCount || 0,
      },
      undefined,
      true,
    );

    onSelectProposedInsuredIndex(insuredPeopleCount || 0);

    if (isJetDecisionWidgetEnabled && application) {
      void queryClient.invalidateQueries([QueryId.jetDecisionOutcomes, application.id]);
    }
  }, [
    versionedAnswers,
    questionnaireEngine,
    onAnswerChange,
    areAllFieldsDisabled,
    application,
    hasUnsavedChanges,
    manuallySaveAnswers,
    isJetDecisionWidgetEnabled,
    onSelectProposedInsuredIndex,
    queryClient,
  ]);

  const onRemoveProposedInsured = useCallback(
    async (surrogateId: string): Promise<void> => {
      if (areAllFieldsDisabled) return;
      hasUnsavedChanges && (await manuallySaveAnswers());

      if (!application || surrogateId === '') return;

      removeItemFromCollection(surrogateId, NodeIds.insuredPeople, NodeIds.insuredSurrogateId);

      void deleteAllOutcomesForParticipant({ participantId: surrogateId, applicationId: application.id });
      onCloseDeleteProposedInsuredModal();
      onSelectProposedInsuredIndex(0);

      if (isProductsWidgetTotalPremiumsEnabled) {
        void queryClient.invalidateQueries([QueryId.productsWidgetTotalPremiums, application.id]);
      }

      if (isJetDecisionWidgetEnabled) {
        void queryClient.invalidateQueries([QueryId.jetDecisionOutcomes, application.id]);
      }
    },
    [
      queryClient,
      onSelectProposedInsuredIndex,
      onCloseDeleteProposedInsuredModal,
      isProductsWidgetTotalPremiumsEnabled,
      isJetDecisionWidgetEnabled,
      application,
      areAllFieldsDisabled,
      manuallySaveAnswers,
      hasUnsavedChanges,
      removeItemFromCollection,
    ],
  );

  const onAnswerComplete = useCallback(
    (fieldId: string) => {
      TypewriterTracking.completedField({
        fieldId,
        hashedId: hashedApplicationId,
      });
    },
    [hashedApplicationId],
  );

  const onOpenDocumentsDrawer = useCallback(() => {
    TypewriterTracking.clickedButton({
      buttonName: 'documents drawer',
      hashedId: application?.id ? hash(application.id) : null,
    });
    onOpenDocumentDrawerHook();
  }, [application, onOpenDocumentDrawerHook]);

  const processSubmission = useCallback(
    async (signers: ESignSigner2FAInfo[] = []) => {
      if (!application || !applicationSignatureType) return;
      const selectedProduct = quotedProducts.find((product) => product.productId === application.product);
      const premiumForSelectedProduct = selectedProduct?.premium || productsEntity?.totalPremium;

      let updatedApplication: Application | undefined;

      if (application.isPreview) {
        updatedApplication = await dispatch(
          processPreviewApplicationSubmission({ application, productQuote: premiumForSelectedProduct }),
        );
      } else if (applicationSignatureType === SignatureType.eSignature) {
        if (signers.length === 0 && application.mode === ApplicationMode.digital) {
          throw new Error('Failed to get signers for application.');
        }

        updatedApplication = await dispatch(
          processApplicationSubmission({
            application,
            productQuote: premiumForSelectedProduct,
            signatureType: applicationSignatureType,
            signers,
            // TODO [DEV-10295] https://breathelife.atlassian.net/browse/DEV-10295 Don't pass this down once we migrate AA submission to react-query
            sendESignCeremonyMutation,
          }),
        );
      } else {
        updatedApplication = await dispatch(
          processApplicationSubmission({
            application,
            productQuote: premiumForSelectedProduct,
            signatureType: applicationSignatureType,
          }),
        );
      }

      setHasInitiatedSubmission(true);

      if (updatedApplication) {
        queryClient.setQueryData([QueryId.application, application.id], updatedApplication);
        void queryClient.invalidateQueries([QueryId.application, application.id]);
        void queryClient.invalidateQueries([QueryId.pointOfSaleDecision, application.id]);
      }
    },
    [
      application,
      applicationSignatureType,
      quotedProducts,
      productsEntity?.totalPremium,
      setHasInitiatedSubmission,
      queryClient,
      dispatch,
      sendESignCeremonyMutation,
    ],
  );

  const onSubmitApplication = useCallback(async () => {
    if (
      !application ||
      !applicationSignatureType ||
      (!application.isPreview && applicationSignatureType === SignatureType.eSignature)
    )
      return;

    onOpenSubmitApplicationModal();

    return processSubmission();
  }, [application, applicationSignatureType, onOpenSubmitApplicationModal, processSubmission]);

  const handleConfirmSubmitPaperAppModal = useCallback(async () => {
    onCloseSubmitPaperAppModal();
    try {
      await processSubmission();
      dispatch(
        notificationSlice.actions.setSuccess({
          message: t('modals.submitPaperApp.submitSuccessMessage'),
        }),
      );
      closeAssistedApplication();
    } catch (error: any) {
      dispatch(
        notificationSlice.actions.setError({
          message: t('modals.submitPaperApp.submitErrorMessage'),
        }),
      );
    }
  }, [onCloseSubmitPaperAppModal, processSubmission, closeAssistedApplication, dispatch, t]);

  const { data: participants } = useProcessParticipantsQuery(application?.id, {
    enabled: applicationSignatureType === SignatureType.eSignature && isQuestionnaireCompleted,
  });

  const onLockApplication: () => void = useCallback(async () => {
    const selectedProduct = quotedProducts.find((product) => product.productId === application.product);
    const premiumForSelectedProduct = selectedProduct?.premium || productsEntity?.totalPremium;
    if (application.id && participants) {
      await submitInsuranceApplicationMutation.mutateAsync({
        applicationId: application.id,
        productQuote: premiumForSelectedProduct,
      });
      updateESignCeremonyStatusMutation.mutate({
        applicationId: application.id,
        data: { status: ESignCeremonyStatus.IN_PROGRESS, signers: participants },
      });
    }
  }, [
    quotedProducts,
    productsEntity?.totalPremium,
    application.id,
    application.product,
    submitInsuranceApplicationMutation,
    updateESignCeremonyStatusMutation,
    participants,
  ]);

  const onUnlockApplication: () => void = useCallback(async () => {
    updateESignCeremonyStatusMutation.mutate({
      applicationId: application.id,
      data: { status: ESignCeremonyStatus.DRAFT },
    });
  }, [application.id, updateESignCeremonyStatusMutation]);

  // This effect detects if the AA is unmounted due to the browser's back button being pressed
  useEffect(() => {
    const currentLocation = location.href;
    return () => {
      if (navigationType === 'POP' && !location.href.includes(currentLocation)) {
        void onClose();
      }
    };
  }, [navigationType, onClose]);

  useEffect(() => {
    if (!openESignatureDrawerRequested) return;
    if (!isSubmitButtonDisabled) {
      setShouldPointOfSalesTriggerLoadingStatus(false);
      onOpenESignatureDetails();
    } else {
      dispatch(
        notificationSlice.actions.setError({
          message: t('notifications.unableToSendToSignature'),
        }),
      );
    }
    setOpenESignatureDrawerRequested(false);
  }, [isSubmitButtonDisabled, openESignatureDrawerRequested, onOpenESignatureDetails, dispatch, t]);

  const onSubmitESignature = (signers: ESignSigner2FAInfo[]): void => {
    if (DECOUPLE_ESIGN_SUBMISSION_ENABLED) {
      updateESignCeremonyStatusMutation.mutate({
        applicationId: application.id,
        data: { status: ESignCeremonyStatus.SENT, signers },
      });
    } else {
      void processSubmission(signers);
    }
  };

  async function onOpenESignatureDrawer(): Promise<void> {
    !application.submitted && (await refreshPricingManual());
    setOpenESignatureDrawerRequested(true);
  }

  function onSetActiveSectionIndex(newActiveSectionIndex: number): void {
    setStoredSectionIndices((prev) => {
      return {
        ...prev,
        activeSectionIndex: newActiveSectionIndex,
      };
    });
  }

  function onSwitchSectionGroupTab(sectionGroupId: SectionGroupId): void {
    if (sectionGroupId !== currentSectionGroupId) {
      setStoredSectionIndices((prev) => {
        return {
          ...prev,
          previousSectionIndex: activeSectionIndex,
          activeSectionIndex: previousSectionIndex,
        };
      });
    }

    setStoredSectionIndices((prev) => {
      return {
        ...prev,
        currentSectionGroupId: sectionGroupId,
      };
    });
  }

  // Display the esign loader when sending the eSignCeremony to sign
  const isESignatureDetailLoading = DECOUPLE_ESIGN_SUBMISSION_ENABLED
    ? updateESignCeremonyStatusMutation.isLoading
    : sendESignCeremonyMutation.isLoading;

  const skipToAssistedApplicationContentId = 'assistedApplicationContent';

  return (
    <AssistedApplicationContext.Provider
      value={{
        // Proposed insured
        currentSectionGroupId,
        onSwitchSectionGroupTab,
        proposedInsuredTabs,
        currentProposedInsuredIndex,
        onSelectProposedInsured: onSelectProposedInsuredIndex,
        onAddProposedInsured,
        onRemoveProposedInsured,
        activeSectionIndex,
        onSetActiveSectionIndex,

        // Form
        areAllFieldsDisabled,
        isSavingAnswers,
        onAnswerChange,
        onAnswerComplete,
        onBulkAnswerClear,
        applicationLineOfBusiness: application.lineOfBusiness,
      }}
    >
      <Box display='flex' flexDirection='column' height='100vh'>
        <Box>
          <SkipLink href={`#${skipToAssistedApplicationContentId}`} title={t('skipLinks.header')} />
          {googleMapsApiKey && <GoogleMapsHelmet googleMapsApiKey={googleMapsApiKey} />}
          <AssistedApplicationHeader
            applicantName={applicantName}
            isApplicantMinor={isApplicantMinor}
            applicationSignatureType={applicationSignatureType}
            applicationMode={application.mode}
            close={() => {
              void onClose();
              closeAssistedApplication();
            }}
            isMissingRequiredFiles={isMissingRequiredFiles}
            isMaxFileSizeReached={isMaxFilesSizeReached}
            isPreview={application.isPreview}
            isSubmitButtonDisabled={isSubmitButtonDisabled}
            isSubmitButtonVisible={isSubmitButtonVisible}
            onSubmitApplication={onSubmitApplication}
            onOpenESignatureDetails={onOpenESignatureDrawer}
            onOpenFileAttachmentModal={onOpenFileAttachmentModal}
            onOpenDocumentsDrawer={onOpenDocumentsDrawer}
            triggerIdentityVerification={triggerIdentityVerification}
            onOpenInstantIdReportDrawer={onOpenInstantIdReportDrawer}
            onOpenSubmitPaperAppModal={onOpenSubmitPaperAppModal}
          />
        </Box>
        <Box id={skipToAssistedApplicationContentId}>
          <AssistedApplicationView
            renderingQuestionnaire={renderingQuestionnaire}
            setDisplayValidationErrors={setDisplayValidationErrors}
            selectedProductId={application.product}
            applicationMode={application.mode}
            coverageAmount={application.coverageAmount}
            isApplicationSubmitted={application.submitted}
            isApplicationSigned={application.signed}
            isQuestionnaireCompleted={isQuestionnaireCompleted}
            onOpenSubmissionDetailsModal={onOpenSubmissionDetailsModal}
            productsEntity={productsEntity}
            proposedInsuredIndexToDelete={proposedInsuredIndexToDelete}
            onOpenDeleteProposedInsuredModal={onOpenDeleteProposedInsuredModal}
            onCloseDeleteProposedInsuredModal={onCloseDeleteProposedInsuredModal}
            onLockApplication={onLockApplication}
            onUnlockApplication={onUnlockApplication}
            onOpenESignatureDetails={onOpenESignatureDrawer}
            isESignStatusChanging={isESignStatusChanging}
            applicationSignatureType={applicationSignatureType}
            isReadOnly={userCanOnlyReadApplication}
          />
        </Box>
      </Box>

      {DOCUMENTS_DRAWER_ENABLED && (
        <RestrictedToUserPermissions requiredPermissions={[Permission.ApplicationFileRead]}>
          <DocumentsDrawer
            isOpen={isDocumentDrawerOpen}
            onClose={onCloseDocumentDrawer}
            application={application}
            onChangeMissingRequiredFiles={onChangeMissingRequiredFiles}
            requiredFiles={requiredFiles}
            otherFiles={otherFiles}
            onMaxFileSizeReached={onChangeIsMaxFilesSizeReached}
          />
        </RestrictedToUserPermissions>
      )}

      {applicationSignatureType === SignatureType.eSignature && (
        <Fragment>
          <ESignatureDetailsContainer
            isLoading={isESignatureDetailLoading}
            isOpen={isESignatureDetailsOpen}
            onClose={onCloseESignatureDetails}
            onSubmit={onSubmitESignature}
            requiredFiles={requiredFiles}
          />
          <InfoMessageTrackESignatureModal
            isOpen={isInfoMessageTrackESignatureModalOpen}
            onClose={onCloseInfoMessageTrackESignatureModal}
          />
        </Fragment>
      )}
      {application.mode === ApplicationMode.paper && (
        <SubmitPaperAppModal
          isOpen={isSubmitPaperAppModalOpen}
          onClose={onCloseSubmitPaperAppModal}
          onConfirm={handleConfirmSubmitPaperAppModal}
        />
      )}
    </AssistedApplicationContext.Provider>
  );
}
