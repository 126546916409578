import { ReactElement, useContext, useCallback, useState, useMemo, Fragment } from 'react';
import { useTranslation } from 'react-i18next';
import { ValidationError } from 'yup';

import { Grid } from '@breathelife/mui';
import {
  ButtonFieldBlueprint,
  FieldBlueprint,
  FieldTypes,
  FieldValidation,
  InformationFieldBlueprintVariant,
  isTextFieldBlueprint,
  NumberFieldValidation,
  ParticipantRoles,
  QuestionBlueprint,
} from '@breathelife/types';
import { AutocompleteOption, Box, SimpleCheckbox } from '@breathelife/ui-components';

import { SubmitButton } from '../../../../../../Components/Button/SubmitButton';
import { getOptionsFromEnum } from '../../../../../../Helpers/options';
import { NodeDetail } from '../../../../../../Helpers/questionnaireEditor/questionnaireNodeIds';
import {
  getFieldTypeOptions,
  getInfoFieldStylingOptions,
} from '../../../../../../Helpers/questionnaireEditor/selectOptions';
import { useCarrierContext } from '../../../../../../Hooks';
import { ModalLayout } from '../../../../../../Layouts/Modal/ModalLayout';
import { QuestionnaireEditorContext } from '../../../ContextProvider/QuestionnaireEditorContextProvider';
import { QuestionnaireVersionDataContext } from '../../../ContextProvider/QuestionnaireVersionDataContextProvider';
import { EditorDropdown } from '../../Components/EditorDropdown';
import { IconNameSelector } from '../../Components/IconNameSelector';
import NavigatesToNextStepCheckbox from '../../Components/NavigatesToNextStepCheckbox';
import { AddressAutocompleteFieldEditor } from '../../Editors/AddressAutocompleteFieldEditor';
import { AgreeFieldEditor } from '../../Editors/AgreeFieldEditor';
import { BooleanFieldDefaultValueEditor } from '../../Editors/defaultValueEditors/BooleanFieldDefaultValueEditor';
import { NumberFieldDefaultValueEditor } from '../../Editors/defaultValueEditors/NumberFieldDefaultValueEditor';
import { FieldSetters } from '../../Helpers/helperTypes';
import { FieldDataSetter } from '../useDataByField/dataSetters/FieldDataSetter';
import { AddressAutocompleteFieldSetters } from '../useDataByField/useAddressAutocompleteFieldData';
import { AgreeFieldSetters } from '../useDataByField/useAgreeFieldData';
import { ButtonFieldSetters } from '../useDataByField/useButtonFieldData';
import { CheckboxFieldSetters } from '../useDataByField/useCheckboxFieldData';
import { InformationFieldSetters } from '../useDataByField/useInformationFieldData';
import { NumberFieldSetters } from '../useDataByField/useNumberFieldData';
import { SignatureFieldSetters } from '../useDataByField/useSignatureFieldData';
import { AdvancedBlueprintOptionsForField } from './FieldEditors/AdvancedBlueprintOptionsForField';
import { AnswerNodeIdInput } from './FieldEditors/AnswerNodeIdInput';
import { FieldLayoutInputs } from './FieldEditors/FieldLayoutInputs';
import {
  TitleInAllLanguagesInputs,
  TextInAllLanguagesInputs,
  ButtonTextInAllLanguagesInputs,
  PlaceholderInAllLanguagesInputs,
} from './FieldEditors/TextInLanguagesInputs';
import { ValidateAsInput } from './FieldEditors/ValidateAsInput';
import { DropdownFieldSetters } from '../useDataByField/useDropdownFieldData';

export function FieldCreator(props: {
  onCloseModal: () => void;
  onSubmit: (blueprint: FieldBlueprint) => void;
  dataLabelOptions: AutocompleteOption[];
  repeatableNodeId?: string;
  displayAsCard?: boolean;
  initialData?: Partial<FieldBlueprint>;
  questionBlueprint: QuestionBlueprint;
}): ReactElement | null {
  const { onCloseModal, onSubmit, dataLabelOptions, questionBlueprint, repeatableNodeId, initialData, displayAsCard } =
    props;

  const { questionnaireVersionData } = useContext(QuestionnaireVersionDataContext);
  const questionnaireNodeIds = questionnaireVersionData?.questionnaireNodeIds;
  const { selectedLanguage } = useContext(QuestionnaireEditorContext);
  const { t } = useTranslation();
  const [fieldType, setFieldType] = useState<FieldTypes>(initialData?.fieldType || FieldTypes.input);

  const [data, setData] = useState<FieldBlueprint | undefined>(undefined);
  const [setters, setSetters] = useState<FieldSetters | undefined>(undefined);
  const [validationOptions, setValidationOptions] = useState<AutocompleteOption[] | undefined>(undefined);
  const [defaultValueValidationError, setDefaultValueValidationError] = useState<ValidationError | undefined>(
    undefined,
  );
  const [isValidBlueprintAutocompleteFields, setIsValidBlueprintAutocompleteFields] = useState<boolean>(false);
  const [isValidBlueprint, setIsValidBlueprint] = useState<boolean>(false);

  const nodeIds: NodeDetail[] = repeatableNodeId
    ? [
        ...(questionnaireNodeIds?.notInQuestionnaire.withRepeatableAncestor[repeatableNodeId] ?? []),
        ...(questionnaireNodeIds?.inQuestionnaire.withRepeatableAncestor[repeatableNodeId] ?? []),
      ]
    : [
        ...(questionnaireNodeIds?.notInQuestionnaire.noRepeatability ?? []),
        ...(questionnaireNodeIds?.inQuestionnaire.noRepeatability ?? []),
      ];

  const fieldTypeOptions = useMemo(() => getFieldTypeOptions(t), [t]);

  const handleSubmit = useCallback(
    (blueprint) => {
      if (blueprint) {
        onSubmit(blueprint);
      }
      onCloseModal();
    },
    [onSubmit, onCloseModal],
  );

  const infoFieldStylingOptions = useMemo(() => getInfoFieldStylingOptions(t), [t]);
  const participantRoleOptions = getOptionsFromEnum<ParticipantRoles>(ParticipantRoles, 'participantRole');
  const { languageSettings } = useCarrierContext();
  const enabledLanguages = languageSettings.enabledLanguages;
  if (!selectedLanguage) {
    return null;
  }

  return (
    <Fragment>
      <FieldDataSetter
        fieldType={fieldType}
        initialData={initialData}
        setData={(d) => setData(d)}
        dataLabelOptions={dataLabelOptions}
        setSetters={(s) => setSetters(s)}
        setValidationOptions={setValidationOptions}
        setIsValidBlueprint={setIsValidBlueprint}
        setDefaultValueValidationError={setDefaultValueValidationError}
      />
      {data && setters && (
        <ModalLayout
          maxWidth='md'
          isOpen={true}
          closeModal={onCloseModal}
          title={t('admin.questionnaireManagement.input.createField.title')}
          submitButton={
            <SubmitButton
              disabled={
                !isValidBlueprint || (data.fieldType === FieldTypes.autocomplete && !isValidBlueprintAutocompleteFields)
              }
              onClick={() => handleSubmit(data)}
              data-testid='questionnaire-editor-field-create'
            >
              {t('cta.save')}
            </SubmitButton>
          }
        >
          <Grid container spacing={2} alignItems='center'>
            <Grid item xs={6} key={`createField-fieldType`}>
              <EditorDropdown
                id='addFieldFieldTypeDropdown'
                label={t('admin.questionnaireManagement.input.fieldType')}
                selectedOptionValue={fieldType}
                onChange={(value) => {
                  if (value !== null) {
                    setFieldType(value as FieldTypes);
                  }
                }}
                options={fieldTypeOptions}
              />
            </Grid>
            {fieldType === FieldTypes.number && setters.setValidateAs && validationOptions && (
              <Grid item xs={6} key={`createField-numericalDataType`}>
                <ValidateAsInput
                  value={data.validateAs}
                  handleChange={(value) =>
                    (setters.setValidateAs as (value: NumberFieldValidation) => void)(value as NumberFieldValidation)
                  }
                  validationOptions={validationOptions}
                />
              </Grid>
            )}
            {fieldType === FieldTypes.button &&
              (data as ButtonFieldBlueprint).buttonText &&
              (setters as ButtonFieldSetters).setButtonText && (
                <Fragment>
                  <ButtonTextInAllLanguagesInputs
                    value={(data as ButtonFieldBlueprint).buttonText}
                    handleChange={(setters as ButtonFieldSetters).setButtonText}
                  />
                  <Grid item xs={6} key={`createField-buttonIconName`}>
                    <IconNameSelector
                      label={t('admin.questionnaireManagement.input.iconName')}
                      value={data.iconName}
                      onChange={(value) => setters.setIconName(value)}
                      disabled={false}
                    />
                  </Grid>
                </Fragment>
              )}
            {validationOptions && (
              <Grid item xs={6} key={`createField-validationType`}>
                <ValidateAsInput
                  value={data.validateAs}
                  handleChange={(value) =>
                    (setters.setValidateAs as (value: FieldValidation) => void)(value as FieldValidation)
                  }
                  validationOptions={validationOptions}
                />
              </Grid>
            )}

            <Grid item xs={fieldType === FieldTypes.button ? 6 : 12} key={`createField-isRequiredField`}>
              <SimpleCheckbox
                id='createField-isRequiredField'
                label={t('admin.questionnaireManagement.input.requiredField')}
                checked={!data.optional}
                onChange={(event) => {
                  setters.setRequired(event.target.checked);
                }}
              />
            </Grid>
            <Grid item xs={12} key={`createField-isDisabledField`}>
              <SimpleCheckbox
                id='createField-isDisabledField'
                label={t('admin.questionnaireManagement.input.disabledField')}
                checked={!!data.disabled}
                onChange={(event) => {
                  setters.setDisabled(event.target.checked);
                }}
              />
            </Grid>
            {fieldType === FieldTypes.button && (
              <Grid item xs={6} key={`createField-buttonText-triggerStepNavigation`}>
                <NavigatesToNextStepCheckbox
                  prefix='create-field'
                  checked={!!data.triggerStepNavigation}
                  onChange={setters.setTriggerStepNavigation}
                />
              </Grid>
            )}

            <Grid item xs={6} key={`createField-answerNodeId`}>
              <AnswerNodeIdInput
                selectedNodeId={data.answerNodeId}
                nodeIds={nodeIds}
                handleChange={setters.setAnswerNodeId}
              />
            </Grid>

            {data.fieldType === FieldTypes.signature && (setters as SignatureFieldSetters).setParticipantRole ? (
              <Grid item xs={6} key={`createField-participantRole`}>
                <EditorDropdown
                  label={t('admin.questionnaireManagement.input.participantRole')}
                  selectedOptionValue={data.participantRole}
                  onChange={(newValue) => {
                    (setters as SignatureFieldSetters).setParticipantRole(newValue as ParticipantRoles);
                  }}
                  options={participantRoleOptions}
                />
              </Grid>
            ) : (
              <Grid item xs={6} key={`createField-spacing`} />
            )}

            <TitleInAllLanguagesInputs handleChange={setters.setTitle} value={data.title} />
            <TextInAllLanguagesInputs handleChange={setters.setText} value={data.text} />

            {isTextFieldBlueprint(fieldType) && (
              <PlaceholderInAllLanguagesInputs handleChange={setters.setPlaceholder} value={data.placeholder} />
            )}
            {data.fieldType === FieldTypes.information && (setters as InformationFieldSetters).setVariant && (
              <Grid item xs={6} key={'createField-informationFieldVariant'}>
                <EditorDropdown
                  id='informationFieldVariant'
                  label={t('admin.questionnaireManagement.input.infoFieldStyling')}
                  selectedOptionValue={data?.variant}
                  onChange={(newValue) => {
                    if (newValue !== null) {
                      (setters as InformationFieldSetters).setVariant(newValue as InformationFieldBlueprintVariant);
                    }
                  }}
                  options={infoFieldStylingOptions}
                />
              </Grid>
            )}
            {data.fieldType === FieldTypes.autocomplete &&
              (setters as AddressAutocompleteFieldSetters).setCountryCode &&
              (setters as AddressAutocompleteFieldSetters).setAddressAutocompleteNodeId &&
              (setters as AddressAutocompleteFieldSetters).setPostalCodeOrZip &&
              (setters as AddressAutocompleteFieldSetters).setCity && (
                <AddressAutocompleteFieldEditor
                  questionBlueprint={questionBlueprint}
                  blueprint={data}
                  nodeIds={nodeIds}
                  disabled={false}
                  onIsValidChange={setIsValidBlueprintAutocompleteFields}
                  setters={{
                    setPostalCodeOrZip: (setters as AddressAutocompleteFieldSetters).setPostalCodeOrZip,
                    setStateOrProvince: (setters as AddressAutocompleteFieldSetters).setStateOrProvince,
                    setCountryCode: (setters as AddressAutocompleteFieldSetters).setCountryCode,
                    setCity: (setters as AddressAutocompleteFieldSetters).setCity,
                    setAddressAutocompleteNodeId: (setters as AddressAutocompleteFieldSetters)
                      .setAddressAutocompleteNodeId,
                    setStreetAddress: (setters as AddressAutocompleteFieldSetters).setStreetAddress,
                  }}
                />
              )}
            {(data.fieldType === FieldTypes.number || data.fieldType === FieldTypes.money) &&
              (setters as NumberFieldSetters).setDefaultValue &&
              defaultValueValidationError && (
                <Grid item xs={6} key={'createField-defaultNumberValueEditor'}>
                  <NumberFieldDefaultValueEditor
                    fieldType={data.fieldType}
                    value={data.defaultValue}
                    onChange={(value?: number) => {
                      (setters as NumberFieldSetters).setDefaultValue?.(value);
                    }}
                    validationErrorFromProps={defaultValueValidationError}
                    numericalDataType={data.fieldType === FieldTypes.number ? data.numericalDataType : undefined}
                    disabled={false}
                  />
                </Grid>
              )}

            {data.fieldType === FieldTypes.checkbox && (setters as CheckboxFieldSetters).setDefaultValue && (
              <Grid item xs={6} key={'createField-defaultCheckboxValueEditor'}>
                <BooleanFieldDefaultValueEditor
                  onChange={(value) => {
                    (setters as CheckboxFieldSetters).setDefaultValue(value);
                  }}
                  value={data.defaultValue as boolean}
                  disabled={false}
                />
              </Grid>
            )}

            {data.fieldType === FieldTypes.agree &&
              (setters as AgreeFieldSetters).setModalHeader &&
              (setters as AgreeFieldSetters).setModalText && (
                <AgreeFieldEditor
                  languages={enabledLanguages}
                  disabled={false}
                  consentModalHeader={data.modalHeader || {}}
                  consentText={data.modalText || {}}
                  onConsentModalHeaderChange={(value, language) =>
                    (setters as AgreeFieldSetters).setModalHeader(language, value)
                  }
                  onConsentTextChange={(value, language) =>
                    (setters as AgreeFieldSetters).setModalText(language, value)
                  }
                  spacing={2}
                />
              )}

            <FieldLayoutInputs
              sizeValue={data.layout?.size}
              handleSizeChange={setters.setSize}
              forceNewLineValue={data.layout?.forceNewLine}
              handleForceNewLineChange={setters.setForceNewLine}
            />

            {data.fieldType === FieldTypes.dropdown && (
              <Grid item xs={12} key={`createField-isSearchableField`}>
                <SimpleCheckbox
                  id='createField-isSearchableField'
                  label={t('admin.questionnaireManagement.input.searchable')}
                  checked={!!data.searchable}
                  onChange={(event) => {
                    (setters as DropdownFieldSetters).setSearchable(event.target.checked);
                  }}
                />
              </Grid>
            )}

            {displayAsCard && (
              <Box pt={2}>
                <SimpleCheckbox
                  id='createField-displayInCardPreview'
                  label={t('admin.questionnaireManagement.input.displayInCardPreview')}
                  checked={!!data.displayInCardPreview}
                  onChange={(event) => {
                    setters.setDisplayInCardPreview(event.target.checked);
                  }}
                />
              </Box>
            )}

            <Box pt={2}>
              <AdvancedBlueprintOptionsForField data={data} setters={setters} dataLabelOptions={dataLabelOptions} />
            </Box>
          </Grid>
        </ModalLayout>
      )}
    </Fragment>
  );
}
