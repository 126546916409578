import { UseQueryOptions, UseQueryResult, useQuery } from 'react-query';
import { FindUtilitiesTypeDeclarationsParameters } from '@breathelife/types';
import { notificationSlice } from '../../../ReduxStore/Notification/NotificationSlice';
import { useDispatch } from 'react-redux';

import { QueryId } from '../../common/common.types';
import { getUtilityTypeDeclarations } from '../../../Services/UtilityTypeDeclarationsService';
import i18next from 'i18next';

export function useGetUtilitiesTypeDeclarationQuery(
  utilities: FindUtilitiesTypeDeclarationsParameters['utilities'],
  options?: UseQueryOptions<string>,
): UseQueryResult<string> {
  const query = [QueryId.utilitiesTypeDeclarations, ...utilities];
  const dispatch = useDispatch();
  return useQuery<string>(
    query,
    () => {
      return getUtilityTypeDeclarations({ utilities });
    },
    {
      ...options,
      onError: (error: any) => {
        options?.onError?.(error);
        dispatch(
          notificationSlice.actions.setError({
            message: i18next.t('notifications.failedToFetchEntity'), // TODO proper text
          }),
        );
      },
    },
  );
}
