import { TFunction } from 'i18next';
import { useTranslation } from 'react-i18next';

import { IconName, Permission } from '@breathelife/types';

import { PageLayoutTab } from '../Layouts/PageLayout/PageLayout';
import { PlatformFeatures } from '../Models/PlatformFeatures';
import Urls from '../Navigation/Urls';
import { useCarrierContext } from './useCarrierContext';

export function useAdminTabs(): PageLayoutTab[] {
  const { features } = useCarrierContext();
  const { t } = useTranslation();

  const platform = buildPlatformTab(t, features);
  const product = buildProductTab(t, features);
  const audit = buildAuditTab(t);

  const tabs: PageLayoutTab[] = [];
  if (platform) tabs.push(platform);
  if (product) tabs.push(product);
  if (audit) tabs.push(audit);
  return tabs;
}

function buildPlatformTab(t: TFunction, features: PlatformFeatures): PageLayoutTab | undefined {
  const platformTab: PageLayoutTab = {
    label: t('adminTabs.platform'),
    ['data-testid']: 'admin-tab-platform',
    iconName: IconName.producerCog,
    children: [
      {
        to: Urls.settingsManagement.fullPath,
        label: t('adminTabs.settings'),
        ['data-testid']: 'admin-tab-settings',
        permissions: [Permission.SettingsUpdate],
      },
      {
        to: Urls.themeManagement.fullPath,
        label: t('adminTabs.theme'),
        ['data-testid']: 'admin-tab-theme',
        permissions: [Permission.ThemeUpdate],
      },
      {
        to: Urls.userManagement.fullPath,
        label: t('adminTabs.users'),
        ['data-testid']: 'admin-tab-users',
        permissions: [Permission.UserCreate],
      },
      {
        to: Urls.firmManagement.fullPath,
        label: t('adminTabs.firm'),
        ['data-testid']: 'admin-tab-firm',
        permissions: [Permission.InsuranceFirmUpdate],
      },
    ],
  };

  if (features.thirdPartyIntegrations?.enabled) {
    platformTab.children?.push({
      to: Urls.thirdPartyIntegrations.fullPath,
      label: t('adminTabs.thirdPartyIntegrations'),
      ['data-testid']: 'admin-tab-third-party-integrations',
      permissions: [
        Permission.ThirdPartyIntegrationConfigurationEdit,
        Permission.ThirdPartyIntegrationConfigurationRead,
      ],
    });
  }

  if (features.applicationSupportTools?.enabled) {
    platformTab.children?.push({
      to: Urls.applicationSupportApplications.fullPath,
      label: t('adminTabs.applicationSupport'),
      ['data-testid']: 'admin-tab-application-support',
      permissions: [Permission.ApplicationSupportApplicationsRead],
    });
  }

  return platformTab.children?.length ? platformTab : undefined;
}

function buildProductTab(t: TFunction, features: PlatformFeatures): PageLayoutTab | undefined {
  const productTab: PageLayoutTab = {
    label: t('adminTabs.product'),
    ['data-testid']: 'admin-tab-products',
    iconName: IconName.producerUmbrella,
    children: [
      {
        to: Urls.productManagement.fullPath,
        label: t('adminTabs.product'),
        ['data-testid']: 'admin-tab-product',
        permissions: [Permission.InsuranceProductUpdate],
      },
    ],
  };

  if (features.questionnaireEditor.enabled) {
    productTab.children?.push({
      to: Urls.questionnaireManagement.fullPath,
      label: t('adminTabs.questionnaire'),
      basePathForRelatedRoutes: Urls.questionnaireManagement.fullPath,
      ['data-testid']: 'admin-tab-questionnaire',
      permissions: [Permission.QuestionnaireUpdate],
    });
  }

  if (features.salesDecisionRules?.enabled) {
    productTab.children?.push({
      to: Urls.salesDecisionRulesManagement.fullPath,
      label: t('adminTabs.salesDecisionRules'),
      ['data-testid']: 'admin-tab-sales-decision-rules',
      permissions: [Permission.SalesDecisionRuleCreate],
    });
  }

  if (features.documentTemplate?.enabled) {
    productTab.children?.push({
      to: Urls.documentsManagement.fullPath,
      label: t('pageTitles.documents'),
      ['data-testid']: 'admin-tab-document-templates',
      permissions: [
        Permission.FileTemplateCreate,
        Permission.FileTemplateRead,
        Permission.FileTemplateRemove,
        Permission.FileTemplateUpdate,
      ],
    });
  }

  if (features.questionnaireBuilder?.enabled) {
    productTab.children?.push({
      to: Urls.questionnaireBuilder.fullPath,
      label: t('adminTabs.questionnaireBuilder'),
      ['data-testid']: 'admin-tab-questionnaire-builder',
      permissions: [Permission.QuestionnaireUpdate],
    });
  }

  if (features.entityMappings?.enabled) {
    productTab.children?.push({
      to: Urls.entityManagementMapping.fullPath,
      basePathForRelatedRoutes: Urls.entityManagement.fullPath,
      label: t('adminTabs.entityMappingManagement'),
      ['data-testid']: 'admin-tab-entity-mapping-management',
      permissions: [Permission.EntityMappingUpdate],
    });
  }

  return productTab.children?.length ? productTab : undefined;
}

function buildAuditTab(t: TFunction): PageLayoutTab | undefined {
  const auditTab: PageLayoutTab = {
    label: 'Audit',
    ['data-testid']: 'admin-tab-audit',
    iconName: IconName.producerCogAndSearch,
    children: [
      {
        to: Urls.auditLogs.fullPath,
        label: t('adminTabs.auditLogs'),
        ['data-testid']: 'admin-tab-audit-logs',
        permissions: [Permission.AuditLogsRead],
      },
    ],
  };

  return auditTab.children?.length ? auditTab : undefined;
}
