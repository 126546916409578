import { PropsWithChildren, ReactElement, createContext, useState } from 'react';

type Context = {
  questionnaireVersionId: string;
  setQuestionnaireVersion: (qvid: string) => void;
};

export const QuestionnaireVersionFilterContext = createContext<Context>({
  questionnaireVersionId: '',
  setQuestionnaireVersion: () => {},
});

export function QuestionnaireVersionFilterContextProvider(props: PropsWithChildren<unknown>): ReactElement {
  const [id, setId] = useState('');

  const value = {
    questionnaireVersionId: id,
    setQuestionnaireVersion: setId,
  };

  return (
    <QuestionnaireVersionFilterContext.Provider value={value}>
      {props.children}
    </QuestionnaireVersionFilterContext.Provider>
  );
}
