import _ from 'lodash';
import { useCallback, useEffect, useMemo, useRef, useState } from 'react';

import {
  OnAnswerChange,
  OnBulkAnswerClear,
  QuestionnaireEngine,
  RepeatedIndices,
} from '@breathelife/questionnaire-engine';
import { VersionedAnswers, YesNoValue } from '@breathelife/types';

import { Application } from '../../Models/Application';
import { useCarrierContext } from '../../Hooks';
import { SaveAssistedApplicationAnswers } from '../../ReactQuery/AssistedApplication/assistedApplication.mutations';

type UseAutoSaveAnswersProps = {
  application: Application;
  questionnaireEngine: QuestionnaireEngine;
  saveAnswers: SaveAssistedApplicationAnswers;
  isSavingAnswers: boolean;
  shouldRefreshAnswers: boolean;
  nodesToRefresh: Record<string, unknown>[];
  onAfterRefreshAnswers: () => void;
};

type UseAutoSaveAnswersOutput = {
  answers: VersionedAnswers;
  onAnswerChange: OnAnswerChange;
  onBulkAnswerClear: OnBulkAnswerClear;
  hasUnsavedChanges: boolean;
  manuallySaveAnswers: (isClosing?: boolean, isManuallySavingAnswers?: boolean) => Promise<void>;
  removeItemFromCollection: (
    surrogateId: string,
    collectionNodeId: string,
    collectionSurrogateIdNodeId: string,
  ) => void;
};

export function useAutoSaveAnswers(props: UseAutoSaveAnswersProps): UseAutoSaveAnswersOutput {
  const {
    application,
    questionnaireEngine,
    saveAnswers,
    isSavingAnswers,
    shouldRefreshAnswers,
    nodesToRefresh,
    onAfterRefreshAnswers,
  } = props;

  const { features } = useCarrierContext();

  const [answers, setAnswers] = useState<VersionedAnswers>(() => {
    return questionnaireEngine.getVersionedAnswersWithDefaultValues(
      new VersionedAnswers({
        v1: application.answers || {},
        v2: application.answersV2 || {},
      }),
    );
  });

  const [shouldSaveAnswers, setShouldSaveAnswers] = useState(false);
  const setShouldSaveAnswersDebounced = useMemo(() => _.debounce(setShouldSaveAnswers, 3000), []);

  const nodeIdsRef = useRef(new Set<string>());

  const [answersChangeCount, setAnswersChangeCount] = useState(0);
  const [answersStateCount, setAnswersStateCount] = useState(-1); // set to -1 to make sure answersStateCount goes back at 0 on first mount

  /*
  answersChangeCount and answersStateCount tracks each field input change and each answers state change.
  They are reset every time answers are saved (when saveAnswers gets called)
  Their purpose is to determined when it's dirty (i.e an answer was changed but not yet saved)
  but also to know if an answer was changed and not yet reflected in the current answer state
  since questionnaireEngine.updateAnswer is slow (heavy computation)
  This is only useful to know at the time the user clicks to close the assisted application.
  */
  const isDirty = answersChangeCount > 0;
  const answersStateWillUpdate = answersChangeCount > answersStateCount;

  // counts all changes of answers state
  // this hooks should never contain any other logic that this, there should be no need to update it.
  useEffect(() => {
    setAnswersStateCount((prev) => prev + 1);
  }, [answers]);

  // answers refresh
  useEffect(() => {
    if (shouldRefreshAnswers) {
      setAnswersStateCount(-1); // set to -1 to make sure answersStateCount goes back at 0 once the answers are refreshed
      setAnswers(
        questionnaireEngine.getVersionedAnswersWithDefaultValues(
          new VersionedAnswers({
            v1: application.answers,
            v2: application.answersV2,
          }),
        ),
      );
      onAfterRefreshAnswers();
    } else if (nodesToRefresh.length > 0) {
      // TODO: MAYBE CLONE with something else. instead of using ...answers.v1
      let updatedAnswers: VersionedAnswers = new VersionedAnswers({ v1: { ...answers.v1 }, v2: { ...answers.v2 } });
      nodesToRefresh.forEach((node) => {
        updatedAnswers = questionnaireEngine.updateAnswer(
          updatedAnswers,
          node.nodeId as string,
          node.value,
          undefined,
          node.collectionInstanceIdentifiers as RepeatedIndices,
        );
      });
      setAnswers(updatedAnswers);
      setShouldSaveAnswers(false);
      onAfterRefreshAnswers();
    }
  }, [application, questionnaireEngine, shouldRefreshAnswers]);

  function reset(): void {
    nodeIdsRef.current.clear();
    setShouldSaveAnswers(false);
    setAnswersChangeCount(0);
    setAnswersStateCount(0);
  }

  // effect to save answers
  useEffect(() => {
    if (!shouldSaveAnswers) return;
    const canSaveAnswers = isDirty && !answersStateWillUpdate && !isSavingAnswers;
    if (!canSaveAnswers) return;

    void saveAnswers({
      applicationId: application.id,
      answers: answers.v1,
      answersV2: answers.v2,
      updatedNodeIds: [...nodeIdsRef.current],
      isClosing: false,
      isManuallySavingAnswers: false,
    });

    reset();
  }, [answers, saveAnswers, shouldSaveAnswers, isDirty, answersStateWillUpdate, isSavingAnswers]);

  const onAnswerChange: OnAnswerChange = useCallback(
    (nodeId, changedAnswer, effects, repeatedIndices, __triggerStepNavigation, updateWithDefaultValue) => {
      nodeIdsRef.current.add(nodeId);

      setAnswersChangeCount((prev) => prev + 1);

      setAnswers((previousAnswers: VersionedAnswers): VersionedAnswers => {
        let updatedVersionedAnswers = questionnaireEngine.updateAnswer(
          previousAnswers,
          nodeId,
          changedAnswer,
          effects,
          repeatedIndices,
        );

        if (updateWithDefaultValue) {
          updatedVersionedAnswers = questionnaireEngine.getVersionedAnswersWithDefaultValues(updatedVersionedAnswers);
        }

        return updatedVersionedAnswers;
      });

      // Skip debounce and save immediately for nodes in array
      const nodeIdsSkippingDebounce = features.assistedApplication?.nodeIdsSkippingDebounce;
      if (
        _.isArray(nodeIdsSkippingDebounce) &&
        nodeIdsSkippingDebounce.includes(nodeId) &&
        changedAnswer === YesNoValue.no
      ) {
        setShouldSaveAnswers(true);
        setShouldSaveAnswersDebounced.cancel();
      } else {
        setShouldSaveAnswersDebounced(true);
      }
    },
    [setShouldSaveAnswersDebounced, questionnaireEngine],
  );

  const manuallySaveAnswers = useCallback(
    async (isClosing = false, isManuallySavingAnswers = false) => {
      if (isSavingAnswers) return;

      setShouldSaveAnswersDebounced.cancel();

      await saveAnswers({
        applicationId: application.id,
        answers: answers.v1,
        answersV2: answers.v2,
        updatedNodeIds: [...nodeIdsRef.current],
        isClosing,
        isManuallySavingAnswers,
      });

      reset();
    },
    [answers, isSavingAnswers, nodeIdsRef.current, setShouldSaveAnswersDebounced],
  );

  const onBulkAnswerClear: OnBulkAnswerClear = useCallback(
    (fields, effects, repeatedIndices) => {
      // TODO: MAYBE CLONE with something else. instead of using ...answers.v1
      let updatedAnswers: VersionedAnswers = new VersionedAnswers({ v1: { ...answers.v1 }, v2: { ...answers.v2 } });

      for (const field of fields) {
        const nodeId = field.nodeId;
        nodeIdsRef.current.add(nodeId);

        // TODO: Maybe return both references ?
        updatedAnswers = questionnaireEngine.updateAnswer(updatedAnswers, nodeId, undefined, effects, repeatedIndices);
      }

      // TODO: Maybe have just one state for both.
      setAnswers(updatedAnswers);

      void saveAnswers({
        applicationId: application.id,
        answers: updatedAnswers.v1,
        answersV2: updatedAnswers.v2,
        updatedNodeIds: [...nodeIdsRef.current],
        isClosing: false,
        isManuallySavingAnswers: true,
      });

      reset();
    },
    [answers, nodeIdsRef.current, questionnaireEngine],
  );

  const removeItemFromCollection = useCallback(
    (surrogateId: string, collectionNodeId: string, collectionSurrogateIdNodeId: string) => {
      setAnswers((previousAnswers: VersionedAnswers) => {
        const repeatedCollectionAnswers = questionnaireEngine.getRepeatedAnswers(
          previousAnswers,
          collectionNodeId,
          [collectionSurrogateIdNodeId],
          {},
        );

        const collectionItem = repeatedCollectionAnswers?.[surrogateId];
        if (!collectionItem) {
          throw new Error(
            `Could not find item ${surrogateId} from collection '${collectionNodeId}' for application: ${application.id}`,
          );
        }

        setAnswersChangeCount((prev) => prev + 1);

        const [hasRemovedItem, updatedAnswersWithUnsettedAnswer] = questionnaireEngine.unsetAnswer(
          previousAnswers,
          collectionNodeId,
          {
            [collectionNodeId]: collectionItem.repeatedIndex,
          },
        );

        if (!hasRemovedItem) {
          throw new Error(
            `Unable to remove item '${surrogateId}' at index '${collectionItem.repeatedIndex}' from collection '${collectionNodeId}' for application: ${application.id}`,
          );
        }

        const [, updatedVersionedAnswersWithRemovedUndefined] =
          questionnaireEngine.removeUndefinedAnswersFromCollection(collectionNodeId, updatedAnswersWithUnsettedAnswer);
        return updatedVersionedAnswersWithRemovedUndefined;
      });

      setShouldSaveAnswers(true);
    },
    [questionnaireEngine],
  );

  return {
    answers,
    onAnswerChange,
    onBulkAnswerClear,
    hasUnsavedChanges: isDirty,
    manuallySaveAnswers,
    removeItemFromCollection,
  };
}
