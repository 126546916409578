import { Box } from '@breathelife/mui';
import { ReactElement, ChangeEvent, useCallback } from 'react';
import { useTranslation } from 'react-i18next';

import { SubmitButton } from '../../../../Components/Button/SubmitButton';
import { Checkbox } from '../../../../Components/Checkbox/Checkbox';
import Typography from '../../../../Components/Typography';
import { useCarrierContext, useDispatch } from '../../../../Hooks';
import { ModalLayout } from '../../../../Layouts/Modal/ModalLayout';
import { assistedApplicationSlice } from '../../../../ReduxStore/AssistedApplication/AssistedApplicationSlice';

type InfoMessageTrackESignatureModalProps = {
  isOpen: boolean;
  onClose: () => void;
};

export function InfoMessageTrackESignatureModal(props: InfoMessageTrackESignatureModalProps): ReactElement {
  const { isOpen, onClose } = props;
  const { features } = useCarrierContext();
  const dispatch = useDispatch();
  const { t } = useTranslation();

  const onCheckboxChange = useCallback(
    (event: ChangeEvent<HTMLInputElement>) => {
      dispatch(assistedApplicationSlice.actions.setDisableTrackESignInfoModal(event.target.checked));
    },
    [dispatch],
  );

  const onClickClose = useCallback(() => {
    onClose();

    // Run delighted integration if enabled
    if (features.delighted.enabled) {
      document.dispatchEvent(new Event('show-delighted-survey'));
    }
  }, [features.delighted.enabled, onClose]);

  return (
    <ModalLayout
      maxWidth='sm'
      isOpen={isOpen}
      closeModal={onClickClose}
      hideCancel
      title={t('modals.assistedApplication.trackESignature.title')}
      submitButton={
        <SubmitButton onClick={onClickClose}>
          {t('modals.assistedApplication.trackESignature.confirmButton')}
        </SubmitButton>
      }
    >
      <Box mb={4}>
        <Typography variant='body1'>{t('modals.assistedApplication.trackESignature.body')}</Typography>
      </Box>
      <Box>
        <Checkbox label={t('modals.assistedApplication.trackESignature.checkboxLabel')} onChange={onCheckboxChange} />
      </Box>
    </ModalLayout>
  );
}
