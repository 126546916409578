import { TableRow } from '@breathelife/mui';

import styled from '../../Styles/themed-styled-components';

export const StyledTableContainer = styled.div<{ autoHeight: boolean }>`
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  justify-content: space-between;
  overflow: auto;
`;

export const StyledTableRow = styled(TableRow)`
  :hover {
    cursor: pointer;
  }
  &:focus:not(:active) {
    border: 2px solid ${(props) => props.theme.colors.tertiary.default} !important;
  }
`;
