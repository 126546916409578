import { ReactElement, MutableRefObject, Fragment, useState, useContext } from 'react';
import { useGetEntityQuery } from '../../../../ReactQuery/Admin/Entities/entities.queries';
import { useUpdateEntityMutation } from '../../../../ReactQuery/Admin/Entities/entities.mutations';
import { Button } from '../../../../Components/Button/Button';
import styled from '../../../../Styles/themed-styled-components';
import { DeleteEntityModal } from './DeleteEntityModal';
import { useTranslation } from 'react-i18next';
import { useFetchQuestionnaireVersionQuery } from '../../../../ReactQuery/Admin/Questionnaire/questionnaireVersion.queries';
import { QuestionnaireVersionFilterContext } from '../../../../Context/QuestionnaireVersionFilterContextProvider';

const ButtonContainer = styled.div`
  display: flex;
  justify-content: end;
  column-gap: 15px;
`;

type Props = {
  entityId: string;
  entityCode: MutableRefObject<string>;
  onDelete: () => void;
};

export function UpdateActionButtons(props: Props): ReactElement {
  const { questionnaireVersionId } = useContext(QuestionnaireVersionFilterContext);
  const { data: entity } = useGetEntityQuery(props.entityId);
  const { data: questionnaireVersion } = useFetchQuestionnaireVersionQuery(questionnaireVersionId);
  const { mutate: updateEntityMutation, isLoading } = useUpdateEntityMutation();
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);
  const { t } = useTranslation();

  const onUpdateMutation = (): void => {
    updateEntityMutation({
      id: entity?.id || '',
      questionnaireVersionId: entity?.questionnaireVersionId || '',
      name: entity?.name || '',
      schema: props.entityCode.current,
    });
  };

  const onDelete = (): void => {
    setIsDeleteModalOpen(false);
    props.onDelete();
  };

  const disabled = !questionnaireVersion?.isDraft || !entity || isLoading;

  return (
    <Fragment>
      <ButtonContainer>
        <Button variant='outlined' disabled={disabled} onClick={() => setIsDeleteModalOpen(true)}>
          {t('admin.entityMappings.deleteButtonLabel')}
        </Button>

        <Button variant='contained' color='primary' disabled={disabled} onClick={onUpdateMutation}>
          {t('admin.entityMappings.saveButtonLabel')}
        </Button>
      </ButtonContainer>

      <DeleteEntityModal
        entity={entity}
        isOpen={isDeleteModalOpen}
        onDelete={onDelete}
        onCancel={() => setIsDeleteModalOpen(false)}
      />
    </Fragment>
  );
}
