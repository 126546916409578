import { forwardRef, ReactElement, useCallback, useContext, useEffect, useState } from 'react';
import { DraggableProvidedDraggableProps, DraggableProvidedDragHandleProps } from '@hello-pangea/dnd';
import { useTranslation } from 'react-i18next';

import { Box, Chip, Grid, Tooltip } from '@breathelife/mui';
import { ValidationError } from '@breathelife/questionnaire-engine';
import {
  BlueprintConditionValue,
  IconName,
  Language,
  Localizable,
  SelectOptionBlueprint,
  SelectOptionPartIdentifier,
} from '@breathelife/types';
import { Icon, Input } from '@breathelife/ui-components';

import { SubmitButton } from '../../../../../Components/Button/SubmitButton';
import { ConditionView } from '../../../../../Components/Conditions/ConditionView';
import { MenuAction } from '../../../../../Components/PopupMenu/PopupMenu';
import { Strikeable } from '../../../../../Components/Typography';
import { getOptionIdFieldValidationError } from '../../../../../Helpers/inputValidation/form/createSelectOption';
import { QuestionnaireNodeIds } from '../../../../../Helpers/questionnaireEditor/questionnaireNodeIds';
import { useCarrierContext } from '../../../../../Hooks/useCarrierContext';
import { ModalLayout } from '../../../../../Layouts/Modal/ModalLayout';
import { TextInput } from '../../../../../Pages/Admin/Questionnaire/QuestionnaireEditor/Components/TextInput';
import {
  useRemoveQuestionnaireElementBlueprint,
  useUpdateQuestionnaireElementBlueprint,
} from '../../../../../ReactQuery/Admin/Questionnaire/questionnaireVersion.mutations';
import { QuestionnaireVersionDataContext } from '../../ContextProvider/QuestionnaireVersionDataContextProvider';
import { ActionButtons, RemovableResources } from '../Components/ActionButtons';
import { useHighlightedTextInBlueprintElement } from '../Hooks/useHighlightedTextInBlueprintElement';
import { ConditionsEditorModal } from './ConditionsEditor';

const CHIP_STYLES = { height: '16px', fontSize: '.85rem' };

type Props = {
  blueprint: SelectOptionBlueprint;
  partIdentifier: SelectOptionPartIdentifier;
  selectedLanguage: Language;
  parentHidden: boolean;
  dragHandleProps?: DraggableProvidedDragHandleProps;
  draggableProps: DraggableProvidedDraggableProps;
  isDraggable: boolean;
  isDefaultValue?: boolean;
  defaultValueButtonProps?: {
    onClick: () => void;
  };
  questionnaireNodeIds: QuestionnaireNodeIds;
  collectionContext: string[];
  condition?: BlueprintConditionValue;
  existingOptionIds: string[];
};

type OptionInformation = {
  optionId: string;
  validation?: ValidationError;
};

export const SelectOptionBlueprintEditor = forwardRef<HTMLDivElement, Props>((props, ref) => {
  const {
    blueprint,
    partIdentifier,
    selectedLanguage,
    parentHidden,
    dragHandleProps,
    draggableProps,
    isDraggable,
    isDefaultValue,
    defaultValueButtonProps,
    questionnaireNodeIds,
    collectionContext,
    condition,
    existingOptionIds,
  } = props;
  const textHighlights = useHighlightedTextInBlueprintElement({ partIdentifier, blueprint });

  const [isInitialized, setIsInitialized] = useState(false);
  const [optionText, setOptionText] = useState<Partial<Localizable>>({});
  const [isConditionModalOpen, setIsConditionModalOpen] = useState(false);
  const [isOptionsModalOpen, setIsOptionsModalOpen] = useState(false);

  const { questionnaireVersionId } = useContext(QuestionnaireVersionDataContext);

  const removeBlueprint = useRemoveQuestionnaireElementBlueprint(questionnaireVersionId);
  const updateBlueprint = useUpdateQuestionnaireElementBlueprint(questionnaireVersionId);

  const { languageSettings } = useCarrierContext();
  const enabledLanguages = languageSettings.enabledLanguages;

  useEffect(() => {
    if (isInitialized) return;

    const initialOptionText = blueprint.text;
    if (initialOptionText) {
      setOptionText(initialOptionText);
    }

    setIsInitialized(true);
  }, [blueprint, isInitialized]);

  const disabled = parentHidden || !!blueprint.hidden;
  const { t } = useTranslation();

  const draggableBtnProps = {
    handleButtonProps: { dragHandleProps },
    disabled: parentHidden,
    dragDisabled: !isDraggable,
  };

  let removeItemButtonProps = undefined;
  let hideItemButtonProps = undefined;
  if (blueprint.isCustom) {
    // Custom select options can only be removed.
    removeItemButtonProps = {
      resourceName: RemovableResources.selectOption,
      onRemoveItemClick: () => {
        void removeBlueprint(partIdentifier);
      },
    };
  } else {
    // Non-custom select options can only be hidden.
    hideItemButtonProps = {
      isItemHidden: !!blueprint.hidden,
      onHideItemButtonClick: (isHidden: boolean) => {
        void updateBlueprint({
          partIdentifier,
          update: { property: 'hidden', value: isHidden },
        });
      },
    };
  }

  const handleAdditionalActions = (): MenuAction[] | undefined => {
    const additionalActions: MenuAction[] = [];
    if (defaultValueButtonProps) {
      additionalActions.push({
        tag: 'MenuAction',
        onClick: () => defaultValueButtonProps.onClick(),
        icon: <Icon name={isDefaultValue ? IconName.close : IconName.checkmark} size='10px' />,
        label: isDefaultValue ? 'Remove default' : 'Make default',
        disabled: false,
      });
    }
    additionalActions.push({
      tag: 'MenuAction',
      onClick: () => setIsConditionModalOpen(true),
      icon: <Icon name={IconName.threeDots} size='10px' />,
      label: 'Visible if',
      disabled: false,
    });
    additionalActions.push({
      tag: 'MenuAction',
      onClick: () => {
        setIsOptionsModalOpen(true);
      },
      icon: <Icon name={IconName.edit} size='10px' />,
      label: 'Rename OptionsId',
      disabled: false,
    });
    return additionalActions;
  };

  const initialOptionPartName = blueprint.partName;
  const handleValidation = useCallback(
    (value: string) => {
      return getOptionIdFieldValidationError(value, existingOptionIds, enabledLanguages);
    },
    [existingOptionIds, enabledLanguages],
  );

  return (
    <Grid container justify='space-between' alignItems='center' ref={ref} {...draggableProps}>
      <Grid item xs={isDefaultValue || condition ? 7 : 9}>
        <Strikeable strike={!!blueprint.hidden}>
          <TextInput
            highlighted={!!textHighlights.text}
            value={optionText[selectedLanguage] ?? ''}
            onChange={(event) => {
              const value = event.target.value;
              setOptionText((previousValue) => ({ ...previousValue, [selectedLanguage]: value }));
            }}
            disabled={disabled}
            onBlur={() => {
              void updateBlueprint({
                partIdentifier,
                update: { property: 'text', value: optionText },
              });
            }}
          />
        </Strikeable>
      </Grid>

      <Box style={{ display: 'flex', flexDirection: 'column', flexWrap: 'wrap', paddingTop: '8px' }}>
        {isDefaultValue && (
          <Box height='50%'>
            <Chip label='Default' size='small' variant='outlined' style={CHIP_STYLES} />{' '}
          </Box>
        )}
        {condition && (
          <Box height='50%' paddingTop='2px'>
            <Tooltip
              arrow
              placement='top'
              title={<ConditionView condition={condition} selectedLanguage={selectedLanguage} />}
            >
              <Chip label='Visible if' size='small' variant='outlined' style={CHIP_STYLES} />
            </Tooltip>
          </Box>
        )}
      </Box>

      <ActionButtons
        removeItemButtonProps={removeItemButtonProps}
        hideItemButtonProps={hideItemButtonProps}
        additionalActions={handleAdditionalActions()}
        {...draggableBtnProps}
      />
      {isConditionModalOpen && (
        <ConditionsEditorModal
          save={(condition) => {
            void updateBlueprint({
              partIdentifier,
              update: { property: 'visible', value: condition },
            });
          }}
          closeModal={() => setIsConditionModalOpen(false)}
          questionnaireNodeIds={questionnaireNodeIds}
          collectionContext={collectionContext}
          existingCondition={blueprint.visible}
          editHeadingText={t('admin.questionnaireManagement.rules.visibility.edit')}
          selectedLanguage={selectedLanguage}
        />
      )}
      {isOptionsModalOpen && (
        <OptionIdModal
          optionId={initialOptionPartName}
          onSave={(optionId: string) => {
            void updateBlueprint({ partIdentifier, update: { property: 'partName', value: optionId } });
          }}
          onCloseModal={() => setIsOptionsModalOpen(false)}
          validate={handleValidation}
        />
      )}
    </Grid>
  );
});

type OptionIdModalProps = {
  optionId: string;
  onSave: (optionId: string) => void;
  onCloseModal: () => void;
  validate: (value: string) => ValidationError | undefined;
};

function OptionIdModal(props: OptionIdModalProps): ReactElement {
  const { t } = useTranslation();
  const [state, setState] = useState<OptionInformation>({ optionId: props.optionId });

  return (
    <ModalLayout
      maxWidth='md'
      isOpen={true}
      closeModal={props.onCloseModal}
      title={t('admin.questionnaireManagement.input.renameOption.title')}
      submitButton={
        <SubmitButton
          disabled={!!state.validation}
          onClick={() => {
            props.onSave(state.optionId);
            props.onCloseModal();
          }}
          data-testid='questionnaire-editor-select-option-create'
        >
          {t('cta.save')}
        </SubmitButton>
      }
    >
      <Box pt={1}>
        <Input
          required
          inputVariant='outlined'
          label={t('admin.questionnaireManagement.input.renameOption.optionId')}
          value={state.optionId}
          validationError={state.validation}
          onChange={(event) => {
            const value = event.target.value;
            setState({ optionId: value, validation: props.validate(value) });
          }}
        />
      </Box>
    </ModalLayout>
  );
}
