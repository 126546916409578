import dayjs from 'dayjs';
import i18next from 'i18next';
import _ from 'lodash';
import { ReactElement } from 'react';
import { Column } from 'react-table';
import styled from '../../Styles/themed-styled-components';

import {
  ApplicationMode,
  ESignCeremonyStatus,
  LeadsTableColumnName,
  LeadTableColumnInfo,
  LeadTableColumnKeys,
  LineOfBusinessName,
  Permission,
} from '@breathelife/types';

import { ContactedIcon } from '../../Assets/Icons/ContactedIcon';
import { ApplicationModeBadge } from '../../Components/Badge/ApplicationModeBadge';
import { ApplicationLineOfBusinessBadge } from '../../Components/Badge/ApplicationLineOfBusinessBadge';
import { ESignCeremonyStatusBadge } from '../../Components/Badge/ESignCeremonyStatusBadge';
import { StatusBadge } from '../../Components/Badge/StatusBadge';
import { AssignToSelection } from '../../Components/Columns/AssignToSelection';
import { Email } from '../../Components/Columns/Email';
import { CommunicationView } from '../../Components/CommunicationView/CommunicationView';
import { setMonthShorthandToEnglishFormat } from '../../Helpers/datetime';
import { Lead } from '../../Models/Lead';
import { Application } from '../../Models/Application';

setMonthShorthandToEnglishFormat();

export type RenderedLeadTableColumnInfo = Column<Lead>;

export const ContactedCellWrapper = styled.div`
  width: '125px';
  text-align: 'center';
`;

export const columnToLeadsFieldsMapping: {
  [Column in LeadsTableColumnName]: keyof Lead | Array<keyof Lead> | ('applications' | 'eSignCeremonies')[];
} = {
  [LeadsTableColumnName.applicationMode]: 'applications',
  [LeadsTableColumnName.assignedToName]: 'user',
  [LeadsTableColumnName.communication]: 'leads-communication',
  [LeadsTableColumnName.contacted]: 'contacted',
  [LeadsTableColumnName.createdAt]: 'createdAt',
  [LeadsTableColumnName.email]: 'email',
  [LeadsTableColumnName.fullName]: ['firstName', 'lastName'],
  [LeadsTableColumnName.lineOfBusiness]: 'applications',
  [LeadsTableColumnName.ownerDetails]: 'ownerDetails',
  [LeadsTableColumnName.phoneNumber]: 'phoneNumber',
  [LeadsTableColumnName.progress]: 'progress',
  [LeadsTableColumnName.refNo]: 'applications',
  [LeadsTableColumnName.refNoIncrement]: 'applications',
  [LeadsTableColumnName.referralCode]: 'referralCode',
  [LeadsTableColumnName.signatureStatus]: ['applications', 'eSignCeremonies'],
  [LeadsTableColumnName.status]: 'status',
  [LeadsTableColumnName.updatedAt]: 'updatedAt',
};

export const columnToSortingFieldMapping: Partial<{
  // TODO: FIXME: backend is re-converting applicationMode into mode which is the type of the application. We should be using mode here
  [Column in LeadsTableColumnName]:
    | keyof Pick<Lead, 'createdAt' | 'updatedAt' | 'status' | 'contacted' | 'email' | 'firstName' | 'applicationRefNo'>
    | keyof Pick<Application, 'lineOfBusiness'>
    | 'applicationMode';
}> = {
  [LeadsTableColumnName.applicationMode]: 'applicationMode',
  [LeadsTableColumnName.contacted]: 'contacted',
  [LeadsTableColumnName.createdAt]: 'createdAt',
  [LeadsTableColumnName.email]: 'email',
  [LeadsTableColumnName.fullName]: 'firstName',
  [LeadsTableColumnName.lineOfBusiness]: 'lineOfBusiness',
  [LeadsTableColumnName.refNoIncrement]: 'applicationRefNo',
  [LeadsTableColumnName.status]: 'status',
  [LeadsTableColumnName.updatedAt]: 'updatedAt',
};

const columnToDefaultTitleMapping: { [Column in LeadsTableColumnName]: string } = {
  [LeadsTableColumnName.createdAt]: LeadTableColumnKeys.creationDate,
  [LeadsTableColumnName.updatedAt]: LeadTableColumnKeys.updatedAt,
  [LeadsTableColumnName.assignedToName]: LeadTableColumnKeys.assignedTo,
  [LeadsTableColumnName.status]: LeadTableColumnKeys.status,
  [LeadsTableColumnName.communication]: LeadTableColumnKeys.bestTimeToReach,
  [LeadsTableColumnName.contacted]: LeadTableColumnKeys.contacted,
  [LeadsTableColumnName.email]: LeadTableColumnKeys.email,
  [LeadsTableColumnName.fullName]: LeadTableColumnKeys.clientName,
  [LeadsTableColumnName.phoneNumber]: LeadTableColumnKeys.phoneNumber,
  [LeadsTableColumnName.progress]: LeadTableColumnKeys.progress,
  [LeadsTableColumnName.refNo]: LeadTableColumnKeys.applicationRefNo,
  [LeadsTableColumnName.referralCode]: LeadTableColumnKeys.referralCode,
  [LeadsTableColumnName.ownerDetails]: LeadTableColumnKeys.ownerDetails,
  [LeadsTableColumnName.refNoIncrement]: LeadTableColumnKeys.refNoIncrement,
  [LeadsTableColumnName.applicationMode]: LeadTableColumnKeys.applicationMode,
  [LeadsTableColumnName.lineOfBusiness]: LeadTableColumnKeys.lineOfBusiness,
  [LeadsTableColumnName.signatureStatus]: LeadTableColumnKeys.signatureStatus,
};

type ColumnAccessorReturnTypes = {
  [LeadsTableColumnName.createdAt]: Lead['createdAt'];
  [LeadsTableColumnName.updatedAt]: Lead['updatedAt'];
  [LeadsTableColumnName.assignedToName]: Lead;
  [LeadsTableColumnName.status]: Lead['status'];
  [LeadsTableColumnName.communication]: Lead['leads-communication'];
  [LeadsTableColumnName.contacted]: boolean;
  [LeadsTableColumnName.email]: Lead['email'];
  [LeadsTableColumnName.fullName]: string;
  [LeadsTableColumnName.phoneNumber]: Lead['phoneNumber'];
  [LeadsTableColumnName.progress]: Lead['progress'];
  [LeadsTableColumnName.refNo]: string | null | undefined;
  [LeadsTableColumnName.referralCode]: Lead['referralCode'];
  [LeadsTableColumnName.ownerDetails]: Lead['ownerDetails'];
  [LeadsTableColumnName.refNoIncrement]: number | null | undefined;
  [LeadsTableColumnName.applicationMode]: ApplicationMode;
  [LeadsTableColumnName.lineOfBusiness]: LineOfBusinessName | undefined;
  [LeadsTableColumnName.signatureStatus]: ESignCeremonyStatus;
};

type StandardColumnsDefault = {
  [name in LeadsTableColumnName]: {
    permission?: Permission;
    Cell: (data: { value: ColumnAccessorReturnTypes[name] }) => ReactElement | string | null | undefined;
    accessor: (row: Lead) => ColumnAccessorReturnTypes[name];
  };
};

function getLeadApplication(lead: Lead): Application {
  return (
    lead.applications.find((application) => {
      return application.id === lead.applicationId;
    }) || lead.applications[0]
  );
}

const standardColumnsDefault: StandardColumnsDefault = {
  [LeadsTableColumnName.createdAt]: {
    accessor: (lead: Lead) => lead[LeadsTableColumnName.createdAt],
    Cell: ({ value }) => (value ? dayjs(value).format('MMM D, YYYY') : null),
  },
  [LeadsTableColumnName.updatedAt]: {
    accessor: (lead: Lead) => lead[LeadsTableColumnName.updatedAt],
    Cell: ({ value }) => (value ? dayjs(value).format('MMM D, YYYY') : null),
  },
  [LeadsTableColumnName.assignedToName]: {
    accessor: (lead: Lead) => lead,
    Cell: ({ value }) => {
      return <AssignToSelection lead={value} />;
    },
    permission: Permission.LeadAssignRead,
  },
  [LeadsTableColumnName.status]: {
    accessor: (lead: Lead) => lead[LeadsTableColumnName.status],
    Cell: ({ value }) => <StatusBadge status={value as Lead['status']} />,
  },
  [LeadsTableColumnName.signatureStatus]: {
    accessor: (lead: Lead) => getLeadApplication(lead)?.eSignCeremonies?.[0]?.status,
    Cell: ({ value }) => (value ? <ESignCeremonyStatusBadge status={value} /> : null),
  },
  [LeadsTableColumnName.communication]: {
    accessor: (lead: Lead) => lead[LeadsTableColumnName.communication],
    Cell: ({ value }) => <CommunicationView format='12' communication={value} />,
  },
  [LeadsTableColumnName.contacted]: {
    accessor: (lead: Lead) => lead[LeadsTableColumnName.contacted],
    Cell: ({ value }) =>
      value ? (
        <ContactedCellWrapper>
          <ContactedIcon role='img' aria-label={i18next.t('leadDetailDrawer.connectedWithClient')} />
        </ContactedCellWrapper>
      ) : null,
  },
  [LeadsTableColumnName.fullName]: {
    accessor: (lead: Lead) => `${lead.firstName || ''}  ${lead.lastName || ''}`,
    Cell: ({ value }) => {
      const dashesSeparatedName = value.trim().split(/\s+/).join('-');
      return value ? <span data-testid={`lead-name-${dashesSeparatedName}`}>{value}</span> : null;
    },
  },
  [LeadsTableColumnName.email]: {
    accessor: (lead: Lead) => lead[LeadsTableColumnName.email],
    Cell: ({ value }) => <Email email={value} />,
  },
  [LeadsTableColumnName.phoneNumber]: {
    accessor: (lead: Lead) => lead[LeadsTableColumnName.phoneNumber],
    Cell: ({ value }) => <span>{value}</span>,
  },
  [LeadsTableColumnName.progress]: {
    accessor: (lead: Lead) => lead[LeadsTableColumnName.progress],
    Cell: ({ value }) => <span>{_.isNumber(value) ? `${value}%` : i18next.t('notApplicable')}</span>,
  },
  [LeadsTableColumnName.refNo]: {
    accessor: (lead: Lead) => getLeadApplication(lead)?.refNo,
    Cell: ({ value }) => <span>{value}</span>,
  },
  [LeadsTableColumnName.refNoIncrement]: {
    accessor: (lead: Lead) => getLeadApplication(lead)?.refNoIncrement,
    Cell: ({ value }) => <span>{value}</span>,
  },
  [LeadsTableColumnName.referralCode]: {
    accessor: (lead: Lead) => lead[LeadsTableColumnName.referralCode],
    Cell: ({ value }) => <span>{value}</span>,
  },
  [LeadsTableColumnName.ownerDetails]: {
    accessor: (lead: Lead) => lead[LeadsTableColumnName.ownerDetails],
    Cell: ({ value }) => <span>{value}</span>,
  },
  [LeadsTableColumnName.applicationMode]: {
    accessor: (lead: Lead) => getLeadApplication(lead)?.mode,
    Cell: ({ value }) => (value ? <ApplicationModeBadge mode={value} /> : ''),
  },
  [LeadsTableColumnName.lineOfBusiness]: {
    accessor: (lead: Lead) => getLeadApplication(lead)?.lineOfBusiness,
    Cell: ({ value }) => (value ? <ApplicationLineOfBusinessBadge lineOfBusiness={value} /> : ''),
  },
};

export function getRenderedLeadTableColumnsInfo(
  userPermissions: Permission[],
  columns: LeadTableColumnInfo[],
  t: (key: string) => string,
): RenderedLeadTableColumnInfo[] {
  const filteredColumns = columns.filter((column) => {
    const defaultColumn = standardColumnsDefault[column.name];
    const columnPermission = column.permission || defaultColumn.permission;
    if (!columnPermission) return true;
    return userPermissions.includes(columnPermission);
  });
  return filteredColumns.map((column) => {
    const defaultColumn = standardColumnsDefault[column.name];
    const defaultColumnAccessor = defaultColumn?.accessor;
    const defaultColumnCell = defaultColumn?.Cell;
    const titleKey = column.title ?? columnToDefaultTitleMapping[column.name];

    return {
      Header: t(`leadTableColumns.${titleKey}`),
      Cell: defaultColumnCell,
      accessor: defaultColumnAccessor,
      disableSortBy: !columnToSortingFieldMapping[column.name],
      id: column.name,
    };
  });
}

export function buildLeadFieldsToFetch(columns: LeadTableColumnInfo[]): string[] {
  const columnNames = columns.map((column) => column.name);
  const filteredColumnToLeadsFieldsMapping = _.pick(columnToLeadsFieldsMapping, columnNames);
  return _.flow([_.values, _.flatten, _.uniq])(filteredColumnToLeadsFieldsMapping);
}
