import ReactHtmlParser from 'html-react-parser';
import { isValidElement, ReactElement, ReactNode } from 'react';

import { FormControl, FormControlLabel, Radio as MuiRadio, RadioGroup as MuiRadioGroup } from '@breathelife/mui';
import { StyledFormControlLabel } from '@breathelife/ui-components';

import Typography from '../../Components/Typography';
import styled from '../../Styles/themed-styled-components';

type RadioGroupProps<T = string> = {
  name: string;
  label?: string;
  value?: T;
  onChange: (value: T) => void;
  children: ReactNode;
};

type RadioProps<T = string> = {
  label: string | ReactElement | null;
  value: T;
  disabled?: boolean;
  className?: string;
};

const StyledFormControl = styled(FormControl)`
  width: 100%;
`;

const StyledRadioFormControlLabel = styled(FormControlLabel)`
  background-color: ${(props) => props.theme.colors.grey[20]};
  border-radius: 4px;
  margin: unset !important;
  padding-right: 8px;
  padding-top: 4px;
  padding-bottom: 4px;
  width: 100%;

  &&&&& .MuiFormControlLabel-label {
    padding-bottom: 2px;
  }
`;

const StyledRadio = styled(MuiRadio)`
  & > span {
    background-color: ${(props) => props.theme.colors.grey[0]};
  }
`;

export function Radio<T extends string>(props: RadioProps<T>): ReactElement {
  const isLabelAnElement = isValidElement(props.label);

  return (
    <StyledRadioFormControlLabel
      value={props.value}
      control={<StyledRadio color='primary' />}
      label={
        <Typography variant='body3' grey={props.disabled ? 60 : 90}>
          {isLabelAnElement ? props.label : null}
          {!isLabelAnElement && props.label ? ReactHtmlParser(props.label as string) : null}
        </Typography>
      }
      disabled={props.disabled}
      className={props.className}
    />
  );
}

export default function RadioGroup<T extends string>(props: RadioGroupProps<T>): ReactElement {
  return (
    <StyledFormControl fullWidth variant='outlined'>
      <StyledFormControlLabel
        showError={false}
        control={
          <MuiRadioGroup
            onChange={(event) => props.onChange(event.target.value as T)}
            // We want to have a controlled radio group, but we might have an undefined value at start
            // to force a user to select a value. Without this empty string, a warning will be displayed in the console
            // saying that we are trying to change an uncontrolled radio group to a controlled radio group.
            value={props.value || ''}
            aria-label={props.label}
            name={props.name}
          >
            {props.children}
          </MuiRadioGroup>
        }
        label={props.label && ReactHtmlParser(props.label)}
        labelPlacement='top'
      />
    </StyledFormControl>
  );
}
