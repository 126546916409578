import { ReactElement, Fragment, useEffect, useState, useCallback } from 'react';
import { useTranslation } from 'react-i18next';

import { Box, Divider } from '@breathelife/mui';
import { DocumentVariant, FileTemplate, FileTemplateOrigin, StoredFileDocType } from '@breathelife/types';

import { Button } from '../../../../Components/Button/Button';
import { SubmitButton } from '../../../../Components/Button/SubmitButton';
import { IconButton } from '../../../../Components/Controls';
import { Icon } from '../../../../Components/Icons';
import Typography from '../../../../Components/Typography';
import { DocumentTemplateValidation } from '../../../../Helpers/inputValidation/form/documentTemplate';
import { CreateFileTemplateData } from '../../../../Services/FileTemplateService';
import { DocumentEditorForm, DocumentEditorFormFields } from './DocumentEditorForm';
import { DocumentRulesView } from './DocumentRule/DocumentRulesView';
import { FileTemplateRecipient } from './FileTemplateRecipient/FileTemplateRecipient';

const defaultFormFields: DocumentEditorFormFields = {
  name: '',
  origin: FileTemplateOrigin.ExternalStatic,
  externalUrl: '',
  docType: StoredFileDocType.Attachment,
  identifier: '',
  isRequired: true,
  variant: 'none',
  engName: '',
  frName: '',
};

function transformDataToFormFields(data: FileTemplate): DocumentEditorFormFields {
  return {
    ...data,
    identifier: data.identifier || '',
    externalUrl: data.externalUrl || '',
    variant: data.variant || 'none',
    isRequired: data.isRequired,
    engName: data.localizedName.en,
    frName: data.localizedName.fr,
  };
}

type Props = {
  onClose: () => void;
  isSavingTemplate?: boolean;
  fileTemplate?: FileTemplate;
  fileTemplateId?: string;
  onSubmit: (documentField: Partial<CreateFileTemplateData>) => void;
  onArchive?: (fileTemplateId: string) => void;
};

export function DocumentEditor(props: Props): ReactElement {
  const { t } = useTranslation();
  const { onClose, fileTemplateId, isSavingTemplate, onArchive } = props;

  const [fileTemplate, setFileTemplate] = useState(
    props.fileTemplate ? transformDataToFormFields(props.fileTemplate) : defaultFormFields,
  );

  const isDocumentFormValid = DocumentTemplateValidation.getFormSchema().isValidSync(fileTemplate);
  const isSaveBtnDisabled = !isDocumentFormValid;

  const onSave = async (): Promise<void> => {
    if (!isDocumentFormValid) {
      return;
    }

    const submissionData: CreateFileTemplateData = {
      ...fileTemplate,
      origin: fileTemplate.origin as FileTemplateOrigin,
      docType: fileTemplate.docType as StoredFileDocType,
      identifier: fileTemplate.identifier || null,
      externalUrl: fileTemplate.externalUrl || null,
      isRequired: fileTemplate.isRequired,
      variant: fileTemplate.variant === 'none' ? null : (fileTemplate.variant as DocumentVariant),
      localizedName: { en: fileTemplate.engName, fr: fileTemplate.frName },
    };

    await props.onSubmit(submissionData);
  };

  useEffect(() => {
    setFileTemplate(props.fileTemplate ? transformDataToFormFields(props.fileTemplate) : defaultFormFields);
  }, [props.fileTemplate]);

  const onArchiveDocument = useCallback(() => {
    return fileTemplateId && onArchive && onArchive(fileTemplateId);
  }, [fileTemplateId, onArchive]);

  return (
    <Fragment>
      <Box m={2}>
        <Box display='flex' justifyContent='space-between'>
          <Typography variant='h2'>{t('admin.documentDetailManagement.title')}</Typography>
          <Box display='flex' justifyContent='space-between' gridGap={8}>
            <IconButton
              icon={<Icon name='archive' alt={t('cta.archive')} />}
              title={t('cta.archive')}
              disabled={Boolean(props.fileTemplate?.archived)}
              onClick={onArchiveDocument}
            />

            <Button color='primary' variant='outlined' onClick={onClose}>
              {t('cta.cancel')}
            </Button>

            <SubmitButton
              data-testid='document-editor-save'
              disabled={isSaveBtnDisabled}
              isLoading={isSavingTemplate}
              onClick={onSave}
            >
              {t('cta.save')}
            </SubmitButton>
          </Box>
        </Box>
        <Box mt={2}>
          <DocumentEditorForm fileTemplate={fileTemplate} onChange={setFileTemplate} />
        </Box>
      </Box>
      <Divider />

      {fileTemplateId && (
        <Fragment>
          <DocumentRulesView fileTemplateId={fileTemplateId} />
          <Divider />
        </Fragment>
      )}

      {fileTemplateId && <FileTemplateRecipient fileTemplateId={fileTemplateId} />}
    </Fragment>
  );
}
