import { ReactElement, useContext, useMemo } from 'react';
import { CarrierContext } from '../../../Context/CarrierContext';
import Urls from '../../../Navigation/Urls';
import { Navigate, useNavigate, useLocation, Outlet } from 'react-router-dom';
import { Tabs as TabsComponent, TabType } from '../../../Components/Tabs/Tabs';
import { useTranslation } from 'react-i18next';
import styled from '../../../Styles/themed-styled-components';
import { Box } from '@breathelife/ui-components';
import { useCarrierContext } from '../../../Hooks';
import { QuestionnaireVersionFilterContextProvider } from '../../../Context/QuestionnaireVersionFilterContextProvider';

const TabContent = styled.div`
  display: flex;
  flex-grow: 1;
  ${(props) => `border-top: 1px solid ${props.theme.colors.primary['default']};`}
`;

export function EntityPage(): ReactElement {
  const [t] = useTranslation();
  const { features } = useCarrierContext();

  const { pathname } = useLocation();
  const navigate = useNavigate();

  const tabTypes: TabType[] = useMemo(() => {
    const tabs = [
      {
        label: t(`admin.entityMappings.tabs.entityMapping`),
      },
    ];
    if (features.entityMappings?.enabled && features.entityMappings.carrierDefinedEntities.enabled) {
      tabs.push({
        label: t(`admin.entityMappings.tabs.customEntity`),
      });
    }
    return tabs;
  }, [t, features]);

  const { entityMappings } = useContext(CarrierContext);
  if (!entityMappings || !entityMappings.enabled) {
    return <Navigate to={Urls.questionnaireManagement.fullPath} replace />;
  }

  return (
    <Box display='flex' flexDirection='column' flexGrow={1}>
      <div>
        <TabsComponent
          tabs={tabTypes}
          value={pathname === Urls.entityManagementMapping.fullPath ? 0 : 1}
          onChange={(_, index) =>
            index === 1
              ? navigate(Urls.entityManagementEntity.fullPath)
              : navigate(Urls.entityManagementMapping.fullPath)
          }
        />
      </div>

      <TabContent>
        <QuestionnaireVersionFilterContextProvider>
          <Outlet />
        </QuestionnaireVersionFilterContextProvider>
      </TabContent>
    </Box>
  );
}
