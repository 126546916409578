import { Box } from '@breathelife/mui';
import { ReactElement } from 'react';

import { BlueprintConditionValue, BooleanOperator, ConsiderationBlueprint } from '@breathelife/types';

import { QuestionnaireNodeIds } from '../../../../Helpers/questionnaireEditor/questionnaireNodeIds';
import { DetailViewType } from '../../../../Models/Layout';
import { SalesDecisionRuleDetailHeader } from './SalesDecisionRuleDetailHeader';
import { SalesDecisionRuleEditor } from './SalesDecisionRuleEditor';

type Props = {
  onDrawerClose: () => void;
  formState: ConsiderationBlueprint & { identifier: string };
  onFormUpdate: (data: Partial<ConsiderationBlueprint>) => void;
  onConditionUpdate: (data: BlueprintConditionValue, path: string) => void;
  onRuleIdentifierUpdate: (data: string) => void;
  onBooleanOperatorChange: (booleanOperator: BooleanOperator, path: string) => void;
  onAddCondition: (path: string) => void;
  onAddNestedCondition: (path: string) => void;
  onRemoveCondition: (path: string) => void;
  onClickSave: () => void;
  isFormValid: boolean;
  onDelete: () => void;
  ruleCanBeDeleted: boolean;
  questionnaireNodeIds: QuestionnaireNodeIds;
  viewType: DetailViewType;
};

export function SalesDecisionRuleDetailView({
  onDrawerClose,
  formState,
  onFormUpdate,
  onClickSave,
  isFormValid,
  onDelete,
  ruleCanBeDeleted,
  questionnaireNodeIds,
  onConditionUpdate,
  onBooleanOperatorChange,
  onAddCondition,
  onAddNestedCondition,
  onRemoveCondition,
  viewType,
  onRuleIdentifierUpdate,
}: Props): ReactElement {
  return (
    <Box display='flex' flexDirection='column' justifyContent='space-between' height='100%'>
      <SalesDecisionRuleDetailHeader
        onDrawerClose={onDrawerClose}
        onClickSave={onClickSave}
        isFormValid={isFormValid}
        onDelete={onDelete}
        ruleCanBeDeleted={ruleCanBeDeleted}
        viewType={viewType}
      />
      <SalesDecisionRuleEditor
        formState={formState}
        onFormUpdate={onFormUpdate}
        questionnaireNodeIds={questionnaireNodeIds}
        onConditionUpdate={onConditionUpdate}
        onBooleanOperatorChange={onBooleanOperatorChange}
        onAddCondition={onAddCondition}
        onAddNestedCondition={onAddNestedCondition}
        onRemoveCondition={onRemoveCondition}
        onRuleIdentifierUpdate={onRuleIdentifierUpdate}
      />
    </Box>
  );
}
