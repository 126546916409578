import i18next from 'i18next';
import { AxiosError } from 'axios';
import { CreateEntityData, Entity, UpdateEntityData } from '@breathelife/types';
import { UseMutationResult, useMutation, useQueryClient } from 'react-query';
import { useDispatch } from 'react-redux';
import ApiService from '../../../Services/ApiService';
import { notificationSlice } from '../../../ReduxStore/Notification/NotificationSlice';

type UseCreateEntityMutation = () => UseMutationResult<Entity, AxiosError<any>, CreateEntityData>;
export const useCreateEntityMutation: UseCreateEntityMutation = () => {
  const dispatch = useDispatch();
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: async (data: CreateEntityData): Promise<Entity> => {
      const response = await ApiService.admin.createEntity(data);
      return response.data;
    },

    onSuccess: async (data) => {
      const message = i18next.t('admin.entityMappings.createEntitySuccess', { name: data.name });
      dispatch(notificationSlice.actions.setSuccess({ message }));

      queryClient.setQueryData(['entity', data.id], data);

      await queryClient.invalidateQueries(['entities', data.questionnaireVersionId]);
    },

    onError: (__error, variables) => {
      const message = i18next.t('admin.entityMappings.createEntityFail', { name: variables.name });
      dispatch(notificationSlice.actions.setError({ message }));
    },
  });
};

type UseUpdateEntityMutation = () => UseMutationResult<Entity, AxiosError<any>, { id: string } & UpdateEntityData>;
export const useUpdateEntityMutation: UseUpdateEntityMutation = () => {
  const dispatch = useDispatch();
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: async ({ id, ...entity }): Promise<Entity> => {
      const response = await ApiService.admin.updateEntity({ id, entity });
      return response.data;
    },

    onSuccess: async (data) => {
      const message = i18next.t('admin.entityMappings.updateEntitySuccess');
      dispatch(notificationSlice.actions.setSuccess({ message }));

      queryClient.setQueryData(['entity', data.id], data);

      await queryClient.invalidateQueries(['entities', data.questionnaireVersionId]);
    },

    onError: () => {
      const message = i18next.t('admin.entityMappings.updateEntityFail');
      dispatch(notificationSlice.actions.setError({ message }));
    },
  });
};

type UseDeleteEntityMutation = () => UseMutationResult<Entity, AxiosError<any>, { id: string; name: string }>;
export const useDeleteEntityMutation: UseDeleteEntityMutation = () => {
  const dispatch = useDispatch();
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: async (data): Promise<Entity> => {
      const response = await ApiService.admin.deleteEntity(data.id);
      return response.data;
    },

    onSuccess: async (entity) => {
      const message = i18next.t('admin.entityMappings.deleteEntitySuccess', { name: entity.name });
      dispatch(notificationSlice.actions.setSuccess({ message }));

      await queryClient.invalidateQueries(['entities', entity.questionnaireVersionId]);
    },

    onError: (__error, variables) => {
      const message = i18next.t('admin.entityMappings.deleteEntityFail', { name: variables.name });
      dispatch(notificationSlice.actions.setError({ message }));
    },
  });
};
