import i18next from 'i18next';
import { useQuery, UseQueryOptions, UseQueryResult } from 'react-query';
import { useDispatch } from 'react-redux';

import { Entity, GetEntityMappingParams, GetEntityMappingReturnValue, Namespace } from '@breathelife/types';

import { QueryId } from '../../../ReactQuery/common/common.types';
import { notificationSlice } from '../../../ReduxStore/Notification/NotificationSlice';
import { getEntityMapping } from '../../../Services/EntityMappingService';
import ApiService from '../../../Services/ApiService';

type EntityMappingQuery = Partial<{ questionnaireVersionId: string; namespace: Namespace; mapping: string }>;

export function isCompleteEntityMappingRequestOptions(
  mappingOptions: EntityMappingQuery,
): mappingOptions is GetEntityMappingParams {
  return Boolean(mappingOptions.mapping && mappingOptions.namespace && mappingOptions.questionnaireVersionId);
}

export function useGetEntityMappingQuery(
  fetchOptions: EntityMappingQuery,
  options?: UseQueryOptions<GetEntityMappingReturnValue | undefined>,
): UseQueryResult<GetEntityMappingReturnValue | undefined> {
  const dispatch = useDispatch();
  const query = [
    QueryId.entityMappings,
    fetchOptions.mapping,
    fetchOptions.namespace,
    fetchOptions.questionnaireVersionId,
  ];

  return useQuery<GetEntityMappingReturnValue | undefined>(
    query,
    () => {
      if (isCompleteEntityMappingRequestOptions(fetchOptions)) {
        return getEntityMapping(fetchOptions);
      }
      return;
    },
    {
      ...options,
      enabled: isCompleteEntityMappingRequestOptions(fetchOptions),
      onError: (error: any) => {
        options?.onError?.(error);
        dispatch(
          notificationSlice.actions.setError({
            message: i18next.t('notifications.failedToFetchEntity'), // TODO proper text
          }),
        );
      },
    },
  );
}

export const useGetEntityMappingDependenciesQuery = (entityMappingId: string): UseQueryResult<Entity[]> => {
  return useQuery({
    enabled: Boolean(entityMappingId),

    queryKey: [QueryId.entityMappingDependencies, entityMappingId],

    queryFn: async (): Promise<Entity[]> => {
      const response = await ApiService.admin.getEntityMappingDependencies(entityMappingId);
      return response.data;
    },
  });
};
