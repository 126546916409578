import { ReactElement, useContext } from 'react';
import { useTranslation } from 'react-i18next';

import { Entity, EntityMapping } from '@breathelife/types';
import { useGetEntitiesQuery } from '../../../../ReactQuery/Admin/Entities/entities.queries';
import { useGetEntityMappingDependenciesQuery } from '../../../../ReactQuery/Admin/EntityMappings/entityMappings.queries';
import {
  useAddEntityMappingDependencyMutation,
  useRemoveEntityMappingDependencyMutation,
} from '../../../../ReactQuery/Admin/EntityMappings/entityMappings.mutations';
import { AutoCompleteMultiple } from '@breathelife/ui-components';
import { EntityMappingContext } from '../../../../Context/EntityMappingContextProvider';

type Props = {
  entityMapping: EntityMapping;
};

export function EntityMappingDependencies(props: Props): ReactElement {
  const { entityMapping } = props;
  const { mappingCodeHasChanged } = useContext(EntityMappingContext);
  const { data: qvEntities = [] } = useGetEntitiesQuery(entityMapping.questionnaireVersionId);
  const { data: dependencies = [] } = useGetEntityMappingDependenciesQuery(entityMapping.id);
  const addMutation = useAddEntityMappingDependencyMutation();
  const removeMutation = useRemoveEntityMappingDependencyMutation();

  const { t } = useTranslation();

  const items = qvEntities.filter((entity) => entity.name !== entityMapping.mapping);
  const selectedItems = qvEntities
    .filter((entity) => entity.name !== entityMapping.mapping)
    .filter((entity) => dependencies.map((d) => d.id).includes(entity.id));

  const addDependency = (dependencies: Entity[]): void => {
    const addedEntity = dependencies.find((d) => !selectedItems.find((s) => s.id === d.id));
    if (!addedEntity) return;
    const entityMappingDependencies = { entityMappingId: entityMapping.id, entityId: addedEntity.id };
    addMutation.mutate({ entityMapping, entityMappingDependencies });
  };

  const removeDependency = (dependencies: Entity[]): void => {
    const removedEntity = selectedItems.find((s) => !dependencies.find((d) => d.id === s.id));
    if (!removedEntity) return;
    const entityMappingDependencies = { entityMappingId: entityMapping.id, entityId: removedEntity.id };
    removeMutation.mutate({ entityMapping, entityMappingDependencies });
  };

  const onChange = (dependencies: Entity[]): void => {
    const isAddingDep = dependencies.length > selectedItems.length;
    isAddingDep ? addDependency(dependencies) : removeDependency(dependencies);
  };

  return (
    <AutoCompleteMultiple
      label={t('admin.entityMappings.dependenciesLabel')}
      disabled={mappingCodeHasChanged}
      optionInfo={{
        items,
        selectedItems,
        onChange,
        toOption: (entity) => ({ label: entity.name, value: entity.id }),
      }}
    />
  );
}
