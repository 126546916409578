import { Theme } from '@breathelife/types';
import ApiService from './ApiService';

export async function fetchThemes(): Promise<Theme[]> {
  const response = await ApiService.fetchThemes();
  return response.data;
}

export async function updateTheme(themeId: string, data: Partial<Theme>): Promise<Theme> {
  const response = await ApiService.updateTheme(themeId, data);
  return response.data;
}
